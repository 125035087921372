export const textList = [
  {
    title: "Why we process personal data",
    body: `We gather and process your Personal Data in order to provide you with the best services and user experience, as well as to develop, improve and customize the Service and its functionalities, and to meet the required safety requirements and other statutory requirements. We may use your Personal Data for (i) solving your problems and responding to your requests, problems and concerns; (ii) sending you in-product communications via bot or other in-product messaging tools; (iii) sending you e-mail messages for service and administrative purposes.
  We further process your Personal Data to provide better and more personalized offers and services. Personal Data can for example be treated, coexist, segmented and analysed in order to, through targeted marketing actions by us or our partners, provide information, offers or recommendations of goods and services, which are customized according to user preferences, behaviours, needs or lifestyle. We will keep you up to date on the latest improvements and features of the Service by sending you our newsletter. If you do not want receive any such targeted marketing actions from us, you can opt out by unsubscribing from the newsletter.`,
  },
  {
    title: "Data processor and third parties",
    body: `We use third party service providers for providing customer support and improved features of our Application and the Service. Some of our partners and suppliers send and/or store data outside the EU/EEA. As a consequence, your Personal Data may be transferred to, or stored in, a country outside the EU/EEA. However, it is always VELV, as the data collector, that is responsible for your Personal Data. We strive to, at our best efforts, achieve and uphold an adequate level of integrity for your Personal Data as required by law. At our best efforts, we employ the EU model contractual clauses for the transfer of personal data to third countries. For more information about these clauses, please visit Model Contracts for the transfer of personal data to third countries. For the above stated purposes in section 3, we may transfer, share and disclose your Personal Data to our partners, suppliers and other third parties within and outside the EU/EEA where you may have less legal rights in relation to your personal data. You understand and agree that the privacy policy of third parties will govern all use of information provided to third parties in accordance with this Privacy Policy. Personal data may be disclosed if it is necessary in order to comply with applicable legal requirements or requirements from authorities, to preserve our legal interests or to detect, prevent, or pay attention to fraud and other security or technical issues. By accepting this Privacy Policy, you agree to that your Personal Data may be transferred to, shared with, processed and stored by VELV&rsquo;s partners, suppliers and any other third parties with business operations within and outside the EU/EEA as outlined in this Privacy Policy.`,
  },
  {
    title: "Changes to the privacy policy",
    body: `If we make changes to this Privacy Policy, we will notify you by publication to www.velvmusic.com. If the changes are material, we will provide you with additional, prominent notice as appropriate under the circumstances and, where required under applicable law, ask for your consent.`,
  },
  {
    title: "Protection of personal data",
    body: `We maintain administrative, technical and physical safeguards in an effort to protect against unauthorized access, use, modification and disclosure of personal information in our custody and control. To provide you with an increased level of security online, access to your personal information on certain of our websites may be protected with a password you select. We strongly recommend that you do not disclose your password to anyone. We will never ask you for your password in any unsolicited communication.`,
  },
  {
    title: "Children's information",
    body: `VELV is not directed to children under 12 years of age. If You are at least 12, but are still a minor (this depends on where you live), You must have your parent or legal guardian's permission to use the Service. Please have him or her read this Agreement with you. If you learn that a minor child has provided us with Personal Data without your consent, please contact us.`,
  },
  {
    title: "Severability",
    body: `If any provision of this Privacy Policy is found to be held invalid by a court competent jurisdiction, that provision only will be limited to the minimum extent necessary and the remaining provisions will remain in full force and effect.`,
  },
  {
    title: "Limitation of liability",
    body: `VELV is not liable for unauthorized access, or loss, of user data caused for example by system errors or hackers.`,
  },
  {
    title: "Miscellaneous",
    body: `VELV is responsible for ensuring that your Personal Data is treated in accordance with applicable legislation. Once per calendar year, you are entitled to, by a signed application, obtain a free of cost transcript of the Personal Data we hold about you. We will at your request or by our own initiative correct information found to be incorrect or misleading. VELV has the right to assign and transfer its rights and obligations, in part or in full, under this Privacy Policy. Furthermore, VELV has the right to subcontract the performances of our duties. If the ownership of VELV's business changes, VELV may transfer your information, subscription and user account to another legal entity so that they can continue to provide you with the Services. This new legal entity that will hold your information, subscription and user account will still have to honor the commitments VELV and you have made in this Privacy Policy. These Terms were written in English. To the extent any translated version of these Terms conflicts with the English version, the English version shall prevail.`,
  },
  {
    title: "Governing law and dispute resolution",
    body: `This Privacy Policy shall be governed by and construed in accordance with Swedish laws, without regard to its conflict of law rules and excluding the UN Convention of International Sale of Goods. Any dispute, controversy or claim arising out of or in connection with this Privacy Policy or your use of the Service, or the breach, termination or invalidity thereof, shall be finally settled by Swedish courts of general jurisdiction, unless otherwise provided by mandatory law.`,
  },
  {
    title: "Contact information",
    body: `If you have any questions, complaints or comments about the Services you may contact us at: Info@velvmusic.com`,
  },
];

export const producerList = [
  {
    title: "Introduction",
    body: `By your registration or using this Music Platform you agree to the terms and conditions for use of the Music Platform and the services provided. If you do not agree to any of the provisions set out, you should not use this Music Platform`,
  },
  {
    title: "Description of the Music Platform",
    body: `The Music Platform VELV is a hosting service. Registered users of the Music Platform may submit, upload and post material such as audio, images, artwork and other material. You are solely liable for the material that you upload. You acknowledge and accept that VELV will submit and review the uploaded material for approval through screeners and that the material will only be distributed to third parties if it is in line with the musical brief and meets the quality approval of VELV.
  
    You may terminate your account at any time, provided that we are not obliged to return any material uploaded by you.`,
  },
  {
    title: "Ownership",
    body: `You agree and warrant that you have the responsibility for any and all audio recordings or other material that you upload on the Music Platform, and that you will indemnify VELV from any claims made from third parties regarding the use of such material including samples which have not been cleared or else infringe upon the right of any person or third party.
  
    You will continue to own your material and we will not claim any ownership rights. We are not responsible for any theft of the uploaded material by a third party and you are advised to have registered the copyrights in the underlying compositions with the collecting societies.`,
  },
  {
    title: "Rights Granted",
    body: `
    The rights granted to us by the upload include a worldwide, non-exclusive, royalty-free licence to use, distribute and perform that material you upload on the Music Platform for promoting and redistributing the material through to third parties.`,
  },
  {
    title: "Refund",
    body: `The audio files that pass the screening process are forwarded to the A&R or client, who then provides the final feedback status to the user through VELV's proprietary tracking system.
  
    Refunds are only issued if a music brief has been withdrawn by VELV, the client, or if VELV has failed to deliver according to the terms of this agreement."`,
  },
  {
    title: "Data protection, privacy and cookies",
    body: `All personal data that you provide to us in connection with your use of the Music Platform is collected, stored, used and disclosed by VELV in accordance with our Privacy Policy.
  
    By accepting these Terms of Use and using the Music Platform, you also accept the terms of the Privacy Policy, which can be found and read on our website at www.velvmusic.com .`,
  },
  {
    title: "Legal",
    body: `The Terms and Conditions shall be in accordance with the laws and jurisdiction of Sweden. Disputes arising out of, or in connection with this Agreement, shall be settled by the court of Stockholm as first instance.
  
    You will indemnify and keep us fully indemnified against losses and liabilities costs and expenses in respect of claims on the grounds that the material or any part thereof infringes the intellectual property rights of any third party.
    
    In no event will we be liable for any loss or damage including without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits arising out of, or in connection with, the use of this Music Platform.`,
  },
  {
    title: "Other terms",
    body: `Each of the Parties will remain independent of each other. Nothing in this Agreement constitutes an agency, employment, company or joint venture relationship of any kind between the Parties. Any rights not expressly granted above are specifically reserved by you.
  
    Every effort is made to keep the Music Platform up and running smoothly. However, VELV takes no responsibility for, and will not be liable for, the Music Platform being temporarily unavailable due to technical issues beyond our control.`,
  },
];

export const managerList = [
  {
    title: "Introduction",
    body: `VELV grants you access and the approval required to be able to post music briefs on the VELV Music Platform. By your registration to the VELV account you accept the Terms and Conditions for the services hereunder. These Terms and Conditions create an Agreement between you and VELV.`,
  },
  {
    title: "Services",
    body: `You are required to listen to the submitted audio demos and evaluate them before the self-imposed deadline you set.`,
  },
  {
    title: "Rights Granted",
    body: `You warrant that you have the right to convey the briefs and upload material on behalf of the artist, record company, film company or production company.
  
        VELV takes no responsibility for disputes regarding uploaded material from your account such as images, logos, audio files, videos and text information.`,
  },
  {
    title: "Period",
    body: `The Term of this Agreement will commence upon signing and continue thereafter until the VELV account is terminated by either you or VELV.`,
  },
  {
    title: "Confidentiality",
    body: `The Submitted audio demos may not be resold or licensed to third parties without the authors' approval.
  
        You ensure that illegal copying and/or plagiarism of the Submitted audio demos shall not occur and that you will not infringe the copyright in the Submitted audio demos.`,
  },
  {
    title: "Data protection, privacy and cookies",
    body: `All personal data that you provide to us in connection with your use of the Music Platform is collected, stored, used and disclosed by VELV in accordance with our Privacy Policy.
  
        By accepting these Terms of Use and using the Music Platform, you also accept the terms of the Privacy Policy, which can be found and read on our website at www.velvmusic.com.`,
  },
  {
    title: "Legal",
    body: `
        The Terms and Conditions shall be in accordance with the laws and jurisdiction of Sweden. Disputes arising out of, or in connection with this Agreement, shall be settled by the court of Stockholm as first instance.
        
        In no event will we be liable for any loss or damage including without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits arising out of, or in connection with, the use of this Music Platform.`,
  },
  {
    title: "Other terms",
    body: `Each of the Parties will remain independent of each other. Nothing in this Agreement constitutes an agency, employment, company or joint venture relationship of any kind between the Parties. Any rights not expressly granted above are specifically reserved by you.
  
        Every effort is made to keep the Music Platform up and running smoothly. However, VELV takes no responsibility for, and will not be liable for, the Music Platform being temporarily unavailable due to technical issues beyond our control.`,
  },
];

export const screenerList = [
  {
    title: "Introduction",
    body: `You have been selected by VELV to become part of the screener team for VELV´s Music Platform. By your registration to the VELV account you accept the Terms and Conditions for the screening services provided by you hereunder. These Terms and Conditions create an Agreement between you and VELV.`,
  },
  {
    title: "Services",
    body: `You are expected as a screener to listen carefully to the material that has been submitted to you and comply with all reasonable instructions given by VELV in connection with the performance of the assignment.`,
  },
  {
    title: "Period",
    body: `The Term of this Agreement will commence upon signing and continue thereafter until the VELV account is terminated by either you or VELV.`,
  },
  {
    title: "Termination",
    body: `Both parties will have the ability to terminate the Agreement without notice by closing the account. In the event that VELV is required to close the account, any remaining funds will be transferred to the specified bank account.`,
  },
  {
    title: "Fees",
    body: `VELV will pay you an hourly fee for the time devoted to listening to material as part of the Services hereunder. By using the service, you agree to our hourly fee and the timer system displayed in the wallet view. If you do not agree to any of the provisions set out, you should not use this Music Platform.
  
      Your earnings accumulate, and the minimum amount for withdrawal is 50 USD, which cannot be paid out in parts. The withdrawal process takes approximately 1-5 business days, and we will do our best to comply with this timeline.`,
  },
  {
    title: "Tax",
    body: `You will be responsible for the payment of all taxes, social costs and other amounts due in your country of domicile in connection with any payment made under or in connection with the Services. For screeners that are Swedish VELV will have to collect your personal number in order to be able to report to the Swedish Tax Agency how much earnings you have received in each year (swe: kontrolluppgift).`,
  },
  {
    title: "Confidentiality",
    body: `You guarantee that you will not to a third party communicate any trade secrets relating to the business of VELV and that you will not to a third party outside the scope of the VELV Music Platform distribute data information, tracks of music, images or any other material that you have received as part of the Services. In the event that such information or material as aforementioned is used or distributed by you without VELV’s permission then your account will be terminated by VELV and may lead to legal action by VELV.`,
  },
  {
    title: "Data protection, privacy and cookies",
    body: `All personal data that you provide to us in connection with your use of the Music Platform is collected, stored, used and disclosed by VELV in accordance with our Privacy Policy.
  
      By accepting these Terms of Use and using the Music Platform, you also accept the terms of the Privacy Policy, which can be found and read on our website at www.velvmusic.com.`,
  },
  {
    title: "Legal",
    body: `The Terms and Conditions shall be in accordance with the laws and jurisdiction of Sweden. Disputes arising out of, or in connection with this Agreement, shall be settled by the court of Stockholm as first instance.
  
      In no event will we be liable for any loss or damage including without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits arising out of, or in connection with, the use of this Music Platform.`,
  },
  {
    title: "Other terms",
    body: `Each of the Parties will remain independent of each other. Nothing in this Agreement constitutes an agency, employment, company or joint venture relationship of any kind between the Parties. Any rights not expressly granted above are specifically reserved by you.
  
      Every effort is made to keep the Music Platform up and running smoothly. However, VELV takes no responsibility for, and will not be liable for, the Music Platform being temporarily unavailable due to technical issues beyond our control.`,
  },
];
