import { useEffect, useRef, useState } from "react";

export function AudioPlayer({
  rewindIcon,
  playIcon,
  pauseIcon,
  source,
  demo,
  addScreeningEvent,
  addScreeningEventDuration,
  autoPlay = false,
  showSkipIcon = false,
  firstRender,
  setIndex,
  index = 0,
  totalDemos = 0,
  forcePause = false,
  updateDropCheckbox,
}) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [currentTimeDisplay, setCurrentTimeDisplay] = useState("0:00");
  const [totalTimeDisplay, setTotalTimeDisplay] = useState("0:00");
  const [isSongLoading, setSongLoading] = useState(true);
  const audio = useRef();
  const progressBar = useRef();

  useEffect(() => {
    if (autoPlay && !isPlaying && !firstRender) {
      audio.current.addEventListener("loadedmetadata", function () {
        addScreeningEvent(demo?.pitchId, audio.current?.duration);
      });
      setIsPlaying(true);
    }
    audio.current.addEventListener("timeupdate", () => {
      const currentTime = audio?.current?.currentTime;
      const duration = audio?.current?.duration;

      if (currentTime === duration) {
        updateDropCheckbox && updateDropCheckbox();
        setIsPlaying(false);
      }
      const currentMinutes = Math.floor(currentTime / 60);
      const currentSeconds = Math.floor(currentTime % 60);
      const totalMinutes = Math.floor(duration / 60);
      const totalSeconds = Math.floor(duration % 60);

      setCurrentTimeDisplay(
        `${currentMinutes}:${currentSeconds < 10 ? "0" : ""}${currentSeconds}`
      );
      setTotalTimeDisplay(
        `${totalMinutes}:${totalSeconds < 10 ? "0" : ""}${totalSeconds}`
      );

      const progress = (currentTime / duration) * 100;
      if (progress) progressBar.current.style.width = `${progress}%`;
    });
  }, [source]);

  const handlePlay = () => {
    if (!source) return;
    if (isPlaying) {
      updateDropCheckbox && updateDropCheckbox();
      audio.current.pause();
    } else {
      addScreeningEvent(demo?.pitchId, audio.current?.duration);
      audio.current.play();
    }
    setIsPlaying((prev) => !prev);
  };

  function skipTime(seconds) {
    if (!isNaN(audio.current?.duration)) {
      const newTime = Math.min(
        audio.current.currentTime + seconds,
        audio.current?.duration
      );
      audio.current.currentTime = newTime;
    }
  }
  useEffect(() => {
    // Disable autoplay on the first render
    if (audio.current && firstRender) {
      audio.current.pause();
    }
  }, [firstRender]);

  const handleTimeUpdate = () => {
    if (audio.current.currentTime <= 121) {
      setCurrentTime(audio.current.currentTime);
    }
  };

  useEffect(() => {
    const audioFile = audio.current;
    // Variable to store the last rounded time
    let lastRoundedTime = -1;
    audioFile.addEventListener("timeupdate", () => {
      // Get the current playback time in seconds
      const currentTime = Math.round(audioFile.currentTime);
      // Check if the current time is a whole number (i.e., a new second)
      if (
        currentTime !== lastRoundedTime &&
        currentTime % 1 === 0 &&
        currentTime > 0
      ) {
        lastRoundedTime = currentTime;

        addScreeningEventDuration(demo?.pitchId, currentTime);
      }
    });
    if (audio?.current?.currentTime) audio.current.currentTime = 0;

    return () => {
      audioFile.removeEventListener("timeupdate", handleTimeUpdate);
    };
  }, []);

  useEffect(() => {
    if (forcePause) {
      audio.current.pause();
      setIsPlaying(false);
    }
  }, [forcePause]);

  const handleCanPlayThrough = () => {
    setSongLoading(false);
  };

  useEffect(() => {
    const audioElement = audio.current;

    if (audioElement) {
      audioElement.addEventListener("canplaythrough", handleCanPlayThrough);

      return () => {
        audioElement.removeEventListener(
          "canplaythrough",
          handleCanPlayThrough
        );
      };
    }
  }, [source]);

  return (
    <section
      className={`w-full relative rounded-2xl mt-6 mb-4 lg:mb-4 justify-center flex flex-col items-center ml-0 left-0 ${
        !source ? "pointer-events-none" : ""
      }`}
    >
      {isSongLoading && (
        <div className="flex justify-center items-center text-white bg-black w-full h-full absolute rounded-lg z-50">
          Loading...
        </div>
      )}
      <audio
        id="audio"
        className="hidden"
        controls
        ref={audio}
        autoPlay={autoPlay}
      >
        <source src={source} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
      <div className="w-full flex items-center gap-6">
        <div className="left-0 top-0 text-neutral-500 font-bold leading-5">
          {currentTimeDisplay}
        </div>
        <div className="relative w-full -mt-1.5">
          <div className="w-full h-1.5 absolute bg-gray-500 rounded-[20px]" />
          <div
            className={`w-full h-1.5 absolute ${
              currentTime ? "bg-violet-600" : "bg-gray-500"
            } rounded-[20px]`}
            id="progress-bar"
            ref={progressBar}
          />
        </div>
        <div className="top-0 text-neutral-500 font-bold leading-5">
          {totalTimeDisplay}
        </div>
      </div>
      <div
        className={`px-2.5 pt-2 justify-start items-center gap-${
          showSkipIcon ? "6" : "12"
        } inline-flex`}
      >
        <div
          className="w-6 h-6 relative cursor-pointer"
          onClick={() => skipTime(-10)}
        >
          <img src={rewindIcon} className="cursor-pointer" alt="rewind back" />
          <div className="left-[5.8px] top-[2.5px] absolute text-neutral-500 font-bold leading-5 text-[10px]">
            10
          </div>
        </div>
        {showSkipIcon && (
          <button
            disabled={index <= 0}
            onClick={() => {
              if (index > 0) setIndex((prev) => prev - 1);
              setIsPlaying(false);
            }}
            className={`flex items-center ${index <= 0 && "opacity-20"}`}
          >
            <div className="h-7 w-1 bg-neutral-500 -mr-2 rounded-full"></div>
            <img
              src={playIcon}
              alt="skip before"
              className="w-10 h-10 rotate-180 cursor-pointer"
            />
          </button>
        )}
        <div className="w-11 h-11 relative">
          <button
            className="w-11 h-11 left-0 top-0 absolute rounded-full border-4 border-neutral-500 flex justify-center items-center"
            onClick={() => source && handlePlay()}
          >
            {isPlaying ? (
              <img
                alt="pause"
                className="w-8 h-8 absolute cursor-pointer p-1.5"
                src={pauseIcon}
              />
            ) : (
              <img
                src={playIcon}
                alt="play"
                className="w-8 h-8 absolute cursor-pointer"
              />
            )}
          </button>
        </div>

        {showSkipIcon && (
          <button
            disabled={index >= totalDemos - 1}
            onClick={() => {
              if (index < totalDemos - 1) setIndex((prev) => prev + 1);
              setIsPlaying(false);
            }}
            className={`flex items-center ${
              index >= totalDemos - 1 && "opacity-20"
            }`}
          >
            <img
              src={playIcon}
              alt="skip after"
              className="w-10 h-10 cursor-pointer"
            />
            <div className="h-7 w-1 bg-neutral-500 -ml-2 rounded-full"></div>
          </button>
        )}
        <div
          className="w-6 h-6 relative cursor-pointer"
          onClick={() => skipTime(10)}
        >
          <img
            src={rewindIcon}
            className="-scale-x-100 cursor-pointer"
            alt="rewind back"
          />
          <div className="left-[5.8px] top-[2.5px] absolute text-neutral-500 font-bold leading-5 text-[10px]">
            10
          </div>
        </div>
      </div>
    </section>
  );
}
