/* eslint-disable react-hooks/exhaustive-deps */
import {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { auth, db, messaging } from "config/firebase-config";
import { ScrollToTop, deleteCookie, parseFirebaseErrors } from "utils/helpers";
import velvLogo from "assets/images/velv-logo.gif";
import dropIcon from "assets/icons/drop-icon.svg";
import starIcon from "assets/icons/star-icon.svg";
import pitchesIcon from "assets/icons/pitches-icon.svg";
import arrowIcon from "assets/icons/arrow-icon.svg";
import addIcon from "assets/icons/plus-add-icon.svg";
import checkmarkIcon from "assets/icons/checkmark-icon.svg";
import homeIcon from "assets/icons/home-icon.svg";
import bellIcon from "assets/icons/bell-icon.svg";
import slidersIcon from "assets/icons/sliders-icon.svg";
import closeIcon from "assets/icons/close-icon.svg";
import hamburgerIcon from "assets/icons/hamburger-icon.svg";
import walletIcon from "assets/icons/wallet-icon.svg";
import betaIcon from "assets/images/beta.png";
import { ROLES_LIST } from "utils/constants";
import { AuthContext } from "context/AuthContext";
import {
  MenuSectionFilters,
  MenuSectionNotifications,
} from "components/Sidebar/Sidebar";
import { onMessage } from "firebase/messaging";
import { MenuDropdown } from "./MenuDropdown";

export function Header({ updatedUserImage = 0 }) {
  const { value } = useContext(AuthContext);
  const menuRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const [tabActive, setTabActive] = useState(1);
  const [showMenu, setShowMenu] = useState(false);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const [notifications, setNotifications] = useState([]);

  onMessage(messaging, (payload) => {
    setNotifications((prev) => [...prev, payload?.notification]);
  });

  useLayoutEffect(() => {
    switch (location.pathname) {
      case "/":
      case "/genre/newdrops":
      case "/genre/hiphopRap":
      case "/genre/other":
        setTabActive(1);
        break;
      case "/favorites":
        setTabActive(2);
        break;
      case "/my-pitches":
        setTabActive(3);
        break;
      case "/drop/add":
        setTabActive(4);
        break;
      case "/wallet":
        setTabActive(2);
        break;
      case "/drop/mydrops":
        setTabActive(2);
        break;
      case "/drop/myinbox":
        setTabActive(3);
        break;
      default:
        setTabActive(0);
        return;
    }
  }, [location]);

  const handleClick = (tab) => {
    setTabActive(tab);

    switch (tab) {
      case 1:
        navigate("/");
        break;
      case 2:
        navigate("/favorites");
        break;
      case 3:
        navigate("/my-pitches");
        break;
      case 4:
        navigate("/drop/add");
        break;
      default:
        return;
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      localStorage.removeItem("user");
      deleteCookie("user");
    } catch (error) {
      console.error("Error: ", parseFirebaseErrors(error?.code));
    }
  };

  const getItem = async (id) => {
    const docRef = doc(db, "users", id);
    try {
      setLoading(true);
      const user = JSON.parse(localStorage.getItem("user"));
      if (user) setUser(user);
      else {
        const snapshot = await getDoc(docRef);
        setUser(snapshot.data());
      }
    } catch (error) {
      console.log("Error", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showMenu]);

  const fetchData = async () => {
    try {
      await getItem(auth?.currentUser?.uid);
    } catch (error) {
      console.error("Error getting item:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [value]);

  useEffect(() => {
    if (updatedUserImage) {
      fetchData();
    }
  }, [updatedUserImage]);

  return (
    <>
      <div className="flex relative justify-between items-center px-0 lg:px-10 mt-6 lg:mt-14 h-[52px]">
        <img
          src={betaIcon}
          alt="beta"
          className="absolute -top-[2px] left-10 w-16 h-16 lg:-top-10 lg:left-0 lg:w-24 lg:h-24"
        />
        <ScrollToTop />
        <div className="hidden lg:flex xl:w-40"></div>
        <img
          src={hamburgerIcon}
          alt="hamburger"
          className="flex lg:hidden menu-icon active-icon"
          onClick={() => setShowSidebar(true)}
        />
        <MobileSideMenu
          showSidebar={showSidebar}
          setShowSidebar={setShowSidebar}
          setShowNotifications={setShowNotifications}
          showNotifications={showNotifications}
          notifications={notifications}
        />
        <div className="hidden lg:flex bg-[#000000] justify-start items-center text-white rounded-2xl font-semibold bg-opacity-40 backdrop-blur-sm max-w-[718px] mx-auto w-fit">
          {user?.profession === ROLES_LIST.PRODUCER ||
          user?.profession === ROLES_LIST.SONG_WRITER ||
          user?.profession === ROLES_LIST.SONG_WRITER_PRODUCER ? (
            <ProducerHeader tabActive={tabActive} handleClick={handleClick} />
          ) : null}
          {user?.profession === ROLES_LIST.SCREENER ? (
            <ScreenerHeader
              navigate={navigate}
              tabActive={tabActive}
              handleClick={handleClick}
            />
          ) : null}
          {user?.profession === ROLES_LIST.ANR ||
          user?.profession === ROLES_LIST.MANAGER ||
          user?.profession === ROLES_LIST.SUPERVISOR ||
          user?.profession === ROLES_LIST.CORPORATE ? (
            <AnrHeader
              tabActive={tabActive}
              handleClick={handleClick}
              navigate={navigate}
            />
          ) : null}
        </div>
        <div ref={menuRef}>
          <MenuDropdown
            showMenu={showMenu}
            setShowMenu={setShowMenu}
            loading={loading}
            user={user}
            onClickProfile={() => navigate("/account")}
            onClickLogout={handleLogout}
          />
        </div>
      </div>
      <div className="flex lg:hidden bg-[#000000] justify-start items-center text-white rounded-2xl font-semibold bg-opacity-40 backdrop-blur-sm mx-auto w-full mt-6 lg:mt-0">
        {user?.profession === ROLES_LIST.PRODUCER ||
        user?.profession === ROLES_LIST.SONG_WRITER ||
        user?.profession === ROLES_LIST.SONG_WRITER_PRODUCER ? (
          <ProducerHeader tabActive={tabActive} handleClick={handleClick} />
        ) : null}
        {user?.profession === ROLES_LIST.SCREENER ? (
          <ScreenerHeader
            navigate={navigate}
            tabActive={tabActive}
            handleClick={handleClick}
          />
        ) : null}
        {user?.profession === ROLES_LIST.ANR ||
        user?.profession === ROLES_LIST.MANAGER ||
        user?.profession === ROLES_LIST.SUPERVISOR ||
        user?.profession === ROLES_LIST.CORPORATE ? (
          <AnrHeader
            tabActive={tabActive}
            handleClick={handleClick}
            navigate={navigate}
          />
        ) : null}
      </div>
    </>
  );
}

function MobileSideMenu({
  showSidebar,
  setShowSidebar,
  setShowNotifications,
  showNotifications,
  notifications,
}) {
  const navigate = useNavigate();
  const [selectAll, setSelectAll] = useState(true);
  const [showFilters, setShowFilters] = useState(false);
  let countryFilters = JSON.parse(localStorage.getItem("filters")) || [];

  return (
    <div
      className={`flex flex-col w-full md:w-[375px] fixed px-8 h-screen top-0 bottom-0 pt-14 z-30 bg-[#141416] ease-in-out duration-300 cursor-pointer overflow-y-auto hide-scroll-bar ${
        showSidebar ? "left-0" : "-left-full"
      }`}
    >
      <section className="flex justify-between items-center mb-8">
        <img src={velvLogo} alt="velv" className="w-10" />
        <img
          src={closeIcon}
          alt="close"
          className="w-4 h-4 ml-auto"
          onClick={() => {
            if (showFilters) setShowFilters(false);
            if (showNotifications) setShowNotifications(false);
            setShowSidebar(false);
          }}
        />
      </section>

      {showNotifications && !showFilters && (
        <>
          <div
            className="flex gap-4 text-white items-center"
            onClick={() => setShowNotifications(false)}
          >
            <img
              src={arrowIcon}
              className="w-3.5 h-3.5 relative rotate-90"
              alt="right"
            />
            <p className="font-bold text-lg">Back</p>
          </div>
          <MenuSectionNotifications
            setSelectAll={setSelectAll}
            checkmarkIcon={checkmarkIcon}
            selectAll={selectAll}
            notifications={notifications}
          />
        </>
      )}

      {showFilters && !showNotifications && (
        <div>
          <div
            className="flex gap-4 text-white items-center"
            onClick={() => setShowFilters(false)}
          >
            <img
              src={arrowIcon}
              className="w-3.5 h-3.5 relative rotate-90"
              alt="right"
            />
            <p className="font-bold text-lg">Back</p>
          </div>
          <MenuSectionFilters
            setSelectAll={setSelectAll}
            checkmarkIcon={checkmarkIcon}
            selectAll={selectAll}
          />

          <button
            onClick={() => navigate(0)}
            className="text-center bg-purple w-full text-white rounded-full py-3 mt-8 font-bold text-sm mb-20"
          >
            Add filters
          </button>
        </div>
      )}
      {!showFilters && !showNotifications && (
        <section className="grid place-items-center mt-3 rounded-xl bg-[#23262F] border border-solid border-[#353945] shadow-lg">
          <label className="w-full">
            <input className="absolute scale-0" type="checkbox" />
            <div className="w-full block max-w-xs overflow-hidden pl-2 py-0 text-white rounded-b-none">
              <div
                onClick={() => {
                  setShowSidebar(false);
                  navigate("/");
                }}
                className="flex gap-3 h-14 cursor-pointer text-[10px] items-center font-bold text-gray-200 w-full justify-start align-middle my-2 px-1 rounded-xl ml-6"
              >
                <img src={homeIcon} alt="home" className="menu-icon" />
                <div className="w-[165.57px] text-gray-200 text-sm leading-5">
                  Home
                </div>
              </div>
              <div className="w-full h-px relative bg-zinc-700" />
              <div
                onClick={() => {
                  setShowFilters(false);
                  setShowNotifications(true);
                }}
                className="relative flex gap-3 h-14 cursor-pointer text-[10px] items-center font-bold text-gray-200 w-full justify-start align-middle ml-6"
              >
                {notifications?.length ? (
                  <div className="bg-red-500 rounded-full flex justify-center items-center w-4 h-4 text-sm text-white font-bold absolute top-2 left-3">
                    {notifications?.length}
                  </div>
                ) : null}
                <img src={bellIcon} alt="bell" className="menu-icon" />
                <div className="w-[165.57px] text-gray-200 text-sm leading-5">
                  Notifications
                </div>
              </div>
              <div className="w-full h-px relative bg-zinc-700" />
              <div
                onClick={() => {
                  setShowNotifications(false);
                  setShowFilters(true);
                }}
                className="flex gap-3 h-14 cursor-pointer text-[10px] items-center font-bold text-gray-200 w-full justify-start align-middle ml-6"
              >
                <div className="relative">
                  <img
                    src={slidersIcon}
                    alt="sliders"
                    className="menu-icon"
                    onClick={() => setShowFilters(true)}
                  />
                  {countryFilters?.length ? (
                    <div className="bg-purple rounded-full flex justify-center items-center w-4 h-4 text-sm text-white font-bold absolute -top-1 right-7 z-10 left-4">
                      {countryFilters?.length}
                    </div>
                  ) : null}
                </div>
                <div className="w-[165.57px] text-gray-200 text-sm leading-5">
                  Filter
                </div>
              </div>
            </div>
          </label>
        </section>
      )}
    </div>
  );
}
/* 
function ProfileItem({ name, title, active = false, user }) {
  const handleLoginAccount = async () => {
    try {
      await signInWithEmailAndPassword(
        auth,
        "vehid.trtak@scaleup.ba",
        "123456"
      );
      window.location.reload();
    } catch (error) {
      console.error("Issue login with this user", error);
    }
  };
  return (
    <div
      onClick={handleLoginAccount}
      className={`flex items-center rounded-xl cursor-pointer hover:bg-[#2D2F33] ${
        active ? "bg-[#2D2F33]" : ""
      }`}
    >
      <img
        src={user?.profileImage || avatarImg}
        alt="avatar"
        className="w-12 h-12 rounded-full"
      />
      <div className="mx-4 font-bold text-sm mr-28 text-white">
        {name}
        <p className="text-[#777E90]">{title}</p>
      </div>
      {active ? (
        <img
          src={checkmarkIcon}
          alt="arrow"
          className="purple-filter mr-4 w-6 h-6 ml-auto"
        />
      ) : null}
    </div>
  );
} */

function ProducerHeader({ tabActive, handleClick }) {
  return (
    <>
      <div
        className={`${tabActive === 1 && "tab-active"} tab`}
        onClick={() => handleClick(1)}
      >
        <img src={dropIcon} alt="drops" />
        Drops
      </div>
      <div
        className={`${tabActive === 2 && "tab-active"} tab`}
        onClick={() => handleClick(2)}
      >
        <img src={starIcon} alt="favorites" />
        Favorites
      </div>
      <div
        className={`${tabActive === 3 && "tab-active"} tab`}
        onClick={() => handleClick(3)}
      >
        <img src={pitchesIcon} alt="pitches" />
        My Pitches
      </div>
    </>
  );
}

function ScreenerHeader({ navigate, tabActive }) {
  return (
    <>
      <div
        className={`${tabActive === 1 && "tab-active"} tab`}
        onClick={() => navigate("/")}
      >
        <img src={dropIcon} alt="drops" />
        Drops
      </div>
      <div
        className={`${tabActive === 2 && "tab-active"} tab`}
        onClick={() => navigate("/wallet")}
      >
        <img src={walletIcon} alt="wallet" />
        Wallet
      </div>
    </>
  );
}

function AnrHeader({ tabActive, navigate }) {
  return (
    <>
      <div
        className={`${tabActive === 1 && "tab-active"} tab`}
        onClick={() => navigate("/drop")}
      >
        <img src={dropIcon} alt="drops" />
        Drops
      </div>
      <div
        className={`${tabActive === 4 && "tab-active"} tab`}
        onClick={() => navigate("/drop/add")}
      >
        <img src={addIcon} alt="pitches" />
        Add drop
      </div>
      <div
        className={`${tabActive === 2 && "tab-active"} tab`}
        onClick={() => navigate("/drop/mydrops")}
      >
        <img src={starIcon} alt="my-drops" />
        My Drops
      </div>
      <div
        className={`${tabActive === 3 && "tab-active"} tab`}
        onClick={() => navigate("/drop/myinbox")}
      >
        <img src={pitchesIcon} alt="my-inbox" />
        My Inbox
      </div>
    </>
  );
}
