import { Tooltip } from "react-tooltip";
import { generateRandomString } from "utils/helpers";

export function TooltipItem({
  icon,
  children,
  className,
  tooltipClassName,
  clickable = false,
  defaultAnchor = "my-anchor-element",
  ...rest
}) {
  const anchor = `${defaultAnchor}-${generateRandomString(5)}`;

  return (
    <>
      <img className={`${anchor} cursor-pointer ml-2 ${className}`} src={icon} alt="info" />
      <Tooltip anchorSelect={`.${anchor}`} {...rest} clickable={clickable}>
        <div
          className={`text-left px-2 py-1 text-white text-sm rounded-md right-auto lg:-right-1 ${tooltipClassName}`}
        >
          {children}
        </div>
      </Tooltip>
    </>
  );
}
