import { useNavigate } from "react-router-dom";
import checkmarkIcon from "assets/icons/checkmark-icon.svg";
import nogoIcon from "assets/icons/no-go-icon.svg";
import infoIcon from "assets/icons/info-icon.svg";

export function Status({ id, pitchId, data }) {
  const navigate = useNavigate();

  const checkStatus = (screening) => {
    if (!screening?.length) return "screening";
    if (screening[0]?.status === "nogo" && screening[1]?.status !== "pitch")
      return "nogo";
    if (screening[0]?.status === "nogo" && screening[1]?.status === "nogo")
      return "nogo";
    if (screening[screening?.length - 1]?.screenerLevel !== "level3")
      return "screening";
    if (screening[screening?.length - 1]?.status === "nogo") return "nogo";
    return "pitch";
  };

  return (
    <>
      <div className="flex text-xs lg:text-base lg:font-semibold mt-12 items-center">
        The progress of the pitching status
        <button
          className="text-center font-bold text-xs ml-5 pt-1"
          onClick={() =>
            navigate("/my-pitches/learn", {
              state: { id: id, pitchId: pitchId },
            })
          }
        >
          <img src={infoIcon} alt="info" className="w-5 h-5 lg:w-6 lg:h-6" />
        </button>
      </div>
      <div className="grid grid-cols-9 text-center max-w-[1120px] mt-12 text-xs lg:text-sm">
        <StatusItem text="Screening" status={"go"} showLine={false} />
        <StatusItem
          text="Pitched"
          status={
            checkStatus(data?.screenings) === "pitch" ||
            data?.result === "pitch"
              ? "go"
              : ""
          }
        />
        <StatusItem text="Hold" status={data?.result === "hold" ? "go" : ""} />
        <StatusItem text="Cut" status={data?.result === "cut" ? "go" : ""} />
        <StatusItem
          text="No Go"
          status={
            checkStatus(data?.screenings) === "nogo" || data?.result === "nogo"
              ? "no"
              : ""
          }
        />
      </div>
    </>
  );
}

function StatusItem({ text, status, showLine = true }) {
  return (
    <>
      {showLine && (
        <div className="w-auto -mx-4 border-b border-solid border-purple h-4 lg:h-5 relative -z-10"></div>
      )}
      <div className="flex flex-col justify-start items-center">
        <div className="border border-solid border-purple rounded-full p-2  w-8 h-8 lg:h-10 lg:w-10 bg-black">
          {status === "go" ? <img src={checkmarkIcon} alt="checkmark" /> : null}
          {status === "no" ? <img src={nogoIcon} alt="nogo" /> : null}
        </div>
        <p className="mt-2">{text}</p>
      </div>
    </>
  );
}
