import { Timestamp } from "firebase/firestore";
import { useEffect, useState } from "react";
import { calculateTimeDifference, displayDate } from "utils/helpers";
import noImage from "assets/images/no-image.png";

const PreviewForm = ({ formValues, sections, image }) => {
  const [date, setDate] = useState(null);

  useEffect(() => {
    const startDate = Timestamp.fromDate(new Date());
    const endDate = Timestamp.fromDate(formValues?.expireTime);
    const date = calculateTimeDifference(startDate, endDate);
    setDate(date);
  }, [formValues]);

  return (
    <div className="max-w-[644px] w-full flex flex-col items-center gap-6 lg:gap-10">
      <section className="w-full relative lg:ml-[650px]">
        <img
          src={image || noImage}
          alt="cover"
          className="object-cover w-full lg:w-[260px] h-[366px] lg:h-[266px] left-0 top-0 bg-gradient-to-r from-black to-black rounded-3xl border border-violet-600"
        />
        <div className="w-full lg:w-[260px] h-[73px] absolute bottom-0 p-[5.91px] left-0 top-[293px] lg:top-[193px] bg-black bg-opacity-80 rounded-xl border border-violet-600 justify-center items-center gap-[7.39px] inline-flex">
          <div className="grow shrink basis-0 h-[37px] justify-center items-center gap-[5.91px] flex">
            <div className="w-[192.07px] flex-col justify-start items-center inline-flex">
              <div className="self-stretch justify-between items-start inline-flex">
                <div className="px-[5.91px] justify-center items-center gap-[7.39px] flex">
                  <div className="text-white text-lg font-normal font-digitalnumbers">
                    {displayDate(date?.day)}
                  </div>
                </div>
                <div className="px-[5.91px] justify-center items-center gap-[7.39px] flex">
                  <div className="text-white text-lg font-normal font-digitalnumbers">
                    {displayDate(date?.hour)}
                  </div>
                </div>
                <div className="px-[5.91px] justify-center items-center gap-[7.39px] flex">
                  <div className="text-white text-lg font-normal font-digitalnumbers">
                    {displayDate(date?.minute)}
                  </div>
                </div>
                <div className="px-[5.91px] justify-center items-center gap-[7.39px] flex">
                  <div className="text-white text-lg font-normal font-digitalnumbers">
                    {displayDate(date?.minute)}
                  </div>
                </div>
              </div>
              <div className="self-stretch justify-between items-start inline-flex">
                <div className="px-[4.43px] justify-center items-center gap-[7.39px] flex">
                  <div className="w-[29.55px] text-right text-white text-sm font-normal  leading-[14.77px]">
                    Day
                  </div>
                </div>
                <div className="px-[4.43px] justify-center items-center gap-[7.39px] flex">
                  <div className="text-right text-white text-sm font-normal  leading-[14.77px]">
                    Hour
                  </div>
                </div>
                <div className="px-[4.43px] justify-center items-center gap-[7.39px] flex">
                  <div className="text-white text-sm font-normal  leading-[14.77px]">
                    Min
                  </div>
                </div>
                <div className="px-[4.43px] justify-center items-center gap-[7.39px] flex">
                  <div className="text-white text-sm font-normal  leading-[14.77px]">
                    Sec
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="w-full rounded-2xl shadow flex-col justify-start items-start gap-2 inline-flex">
        <div className="text-gray-400 text-xl lg:text-base font-normal leading-7">
          Artist
        </div>
        <div className="text-stone-50 text-lg font-extrabold leading-10 tracking-tight capitalize">
          {formValues?.artist || "-"}
        </div>
        <div className="text-gray-400 text-xl lg:text-base font-normal leading-7">
          Label/Company
        </div>
        <div className="text-stone-50 text-lg font-extrabold leading-loose">
          {formValues?.recordLabel || "-"}
        </div>
        <div className="text-gray-400 text-xl lg:text-base font-normal leading-7">Genre</div>
        <div className="text-stone-50 text-lg font-extrabold leading-loose">
          {formValues?.genre || "-"}
        </div>
      </section>

      <section className="relative w-full mt-8">
        <div className="left-0 top-0 flex-col justify-start items-start gap-0 lg:gap-2 inline-flex w-full">
          <span className="text-white text-2xl lg:text-lg font-semibold capitalize leading-[72px]">
            Looking for:
          </span>
          <div className="text-white text-sm lg:text-base font-normal leading-[20.80px] whitespace-pre-wrap">
            {sections?.length ? sections[0]?.text || "" : ""}
          </div>
        </div>
        {sections?.length && sections[1]?.text ? (
          <div className="left-0 top-[210px] mt-8 flex-col justify-start items-start gap-0 lg:gap-2 inline-flex w-full">
            <div className="">
              <span className="text-white text-2xl lg:text-lg font-semibold capitalize leading-[72px]">
                Important note:
              </span>
            </div>
            <div className="text-white text-sm lg:text-base font-normal leading-[20.80px] whitespace-pre-wrap">
              {sections?.length ? sections[1]?.text || "" : ""}
            </div>
          </div>
        ) : null}
      </section>

      <section className="flex-col justify-start items-start gap-[49px] inline-flex">
        <div className="self-stretch flex-col justify-start items-start gap-0 lg:gap-2 flex">
          <div className=" text-white text-2xl lg:text-lg font-semibold capitalize leading-[72px]">
            Product fee & Royalty:
          </div>
          <div className="self-stretch text-white text-sm lg:text-base font-normal leading-[20.80px]">
            Any production fee/flat fee/dealpoint will be negotiated directly
            with the A&R/music supervisor/client on a song-by-song basis.
          </div>
        </div>
        <div className="self-stretch flex-col justify-start items-start gap-0 lg:gap-2 flex">
          <div className="w-full text-white text-2xl lg:text-lg font-semibold capitalize leading-[72px]">
            Publishing requirements:
          </div>
          <div className="self-stretch text-white text-sm lg:text-base font-normal leading-[20.80px]">
            {formValues?.publishingRequirement
              ? "This drop is open for all the writers and  producers with or without Publishing agreements."
              : "This drop requires you to be free from Publishing agreements."}
          </div>
        </div>
        <div className="self-stretch flex-col justify-start items-start gap-0 lg:gap-2 flex">
          <span className="text-white text-2xl lg:text-lg font-semibold capitalize leading-[72px]">
            Velv recommendations:
          </span>
          <ul className="self-stretch text-white text-sm lg:text-base font-normal leading-[20.80px]">
            <li>Make sure that you read and understood the brief correctly</li>
            <li>
              Always make sure to have the stems, instrumentals and acapella
              ready
            </li>
            <li>Do not use producer tags in your demos</li>
          </ul>
        </div>
      </section>
    </div>
  );
};

export default PreviewForm;
