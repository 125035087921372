import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CardItem } from "./CardItem";
import circleArrowIcon from "assets/icons/circle-arrow-icon.svg";
import rightArrowIcon from "assets/icons/right-arrow-icon.svg";
import arrowIcon from "assets/icons/arrow-icon.svg";
import { GENRES_LIST_CAPITAL, ROLES_LIST } from "utils/constants";

export function CardsList({
  title,
  isList,
  data = [],
  showBackBtn = false,
  isFiltered = true,
  onClickItemFavorite = () => {},
}) {
  const user = JSON.parse(localStorage.getItem("user"));
  const ref = useRef();
  const navigate = useNavigate();
  const [isOverflow, setIsOverflow] = useState(undefined);
  const [isOverflowLeft, setIsOverflowLeft] = useState(undefined);
  const filteredData = isFiltered
    ? data.sort((a, b) => a.expireTime - b.expireTime)
    : data;

  useEffect(() => {
    const { current } = ref;

    const trigger = () => {
      const hasOverflow = current.children[0].scrollWidth > current.clientWidth;
      setIsOverflow(hasOverflow);
    };

    if (current) {
      trigger();
    }
  }, [ref, data]);

  const handleScroll = (direction = "right") => {
    let position = ref.current.scrollLeft;
    if (direction === "right") {
      ref.current.scrollLeft += 190;
      position += 190;
    }
    if (direction === "left") {
      ref.current.scrollLeft -= 190;
      position -= 190;
    }

    if (position > 0) setIsOverflowLeft(true);
    else setIsOverflowLeft(false);
  };

  const handleSeeAll = () => {
    navigate(`/genre/${GENRES_LIST_CAPITAL[title].replaceAll(" ", "")}`, {
      state: { genre: data[0]?.genre },
    });
  };

  return (
    <div className="flex flex-col relative">
      <div className="grid grid-cols-3 justify-between mt-6 mr-2">
        <h1 className="text-4xl font-extrabold text-white text-left col-span-2 flex gap-6 items-center">
          {showBackBtn && (
            <img
              src={arrowIcon}
              alt="chevron"
              className="hidden lg:flex rotate-90 w-6 h-6 cursor-pointer"
              onClick={() => navigate("/")}
            />
          )}
          {title}
        </h1>

        {isList ? null : (
          <button
            className="flex justify-center max-h-10 items-center gap-1 ml-auto text-right border-2 px-3 border-solid border-white text-white w-fit rounded-full text-xs hover:shadow-[0_0_10px_0_rgba(255,255,255,1)]"
            onClick={handleSeeAll}
          >
            See all <img src={rightArrowIcon} alt="right arrow" />
          </button>
        )}
      </div>
      {isOverflowLeft ? (
        <div
          className="absolute left-0 z-10 flex items-center w-30 h-[calc(100%-126px)] mt-[5.6rem]"
          onMouseDown={() => handleScroll("left")}
        >
          <img
            src={circleArrowIcon}
            alt="move right"
            className="w-10 cursor-pointer transition-all rotate-180 hover:invert"
          />
        </div>
      ) : null}
      <div
        className="flex relative overflow-x-scroll pb-10 hide-scroll-bar mt-6 scroll-smooth"
        ref={ref}
      >
        <div
          className={`flex ${
            isList ? "flex-wrap" : "flex-nowrap"
          } gap-5 justify-center lg:justify-normal`}
        >
          {filteredData
            ? filteredData?.map((item, index) => {
                /* if (item?.status !== "accept") return null; */
                return (
                  <div
                    className="inline-block relative"
                    key={item?.id || index}
                  >
                    {item?.isExpired ? (
                      <div className="bg-black w-full h-full rounded-3xl absolute z-10 opacity-50"></div>
                    ) : null}
                    <CardItem
                      title={item?.artist || "-"}
                      name={item?.artist || "-"}
                      startDate={item?.createdTime}
                      endDate={item?.expireTime}
                      image={item?.artworkUrl}
                      id={item?.dropId}
                      showFavorites={
                        user?.profession === ROLES_LIST.PRODUCER ||
                        user?.profession === ROLES_LIST.SONG_WRITER ||
                        user?.profession === ROLES_LIST.SONG_WRITER_PRODUCER
                      }
                      data={item}
                      onClickItemFavorite={onClickItemFavorite}
                      expired={item?.isExpired}
                    />
                  </div>
                );
              })
            : null}
        </div>
      </div>
      {isOverflow ? (
        <div
          className="absolute right-0 top-[70px] z-10 flex items-center w-30 h-[calc(100%-266px)] mt-[5.6rem]"
          onMouseDown={() => handleScroll()}
        >
          <img
            src={circleArrowIcon}
            alt="move right"
            className="w-10 cursor-pointer transition-all hover:invert"
          />
        </div>
      ) : null}
    </div>
  );
}
