import { useState } from "react";

const FileUpload = ({ children, className, setFile, type, canDeleteIt = true }) => {
  const [isDragOver, setIsDragOver] = useState(false);

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = () => {
    setIsDragOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragOver(false);

    const files = Array.from(e.dataTransfer.files);
    handleFiles(files);
  };

  const handleFileInputChange = (e) => {
    const files = Array.from(e.target.files);
    if (canDeleteIt || files?.length) {
      handleFiles(files);
    }
  };

  const handleFiles = (files) => {
    try {
      let types = "";
      if (type === "audio") types = ["audio/mp3", "audio/mpeg"];
      else types = ["image/png", "image/jpeg"];
      const allowedFileTypes = types;

      const filteredFiles = files.filter(
        (file) =>
          allowedFileTypes.includes(file.type) /* && file.size <= 5242880 */ // 5 MB limit
      );

      if (filteredFiles) setFile(filteredFiles);
    } catch (error) {
      console.error("Error uploading files!");
    }
  };

  return (
    <div
      className={`${isDragOver ? "border-green-500" : ""} ${className}`}
      onDragEnter={handleDragEnter}
      onDragOver={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <label className="text-center cursor-pointer h-full">
        <input
          type="file"
          className="hidden"
          onChange={handleFileInputChange}
          accept={type === "audio" ? ".mp3, .mpeg" : ".png, .jpg, .jpeg"}
        />
        {children}
      </label>
    </div>
  );
};

export default FileUpload;
