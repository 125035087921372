import { useNavigate, useParams } from "react-router";
import { Header } from "components/shared/Header/Header";
import { MainWrapper } from "components/shared/MainWrapper/MainWrapper";
import { db, auth } from "config/firebase-config";
import { Timestamp, doc, setDoc, query, collection, where, getDocs, updateDoc } from "@firebase/firestore";
import { useEffect } from "react";
import { sendNoticeToCollab } from "utils/helpers";

const SuccessPayment = () => {
  const { demoId } = useParams();
  const navigate = useNavigate();
  const ids = JSON.parse(localStorage.getItem("ids"));
  const user = JSON.parse(localStorage.getItem("user"));

  const addItem = async (item, id) => {
    try {
      const dropsRef = doc(db, "demos", id);
      const userDoc = doc(db, "users", auth?.currentUser?.uid);

      if (user?.discount > 0) {
        await updateDoc(userDoc, {
          discount: user?.discount - 1,
        });
      }

      await setDoc(dropsRef, {
        ...item,
        screenings: [],
        createdTime: Timestamp.fromDate(new Date()),
      });
    } catch (error) {
      console.log("Error adding demo", error);
    }
  };

  const getUserByVelvId = async (velvId) => {
    const qUsers = query(collection(db, "users"), where("velvId", "==", velvId));
    try {
      const snapshot = await getDocs(qUsers);
      if (!snapshot.empty) {
        return snapshot?.docs[0]?.data();
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const notifyCollaborators = async (collaboratorVelvIds, artist) => {
    const title = "New collaboration!";
    const body = `You've been added as a collaborator to ${artist}'s drop.`;
    for (const velvId of collaboratorVelvIds) {
      const user = await getUserByVelvId(velvId);
      if (user?.fcmToken) {
        await sendNoticeToCollab({
          fcmToken: user.fcmToken,
          title,
          body,
        });
      }
    }
  };

  useEffect(() => {
    if (ids[demoId]) {
      (async () => {
        const data = ids[demoId];
        if (data?.collaboratorVelvIds) {
          await notifyCollaborators(data?.collaboratorVelvIds, data?.artist);
        }
        await addItem(data, demoId);
        let removeFromStorage = { ...ids };
        delete removeFromStorage[demoId];
        localStorage.setItem("ids", JSON.stringify(removeFromStorage));
      })();
    } else {
      navigate("/");
    }
  }, []);

  return (
    <MainWrapper>
      <Header />

      <section className="flex flex-col items-center gap-10 mt-20">
        <div className="text-center text-gray-50 text-2xl lg:text-3xl font-bold lg:leading-[56px] lg:mt-8">
          Congratulations! <br />
          <p className="text-2xl mt-4 text-gray-600">
            You successfully submitted your demo 🎉
          </p>
        </div>
        <button
          onClick={() => {
            navigate("/my-pitches");
          }}
          className="px-5 py-3 bg-violet-600 rounded-full text-gray-50 text-lg font-bold leading-snug w-full md:w-fit"
        >
          Check it out
        </button>
      </section>
    </MainWrapper>
  );
};

export default SuccessPayment;
