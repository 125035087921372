import { useEffect, useState } from "react";

export default function SwitchButton({ on, onChange, sm, md, lg }) {
  const [switchBtn, setSwitchBtn] = useState(on);
  // const [size, setSize] = useState("md");

  const handleClick = (btn) => {
    setSwitchBtn((prev) => !prev);
  };

  useEffect(() => {
    onChange(switchBtn);
  }, [switchBtn]);

  return (
    <div
      onClick={() => handleClick(switchBtn)}
      className={`relative w-12 h-6 p-1 cursor-pointer ${
        switchBtn ? "bg-violet-600" : "bg-slate-500"
      } rounded-[32px] justify-start items-center gap-2.5 inline-flex`}
    >
      <div
        className={`w-4 h-4 relative bg-gray-50 rounded-3xl shadow transition duration-300 ease-in-out ${
          switchBtn ? "translate-x-6" : "translate-x-0"
        }`}
      ></div>
    </div>
  );
}
