import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";
import { handleRedirectToApp } from "landing/helpers/functions";
import { Footer } from "landing/components/Footer/Footer";
import velvWhiteLogo from "landing/assets/images/soundtrap/velv-white-logo.svg";
import hamburgerIcon from "landing/assets/images/soundtrap/hamburger-icon.svg";
import vectorTopPink from "landing/assets/images/soundtrap/pink-triangle-vector.svg";
import vectorTopBlue from "landing/assets/images/soundtrap/blue-trap-vector.svg";
import soundtrapLaptop from "landing/assets/images/soundtrap/sountrap-laptop.png";
import velvIphoneStraight from "landing/assets/images/soundtrap/velv-iphone-straight.png";
import arrowDownPink from "landing/assets/images/soundtrap/arrow-down.svg";
import briefIcon from "landing/assets/images/soundtrap/brief-icon.svg";
import velvIphoneSkewed from "landing/assets/images/soundtrap/velv-iphone-skewed.png";
import starOutlineVector from "landing/assets/images/soundtrap/pink-star-outline.svg";
import triangleRoundedVector from "landing/assets/images/soundtrap/triangle-rounded-vector.svg";
import velvIphoneLogo from "landing/assets/images/soundtrap/velv-iphone.png";
import registerTriangleVector from "landing/assets/images/soundtrap/register-triangle.svg";
import editIcon from "landing/assets/images/soundtrap/edit-icon.svg";
import videoPlaceholder from "landing/assets/images/soundtrap/video-placeholder.png";
import playButton from "landing/assets/images/soundtrap/play-button.svg";
import velvIphoneLarge from "landing/assets/images/soundtrap/velv-iphone-large.png";
import soundtrapLaptopSM from "landing/assets/images/soundtrap/soundtrap-laptop-sm.png";
import musicNoteIcon from "landing/assets/images/soundtrap/music-logo-icon.svg";
import vector2Num from "landing/assets/images/soundtrap/vector-2-num.svg";
import vector3Num from "landing/assets/images/soundtrap/vector-3-num.svg";
import vector4Num from "landing/assets/images/soundtrap/vector-4-num.svg";
import noteIcon from "landing/assets/images/soundtrap/music-note-icon.svg";
import velvDiscountIphone from "landing/assets/images/soundtrap/velv-discount-iphone.png";
import velvBriefIphone from "landing/assets/images/soundtrap/briefs-check-img.png";
import swirlArrowIcon from "landing/assets/images/soundtrap/swirl-arrow-icon.svg";
import zoomIcon from "landing/assets/images/soundtrap/zoom-icon.svg";
import telegramIcon from "landing/assets/images/soundtrap/telegram-icon.svg";
import starIcon from "landing/assets/images/soundtrap/purple-star-icon.svg";
import musicNoteGrayIcon from "landing/assets/images/soundtrap/music-note-gray-icon.svg";
import moneyBagIcon from "landing/assets/images/soundtrap/money-bag-icon.svg";
import viteLogo from "landing/assets/images/velv-logo.svg";
import instagramIcon from "landing/assets/icons/instagram-icon.svg";
import facebookIcon from "landing/assets/icons/facebook-icon.svg";
import youtubeIcon from "landing/assets/icons/youtube-icon.svg";
import discountIcon from "landing/assets/images/soundtrap/discount-icon.svg";
import lucianoBriefImg from "landing/assets/images/soundtrap/luciano-brief-img.png";
import koreanGirlArtistImg from "landing/assets/images/soundtrap/korean-female-brief-img.png";
import naaakBriefImg from "landing/assets/images/soundtrap/naaa-brief-img.png";
import ramiBriefImg from "landing/assets/images/soundtrap/rami-brief-img.png";
import xIcon from "landing/assets/icons/x-icon.svg";
import expandIcon from "landing/assets/icons/expand-icon.svg";
import velvVideo from "landing/assets/videos/VELV_TEASER_BLACK.mp4";

export const SoundTrap = () => {
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(false);
  const [showVideo, setShowVideo] = useState(false);

  const handleShowMenu = () => {
    setShowMenu((prev) => !prev);
  };
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showMenu]);

  const setCookie = (name, value, days) => {
    let expires = "";
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie =
      name + "=" + (value || "") + expires + `; domain=.velvmusic.com; path=/;`;
  };

  useEffect(() => {
    setCookie("soundtrap", true, 3);
  }, []);

  const handleGAClick = (label) => {
    ReactGA.event({
      category: label || "",
      action: "soundtrap_lp_button",
      label: "click",
    });
  };

  return (
    <div className="bg-[#262529] flex flex-row justify-center w-full font-['Poppins']">
      <div className="w-full relative">
        <section className="max-w-[1080px] mx-auto">
          {/* HEADER */}
          <div className="mx-6 flex flex-row items-center py-4 justify-start">
            <img
              className="w-[111px] h-[35px]"
              alt="Vector"
              src={velvWhiteLogo}
            />
            <div className="flex ml-auto gap-5">
              <Button
                onClick={() => {
                  handleGAClick("signup");
                  handleRedirectToApp("/signup");
                }}
                className="h-8 px-8 py-4 flex bg-soundtrap-blue w-[97px]"
                divClassName="mt-[-13.50px] text-white text-base mb-[-11.50px] "
                text="Sign up"
                type="primary"
              />
              <Button
                onClick={() => {
                  handleGAClick("login");
                  handleRedirectToApp("/login");
                }}
                className="hidden lg:flex border-soundtrap-blue h-[31px] border border-solid px-[32px] bg-[unset] w-[97px]"
                divClassName="mt-[-13.50px] text-white text-base mb-[-11.50px] "
                text="Login"
                type="primary"
              />
              <img
                className="w-[30px] h-[30px] lg:ml-4 cursor-pointer"
                alt="Vector"
                src={hamburgerIcon}
                onClick={handleShowMenu}
              />
            </div>
          </div>

          {showMenu && (
            <div
              ref={menuRef}
              className="bg-black bg-opacity-90 fixed left-0 md:left-auto lg:right-0 h-[100dvh] w-full md:w-[380px] z-20 text-white text-2xl lg:pl-20 top-0"
            >
              <div className="flex justify-end text-right mt-16 mb-20 mr-20">
                <img
                  src={xIcon}
                  alt="close"
                  className="cursor-pointer relative z-30"
                  onClick={handleShowMenu}
                />
              </div>
              <ul className="flex ml-8 lg:ml-0 flex-col gap-4">
                <li
                  className="cursor-pointer"
                  onClick={() => {
                    handleGAClick("login");
                    handleRedirectToApp("/login");
                  }}
                >
                  Login
                </li>
                <li
                  className="cursor-pointer"
                  onClick={() => {
                    handleGAClick("signup");
                    handleRedirectToApp("/signup");
                  }}
                >
                  Sign Up
                </li>
                <li
                  className="cursor-pointer"
                  onClick={() => {
                    navigate("/about");
                    handleShowMenu();
                  }}
                >
                  About
                </li>
                <li
                  className="cursor-pointer"
                  onClick={() => {
                    navigate("/howto");
                    handleShowMenu();
                  }}
                >
                  How to
                </li>
                <li className="cursor-pointer">
                  <details>
                    <summary>
                      Legal
                      <img
                        src={expandIcon}
                        alt="expand"
                        className="icon !w-7 h-7"
                      />
                    </summary>
                    <li
                      className="cursor-pointer text-xl py-3 ml-2"
                      onClick={() => {
                        navigate("/termsconditions");
                        handleShowMenu();
                      }}
                    >
                      Terms & condition
                    </li>
                    <li
                      className="cursor-pointer text-xl py-3 ml-2"
                      onClick={() => {
                        navigate("/privacycookies");
                        handleShowMenu();
                      }}
                    >
                      Private policy & Cookies
                    </li>
                  </details>
                </li>
                <li
                  className="cursor-pointer"
                  onClick={() => {
                    navigate("/contact");
                    handleShowMenu();
                  }}
                >
                  Contact
                </li>
              </ul>
            </div>
          )}

          <div className="mx-8 lg:mx-20 mb-16">
            <div className="flex leading-2 flex-col lg:flex-row justify-between w-full h-full mt-20">
              <div className="w-full">
                <div className="gap-1 lg:w-[498px]">
                  <span className="text-pink-200 text-4xl lg:text-6xl font-bold lg:leading-[70px]">
                    Pitch{" "}
                  </span>
                  <span className="text-white text-4xl lg:text-6xl lg:leading-[70px]">
                    your songs
                  </span>
                  <span className="text-pink-200 text-4xl lg:text-6xl font-bold lg:leading-[70px] mt-2">
                    {" "}
                    <br />
                    directly
                  </span>
                  <span className="text-white text-4xl lg:text-6xl lg:leading-[70px]">
                    {" "}
                    to the{" "}
                  </span>
                  <span className="text-pink-200 text-4xl lg:text-6xl font-bold lg:leading-[70px]">
                    labels
                  </span>
                </div>
                <div className="text-white text-xl  mt-4">
                  Groundbreaking access to the music industry
                </div>
                <button
                  onClick={() => {
                    handleGAClick("signup");
                    handleRedirectToApp("/signup");
                  }}
                  className="w-40 lg:w-[249px] h-12 lg:h-16 p-2.5 mt-6 bg-indigo-600 rounded-full justify-center items-center gap-2.5 inline-flex text-white text-base lg:text-2xl font-semibold  leading-tight"
                >
                  Sign up for free
                </button>
              </div>
              <div className="w-full relative h-[302px] max-w-[470px] mx-auto flex justify-center items-center lg:-ml-12">
                <img
                  className="absolute w-[173px] h-[174px] right-12 lg:right-32 -top-6"
                  alt="Vector"
                  src={vectorTopPink}
                />
                <img
                  className="absolute h-[250px] w-[300px] mt-[72px]"
                  alt="Vector"
                  src={vectorTopBlue}
                />
                <img
                  className="absolute w-20 h-40 left-12 lg:left-24 top-36 z-10"
                  src={velvIphoneStraight}
                  alt="velv iphone straight"
                />
                <img
                  className="absolute w-96 h-auto lg:h-56 top-32 lg:top-24"
                  src={soundtrapLaptop}
                  alt="soundtrap laptop"
                />
              </div>
            </div>
            <div>
              <div className="mt-11">
                <span className="text-white text-4xl font-normal">Latest</span>
                <span className="text-pink-200 text-4xl font-normal"> </span>
                <span className="text-pink-200 text-4xl font-bold">briefs</span>
              </div>

              <div className="flex flex-col lg:flex-row gap-6 mt-8">
                <div className="flex gap-4 lg:gap-6">
                  <div className="relative w-fit">
                    <img
                      className="absolute hidden lg:flex -mt-3 -right-1 w-8 h-9"
                      alt="Vector"
                      src={briefIcon}
                    />
                    <div className="w-24 lg:w-36 h-36 lg:h-48 rounded-md">
                      <img
                        className="w-24 lg:w-36 h-36 lg:h-48 rounded-md object-fill"
                        alt="Vector"
                        src={lucianoBriefImg}
                      />
                    </div>
                  </div>
                  <div className="relative w-fit">
                    <img
                      className="absolute hidden lg:flex -mt-3 -right-1 w-8 h-9"
                      alt="Vector"
                      src={briefIcon}
                    />
                    <div className="w-24 lg:w-36 h-36 lg:h-48 rounded-md">
                      <img
                        className="w-24 lg:w-36 h-36 lg:h-48 rounded-md object-fill"
                        alt="Vector"
                        src={koreanGirlArtistImg}
                      />
                    </div>
                  </div>
                  <div className="relative w-fit">
                    <img
                      className="absolute -mt-3 -right-1 w-8 h-9"
                      alt="Vector"
                      src={briefIcon}
                    />
                    <div className="w-24 lg:w-36 h-36 lg:h-48 rounded-md">
                      <img
                        className="w-24 lg:w-36 h-36 lg:h-48 rounded-md object-fill"
                        alt="Vector"
                        src={naaakBriefImg}
                      />
                    </div>
                  </div>
                  <div className="relative hidden lg:flex w-fit justify-center">
                    <img
                      className="absolute -mt-3 -right-1 w-8 h-9"
                      alt="Vector"
                      src={briefIcon}
                    />
                    <div className="w-24 lg:w-36 h-36 lg:h-48 rounded-md">
                      <img
                        className="w-24 lg:w-36 h-36 lg:h-48 rounded-md object-fill"
                        alt="Vector"
                        src={ramiBriefImg}
                      />
                    </div>
                  </div>
                  <div className="relative w-32 h-38 -mt-4 -ml-24 bg-gradient-to-l from-50% from-[#262529]" />
                </div>
                <button
                  onClick={() => {
                    handleGAClick("signup");
                    handleRedirectToApp("/signup");
                  }}
                  className="flex justify-end lg:justify-center text-pink-200 text-base font-semibold  my-auto -ml-6"
                >
                  Explore all
                  <img
                    className="-rotate-90 ml-1"
                    alt="Vector"
                    src={arrowDownPink}
                  />
                </button>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-white w-full">
          <div className="max-w-[1080px] mx-auto pt-20 relative">
            <div className="flex flex-col lg:flex-row items-center lg:items-start mx-8 lg:mx-44">
              <div className="">
                <div className="lg:w-[465px] text-indigo-600 text-3xl lg:text-4xl font-bold lg:leading-10">
                  Sign up for free, and pay only per submission.
                </div>
                <div className="lg:w-[465px] text-indigo-600 text-xl lg:text-2xl font-bold lg:leading-10 mt-4">
                  50% discount for Soundtrap users.
                </div>
                <div className="lg:w-[360px] text-zinc-800 text-base font-normal  leading-normal mt-4">
                  Soundtrap users get 50% off their first 3 submissions on Velv
                  valid for 6 months from the day of signing up. Let’s pitch
                  your song and get that contract signed, sealed and delivered.
                </div>
              </div>
              <div className="relative w-full h-full flex justify-center items-center">
                <img
                  src={starOutlineVector}
                  className="absolute -left-2 top-6"
                  alt="star outline"
                />

                <img src={triangleRoundedVector} className="absolute" alt="rounded triangle"/>
                <img
                  src={velvIphoneSkewed}
                  className="relative z-10 lg:-mt-14 -ml-[50px] w-[267px]"
                  alt="velv iphone skewed"
                />
              </div>
            </div>
            {/* ONE */}
            <div className="flex mx-8 lg:ml-28 border-b border-solid border-slate-200 pb-4 lg:border-none">
              <div className="flex items-center">
                <div className="text-indigo-700 text-4xl lg:text-8xl font-black  leading-10 relative flex justify-center items-center h-full">
                  <p className="relative z-10">1.</p>
                  <img
                    src={registerTriangleVector}
                    alt="shape 1"
                    className="absolute h-[90px] w-24"
                  />
                </div>
                <div className="relative hidden lg:flex -ml-3 z-20">
                  <div className="w-10 h-12 bg-indigo-600 rounded-md absolute z-10 -right-4 top-1 flex justify-center items-center">
                    <img className="w-7 h-7 ml-1" src={editIcon} alt="edit icon"/>
                  </div>
                  <img className="w-20 h-40 top-0" src={velvIphoneLogo} alt="velv phone logo"/>
                </div>
              </div>
              <div className="flex flex-col ml-3 justify-center">
                <span className="text-black lg:text-4xl font-bold  leading-10">
                  Register for Velv
                  <br />
                </span>
                <span className="text-gray-400 lg:text-black font-medium text-base lg:font-light  leading-relaxed">
                  Sign up on velvmusic.com to claim your discount.
                </span>
              </div>
            </div>
            {/* TWO */}
            <div className="flex justify-end items-center mx-8 lg:mr-28 mt-4 lg:-mt-8 border-b border-solid border-slate-200 pb-4 lg:border-none">
              <div className="flex justify-end flex-col mr-3 max-w-[380px]">
                <span className="text-black lg:text-4xl font-bold  leading-10 text-right">
                  Check briefs on Velv
                  <br />
                </span>
                <span className="text-gray-400 lg:text-black font-medium text-base lg:font-light  leading-relaxed">
                  Choose from official music briefs by artists and record labels
                  from around the world.
                </span>
              </div>
              <div className="flex items-center">
                <div className="text-indigo-700  text-4xl lg:text-8xl font-black  leading-10 relative flex justify-center items-center h-full">
                  <p className="relative z-10 h-full">2.</p>
                  <img
                    src={vector2Num}
                    alt="shape 1"
                    className="absolute w-28 h-32"
                  />
                </div>
                <div className="relative hidden lg:flex -ml-3 z-20">
                  <div className="w-10 h-12 bg-indigo-600 rounded-md absolute z-10 -right-4 -top-2 flex justify-center items-center">
                    <img className="" src={briefIcon} alt="brief icon"/>
                  </div>
                  <img className="w-20 h-40 top-0" src={velvBriefIphone} alt="velv brief iphone"/>
                </div>
              </div>
            </div>
            {/* THREE */}
            <div className="flex items-center ml-8 lg:ml-28 mt-8 border-b border-solid border-slate-200 pb-4 lg:border-none">
              <div className="flex items-center">
                <div className="text-indigo-700 text-4xl lg:text-8xl font-black  leading-10 relative flex justify-center items-center h-full">
                  <p className="relative z-10">3.</p>
                  <img
                    src={vector3Num}
                    alt="shape 1"
                    className="absolute w-28 h-32"
                  />
                </div>
                <div className="relative hidden lg:flex -ml-3 z-20">
                  <div className="w-10 h-12 bg-indigo-600 rounded-md absolute z-10 -right-2 -top-8 flex justify-center items-center">
                    <img className="" src={musicNoteIcon} alt="music note icon"/>
                  </div>
                  <img className="top-0" src={soundtrapLaptopSM} alt="sountrap laptop small" />
                </div>
              </div>
              <div className="flex flex-col ml-3">
                <span className="text-black lg:text-4xl font-bold  leading-10">
                  Finalize your song in Soundtrap
                  <br />
                </span>
                <span className="text-gray-400 lg:text-black font-medium text-base lg:font-light leading-relaxed">
                  Finalize your song in Soundtrap and pitch your music through{" "}
                  <br />
                  Velv to the artist brief that best matches your song.
                </span>
              </div>
            </div>
            {/* FOUR */}
            <div className="flex justify-end items-center mx-8 lg:mr-28 mt-8 border-b border-solid border-slate-200 pb-4 lg:border-none">
              <div className="flex justify-end flex-col mr-3 max-w-[555px]">
                <span className="text-black lg:text-4xl font-bold  lg:leading-12 text-left">
                  Claim your exclusive Soundtrap discount, and submit your demo
                  <br />
                </span>
                <span className="text-gray-400 lg:text-black font-medium text-base lg:font-light  leading-relaxed mt-3">
                  Benefit from an exclusive Soundtrap discount of 50% for your
                  first 3 submissions on Velv valid for 6 months from the day of
                  signing up.
                </span>
              </div>
              <div className="flex items-center">
                <div className="text-indigo-700 text-4xl lg:text-8xl font-black  leading-10 relative flex justify-center items-center h-full mt-6">
                  <p className="relative z-10 h-full">4.</p>
                  <img
                    src={vector4Num}
                    alt="shape 1"
                    className="absolute w-28 h-32"
                  />
                </div>
                <div className="relative hidden lg:flex -ml-3 z-20">
                  <div className="w-10 h-12 bg-indigo-600 rounded-md absolute z-10 -right-5 -top-2 flex justify-center items-center">
                    <img className="" src={noteIcon} alt="note icon"/>
                  </div>
                  <div className="w-10 h-12 bg-indigo-600 rounded-md absolute z-10 -right-16 -top-2 flex justify-center items-center">
                    <img className="" src={discountIcon} alt="discount icon"/>
                  </div>
                  <img className="top-0" src={velvDiscountIphone} alt="velv discount iphone"/>
                </div>
              </div>
            </div>
            <div className="w-full flex justify-center mb-12 lg:mb-32 mt-20">
              <button
                onClick={() => {
                  handleGAClick("signup");
                  handleRedirectToApp("/signup");
                }}
                className="w-40 lg:w-[249px] h-12 lg:h-16 p-2.5 mx-auto bg-indigo-600 rounded-full justify-center items-center gap-2.5 inline-flex text-white text-base lg:text-2xl font-semibold  leading-tight"
              >
                Sign up for free
              </button>
            </div>

            <div className="mt-14 text-indigo-600 text-4xl lg:text-6xl font-bold text-center w-full mb-10 lg:mb-24 ">
              How Velv works
            </div>

            <div className="group relative flex justify-center items-center w-full h-full mt-4 cursor-pointer">
              {showVideo ? (
                <video
                  className="w-full"
                  autoPlay
                  controls
                  playsInline // Required for Safari
                  loop
                >
                  <source src={velvVideo} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              ) : (
                <button
                  className="flex justify-center items-center w-full h-full"
                  onClick={() => setShowVideo(true)}
                >
                  <img
                    className="w-full max-h-[607px] group-hover:blur-sm transition-all duration-150"
                    src={videoPlaceholder}
                    alt="video placeholder"
                  />
                  <img
                    src={playButton}
                    className="absolute cursor-pointer"
                    alt="play button"
                  ></img>
                </button>
              )}
            </div>

            <div className="absolute hidden lg:block right-20 mt-20">
              <img src={swirlArrowIcon} alt="arrow" />
              <img className="mt-10" src={starIcon} alt="star" />
            </div>
            {/* BROWSE 1 */}
            <div className="mx-8 lg:mx-28 justify-center lg:justify-start items-center gap-5 lg:gap-28 inline-flex mt-10 lg:mt-20">
              <div className="text-pink-200 text-7xl lg:text-9xl font-black ">
                01
              </div>
              <div className="flex-col justify-start items-start gap-2 lg:gap-4 inline-flex">
                <img
                  src={zoomIcon}
                  alt="zoom"
                  className="w-5 lg:w-10 h-5 lg:h-10 relative"
                />
                <div className="flex-col justify-start items-start gap-1 flex">
                  <div className="text-black text-base lg:text-4xl font-bold ">
                    Browse
                  </div>
                  <div className="lg:w-96 text-gray-400 lg:text-black text-base font-normal ">
                    Browse through music briefs from global music markets.
                  </div>
                </div>
              </div>
            </div>
            {/* PITCH 2 */}
            <div className="mt-12 mx-8 lg:mx-28 justify-start items-center gap-5 lg:gap-28 inline-flex">
              <div className="flex-col justify-start items-start gap-2 lg:gap-4 inline-flex">
                <img
                  src={telegramIcon}
                  alt="telegram"
                  className="w-5 lg:w-10 h-5 lg:h-10 relative"
                />
                <div className="flex-col justify-start items-start gap-1 flex">
                  <div className="text-black text-base lg:text-4xl font-bold ">
                    Pitch
                  </div>
                  <div className="lg:w-[20rem] text-gray-400 lg:text-black text-base font-normal ">
                    Pitch to official music briefs and pay only per submission,
                    no other hidden fees!
                  </div>
                </div>
              </div>
              <div className="text-pink-200 text-7xl lg:text-9xl font-black ">
                02
              </div>
            </div>
            {/* TRACK 3 */}
            <div className="mx-8 lg:mx-28 mt-12 justify-start items-center gap-5 lg:gap-28 inline-flex">
              <div className="text-pink-200 text-7xl lg:text-9xl font-black  leading-10">
                03
              </div>
              <div className="flex-col justify-start items-start gap-2 lg:gap-4 inline-flex">
                <img
                  src={musicNoteGrayIcon}
                  alt="zoom"
                  className="w-5 lg:w-10 h-5 lg:h-10 relative"
                />
                <div className="flex-col justify-start items-start gap-1 flex">
                  <div className="text-black text-base lg:text-4xl font-bold ">
                    Track
                  </div>
                  <div className="lg:w-[20rem] text-gray-400 lg:text-black text-base font-normal ">
                    Track your submissions and get status of the pitching
                    process.
                  </div>
                </div>
              </div>
            </div>
            {/* MONETIZE 4 */}
            <div className="mx-8 lg:mx-28 mt-12 justify-start items-center gap-5 lg:gap-28 inline-flex mb-10 lg:mb-20">
              <div className="flex-col justify-start items-start gap-2 lg:gap-4 inline-flex">
                <img
                  src={moneyBagIcon}
                  alt="money bag"
                  className="w-5 lg:w-10 h-5 lg:h-10 relative"
                />
                <div className="flex-col justify-start items-start gap-1 flex">
                  <div className="text-black text-base lg:text-4xl font-bold ">
                    Monetize
                  </div>
                  <div className="lg:w-[20rem] text-gray-400 lg:text-black text-base font-normal ">
                    Get the opportunity to monetize your music by pitching to
                    official music briefs
                  </div>
                </div>
              </div>
              <div className="text-pink-200 text-7xl lg:text-9xl font-black  leading-10">
                04
              </div>
            </div>
          </div>
        </section>

        <section className="max-w-[1080px] mx-auto">
          <div className="mx-4 lg:mx-52 pt-16 lg:pt-10 flex items-center flex-row gap-2 lg:gap-28">
            <div>
              <img src={velvIphoneLarge} alt="iphone velv" />
            </div>
            <div className="lg:pt-36">
              <div className="text-pink-200 text-4xl lg:text-6xl font-bold leading-10 mb-4">
                Web App
              </div>
              <div className="text-white text-lg lg:text-4xl font-normal  leading-9 mb-7 lg:mb-28">
                Live now
              </div>
              <button
                onClick={() => {
                  handleGAClick("signup");
                  handleRedirectToApp("/signup");
                }}
                className="w-44 h-14 px-3 lg:px-8 py-3 lg:py-4 bg-indigo-600 rounded-3xl justify-center items-center inline-flex text-white text-base lg:text-2xl font-medium "
              >
                <div className="">Sign up</div>
              </button>
            </div>
          </div>
        </section>

        <section className="max-w-[1080px] mx-auto pt-8">
          <Footer
            viteLogo={viteLogo}
            instagramIcon={instagramIcon}
            facebookIcon={facebookIcon}
            youtubeIcon={youtubeIcon}
          />
        </section>
      </div>
    </div>
  );
};

// eslint-disable-next-line react/prop-types
export const Button = ({
  type,
  className,
  divClassName,
  text = "Label",
  onClick,
}) => {
  return (
    <button
      onClick={onClick || ""}
      className={`all-[unset] box-border inline-flex items-center gap-[10px] px-[12px] py-[8px] h-[32px] rounded-[40px] justify-center relative ${
        type === "secondary"
          ? "bg-variable-collection-light-grey"
          : type === "paywall"
          ? "bg-variable-collection-light-purple"
          : type === "white"
          ? "bg-variable-collection-pure-white"
          : type === "black"
          ? "bg-variable-collection-pure-black"
          : "bg-variable-collection-main-purple"
      } ${className}`}
    >
      <div
        className={`[font-family:'Matter-Medium',Helvetica] w-fit tracking-[0] text-[12px] relative font-medium whitespace-nowrap leading-[normal] ${
          type === "secondary"
            ? "text-variable-collection-pure-black"
            : ["paywall", "white"].includes(type)
            ? "text-variable-collection-dark-purple"
            : "text-variable-collection-pure-white"
        } ${divClassName}`}
      >
        {text}
      </div>
    </button>
  );
};
