import "./Input.scss";

export function Input({
  value,
  type,
  placeholder,
  onChange,
  className,
  ...rest
}) {
  return (
    <input
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      className={`tw-input-login w-full ${className}`}
      {...rest}
    />
  );
}
