import React, { useEffect, useRef, useState } from "react";

export function Dropdown({
  value,
  onChange,
  errorMsg,
  data,
  placeholder,
  height,
  type = "list",
  multiple = false,
  ...rest
}) {
  const [isOpened, setIsOpened] = useState(false);
  const [valuesList, setValuesList] = useState(value);

  const handleChange = (item) => {
    if (multiple) {
      const values = addOrRemoveFromArray(value, item);
      onChange(values);
    } else {
      setIsOpened(false);
      onChange(item);
    }
  };

  function addOrRemoveFromArray(arr, str) {
    // Check if arr is undefined or null, initialize it as an empty array if it is
    if (!Array.isArray(arr)) {
      arr = [];
    }

    const index = arr.indexOf(str);
    if (index !== -1) {
      // String exists, so remove it
      arr.splice(index, 1);
      return [...arr];
    } else {
      if (arr.length < 3) {
        // String doesn't exist, so add it
        arr = [...arr, str];
      } else {
        console.log("Cannot add more than 3 items");
      }
    }
    return arr;
  }

  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpened(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpened]);

  useEffect(() => {
    setValuesList(value);
  }, [value]);

  return (
    <div className="relative grid grid-cols-1 gap-2 h-12" {...rest} ref={menuRef}>
      <button
        onClick={() => setIsOpened((prev) => !prev)}
        className={`tw-input-login h-12 ${
          errorMsg ? "tw-input-error" : ""
        } justify-between focus:ring-0 focus:outline-none font-medium text-sm px-5 py-2.5 text-center inline-flex items-center !text-[#8D8D8D] ${
          valuesList?.length ? "!text-white" : ""
        } ${
          isOpened &&
          "!border-b-0 !rounded-b-none !rounded-3xl !bg-[#030101] !border-white"
        }`}
        type="button"
      >
        <div className="flex gap-2">
          {valuesList?.length && typeof valuesList === "object"
            ? valuesList?.map((item, idx) => (
                <div className="px-2 bg-purple rounded-xl" key={idx}>
                  {item}
                </div>
              ))
            : valuesList?.length && typeof valuesList !== "object"
            ? valuesList
            : placeholder}
        </div>
        <svg
          className="w-2.5 h-2.5 ms-3 ml-auto"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 10 6"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="m1 1 4 4 4-4"
          />
        </svg>
      </button>
      {errorMsg && (
        <span className="text-error text-left text-sm">{errorMsg}</span>
      )}

      {isOpened && (
        <div
          id="dropdown"
          className="absolute z-10 divide-y divide-gray-100 shadow w-full text-left mt-[40px] bg-[#030101] focus:ring-purple dark:focus:ring-purple rounded-b-3xl"
        >
          <ul
            className={`tw-input-login ${height ? height : "max-h-80"} ${
              type === "list" ? "flex-col" : "flex-wrap"
            } overflow-y-auto py-2 text-sm text-gray-700 !border-white dark:text-gray-200 gap-2 flex bg-none !rounded-3xl !rounded-t-none !border-t-0 focus:ring-purple dark:focus:ring-purple ${
              errorMsg ? "tw-input-error" : ""
            }`}
            aria-labelledby="dropdownDefaultButton"
          >
            {data.map((item) => (
              <li
                key={item}
                className={`${
                  type === "list" ? "first:border-t first:pt-4" : ""
                } border-solid border-[#E9ECF2] cursor-pointer`}
                onClick={() => handleChange(item)}
              >
                {type === "list" ? (
                  <div className="block py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white -mx-5 px-5">
                    {item}
                  </div>
                ) : (
                  <div
                    className={`${
                      (valuesList?.length &&
                      typeof valuesList === "object" &&
                      valuesList?.includes(item)
                        ? "bg-purple"
                        : "") ||
                      (valuesList?.length && valuesList === item
                        ? "bg-purple"
                        : "")
                    } block border py-2 border-solid border-purple w-fit rounded-xl px-2 text-xs`}
                  >
                    {item}
                  </div>
                )}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}
