import React, { useEffect, useState } from "react";
import SwitchButton from "components/shared/SwitchButton/SwitchButton";
import { InputRadio } from "components/shared/InputRadio/InputRadio";
import { generateUUID, isValidUrl } from "utils/helpers";

const VALUES_LIST = ["0-100", "0-200", "All", "Other"];

export function DetailsForm({
  formValues,
  setFormValues,
  setSections,
  sections,
  refLinks,
  setReferenceLinks,
  referenceLink,
  setReferenceLink,
}) {
  const [checked, setChecked] = useState(1);
  const [addLinkEnabled, setAddLinkEnabled] = useState(false);

  const handleChecked = (id) => {
    setFormValues((prev) => ({
      ...prev,
      requestedPitchQuantity: id === 4 ? "" : VALUES_LIST[id - 1],
    }));
    setChecked(id);
  };

  const handleSections = (e, id, type) => {
    let value = [...sections];
    value[id] = {
      ...sections[id],
      id: generateUUID(),
      type: type,
      text: e.target.value,
    };
    setSections(value);
  };

  const handleRefLinks = () => {
    setReferenceLinks((prev) => [
      ...prev,
      { ...referenceLink, id: generateUUID() },
    ]);
    setReferenceLink({ urlString: "", title: "" });
  };

  const isButtonDisabled = () => {
    if (
      !addLinkEnabled ||
      !referenceLink.urlString.trim() ||
      !referenceLink.title.trim()
    ) {
      return true;
    }
    return false;
  };

  const removeRefLink = (index) => {
    const updatedItems = [
      ...refLinks.slice(0, index),
      ...refLinks.slice(index + 1),
    ];
    setReferenceLinks(updatedItems);
  };

  useEffect(() => {
    let checkedQuantityIdx = 0;
    if (formValues.requestedPitchQuantity === "") checkedQuantityIdx = 0;
    else if (formValues.requestedPitchQuantity === 100) checkedQuantityIdx = 0;
    else if (formValues.requestedPitchQuantity === 200) checkedQuantityIdx = 1;
    else if (formValues.requestedPitchQuantity === "all")
      checkedQuantityIdx = 2;
    else
      checkedQuantityIdx = VALUES_LIST.indexOf(
        formValues.requestedPitchQuantity
      );
    if (checkedQuantityIdx === -1) setChecked(4);
    else setChecked(checkedQuantityIdx + 1);
  }, []);

  useEffect(() => {
    if (isValidUrl(referenceLink.urlString)) {
      setAddLinkEnabled(true);
    } else {
      setAddLinkEnabled(false);
    }
  }, []);

  return (
    <div className="max-w-[904px] flex-col justify-start items-start gap-10 flex w-full mt-2">
      <section className="w-full self-stretch flex-col justify-start items-start gap-8 flex">
        <section className="w-full h-[116px] flex-col justify-start items-start gap-3 inline-flex">
          <h1 className="text-gray-400 text-xs font-extrabold uppercase leading-3">
            LOOKING FOR
          </h1>
          <div className="self-stretch h-[92px] px-4 py-3 rounded-lg border-2 border-zinc-700 justify-center items-start gap-2.5 inline-flex">
            <textarea
              onChange={(e) => handleSections(e, 0, "lookingFor")}
              value={sections[0]?.text}
              placeholder="Describe in English what you're looking for in your music and lyrics and provide a brief explanation of the project."
              className="grow shrink basis-0 text-white text-sm font-medium leading-normal bg-transparent outline-none h-full resize-none"
            />
          </div>
        </section>
        <section className="w-full h-[116px] flex-col justify-start items-start gap-3 inline-flex">
          <h1 className="text-gray-400 text-xs font-extrabold uppercase leading-3">
            IMPORTANT NOTE
          </h1>
          <div className="self-stretch h-[92px] px-4 py-3 rounded-lg border-2 border-zinc-700 justify-center items-start gap-2.5 inline-flex">
            <textarea
              onChange={(e) => handleSections(e, 1, "importantNote")}
              value={sections[1]?.text}
              placeholder="Insert Description in English"
              className="grow shrink basis-0 text-white text-sm font-medium leading-normal bg-transparent outline-none h-full resize-none"
            />
          </div>
        </section>
        <section className="w-full flex-col justify-start items-start gap-3 inline-flex">
          <div className="self-stretch justify-between items-center inline-flex">
            <h3 className="text-gray-400 text-xs font-extrabold uppercase leading-3 mb-3">
              reference links
            </h3>
          </div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              setReferenceLink({
                urlString: "",
                title: "",
              });
              setAddLinkEnabled(false);
            }}
            className="w-full flex flex-col gap-2"
          >
            <section className="self-stretch flex-col justify-start items-start flex mb-4">
              <input
                type="text"
                placeholder="Artist Name - Song Title"
                value={referenceLink.title}
                onChange={(e) => {
                  setReferenceLink((prev) => ({
                    ...prev,
                    title: e.target.value,
                  }));
                }}
                className="grow shrink basis-0 text-white text-sm font-medium leading-normal bg-transparent outline-none w-full"
              />
              <div className="w-full h-px relative bg-zinc-700"></div>
            </section>
            <section className="self-stretch flex-col justify-start items-end gap-3 flex">
              <div className="self-stretch flex-col justify-start items-start flex">
                <input
                  type="url"
                  placeholder="Insert URL Link"
                  value={referenceLink.urlString}
                  onChange={(e) => {
                    if (isValidUrl(e.target.value)) {
                      setAddLinkEnabled(true);
                    } else {
                      setAddLinkEnabled(false);
                    }
                    setReferenceLink((prev) => ({
                      ...prev,
                      urlString: e.target.value,
                    }));
                  }}
                  className="grow shrink basis-0 text-white text-sm font-medium leading-normal bg-transparent outline-none w-full"
                />
                <div className="w-full h-px relative bg-zinc-700" />
              </div>
            </section>

            <button
              type="submit"
              disabled={isButtonDisabled()}
              onClick={() => handleRefLinks()}
              className="flex-col items-end flex text-right text-white text-sm leading-tight hover:text-opacity-100 font-bold bg-purple rounded-xl w-fit px-3 py-2 disabled:bg-slate-500 disabled:text-gray-300"
            >
              Add link
            </button>
          </form>

          {refLinks?.length &&
            refLinks?.map((item, index) => (
              <>
              <div
                key={index}
                className="flex justify-between items-center w-full my-5 capitalize"
              >
                <a
                  key={index}
                  href={item?.urlString}
                  className="text-purple font-bold leading-tight w-full"
                  target="_blank"
                  rel="noreferrer"
                >
                  {item?.title}
                </a>
                <button
                  type="button"
                  onClick={() => removeRefLink(index)}
                  className="w-full flex-col items-end text-sm flex text-right text-red-500 leading-tight"
                >
                  Remove
                </button>
              </div>
              {index !== refLinks?.length - 1 &&
                <div className="w-full h-px relative bg-zinc-700"></div>
              }
              </>
            ))}
          <section className="flex-col justify-start items-start gap-3 inline-flex border border-solid border-zinc-700 rounded-xl px-4 py-3 w-full">
            <h3 className="text-gray-400 text-xs font-extrabold uppercase leading-3">
              Production fee & royalties
            </h3>
            <div className="h-px relative bg-zinc-700" />
            <p className="text-white text-sm font-medium leading-normal">
              Any production fee/flat fee/dealpoint will be negotiated directly
              with the A&R/music supervisor/client on a song-by-song basis.
            </p>
          </section>

          <section className="flex-col justify-start items-start gap-3 inline-flex mt-8 border border-solid border-zinc-700 rounded-xl px-4 py-3 w-full">
            <div className="self-stretch justify-between items-start inline-flex">
              <h3 className="text-gray-400 text-xs font-extrabold uppercase leading-3">
                publishing requirement
              </h3>
              <SwitchButton
                on={formValues?.publishingRequirement || true}
                onChange={(value) =>
                  setFormValues((prev) => ({
                    ...prev,
                    publishingRequirement: value,
                  }))
                }
              />
            </div>
            <div className="w-full h-px relative bg-zinc-700" />
            <p className="w-full lg:w-[574px] grow shrink basis-0 text-white text-sm font-medium leading-normal">
              {formValues?.publishingRequirement
                ? "This drop is open for all writers and producers with or without Publishing agreements."
                : "This drop requires you to be free from publishing agreements."}
            </p>
          </section>

          <section className="flex-col justify-start items-start gap-3 inline-flex mt-8 border border-solid border-zinc-700 rounded-xl px-4 py-3 w-full">
            <h2 className="text-gray-400 text-xs font-extrabold uppercase leading-3">
              receive quantity of screened demos
            </h2>
            <section className="justify-start items-start gap-1 lg:gap-3 inline-flex">
              {VALUES_LIST.map((value, index) => (
                <InputRadio
                  key={index}
                  value={value}
                  onClick={() => handleChecked(index + 1)}
                  checked={checked === index + 1 || false}
                />
              ))}
            </section>
            {checked === 4 ? (
              <section className="self-stretch flex-col justify-start items-start gap-4 flex w-full">
                <input
                  type="text"
                  placeholder="Enter quantity"
                  value={formValues?.requestedPitchQuantity}
                  onChange={(e) =>
                    setFormValues((prev) => ({
                      ...prev,
                      requestedPitchQuantity: Number(e.target.value),
                    }))
                  }
                  className="grow shrink basis-0 text-white text-sm font-medium leading-normal bg-transparent outline-none w-full"
                />
              </section>
            ) : null}
          </section>
        </section>
      </section>
    </div>
  );
}
