import React from "react";
import { Navigate, useLocation } from "react-router-dom";

const PrivateRoute = ({ children, isAuth, currentRole, roles }) => {
  let location = useLocation();

  if (!isAuth) {
    return <Navigate to="/home" state={{ from: location }} replace />;
  }

  if (roles?.includes(currentRole)) {
    return children;
  } else {
    // Redirect to login or another route
    return <Navigate to="/" replace />;
  }
};

export default PrivateRoute;
