import closeIcon from "assets/icons/close-icon.svg";

export function Modal({ setShowModal, children, title, className = "" }) {
  return (
    <div
      className={`fixed top-0 left-0 flex justify-center items-center bg-zinc-800 bg-opacity-80 w-screen h-screen z-30 ${className}`}
    >
      <div className="max-w-[448px] w-4/5 lg:w-full p-8 bg-black rounded-[20px] shadow flex-col justify-center items-center gap-2 inline-flex">
        <div className="self-stretch justify-start items-center inline-flex">
          <div className="grow shrink basis-0 text-gray-50 text-lg lg:text-2xl font-bold text-center leading-10 -mr-11 max-w-full">
            {title}
          </div>
          <button
            className="p-1 lg:p-2 rounded-[48px] border-2 border-zinc-700 justify-center items-center gap-1 flex ml-auto"
            onClick={() => setShowModal(false)}
          >
            <img
              src={closeIcon}
              alt="close"
              className="w-4 lg:w-5 h-4 lg:h-5 p-1 relative"
            />
          </button>
        </div>
        {children}
      </div>
    </div>
  );
}
