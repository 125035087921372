import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Timestamp,
  collection,
  doc,
  getDocs,
  orderBy,
  query,
  runTransaction,
  where,
} from "firebase/firestore";
import { auth, db } from "config/firebase-config";
import { Header } from "components/shared/Header/Header";
import dropImg from "assets/images/drop-preview.png";
import EmptyState from "components/shared/EmptyState";
import { Loading } from "components/shared/Loading/Loading";

export function ScreenerView({ setHeroImage }) {
  const user = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();
  const [drops, setDrops] = useState(null);
  const [nowScreening, setNowScreening] = useState(null);
  const [loading, setLoading] = useState(true);
  const collectionRef = collection(db, "drops");
  const qDrops = query(collectionRef, orderBy("expireTime", "asc"));

  useEffect(() => {
    (async () => {
      setLoading(true);
      const drops = await fetchData();

      if (drops?.length) {
        setNowScreening(drops[0] || null);
        setHeroImage(drops[0]?.artworkUrl || "");
        const newArray = drops?.slice(1);
        setDrops(newArray || null);
      } else {
        setDrops(null);
        setHeroImage("");
        setNowScreening(null);
      }
      setLoading(false);
    })();
  }, []);

  async function fetchData() {
    try {
      // Fetch all drops
      const firstCollectionSnapshot = await getDocs(qDrops);
      const filteredDrops = firstCollectionSnapshot.docs
        ?.map((doc) => {
          const data = doc.data();
          if (
            Timestamp.fromDate(new Date()) > data?.expireTime &&
            (!data?.activeLevel3Screening ||
              data?.activeLevel3Screening?.userId === auth?.currentUser?.uid) &&
            !data?.deliveryDate
          ) {
            return { id: doc?.id, ...doc.data() };
          }
          return null;
        })
        ?.filter((val) => val !== null);

      //Extract all drops ids
      const ids = filteredDrops.map((doc) => doc.id);
      const data = filteredDrops.map((doc) => ({
        ...doc,
        id: doc.id,
      }));
      const demosRef = collection(db, "demos");

      const secondCollectionSnapshot = await performOrQueryWithLimit(
        demosRef,
        "dropId",
        ids || []
      );

      let demosLvl = ids.reduce((acc, key) => {
        acc[key] = 0;
        return acc;
      }, {});
      let demosLvl1 = { ...demosLvl };
      let demosLvl2 = { ...demosLvl };
      let demosLvl3 = { ...demosLvl };
      let demosTotal = { ...demosLvl };

      // Filter drops based on screener level and screening status
      const filteredDocuments = secondCollectionSnapshot?.filter((data) => {
        const screenings = data?.screenings || [];
        if (
          screenings[0]?.screenerLevel === "level1" &&
          screenings[0].status !== "nogo"
        ) {
          ++demosLvl1[data?.dropId];
        }
        if (
          screenings[0]?.screenerLevel === "level1" &&
          screenings[0].status === "nogo"
        ) {
          ++demosLvl2[data?.dropId];
        }
        if (
          (screenings[1]?.screenerLevel === "level2" &&
            screenings[0]?.screenerLevel === "level1" &&
            screenings[0]?.status === "nogo" &&
            (screenings[1]?.status === "pitch" ||
              screenings[1]?.status === "nogo")) ||
          (screenings[0]?.screenerLevel === "level1" &&
            screenings[0]?.status === "pitch")
        ) {
          ++demosLvl3[data?.dropId];
        }

        ++demosTotal[data?.dropId];
        let index = screenings?.length - 1;
        if (
          (screenings?.length === 0 || screenings?.length === undefined) &&
          !data?.result &&
          user?.screenerLevel === "level1"
        ) {
          return data;
        }
        if (
          screenings?.length === 1 &&
          screenings[0]?.screenerLevel === "level1" &&
          screenings[0]?.status === "nogo" &&
          !data?.result &&
          user?.screenerLevel === "level2"
        ) {
          return true;
        }
        if (
          screenings?.length >= 1 &&
          screenings[0]?.screenerLevel === "level1" &&
          screenings[1]?.screenerLevel !== "level3" &&
          !data?.result &&
          user?.screenerLevel === "level3"
        ) {
          return screenings[index]?.status === "pitch";
        }
        return null;
      });

      let list = [];
      filteredDocuments.forEach((doc) => {
        list = [...list, doc?.dropId];
      });

      Object.keys(demosTotal)?.forEach((item) => {
        if (
          demosLvl2[item] !== demosTotal[item] - demosLvl1[item] &&
          user?.screenerLevel === "level2"
        ) {
          list = list.filter((val) => val !== item);
        }

        if (
          (demosLvl3[item] !== demosLvl2[item] + demosLvl1[item] ||
            demosTotal[item] !== demosLvl3[item]) &&
          user?.screenerLevel === "level3"
        ) {
          list = list.filter((val) => val !== item);
        }
      });

      // Fill data with drops that need screening
      let listData = data?.filter(
        (item) => list.includes(item?.id) && !item?.deliveryDate
      );

      listData = listData.sort(
        (a, b) => a?.expireTime?.toDate() - b?.expireTime?.toDate()
      );

      return listData;
    } catch (error) {
      setDrops([]);
      console.error("Error fetching data:", error);
    }
  }

  const chunkArray = (array, size) => {
    const chunks = [];
    for (let i = 0; i < array.length; i += size) {
      chunks.push(array.slice(i, i + size));
    }
    return chunks;
  };

  const performOrQueryWithLimit = async (collectionRef, field, values) => {
    const MAX_IN_VALUES = 30; // Firestore supports up to 30 values for `in` queries
    const chunks = chunkArray(values, MAX_IN_VALUES);

    const promises = chunks.map((chunk) => {
      const q = query(collectionRef, where(field, "in", chunk));
      return getDocs(q);
    });

    const querySnapshots = await Promise.all(promises);

    let results = [];
    querySnapshots.forEach((snapshot) => {
      snapshot.forEach((doc) => {
        results.push({ id: doc.id, ...doc.data() });
      });
    });

    return results;
  };

  const handleScreeningDrop = async () => {
    try {
      if (user?.screenerLevel === "level3" && user?.profession === "screener") {
        const docRef = doc(db, "drops", nowScreening?.id);

        if (
          nowScreening?.activeLevel3Screening?.userId === auth?.currentUser?.uid
        ) {
          navigate(`/drop/pitch/${nowScreening?.id}`);
          return;
        }

        await runTransaction(db, async (transaction) => {
          const dropDoc = await transaction.get(docRef);
          if (!dropDoc.exists()) {
            console.error("Document does not exist!");
          }
          const activeLevel3Screening = dropDoc.data()?.activeLevel3Screening;
          if (!activeLevel3Screening) {
            transaction.update(docRef, {
              activeLevel3Screening: {
                startedAt: Timestamp.fromDate(new Date()),
                userId: auth?.currentUser?.uid,
              },
            });
            navigate(`/drop/pitch/${nowScreening?.id}`);
          }
        });
      } else {
        navigate(`/drop/pitch/${nowScreening?.id}`);
      }
    } catch (error) {
      console.error("Error setting screener", error);
    }
  };

  return (
    <>
      <Header tab="drops" />
      {loading ? (
        <Loading />
      ) : (
        <Main
          drops={drops}
          nowScreening={nowScreening}
          loading={loading}
          handleScreeningDrop={handleScreeningDrop}
        />
      )}
      {!(nowScreening || drops?.length) && !loading && (
        <EmptyState title={"No drops added"} />
      )}
    </>
  );
}

export const Main = ({ drops, nowScreening, loading, handleScreeningDrop }) => {
  return (
    <>
      <div className="hidden lg:flex gap-4 justify-start items-center text-4xl lg:text-2xl font-extrabold mt-14 text-white text-left">
        Drops
      </div>
      {nowScreening ? (
        <section className="mt-8 flex flex-col items-center">
          <div className="text-center text-gray-50 text-[32px] lg:text-lg lg:font-extrabold font-bold leading-10 mb-8 lg:mb-4">
            Now Screening
          </div>
          {loading ? (
            <div
              className={`skeleton max-w-[300px] w-full h-[300px] relative rounded-xl border border-violet-600 overflow-hidden`}
            ></div>
          ) : (
            <div
              className="max-w-[300px] lg:max-w-[260px] group cursor-pointer hover:bg-black hover:bg-opacity-50 w-full h-[300px] lg:h-[260px] relative rounded-xl border border-violet-600 overflow-hidden"
              onClick={handleScreeningDrop}
            >
              <div className="text-white text-xl font-extrabold absolute top-4 left-4 leading-[41.63px] z-20 capitalize">
                {nowScreening ? nowScreening?.artist : null}
              </div>
              <div className="hidden group-hover:flex w-full h-full absolute z-10 bg-black opacity-50 transition-all duration-300"></div>
              <img
                src={nowScreening && nowScreening?.artworkUrl}
                alt="preview cover art"
                className="h-full w-full object-cover"
              />
            </div>
          )}
        </section>
      ) : null}
      {drops?.length || nowScreening ? (
        <section className="my-10 lg:mb-40">
          <div className="w-full text-center lg:text-left text-gray-50 text-[32px] lg:text-lg lg:font-extrabold font-bold leading-10 mb-9 lg:mb-4">
            Next Up
          </div>
          <div className="flex flex-row justify-start gap-x-6 gap-y-9 flex-wrap">
            {drops?.length ? (
              drops?.map((item) => {
                return (
                  <div
                    key={item.id}
                    className="mx-auto w-[300px] h-[300px] lg:w-[260px] lg:h-[260px] relative rounded-xl border border-violet-600 overflow-hidden"
                  >
                    <div className="text-white text-lg font-extrabold absolute top-2 left-4 leading-[41.63px] capitalize">
                      {item?.artist || ""}
                    </div>
                    <img
                      src={item?.artworkUrl || dropImg}
                      alt="preview cover art"
                      className="h-full w-full object-cover"
                    />
                  </div>
                );
              })
            ) : (
              <div className="text-white text-center w-full font-bold italic">
                No data to display...
              </div>
            )}
          </div>
        </section>
      ) : null}
    </>
  );
};
