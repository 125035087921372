import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import Fuse from "fuse.js";
import { Dropdown } from "components/shared/Dropdown/Dropdown";
import { InputValidation } from "components/shared/InputValidation/InputValidation";
import {
  GENRES_LIST,
  LANGUAGES_LIST,
  LANGUAGES_LIST_OBJ,
} from "utils/constants";
import calendarIcon from "assets/icons/custom-calendar-icon.svg";
import "react-datepicker/dist/react-datepicker.css";
import {
  getAllCountriesList,
  getAllCountriesObject,
  getKeyByValue,
} from "utils/helpers";

export function DropForm({ formValues, setFormValues }) {
  const [searchTerm, setSearchTerm] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [isSupportedCountry, setIsSupportedCountry] = useState(true);

  const allCountriesObject = getAllCountriesObject();

  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);
  const maxDate = new Date(today);
  maxDate.setDate(today.getDate() + 90);

  const fuse = new Fuse(getAllCountriesList(), {
    threshold: 0.4,
    includeScore: true,
  });

  const handleInputChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);

    if (value.trim() === "") {
      setSuggestions([]);
    } else {
      const results = fuse.search(value);
      const filteredSuggestions = results.map((result) => result.item);
      setSuggestions(filteredSuggestions);
    }
  };

  const handleSuggestionClick = (value) => {
    setSearchTerm(value);
    setSuggestions([]);
    setFormValues((prev) => ({
      ...prev,
      regionalOffice: value,
    }));
  };

  const calculateHourDifference = (selectedDate) => {
    let fixedSelectedDate = new Date(selectedDate);

    const currentDate = new Date();

    // Set the time of the fixedSelectedDate to the current time
    fixedSelectedDate.setHours(
      currentDate.getHours(),
      currentDate.getMinutes(),
      currentDate.getSeconds()
    );
    const endDate = new Date(fixedSelectedDate);

    const differenceInMilliseconds = endDate.getTime() - currentDate.getTime();

    // If the difference is less than 24 hours (in milliseconds), add 24 hours worth of milliseconds
    if (differenceInMilliseconds < 24 * 60 * 60 * 1000) {
      endDate.setTime(currentDate.getTime() + 24 * 60 * 60 * 1000 - 1000);
    }

    return endDate;
  };

  useEffect(() => {
    const countriesList = getAllCountriesList();
    if (!searchTerm) return;
    if (countriesList.includes(formValues.regionalOffice))
      setIsSupportedCountry(true);
    else setIsSupportedCountry(false);
  }, [formValues, searchTerm]);

  return (
    <div className="flex-col justify-start items-start gap-10 flex w-full">
      <div className="self-stretch flex-col justify-start items-start gap-8 flex">
        <div className="self-stretch text-gray-50 text-base font-bold leading-normal">
          Drop Details
        </div>
        <div className="self-stretch flex-col justify-start items-start gap-3 flex">
          <h6 className="text-gray-400 text-xs font-extrabold uppercase leading-3">
            Artist/Project
          </h6>
          <InputValidation
            type="text"
            placeholder="Insert Artist/Project name"
            value={formValues.artist}
            onChange={(e) =>
              setFormValues((prev) => ({ ...prev, artist: e.target.value }))
            }
            className="px-4 !py-3"
          />
        </div>
        <div className="self-stretch flex-col justify-start items-start gap-3 flex">
          <h6 className="text-gray-400 text-xs font-extrabold uppercase leading-3">
            deadline
          </h6>
          <div className="w-full flex relative">
            <DatePicker
              selected={formValues?.expireTime}
              minDate={tomorrow}
              maxDate={maxDate}
              className="tw-input-login !w-full !py-3"
              onChange={(value) => {
                setFormValues((prev) => ({
                  ...prev,
                  expireTime: calculateHourDifference(value),
                }));
              }}
              placeholderText="Select deadline"
            />
            <img
              src={calendarIcon}
              className="w-5 h-5 absolute top-[30%] right-6"
              alt="calendar"
            />
          </div>
        </div>
        <DropIDetailsItems
          title={"label/company"}
          placeholder={"Insert Label/Company name"}
          value={formValues.recordLabel}
          onChange={(e) =>
            setFormValues((prev) => ({ ...prev, recordLabel: e.target.value }))
          }
        />
        <div className="self-stretch flex-col justify-start items-start gap-3 flex">
          <div className="text-gray-400 text-xs font-extrabold uppercase leading-3">
            Regional office (country)
          </div>
          <InputValidation
            type="text"
            errorMsg={isSupportedCountry ? null : "Country name not correct"}
            placeholder="Select Regional office (Country)"
            value={
              allCountriesObject[formValues.regionalOffice] ||
              formValues.regionalOffice
            }
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                if (suggestions?.length === 1) {
                  handleSuggestionClick(suggestions[0]);
                }
              }
            }}
            onChange={(e) => {
              setFormValues((prev) => ({
                ...prev,
                regionalOffice: e.target.value,
              }));
              handleInputChange(e);
            }}
            className="px-4 !py-3"
          />
          {suggestions?.length && !isSupportedCountry ? (
            <ul className="!bg-gray-900 !bg-opacity-85 text-white z-10 w-full !rounded-2xl border border-solid tw-input-login px-7 py-5 gap-4 flex flex-col max-h-52 overflow-y-auto">
              {suggestions.map((suggestion, index) => (
                <li
                  key={index}
                  onClick={() => handleSuggestionClick(suggestion)}
                >
                  {suggestion}
                </li>
              ))}
            </ul>
          ) : null}
        </div>
        <div className="self-stretch flex-col justify-start items-start gap-3 flex">
          <div className="text-gray-400 text-xs uppercase font-extrabold leading-3">
            genre (select main genre)
          </div>
          <div className="w-full">
            <Dropdown
              tabIndex="0"
              type="pils"
              data={GENRES_LIST}
              placeholder={"Select main genre"}
              value={getKeyByValue(formValues.genre) || formValues.genre}
              onChange={(value) =>
                setFormValues((prev) => ({ ...prev, genre: value }))
              }
            />
          </div>
        </div>
        <div className="self-stretch flex-col justify-start items-start gap-3 flex">
          <div className="text-gray-400 text-xs font-extrabold uppercase leading-3">
            lyrics language
          </div>
          <div className="w-full">
            <Dropdown
              data={LANGUAGES_LIST}
              type="pils"
              placeholder={"Select Lyrics language for screeners"}
              value={
                LANGUAGES_LIST_OBJ[formValues.language] || formValues.language
              }
              onChange={(value) =>
                setFormValues((prev) => ({ ...prev, language: value }))
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function DropIDetailsItems({ title, placeholder, value, onChange }) {
  return (
    <div className="self-stretch flex-col justify-start items-start gap-3 flex">
      <div className="text-gray-400 text-xs font-extrabold uppercase leading-3">
        {title}
      </div>
      <InputValidation
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e)}
        className="px-4 !py-3"
      />
    </div>
  );
}
