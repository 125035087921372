import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Timestamp,
  addDoc,
  arrayUnion,
  collection,
  deleteField,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
  writeBatch,
} from "firebase/firestore";
import { auth, db } from "config/firebase-config";
import { AudioPlayer } from "components/shared/AudioPlayer/AudioPlayer";
import { MainWrapper } from "components/shared/MainWrapper/MainWrapper";
import { Header } from "components/shared/Header/Header";
import arrowIcon from "assets/icons/arrow-icon.svg";
import playIcon from "assets/icons/play-icon.svg";
import pauseIcon from "assets/icons/pause-icon.svg";
import rewindIcon from "assets/icons/rewind-icon.svg";
import infoIcon from "assets/icons/info-icon.svg";
import { ROLES_LIST, STATUS_STATE } from "utils/constants";
import {
  formatDate,
  generateRandomString,
  generateUUID,
  getCountryFullName,
  isMobileDevice,
  parseURLEncoding,
} from "utils/helpers";
import { TooltipItem } from "components/shared/Tooltip/Tooltip";
import Buttons from "./ButtonsGroup";
import noImage from "assets/images/no-image.png";
import dotsIcon from "assets/icons/dots-icon.svg";
import emailIcon from "assets/icons/email-verification-icon.svg";
import shareIcon from "assets/icons/share-icon.svg";
import loadingAnimation from "assets/icons/loading.svg";
import deleteIcon from "assets/icons/delete-icon.svg";
import { Modal } from "components/shared/Modal";

function PitchingView() {
  let storedArray = JSON.parse(localStorage.getItem("demos")) || {};
  const [showDescription, setShowDescription] = useState(false);
  const [showNoteModal, setShowNoteModal] = useState(false);
  const [showPitchModal, setShowPitchModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { id } = useParams();
  const navigate = useNavigate();
  const [drop, setDrop] = useState([]);
  const [demos, setDemos] = useState(null);
  const [user, setUser] = useState(null);
  const [demosSelected, setDemosSelected] = useState([]);
  const [index, setIndex] = useState(0);
  const [totalDemos, setTotalDemos] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [firstRender, setFirstRender] = useState(true);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [status, setStatus] = useState("");
  const [forcePause, setForcePause] = useState(false);
  const [storedArrayValues, setStoredArrayValues] = useState(storedArray);
  const [isSongPlayed, setIsSongPlayed] = useState(false);
  const [isDoubleClick, setIsDoubleClick] = useState(false);

  const isDouble = useRef(0);

  const getUser = async (id) => {
    const docRef = doc(db, "users", id);
    try {
      const snapshot = await getDoc(docRef);
      if (snapshot.data()?.profession === "screener") setShowNoteModal(true);
      setUser(snapshot.data());
    } catch (error) {
      console.log("Error", error);
    }
  };

  useEffect(() => {
    setFirstRender(true);
  }, []);

  const qDemos = query(collection(db, "demos"), where("dropId", "==", id));

  const getItem = async () => {
    const docRef = doc(db, "drops", id);

    try {
      const snapshot = await getDoc(docRef);
      const data = snapshot.data();

      if (Timestamp.fromDate(new Date()) < data?.expireTime) {
        navigate("/");
      }

      if (
        data?.userId !== auth?.currentUser?.uid &&
        ["anr", "corporate", "manager", "supervisor"].includes(user?.profession)
      )
        navigate("/");

      if (
        user?.profession === "screener" &&
        data?.activeLevel3Screening &&
        data?.activeLevel3Screening?.userId !== auth?.currentUser?.uid
      )
        navigate("/");

      const querySnapshotDemos = await getDocs(qDemos);

      setDrop(data);
      let demoListDemos = [];
      querySnapshotDemos.forEach((doc) => {
        const data = doc.data();

        let screenings = data?.screenings;

        if (
          screenings?.length >= 1 &&
          screenings[0]?.screenerLevel === "level1" &&
          (screenings[1]?.screenerLevel === "level3" ||
            screenings[2]?.screenerLevel === "level3") &&
          ["anr", "corporate", "manager", "supervisor"].includes(
            user?.profession
          )
        ) {
          if (screenings[screenings?.length - 1]?.status === "pitch")
            demoListDemos.push({ ...data, id: doc.id });
          return;
        }
        if (data?.result) return;
        if (
          (screenings?.length === 0 || screenings?.length === undefined) &&
          user?.screenerLevel === "level1"
        ) {
          demoListDemos.push({ ...data, id: doc.id });
        }
        if (
          screenings?.length === 1 &&
          screenings[0]?.screenerLevel === "level1" &&
          screenings[0]?.status === "nogo" &&
          user?.screenerLevel === "level2"
        ) {
          demoListDemos.push({ ...data, id: doc.id });
        }
        if (user?.screenerLevel === "level3") {
          if (screenings?.length === 1 && screenings[0]?.status !== "nogo") {
            demoListDemos.push({ ...data, id: doc.id });
          }
          if (
            screenings?.length > 1 &&
            screenings[0]?.screenerLevel === "level1" &&
            screenings[1]?.screenerLevel !== "level3" &&
            screenings[2]?.screenerLevel !== "level3" &&
            screenings[1]?.status !== "nogo" &&
            user?.screenerLevel === "level3"
          ) {
            demoListDemos.push({ ...data, id: doc.id });
          }
        }
      });

      demoListDemos.sort((a, b) => a.createdTime - b.createdTime);
      setDemos([...demoListDemos]);
      setTotalDemos(demoListDemos?.length || 0);
      return querySnapshotDemos;
    } catch (error) {
      console.log("Error getting drops", error);
    }
  };

  const updateItemANR = async (status) => {
    try {
      setIsLoading(true);
      const itemDoc = doc(db, "demos", demos[index]?.id);

      await updateDoc(itemDoc, {
        result: status,
      });
      const oldDemos = [...demos];
      oldDemos[index].result = status;
      setDemos([...oldDemos]);

      let storedArray = JSON.parse(localStorage.getItem("demos"));
      if (storedArray) {
        storedArray = {
          ...storedArray,
          [id]: storedArray[id].filter(
            (value) => value !== demos[index]?.pitchId
          ),
        };
        localStorage.setItem("demos", JSON.stringify(storedArray));
      }
    } catch (error) {
      console.error("Error updating status of demo", error);
    } finally {
      setIsLoading(false);
      setStatus("");
    }
  };

  const updateItem = async (status, index = 0) => {
    try {
      let updatedItem = {
        ended: drop?.expireTime,
        id: generateUUID(),
        pitchId: demos[index]?.pitchId,
        screenerLevel: user?.screenerLevel,
        started: drop?.createdTime,
        status: status,
        userId: demos[index]?.userId,
      };

      const itemDoc = doc(db, "demos", demos[index]?.id);
      await updateDoc(itemDoc, {
        screenings: arrayUnion({ ...updatedItem }),
      });

      await updateEventStatus(demos[index]?.pitchId);

      let oldDemos = demos;
      const newArray = oldDemos.slice(1);
      setDemos([...newArray]);
      if (newArray?.length === 0) {
        navigate(-1);
      }
    } catch (error) {
      console.error("Error updating demo", error);
    } finally {
      setStatus("");
    }
  };

  const containsObjectWithProp = (arr, obj, key) => {
    return arr.some((item) => item[key] === obj[key]);
  };

  const handleBatchUpdate = async () => {
    try {
      const docRef = doc(db, "drops", id);
      const batch = writeBatch(db);

      for (let i = 0; i < demos?.length; i++) {
        const hasSameValue = containsObjectWithProp(
          demosSelected,
          demos[i],
          "id"
        );
        let updatedItem = {
          ended: drop?.expireTime,
          id: generateUUID(),
          pitchId: demos[i]?.pitchId,
          screenerLevel: user?.screenerLevel,
          started: drop?.createdTime,
          status: hasSameValue ? "pitch" : "nogo",
          userId: auth?.currentUser?.uid,
        };

        const itemDoc = doc(db, "demos", demos[i]?.id);
        const docRef = doc(
          db,
          `users/${auth?.currentUser?.uid}/screeningEvents`,
          `${demos[i]?.pitchId}`
        );

        const docSnapshot = await getDoc(docRef);

        await batch.update(itemDoc, {
          screenings: arrayUnion({ ...updatedItem }),
        });

        if (docSnapshot.exists()) {
          await batch.update(docRef, {
            didSetStatus: true,
          });
        }
      }

      batch
        .commit()
        .then(() => {
          console.log("Batch update successful");
          let filteredDemos = demos.filter(
            (item) =>
              !demosSelected.some(
                (selected) => item["pitchId"] === selected["pitchId"]
              )
          );

          setDemos(filteredDemos);
          setDemosSelected([]);
        })
        .catch((error) => {
          console.error("Error updating documents in batch:", error);
        })
        .finally(async () => {
          await updateDoc(docRef, {
            activeLevel3Screening: deleteField(),
            deliveryDate: Timestamp.fromDate(new Date()),
          });
          navigate("/drop/pitch");
        });
    } catch (error) {
      console.log("Error updating multiple demos status", error);
    } finally {
      setDemosSelected(null);
      setStatus("");
    }
  };

  const handleChangeCheckbox = (id, demo) => {
    const array = [...demosSelected];
    const index = array.findIndex((item) => item?.id === demo?.id);

    if (index === -1) {
      array.push(demo);
    } else {
      array.splice(index, 1);
    }

    setDemosSelected(array);
  };

  const updateEventStatus = async (pitchId) => {
    try {
      const docRef = doc(
        db,
        `users/${auth?.currentUser?.uid}/screeningEvents`,
        `${pitchId}`
      );

      await updateDoc(docRef, {
        didSetStatus: true,
      });
    } catch (error) {
      console.log("Error adding screening event", error);
    }
  };

  const addScreeningEvent = async (pitchId, duration = 0) => {
    if (user?.profession !== "screener") return;
    if (user?.screenerLevel === "level1" || user?.screenerLevel === "level2") {
      setIsSongPlayed(true);
    }
    try {
      if (index < demos?.length && user?.screenerLevel === "level3") {
        storedArray = {
          ...storedArray,
          [id]: [...(storedArray[id] || []), pitchId],
        };
        storedArray[id] = [...new Set(storedArray[id])];
        localStorage.setItem("demos", JSON.stringify(storedArray));
      }
      const docRef = doc(
        db,
        `users/${auth?.currentUser?.uid}/screeningEvents`,
        `${pitchId}`
      );

      await setDoc(docRef, {
        lastChanged: Timestamp.fromDate(new Date()),
        songDuration: Math.floor(duration),
      });
    } catch (error) {
      console.log("Error adding screening event", error);
    }
  };

  const addScreeningEventDuration = async (pitchId, seconds) => {
    try {
      if (user?.profession === "screener") {
        const docRef = collection(
          db,
          `users/${auth?.currentUser?.uid}/screeningEvents`,
          `${pitchId}/events`
        );

        await addDoc(docRef, {
          createdAt: Timestamp.fromDate(new Date()),
          seconds: Math.floor(seconds),
        });
      }
    } catch (error) {
      console.log("Error adding screening event", error);
    }
  };

  const handleEmail = async (userId, demo) => {
    const docRef = doc(db, "users", userId);
    try {
      const snapshot = await getDoc(docRef);
      const userDemo = snapshot.data();
      const subject = `VELV Submission | ${demo?.title}`;
      const body = `_________________________________\n
      Artist: ${drop?.artist}\n
      Company: ${user?.companyName}\n
      Regional Office: ${getCountryFullName(drop?.regionalOffice)}\n
      Demo: ${demo?.title}\n
      
      Velv disclaims all responsibility for dialogues that take place outside the Platform and wishes all parties the best of luck\n
      `;
      const mailTo = `mailto:${userDemo?.email}?subject=${encodeURIComponent(
        subject
      )}&body=${encodeURIComponent(body)}`;

      return mailTo;
    } catch (error) {
      console.log("Error", error);
      return "#";
    }
  };

  const handleDeleteActiveScreener3 = async () => {
    try {
      const itemDoc = doc(db, "drops", id);
      await updateDoc(itemDoc, {
        activeLevel3Screening: deleteField(),
      });
      const storedArray = JSON.parse(localStorage.getItem("demos"));
      if (storedArray) {
        delete storedArray[id];
        localStorage.setItem("demos", JSON.stringify(storedArray));
      }
      navigate("/drop/pitch");
    } catch (error) {
      console.log("Error deleting active screener", error);
    }
  };

  const updateDropCheckbox = () => {
    setStoredArrayValues(storedArray);
  };

  const handleClickOnSong = (item, idx) => {
    isDouble.current += 1;

    const timeout = setTimeout(
      () => {
        if (isDouble.current === 2) {
          if (
            user?.screenerLevel !== "level1" &&
            user?.screenerLevel !== "level2"
          ) {
            setFirstRender(false);
            setIndex(idx);
            const randomNumber = Math.floor(1 + Math.random() * 100);
            setIsDoubleClick(randomNumber);
          } else {
            setFirstRender(false);
          }
          if (
            index < demos?.length &&
            user?.screenerLevel !== "level1" &&
            user?.screenerLevel !== "level2"
          ) {
            storedArray = {
              ...storedArray,
              [id]: [...(storedArray[id] || []), item?.pitchId],
            };
            storedArray[id] = [...new Set(storedArray[id])];
            localStorage.setItem("demos", JSON.stringify(storedArray));
          }
          isDouble.current = 0;
        }
        if (isDouble.current === 1) {
          if (
            user?.screenerLevel !== "level1" &&
            user?.screenerLevel !== "level2"
          ) {
            setFirstRender(true);
            if (index !== idx) setIsDoubleClick(0);
            setIndex(idx);
          }
          if (
            index < demos?.length &&
            user?.screenerLevel !== "level1" &&
            user?.screenerLevel !== "level2"
          ) {
            storedArray = {
              ...storedArray,
              [id]: [...(storedArray[id] || []), item?.pitchId],
            };
            storedArray[id] = [...new Set(storedArray[id])];
            localStorage.setItem("demos", JSON.stringify(storedArray));
          }
          isDouble.current = 0;
        }
        clearTimeout(timeout);
      },
      250,
      item,
      idx
    ); // 250ms delay
  };

  useEffect(() => {
    getUser(auth?.currentUser?.uid);
  }, []);

  useEffect(() => {
    if (user) {
      getItem();
    }
  }, [user]);

  return (
    <MainWrapper showFooter={false}>
      <Header />
      <div className="flex gap-4 justify-start items-center text-xl lg:text-2xl font-extrabold mt-10 text-white text-left cursor-pointer z-50">
        <button
          onClick={() => navigate(`/drop/myinbox`)}
          className="flex items-center gap-3 capitalize"
        >
          <img
            src={arrowIcon}
            alt="chevron"
            className="rotate-90 w-3 h-3 lg:w-5 lg:h-5"
          />
          {drop?.artist || "-"}{" "}
        </button>
        {user?.profession === ROLES_LIST.SCREENER &&
          user?.screenerLevel === "level3" && (
            <button
              onClick={() => setShowDeleteModal(true)}
              className="ml-auto w-5"
            >
              <img src={deleteIcon} alt="delete drop" />
            </button>
          )}
      </div>
      <div className="mt-8 flex items-center xl:items-start w-full flex-col xl:flex-row pb-52">
        <div className="flex flex-col items-center lg:items-start w-full lg:w-fit">
          <img
            src={drop?.artworkUrl || noImage}
            alt="drop"
            className="w-[336px] mx-auto lg:mx-0 h-[316px] lg:w-[240px] lg:h-[240px] object-cover bg-gradient-to-r from-black to-black rounded-3xl border border-violet-600"
          />
          <section className="lg:w-[306px] justify-center flex-col items-start inline-flex lg:mb-16 mt-4">
            <button
              onClick={() => setShowDescription(!showDescription)}
              className="justify-center items-center gap-4 inline-flex"
            >
              <div className="text-gray-50 text-xl font-bold">
                Drop Description
              </div>
              <img
                src={arrowIcon}
                alt="chevron"
                className={`w-3 h-3 mt-[2px] transition-all ${
                  showDescription ? "-rotate-180" : "rotate-0"
                }`}
              />
            </button>
            <div
              className={`max-w-[644px] w-full ${
                showDescription ? "max-h-fit" : "h-0"
              } transition-all duration-300 overflow-hidden mb-8 lg:mb-0 flex-col gap-4 lg:gap-2`}
            >
              <div className="max-w-[593] w-full left-0 top-0 flex-col justify-start items-start inline-flex mt-4">
                <div className="max-w-[247px]">
                  <span className="text-white text-lg font-semibold capitalize">
                    Looking for:
                  </span>
                </div>
                <div className="max-w-[594px] text-white text-sm font-normal leading-[20.80px]">
                  {drop?.sections?.length ? drop?.sections[0]?.text : "-"}
                </div>
              </div>
              {drop?.sections?.length && drop?.sections[1]?.text ? (
                <div className="max-w-[644px] w-full flex-col justify-start items-start inline-flex mt-2">
                  <div className="max-w-[328px]">
                    <span className="text-white text-lg font-semibold capitalize">
                      Important note:
                    </span>
                  </div>
                  <div className="max-w-[644px] text-white text-sm font-normal leading-[20.80px]">
                    {drop?.sections?.length ? drop?.sections[1]?.text : "-"}
                  </div>
                </div>
              ) : null}

              {(user?.profession === ROLES_LIST.ANR ||
                user?.profession === ROLES_LIST.MANAGER ||
                user?.profession === ROLES_LIST.SUPERVISOR ||
                user?.profession === ROLES_LIST.CORPORATE) && (
                <div className="max-w-[593] w-full left-0 top-0 flex-col justify-start items-start gap-4 inline-flex">
                  <div className="max-w-[247px] h-[38px] mt-2">
                    <span className="text-white text-2xl font-bold capitalize leading-[72px]">
                      Summary:
                    </span>
                  </div>
                  <div className="max-w-[594px] text-white text-base font-normal leading-[20.80px] pt-2 flex flex-col gap-3">
                    <div className="flex flex-col">
                      <p className="font-semibold text-lg">Publishing date:</p>
                      <p className="text-sm">
                        {formatDate(drop?.createdTime, true)}
                      </p>
                    </div>
                    <div className="flex flex-col">
                      <p className="font-semibold text-lg">Closing date:</p>
                      <p className="text-sm">
                        {formatDate(drop?.expireTime, true)}
                      </p>
                    </div>
                    <div className="flex flex-col">
                      <p className="font-semibold text-lg">Delivery date:</p>
                      <p className="text-sm">
                        {formatDate(drop?.deliveryDate, true)}
                      </p>
                    </div>
                    <div className="flex flex-col">
                      <p className="font-semibold text-lg">Submissions:</p>
                      <p className="text-sm">{totalDemos}</p>
                    </div>
                    <div className="flex flex-col">
                      <p className="font-semibold text-lg">Delivered:</p>
                      <p className="text-sm">{demos?.length || 0}</p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </section>
        </div>
        <div className="w-full">
          <section className="flex flex-col items-center gap-8 w-full">
            {demos?.length ? (
              demos?.map((item, idx) => (
                <div
                  key={item?.pitchId || idx}
                  className={`w-full ${
                    user?.screenerLevel !== "level1" &&
                    user?.screenerLevel !== "level2"
                      ? "cursor-pointer"
                      : ""
                  } select-none`}
                  onClick={() => handleClickOnSong(item, idx)}
                >
                  <DropItem
                    disabled={
                      user?.screenerLevel === "level3" ||
                      ["anr", "corporate", "manager", "supervisor"].includes(
                        user?.profession
                      )
                        ? !item?.result &&
                          !storedArray[id]?.includes(item?.pitchId)
                        : idx > 0
                    }
                    id={idx + 1}
                    selected={index === idx}
                    demo={item}
                    storedArray={storedArrayValues[id]?.includes(item?.pitchId)}
                    drop={drop}
                    user={user}
                    handleChangeCheckbox={handleChangeCheckbox}
                    handleEmail={handleEmail}
                  />
                </div>
              ))
            ) : (
              <div className="text-white mb-20">No demos to display...</div>
            )}
          </section>
          <div className="fixed left-0 bottom-0 w-full bg-black bg-opacity-70 backdrop-blur-md px-8 pb-5 z-30">
            <div className="max-w-4xl mx-auto inset-x-0">
              {isLoading && (
                <div className="flex justify-center items-center w-full h-full bg-black bg-opacity-70 text-white absolute top-0 z-20">
                  <img
                    src={loadingAnimation}
                    alt="loading"
                    className="text-white w-20"
                  />
                </div>
              )}
              {demos && demos[index]?.mediaUrl ? (
                <AudioPlayer
                  key={
                    user?.screenerLevel === "level3"
                      ? isDoubleClick
                      : user?.screenerLevel === "level1" ||
                        user?.screenerLevel === "level2"
                      ? demos[index]?.mediaUrl
                      : generateRandomString(5)
                  }
                  autoPlay={!firstRender}
                  rewindIcon={rewindIcon}
                  playIcon={playIcon}
                  pauseIcon={pauseIcon}
                  firstRender={firstRender}
                  source={demos[index]?.mediaUrl}
                  demo={{
                    ...demos[index],
                  }}
                  totalDemos={totalDemos}
                  index={index}
                  showSkipIcon={
                    user?.profession === ROLES_LIST.ANR ||
                    user?.profession === ROLES_LIST.MANAGER ||
                    user?.profession === ROLES_LIST.SUPERVISOR ||
                    user?.profession === ROLES_LIST.CORPORATE ||
                    (user?.profession === ROLES_LIST.SCREENER &&
                      user?.screenerLevel === "level3")
                  }
                  setIndex={setIndex}
                  forcePause={forcePause}
                  addScreeningEvent={addScreeningEvent}
                  addScreeningEventDuration={addScreeningEventDuration}
                  updateDropCheckbox={
                    user?.screenerLevel === "level3" && updateDropCheckbox
                  }
                />
              ) : null}
              {/* SCREENER 1,2 */}
              {user?.profession === ROLES_LIST.SCREENER &&
              (user?.screenerLevel === "level1" ||
                user?.screenerLevel === "level2") ? (
                <Buttons.ScreenerOneTwoButtons
                  updateItem={(status) => {
                    setShowConfirmModal(true);
                    setForcePause(Math.floor(Math.random() * 100) + 1);
                    setStatus(status);
                  }}
                  disabled={
                    !isSongPlayed ||
                    !(demos && demos[index]?.mediaUrl) ||
                    isLoading
                  }
                />
              ) : null}

              {/* SCREENER 3 */}
              {user?.profession === ROLES_LIST.SCREENER &&
              user?.screenerLevel === "level3" ? (
                <Buttons.ScreenerThreeButtons
                  demosSelected={demosSelected?.length || 0}
                  demos={demos}
                  handleBatchUpdate={(status) => {
                    setShowConfirmModal(true);
                    setForcePause(Math.floor(Math.random() * 100) + 1);
                    setStatus(status);
                  }}
                  requestedPitchQuantity={drop?.requestedPitchQuantity}
                  disabled={
                    isLoading ||
                    !demosSelected?.length ||
                    !(demos && demos[index]?.mediaUrl)
                  }
                  infoIcon={infoIcon}
                />
              ) : null}

              {/* A&R BUTTONS */}
              {user?.profession === ROLES_LIST.ANR ||
              user?.profession === ROLES_LIST.MANAGER ||
              user?.profession === ROLES_LIST.SUPERVISOR ||
              user?.profession === ROLES_LIST.CORPORATE ? (
                <Buttons.ANRButtons
                  infoIcon={infoIcon}
                  updateItem={(status) => {
                    setShowConfirmModal(true);
                    setForcePause(Math.floor(Math.random() * 100) + 1);
                    setStatus(status);
                  }}
                  disabled={!(demos && demos[index]?.mediaUrl) || isLoading}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
      {showNoteModal && (
        <Modal setShowModal={setShowNoteModal} title="Important note">
          <ul className="flex flex-col gap-4 mt-4 justify-center text-sm lg:text-lg items-center text-gray-50 text-center">
            <li>1. Read the brief carefully</li>
            <li>2. Ensure that song matches brief.</li>
            <li>
              3. Ensure that recording and production maintains professional
              standard.
            </li>
            <li>4. Listen carefully</li>
          </ul>
          <div className="self-stretch flex-col justify-start items-start gap-2 flex">
            <button
              className="self-stretch px-6 py-3 mt-4 bg-violet-500 rounded-[90px] justify-center items-center gap-3 inline-flex cursor-pointer"
              onClick={() => setShowNoteModal(false)}
            >
              <div className="text-center text-gray-50 text-sm lg:text-base font-bold leading-none">
                I understand
              </div>
            </button>
          </div>
        </Modal>
      )}
      {showPitchModal && (
        <Modal setShowModal={setShowPitchModal} title="Pitch">
          <div className="flex flex-col gap-4">
            <p className="text-gray-50 text-base lg:text-lg font-medium leading-normal mb-4 mt-4">
              Are you sure you want to pitch this drop?
            </p>
            <div className="w-full h-10 px-6 py-3 bg-violet-600 rounded-[90px] justify-center items-center gap-3 inline-flex">
              <div className="text-center text-gray-50 text-base font-bold">
                Yes
              </div>
            </div>
            <div className="w-full h-10 px-6 py-3 rounded-[90px] border-2 border-zinc-700 justify-center items-center gap-3 inline-flex">
              <div className="text-center text-gray-50 text-base font-bold">
                Cancel
              </div>
            </div>
          </div>
        </Modal>
      )}
      {showDeleteModal && (
        <Modal setShowModal={setShowDeleteModal} title="Delete">
          <div className="flex flex-col gap-4">
            <p className="text-gray-50 text-base lg:text-lg font-medium leading-normal mb-4 mt-4">
              Are you sure you want to delete this job?
            </p>
            <button
              onClick={handleDeleteActiveScreener3}
              className="w-full h-10 px-6 py-3 text-gray-50 font-bold bg-violet-600 rounded-[90px] justify-center items-center gap-3 inline-flex"
            >
              Yes
            </button>
            <button
              onClick={() => setShowDeleteModal(false)}
              className="w-full h-10 px-6 py-3 text-gray-50 font-bold rounded-[90px] border-2 border-zinc-700 justify-center items-center gap-3 inline-flex"
            >
              Cancel
            </button>
          </div>
        </Modal>
      )}

      {showConfirmModal && (
        <ConfirmationModal
          updateModalState={setShowConfirmModal}
          handleConfirm={updateItemANR}
          status={status}
          setForcePause={setForcePause}
        />
      )}
      {showConfirmModal &&
        user?.profession === ROLES_LIST.SCREENER &&
        (user?.screenerLevel === "level1" ||
          user?.screenerLevel === "level2") && (
          <ConfirmationModal
            updateModalState={setShowConfirmModal}
            handleConfirm={updateItem}
            status={status}
            setForcePause={setForcePause}
            setIsSongPlayed={setIsSongPlayed}
          />
        )}
      {showConfirmModal &&
        user?.profession === ROLES_LIST.SCREENER &&
        user?.screenerLevel === "level3" && (
          <ConfirmationModal
            updateModalState={setShowConfirmModal}
            handleConfirm={handleBatchUpdate}
            status={status}
            setForcePause={setForcePause}
          />
        )}
    </MainWrapper>
  );
}

function DropItem({
  type = "screener",
  disabled,
  id = "1",
  selected = false,
  demo,
  drop,
  user,
  handleChangeCheckbox,
  storedArray,
  handleEmail,
}) {
  const [emailHref, setEmailHref] = useState("#");

  useEffect(() => {
    const fetchEmailHref = async () => {
      if (demo) {
        const href = await handleEmail(demo?.userId, demo);
        setEmailHref(href);
      }
    };
    fetchEmailHref();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [demo]);
  return (
    <div className="relative">
      {disabled && (
        <div className="absolute bg-neutral-900 w-full h-full opacity-0 z-10"></div>
      )}
      <div
        className={`w-full  flex-col justify-start items-start gap-3 inline-flex ${
          disabled && !selected ? "opacity-50" : "opacity-100"
        }`}
      >
        <div className="w-full lg:justify-start items-center inline-flex">
          {user?.screenerLevel !== "level1" &&
            user?.screenerLevel !== "level2" && (
              <div
                className={`hidden lg:flex  mr-12 lg:mr-8 text-xl font-bold leading-relaxed ${
                  selected ? "text-purple" : "text-slate-500"
                }`}
              >
                {id}
              </div>
            )}
          <img
            src={drop?.artworkUrl || noImage}
            alt="drop"
            className="w-16 h-16 justify-center items-center rounded-lg mr-3 lg:mr-8"
          />
          <div className="max-w-[983px] flex-col justify-start items-start gap-1 inline-flex">
            <div
              className={`w-full  text-sm lg:text-lg font-medium leading-normal ${"text-gray-50"} capitalize`}
            >
              {drop?.artist || "-"}
            </div>
            <div
              className={`w-full text-gray-50 text-sm font-normal ${"text-gray-50"}`}
            >
              {demo?.title || "-"}
            </div>
          </div>

          <div className="ml-auto flex">
            {(demo?.result === "hold" || demo?.result === "cut") && (
              <a
                href={emailHref}
                className="flex gap-2 justify-center items-center cursor-pointer border border-solid border-purple rounded-full w-10 h-10 p-1 mr-4 hover:bg-purple bg-transparent"
                aria-label="Send Email"
              >
                <img src={emailIcon} alt="email" className="w-4 h-4" />
              </a>
            )}
            {user?.profession === ROLES_LIST.SCREENER &&
            user?.screenerLevel === "level3" &&
            !disabled &&
            storedArray !== undefined &&
            storedArray !== false ? (
              <input
                onClick={(e) => e.stopPropagation()}
                onChange={(e) => {
                  e.stopPropagation();
                  handleChangeCheckbox && handleChangeCheckbox(id, demo);
                }}
                type="checkbox"
                className="accent-violet-600 w-5 h-5 rounded-md bg-zinc-500 ml-auto mr-4"
              />
            ) : null}
            {(user?.profession === ROLES_LIST.ANR ||
              user?.profession === ROLES_LIST.MANAGER ||
              user?.profession === ROLES_LIST.SUPERVISOR ||
              user?.profession === ROLES_LIST.CORPORATE) && (
              <div className="flex gap-2 lg:gap-7 items-center">
                {demo?.screenings?.length && demo?.result ? (
                  <button className={`status ${demo?.result}`}>
                    <img
                      src={STATUS_STATE[demo?.result]?.icon}
                      alt="icon"
                      className="w-3 lg:w-4 h-3 lg:h-4"
                    />
                    {STATUS_STATE[demo?.result]?.name}
                  </button>
                ) : null}
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <TooltipItem icon={dotsIcon} clickable={true}>
                    <button
                      tabIndex="0"
                      onClick={async () =>
                        await navigator.clipboard.writeText(
                          "https://player.velvmusic.com/?demoUrl=" +
                            parseURLEncoding(demo?.mediaUrl) || ""
                        )
                      }
                      className="flex gap-2 justify-center items-center cursor-pointer click focus:text-purple focus:font-bold"
                    >
                      <img src={shareIcon} alt="share" className="w-4 h-4" />
                      <div>Share copy link</div>
                    </button>
                  </TooltipItem>
                </button>
              </div>
            )}
          </div>
        </div>
        <div
          className={`w-full h-px relative  ${
            selected ? "bg-purple" : "bg-zinc-700"
          }`}
        />
      </div>
    </div>
  );
}

function ConfirmationModal({
  updateModalState,
  handleConfirm,
  status,
  setForcePause,
  setIsSongPlayed,
}) {
  const title = status === "nogo" ? "NoGo" : status;
  return (
    <Modal
      setShowModal={() => {
        updateModalState(false);
        setForcePause(false);
      }}
      className="capitalize"
      title={title}
    >
      <div className="flex flex-col gap-4">
        <p className="text-gray-50 text-base lg:text-lg font-medium leading-normal mb-4 mt-4 text-center">
          Are you sure you want to update this drop status to {title}?
        </p>
        <button
          onClick={() => {
            handleConfirm(status);
            updateModalState(false);
            if (setIsSongPlayed) setIsSongPlayed(false);
          }}
          className="w-full h-10 px-6 py-3 text-gray-50 font-bold bg-violet-600 rounded-[90px] justify-center items-center gap-3 inline-flex"
        >
          Yes
        </button>
        <button
          onClick={() => {
            updateModalState(false);
            setForcePause(false);
          }}
          className="w-full h-10 px-6 py-3 text-gray-50 font-bold rounded-[90px] border-2 border-zinc-700 justify-center items-center gap-3 inline-flex"
        >
          Cancel
        </button>
      </div>
    </Modal>
  );
}

export default PitchingView;
