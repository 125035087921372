import { useNavigate } from "react-router-dom";
import noImage from "assets/images/no-image.png";
import { useEffect, useRef, useState } from "react";
import { Timestamp } from "firebase/firestore";
import { calculateTimeDifference, displayDate } from "utils/helpers";

const DropSuccess = ({
  formValues,
  setFormValues,
  setFile,
  FORM_DEFAULT,
  image,
  edit = false,
}) => {
  const navigate = useNavigate();
  const [date, setDate] = useState(null);
  const [time, setTime] = useState(null);
  const artworkUrl = useRef(image);

  useEffect(() => {
    if (time > 0) {
      if (time === 0) {
      }
      const timer = setTimeout(() => setTime(time - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      if (date?.minute && date?.minute > 0) {
        setTime(59);
        setDate((prev) => ({ ...prev, minute: prev.minute - 1 }));
      } else setTime("00");
    }
  }, [time]);

  useEffect(() => {
    try {
      const startDate = Timestamp.fromDate(new Date());
      const endDate = Timestamp.fromDate(formValues?.expireTime);
      const dates = calculateTimeDifference(startDate, endDate);
      setDate(dates);
      if (artworkUrl.current) setFile("");
      setFormValues(FORM_DEFAULT);
    } catch (error) {
      console.error("Error loading data", error);
    }
  }, []);

  return (
    <div className="max-w-[736px] w-full lg:px-16 pb-8 bg-zinc-800 bg-opacity-30 rounded-2xl shadow lg:border border-zinc-700 flex-col justify-center items-center gap-8 inline-flex">
      <div className="self-stretch lg:pt-8 flex-col justify-center items-center gap-3 flex">
        <section className="max-w-[426px] w-full h-[426px] lg:w-[260px] lg:h-[260px] overflow-hidden relative bg-gradient-to-b from-black to-black rounded-[10.67px] border border-violet-600 justify-start items-center inline-flex ">
          <img
            src={artworkUrl.current || noImage}
            alt="cover"
            className="w-full h-full object-cover "
          />
          <div className="absolute top-4 left-4 text-white text-[27.76px] font-extrabold leading-[41.63px] capitalize">
            {formValues?.artist || ""}
          </div>
        </section>
        <section className="max-w-[426px] w-full lg:w-[260px] flex flex-col justify-start items-end">
          <div className="w-full p-2 lg:!py-1 rounded-2xl border border-violet-600 justify-center items-center gap-2.5 inline-flex">
            <img
              className="w-20 h-20 lg:w-12 lg:h-12 relative rounded-[10.41px] object-cover"
              src={artworkUrl.current || noImage}
              alt="artist"
            />
            <section className="grow shrink basis-0 h-[70px] justify-center items-center gap-2 flex">
              <div className="w-full px-8 lg:px-0 flex-col justify-start inline-flex">
                <div className="text-left text-white text-lg leading-tight capitalize">
                  {formValues?.artist || ""}
                </div>
                <div className="text-2xl lg:text-lg font-digitalnumbers self-stretch justify-between items-start inline-flex text-white">
                  <span>{displayDate(date?.day)}</span>
                  <span>{displayDate(date?.hour)}</span>
                  <span>{displayDate(date?.minute)}</span>
                  <span>{time}</span>
                </div>
                <div className="text-lg leading-tight self-stretch justify-between items-start inline-flex text-white">
                  <div>Day</div>
                  <div>Hour</div>
                  <div>Min</div>
                  <div>Sec</div>
                </div>
              </div>
            </section>
          </div>
          <div />
        </section>
        <div className="text-center text-gray-50 text-2xl lg:text-4xl font-bold lg:leading-[56px] lg:mt-6">
          You have successfully <br />
          {edit ? "edited" : "uploaded"} a Drop!
        </div>
      </div>
      <button
        className="px-5 py-3 bg-violet-600 rounded-full text-gray-50 text-lg font-bold leading-snug w-full lg:w-fit"
        onClick={() => navigate("/")}
      >
        Check it out
      </button>
    </div>
  );
};

export default DropSuccess;
