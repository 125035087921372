import Header from "landing/components/Header/Header";
import WidthWrapper from "landing/components/shared/WidthWrapper/WidthWrapper";
import ScrollToTop from "landing/helpers/ScrollToTop";

function About() {
  return (
    <>
      <Header />
      <div className="bg-[radial-gradient(circle_at_center_left,_var(--tw-gradient-stops))] from-[#40137a8f] from-5% to-[#000] to-30%">
        <ScrollToTop />
        <div className="text-white mx-8 lg:mx-36 pt-14 text-2xl font-extrabold">
          <WidthWrapper>
            <h1>ABOUT</h1>
          </WidthWrapper>
        </div>

        <div className="bg-[#101010]">
          <div className="text-white mx-8 lg:mx-36 mt-14 text-5xl font-extrabold pt-3">
            <WidthWrapper>
              <div className="flex justify-center items-center gap-12">
                <h1 className="border-b-2 border-solid border-transparent pb-3 cursor-pointer text-3xl lg:text-5xl text-center">
                  A groundbreaking music platform
                </h1>
              </div>
            </WidthWrapper>
          </div>
        </div>
        <div className="text-white mx-8 lg:mx-36 pb-32 mt-16 text-center leading-8">
          <WidthWrapper>
            <div>
              <p className="py-6 font-semibold">
                VELV is a groundbreaking music platform with a flexible solution
                for A&R´s, Music supervisors, Songwriters and Music producers
                worldwide. Through our intuitive app A&Rs and Music supervisors
                simply publish their brief including all relevant information
                and can sit back - allowing the best music to come to them. As a
                creator you follow the brief, focus on your creativity and send
                in your best song.There are no subscriptions or other hidden
                fees – just a single payment per submission. You can track the
                entire process in the app and see how you’re getting one step
                closer to putting your craft directly into the hands of major
                artists and Sync placements!
              </p>
            </div>
          </WidthWrapper>
        </div>
      </div>
    </>
  );
}

export default About;
