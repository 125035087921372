import { Header } from "components/shared/Header/Header";
import { Footer } from "components/shared/Footer/Footer";
import { Sidebar } from "components/Sidebar/Sidebar";
import arrowIcon from "assets/icons/arrow-icon.svg";
import { useNavigate } from "react-router-dom";
import "pages/MyPitches/MyPitches.scss";

const Pitches = ({ children, page = "/", showBackBtn = true }) => {
  const navigate = useNavigate();

  return (
    <div className="bg-[radial-gradient(circle_at_center_left,_var(--tw-gradient-stops))] from-[#00315F] to-25%">
      <Sidebar />
      <div className="mx-4 lg:ml-32 lg:mr-16 relative z-10 mb-28 overflow-hidden">
        <Header tab="mypitches" />
        <h1
          className={`flex gap-4 justify-start items-center text-2xl font-extrabold mt-6 lg:mt-14 text-white text-left ${showBackBtn ? 'cursor-pointer' :''}`}
          onClick={() => navigate(`/my-pitches${page}`)}
        >
          {showBackBtn ? (
            <img src={arrowIcon} alt="chevron" className="rotate-90 w-4 h-4 lg:w-5 lg:h-5" />
          ) : null}
          My Pitches
        </h1>

        {children}
      </div>
      <Footer />
    </div>
  );
};

export default Pitches;
