import React from "react";
import ReactDOM from "react-dom";
import ReactGA from "react-ga4";
import App from "./App";
import "./index.scss";

const GA_MEASUREMENT_ID = "G-JL32Q2R8X5";

if (process.env.NODE_ENV === "production") {
  ReactGA.initialize(GA_MEASUREMENT_ID);

  // Track the referrer when the app loads
  const trackReferrer = () => {
    const referrer = document.referrer;
    if (referrer) {
      ReactGA.send("event", {
        category: "Referrer",
        action: "App Load",
        label: referrer,
      });
    }
  };

  trackReferrer();
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
