import { useEffect, useState } from "react";
import {
  auth,
  db,
  getSecondsRateAndBalanceForWallet,
  withdrawFundsForUserWithStripeIdAndFirebaseId,
} from "config/firebase-config";
import { Sidebar } from "components/Sidebar/Sidebar";
import { Footer } from "components/shared/Footer/Footer";
import { Header } from "components/shared/Header/Header";
import { TooltipItem } from "components/shared/Tooltip/Tooltip";
import infoIcon from "assets/icons/info-icon.svg";
import {
  calculateTotalAmount,
  convertSecondsToHMS,
  formatDate,
} from "utils/helpers";
import loadingAnimation from "assets/icons/loading.svg";
import { collection, getDocs } from "firebase/firestore";

const CURRENCY = "usd";

function Wallet() {
  const user = JSON.parse(localStorage.getItem("user"));
  const [time, setTime] = useState({ hours: 0, minutes: 0, seconds: 0 });
  const [totalAmountBalance, setTotalAmountBalance] = useState(0);
  const [rate, setRate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingWithdrawals, setLoadingWithdrawals] = useState(false);
  const [withdrawals, setWithDrawals] = useState(null);

  const getTotalSecondsForUser = async () => {
    try {
      setLoading(true);
      const response = await handleGetSecondAndBalanceWallet();
      return response?.seconds || 0;
    } catch (error) {
      console.error("Error fetching total seconds amount", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getWithdrawalList();
  }, []);

  const getWithdrawalList = async () => {
    const totalSeconds = await getTotalSecondsForUser();
    setTime(convertSecondsToHMS(totalSeconds));

    const docRef = collection(
      db,
      `users/${auth?.currentUser?.uid}/withdrawals`
    );
    try {
      const snapshot = await getDocs(docRef);

      const documents = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      if (documents) setWithDrawals(documents);
    } catch (error) {
      console.log("Failed fetching user", error);
    }
  };

  function displayDate(number, total) {
    let length = 0;
    let num = Math.abs(number || 0);
    if (!total && number < 10) return "0" + num;

    if (total > 1 && number < 10) length = 3;
    if (total > 1 && number >= 10) length = 2;
    if (total > 1 && number >= 100) length = 1;
    if (total > 1 && number >= 1000) length = 0;

    if (length) num = "0".repeat(length) + num;
    return num;
  }

  const handleGetSecondAndBalanceWallet = async () => {
    const response = await fetch(getSecondsRateAndBalanceForWallet, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: auth?.currentUser?.uid,
      }),
    })
      .then((response) => response.json())
      .then((item) => {
        setRate(item?.rate / 100);
        setTotalAmountBalance(item?.balance);
        return item;
      });
    return response;
  };

  const handleWithdrawAmount = () => {
    setLoadingWithdrawals(true);
    fetch(withdrawFundsForUserWithStripeIdAndFirebaseId, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        firebaseId: auth?.currentUser?.uid,
        stripeId: user?.stripeAccount?.accountId,
      }),
    })
      .then((response) => response.json())
      .then((item) => {
        console.log("Success withdraw money", item);
        getWithdrawalList();
      })
      .finally(() => setLoadingWithdrawals(false));
  };

  return (
    <div className="bg-[radial-gradient(circle_at_center_left,_var(--tw-gradient-stops))] from-[#00315F] to-25%">
      <Sidebar />
      <div className="ml-4 lg:ml-32 mr-4 lg:mr-16 relative z-10 overflow-hidden">
        <>
          <Header tab="wallet" />
          <div className="text-4xl mt-12 lg:text-2xl font-extrabold text-white text-left">
            Wallet
          </div>
          {loading ? (
            <div className="rounded-3xl h-[334.50px] w-full flex justify-center items-center flex-col text-white">
              <img
                src={loadingAnimation}
                alt="loading"
                className="text-white w-20"
              />
              <p>Loading...</p>
            </div>
          ) : (
            <div className="w-full pb-[136px] flex-col justify-start items-center gap-8 lg:gap-10 inline-flex mt-7">
              <div className="self-stretch h-[172px] flex-col justify-start items-center gap-4 lg:gap-10 flex">
                <div className="self-stretch h-6 lg:px-[352px] flex-col justify-start items-center gap-4 flex">
                  <div className="self-stretch justify-start lg:justify-center items-center gap-2 inline-flex">
                    <div className="text-left lg:text-center text-slate-500 lg:text-lg leading-normal">
                      Screening Time
                    </div>
                    <TooltipItem
                      icon={infoIcon}
                      className="max-w-52 w-5 h-5"
                      tooltipClassName="w-[300px] lg:w-full md:w-full"
                    >
                      <div className="h-5 bg-white bg-opacity-0 flex-col justify-start items-start flex">
                        <div className="text-center text-white text-xs font-bold  leading-tight">
                          Payment information
                        </div>
                      </div>
                      <div className="bg-white bg-opacity-0 flex-col justify-start items-start flex">
                        <div className="text-white text-xs font-normal  leading-tight">
                          The timer keeps track of the elapsed listening time
                          that is used to calculate the total earnings you’ve
                          accumulated.
                          <br />
                          Listening time is counted from the start of the song
                          and within a maximum time frame of{" "}
                          {user?.screenerLevel === "level1"
                            ? 90
                            : user?.screenerLevel === "level2"
                            ? 60
                            : 120}{" "}
                          seconds.
                        </div>
                      </div>
                    </TooltipItem>
                  </div>
                </div>
                <div className="self-stretch h-[108px] flex-col justify-start items-center gap-2 flex">
                  <div className="justify-start items-start gap-1 inline-flex font-digitalnumbers text-gray-50 text-2xl lg:text-5xl">
                    {displayDate(time.hours, 3)
                      .toString()
                      .split("")
                      .map(String)
                      ?.map((item, index) => (
                        <div key={index}>
                          <NumberItem time={item} />
                        </div>
                      ))}
                    <div className="p-[4.90px] lg:p-2 bg-zinc-700 bg-opacity-60 rounded-lg flex-col justify-center items-center gap-2.5 inline-flex">
                      <div className="text-center leading-[56px] lg:text-[35px] lg:leading-[42px]">
                        :
                      </div>
                    </div>
                    {displayDate(time.minutes)
                      .toString()
                      .split("")
                      .map(String)
                      ?.map((item, index) => (
                        <div key={index}>
                          <NumberItem time={item} />
                        </div>
                      ))}

                    <div className="p-[4.90px] lg:p-2 bg-zinc-700 bg-opacity-60 rounded-lg flex-col justify-center items-center gap-2.5 inline-flex">
                      <div className="text-center leading-[56px] lg:text-[35px] lg:leading-[42px]">
                        :
                      </div>
                    </div>
                    {displayDate(time.seconds)
                      .toString()
                      .split("")
                      .map(String)
                      ?.map((item, index) => (
                        <div key={index}>
                          <NumberItem time={item} />
                        </div>
                      ))}
                  </div>
                  <div className="w-[329px] lg:w-[464px] justify-between items-start inline-flex">
                    <div className="w-[132px] lg:w-[178px] text-right text-slate-500 text-base lg:text-sm font-medium leading-normal">
                      Hour
                    </div>
                    <div className="text-slate-500 text-base lg:text-sm font-medium leading-normal">
                      Min
                    </div>
                    <div className="w-[28.10px] lg:w-[34px] text-center text-slate-500 text-base lg:text-sm font-medium leading-normal">
                      Sec
                    </div>
                  </div>
                </div>
              </div>
              <div className="justify-center items-start lg:gap-20 inline-flex lg:-mt-4">
                <div className="flex-col justify-start items-start gap-6 inline-flex">
                  <div className="flex-col justify-start items-start gap-4 lg:gap-2 flex">
                    <div className="text-slate-500 lg:text-base font-medium leading-normal">
                      Rate
                    </div>
                    <div className="w-[134px] text-white text-lg lg:text-[28px] font-semibold leading-normal">
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: CURRENCY,
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      }).format(rate || 15)}
                      <span className="text-lg lg:text-2xl text-gray-700 ml-2">
                        /h
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex-col justify-start items-start gap-6 inline-flex">
                  <div className="flex-col justify-start items-start gap-4 lg:gap-2 flex">
                    <div className="text-slate-500 lg:text-base font-medium leading-normal">
                      Balance
                    </div>
                    <div className="text-white text-lg lg:text-[28px] font-semibold leading-normal">
                      {calculateTotalAmount(
                        time.hours,
                        time.minutes,
                        time.seconds,
                        rate
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex-col justify-start items-center gap-8 flex mt-4">
                <button
                  onClick={handleWithdrawAmount}
                  disabled={totalAmountBalance < 50 || loadingWithdrawals}
                  className="w-80 h-[44px] px-6 bg-violet-600 disabled:bg-violet-900 disabled:cursor-default rounded-[90px] justify-center items-center gap-3 inline-flex text-center text-gray-50 disabled:text-gray-400 font-bold leading-none cursor-pointer"
                >
                  {loadingWithdrawals ? (
                    <img
                      src={loadingAnimation}
                      alt="loading"
                      className="w-8 h-7 brightness-[103%] invert-[99%] saturate-[6%] hue-rotate-[206deg] contrast-[104%]"
                    />
                  ) : (
                    <div className="py-[6px]">Withdraw</div>
                  )}
                </button>
                <div className="w-[1440px] text-center text-slate-500 lg:text-base font-normal leading-tight">
                  Minimum withdrawal amount is 50 USD
                </div>
              </div>
              <div className="self-stretch h-[76px] flex-col justify-start items-center gap-8 flex">
                <div className="self-stretch text-center text-white lg:text-lg font-normal leading-normal">
                  Transactions
                </div>
                <div className="w-full flex-col justify-start items-center gap-8 flex">
                  <div className="w-full text-center text-white lg:text-lg font-normal leading-tight rounded-lg">
                    {withdrawals?.length ? (
                      <table className="w-full rounded-sm border border-solid border-slate-500">
                        <tr className="bg-zinc-700">
                          <th className="border-r border-solid border-white">
                            #
                          </th>
                          <th className="border-r border-solid border-white">
                            Amount
                          </th>
                          <th className="border-r border-solid border-white">
                            Currency
                          </th>
                          <th>Date</th>
                        </tr>
                        {withdrawals.map((item, index) => (
                          <tr key={item?.id} className="text-white">
                            <td className="border border-slate-500 border-solid">
                              {index + 1}
                            </td>
                            <td className="border border-slate-500 border-solid">
                              {item?.amount / 100 || 0}
                            </td>
                            <td className="uppercase border border-slate-500 border-solid">
                              {item?.currency || "USD"}
                            </td>
                            <td className="border border-slate-500 border-solid">
                              {formatDate(item?.createdAt, true)}
                            </td>
                          </tr>
                        ))}
                      </table>
                    ) : (
                      "No withdrawals."
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      </div>
      <Footer />
    </div>
  );
}

export default Wallet;

function NumberItem({ time, total }) {
  return (
    <div className="p-[4.90px] lg:p-2 bg-zinc-700 bg-opacity-60 rounded-lg flex-col justify-center items-center gap-2.5 inline-flex">
      <div className="text-center leading-[56px] lg:text-[35px] lg:leading-[42px]">
        {time || 0}
      </div>
    </div>
  );
}
