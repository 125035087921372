import WidthWrapper from "landing/components/shared/WidthWrapper/WidthWrapper";
import checkmarkIcon from "landing/assets/icons/checkmark-icon.svg";

export function ScreenersText() {
  return (
    <div className="text-white mx-8 lg:mx-36 text-center pb-32">
      <WidthWrapper>
        <h1 className="text-2xl lg:text-4xl font-extrabold pb-7 pt-16">Screeners</h1>
        <p className="font-semibold">
          VELV Screeners are carefully selected and have a wide professional
          experience in the music industry with a clear understanding of song
          briefs and demo pitching. Our Screeners has an incredible variety
          resumes such as:
        </p>
        <div className="flex gap-4 flex-wrap justify-center mt-20">
          <div className="flex bg-purple rounded-lg w-fit p-3 gap-2 items-center">
            <img src={checkmarkIcon} alt="checkbox" /> Award winning Producers
          </div>
          <div className="flex bg-purple rounded-lg w-fit p-3 gap-2 items-center">
            <img src={checkmarkIcon} alt="checkbox" /> Hit songwriters
          </div>
          <div className="flex bg-purple rounded-lg w-fit p-3 gap-2 items-center">
            <img src={checkmarkIcon} alt="checkbox" /> Mixing and mastering
            engineers
          </div>
          <div className="flex bg-purple rounded-lg w-fit p-3 gap-2 items-center">
            <img src={checkmarkIcon} alt="checkbox" /> Artists
          </div>
          <div className="flex bg-purple rounded-lg w-fit p-3 gap-2 items-center">
            <img src={checkmarkIcon} alt="checkbox" /> DJ's
          </div>
          <div className="flex bg-purple rounded-lg w-fit p-3 gap-2 items-center">
            <img src={checkmarkIcon} alt="checkbox" /> Recordings engineers
          </div>
          <div className="flex bg-purple rounded-lg w-fit p-3 gap-2 items-center">
            <img src={checkmarkIcon} alt="checkbox" /> Managers
          </div>
          <div className="flex bg-purple rounded-lg w-fit p-3 gap-2 items-center">
            <img src={checkmarkIcon} alt="checkbox" /> Publishers
          </div>
          <div className="flex bg-purple rounded-lg w-fit p-3 gap-2 items-center">
            <img src={checkmarkIcon} alt="checkbox" /> A&R
          </div>
        </div>
        <div className="max-w-[813px]">
          <h1 className="text-left text-xl font-extrabold mt-20 uppercase">
            how does it work?
          </h1>
          <p className="text-left my-11">
            The VELV screening process consists of three steps to ensure that
            the submitter has a fair chance, and that the client /A&R receives
            songs that match their brief.
          </p>
          <div className="text-left font-semibold">
            <h1 className="text-xl uppercase mb-2">Screener 1:</h1>
            <p>
              Every submitted demo is meticulously reviewed, ensuring that each
              one aligns with the provided brief guidelines.
            </p>
          </div>
          <div className="text-left font-semibold mt-10">
            <h1 className="text-xl uppercase mb-2">Screener 2:</h1>
            <p>
              The submissions that were not forwarded are undergoing a second
              opinion review to ensure that the correct decision has been made.
            </p>
          </div>
          <div className="text-left font-semibold mt-10">
            <h1 className="text-xl uppercase mb-2">Screener 3:</h1>
            <p>
              A final screening is done before delivery, and the results are
              sent directly to the client/A&R, who then makes the final
              decision.
            </p>
          </div>
          <div className="text-left text-xl mt-14">
            The entire process is tracked and communicated directly to our users
            through our in-app notification system. If the submission captures
            the client/A&R's attention, they will contact the submitter directly
            through email.
          </div>
        </div>
      </WidthWrapper>
    </div>
  );
}
