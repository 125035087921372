import { useState, useEffect, useCallback, useRef } from "react";
import { useParams, useBlocker } from "react-router-dom";
import {
  Timestamp,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import loadingAnimation from "assets/icons/loading.svg";
import { Sidebar } from "components/Sidebar/Sidebar";
import { Header } from "components/shared/Header/Header";
import { Footer } from "components/shared/Footer/Footer";
import checkmarkIcon from "assets/icons/checkmark-icon.svg";
import uploadIcon from "assets/icons/upload-icon.svg";
import FileUpload from "components/FileUpload/FileUpload";
import { auth, db, storage } from "config/firebase-config";
import arrowRight from "assets/icons/arrow-right-icon.svg";
import plusIcon from "assets/icons/plus-add-icon.svg";
import { InputValidation } from "components/shared/InputValidation/InputValidation";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import {
  calculateTimeDifference,
  displayDate,
  generateRandomString,
  generateUUID,
  isWithinSixMonth,
} from "utils/helpers";
import avatarImg from "assets/images/avatar-1.png";
import noImage from "assets/images/no-image.png";
import { Modal } from "components/shared/Modal";

function SubmitDrop() {
  const { id } = useParams();
  const dropIdRef = useRef(generateRandomString(6));
  const submitIdRef = useRef(generateRandomString(6));
  const [tabActive, setTabActive] = useState(1);
  const [drop, setDrop] = useState(null);
  const [file, setFile] = useState([]);
  const [collaborator, setCollaborator] = useState("");
  const [collabError, setCollabError] = useState("");
  const [list, setList] = useState([]);
  const [date, setDate] = useState(null);
  const [stripeUrl, setStripeUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [myVelvId, setMyVelvId] = useState("");
  const [allowNavigate, setAllowNavigate] = useState(false);
  const [user, setUser] = useState(null);

  const blockNavigation = useCallback(
    ({ currentLocation, nextLocation }) => {
      let isBlocking = false;
      if (file?.[0] || collaborator) isBlocking = true;
      return isBlocking && currentLocation.pathname !== nextLocation.pathname;
    },
    [file, collaborator]
  );

  let blocker = useBlocker(blockNavigation);

  useEffect(() => {
    if (blocker?.state === "blocked") setShowDeleteModal(true);
  }, [blocker]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (!file?.length || !collaborator) {
        event.preventDefault();
        event.returnValue = "";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [allowNavigate]);

  const getItem = async () => {
    const docRef = doc(db, "drops", id);
    try {
      const snapshot = await getDoc(docRef);
      const data = snapshot.data();
      if (Timestamp.fromDate(new Date()) > data?.expireTime) {
        setAllowNavigate(true);
        window.location.href = "/";
      } else {
        const id = snapshot.id;

        setDrop({ ...data, id });
        const startDate = data?.createdTime;
        const endDate = data?.expireTime;
        const dateList = calculateTimeDifference(startDate, endDate);

        setDate(dateList);
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const getUser = async (id) => {
    const qUsers = query(collection(db, "users"), where("velvId", "==", id));
    try {
      const snapshot = await getDocs(qUsers);
      return snapshot.docs.map((doc) => {
        const data = doc.data();
        return data;
      });
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const getMyVelvId = async () => {
    const userRef = doc(db, "users", auth?.currentUser?.uid);
    try {
      const snapshot = await getDoc(userRef);
      const user = snapshot?.data();
      setUser(user);
      setMyVelvId(user?.velvId);
    } catch (error) {
      console.log("Error", error);
    }
  };

  useEffect(() => {
    getItem();
    getMyVelvId();
  }, []);

  const handleSubmitCollaborator = async () => {
    const user = await getUser(collaborator);

    if (!user?.length) {
      setCollabError("User with that ID doesn't exist");
    } else if (myVelvId === user[0]?.velvId) {
      setCollabError("Sorry, you can't add yourself as a collaborator.");
    } else if (list?.some((item) => item?.velvId === user[0]?.velvId)) {
      setCollabError(
        "You're attempting to add a collaborator who's already added."
      );
    } else {
      setCollabError("");
      setList((prev) => [...prev, ...user]);
    }

    setCollaborator("");
  };

  const handleDelete = (index) => {
    const updatedItems = [...list.slice(0, index), ...list.slice(index + 1)];
    setCollabError("");
    setList(updatedItems);
  };

  const handleDemoSubmit = () => {
    setIsLoading(true);
    getItem();
    if (Timestamp.fromDate(new Date()) > drop?.expireTime) {
      setAllowNavigate(true);
      window.location.href = "/";
      return;
    }
    const collaboratorsIdsList = list.map((obj) => obj.velvId);
    const data = {
      dropId: id,
      title: file[0]?.name,
      collaboratorVelvIds: [...collaboratorsIdsList],
    };
    if (tabActive === 2) uploadFile(file[0], data);
    else {
      setIsLoading(false);
      setTabActive((prev) => prev + 1);
    }
  };

  const uploadFile = async (file, values) => {
    const pitchId = generateUUID();
    const name = `demos/${new Date().getTime()}-${file.name}`;
    const storageRef = ref(storage, name);

    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
          default:
            break;
        }
      },
      (error) => {
        console.error("Upload Failed", error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
          let data = { ...values };
          data.mediaUrl = downloadURL;
          data.createdTime = Timestamp.fromDate(new Date());
          data.userId = auth?.currentUser?.uid;
          data.pitchId = pitchId;
          data.artist = drop?.artist ?? "";
          const id = generateRandomString(20);
          localStorage.setItem("ids", JSON.stringify({ [id]: { ...data } }));
          setAllowNavigate(true);
          const response = await handleSendToStripe(id, pitchId);
          setStripeUrl(response?.url);
          setIsLoading(false);
          setTabActive((prev) => prev + 1);
          if (response) window.location.href = response?.url;
          setAllowNavigate(false);
        });
      }
    );
  };

  const handleSendToStripe = async (demoId, pitchId) => {
    try {
      const PRICE_ID =
        user?.soundtrap &&
        user?.discount > 0 &&
        isWithinSixMonth(user?.createdTime)
          ? process.env.REACT_APP_STRIPE_PRICE_ID_SOUNDTRAP
          : process.env.REACT_APP_STRIPE_PRICE_ID;
      const url = process.env.REACT_APP_ON_PAY_URL;
      const data = {
        userId: auth?.currentUser.uid,
        dropId: drop?.id,
        priceId: PRICE_ID,
        screenings: [],
        demoId: pitchId,
        successUrl: `${window.location.origin}/demo/${demoId}/success`,
        cancelUrl: `${window.location.origin}/demo/${demoId}/cancel`,
      };

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      const result = await response.json();
      return result;
    } catch (error) {
      console.log("Error:", error);
    }
  };

  return (
    <div className="bg-[radial-gradient(circle_at_center_left,_var(--tw-gradient-stops))] from-[#00315F] to-25%">
      <Sidebar />
      <div className="mx-4 lg:ml-32 lg:mr-16 relative z-20 mb-28">
        {/* overflow-hidden */}
        <Header tab="adddrop" />
        <div className="flex flex-col items-center gap-10 justify-center mx-auto">
          <div className="self-stretch justify-start items-center gap-4 inline-flex">
            <div className="grow shrink basis-0 text-gray-50 text-2xl font-extrabold lg:leading-[56px]">
              Submit Demo
            </div>
          </div>
          <section className="my-auto justify-between lg:items-start items-end gap-2 lg:gap-0 inline-flex w-full mx-auto max-w-[904px]">
            <div className={tabActive === 1 ? "flex" : "hidden lg:flex"}>
              <TabItem
                number={1}
                title={"Upload Track"}
                active={tabActive === 1}
                success={tabActive > 1}
              />
            </div>
            <div>
              <TabItem
                number={2}
                title={"Preview"}
                active={tabActive === 2}
                success={tabActive > 2}
              />
            </div>
            <div
              className={
                tabActive === 3 || tabActive === 2 || tabActive === 4
                  ? "flex"
                  : "hidden lg:flex"
              }
            >
              <TabItem
                number={3}
                title={"Payment"}
                active={tabActive === 3}
                success={tabActive > 2}
              />
            </div>
          </section>

          <section>
            {date && (
              <CoverPreview
                image={drop?.artworkUrl || noImage}
                title={drop?.artist || "-"}
                file={file[0]}
                setFile={setFile}
                dateList={date}
              />
            )}
          </section>
          {tabActive === 1 && (
            <>
              <div className="max-w-[426px] lg:max-w-[260px] lg:-mt-4 w-full h-fit">
                <FileUpload
                  type="audio"
                  setFile={setFile}
                  canDeleteIt={false}
                  className="relative rounded-xl overflow-hidden hover:shadow-[0_0_20px_0_rgba(108,40,255,1)]"
                >
                  <div className="w-full h-[132px] lg:h-[102px] px-2 py-6 bg-black bg-opacity-60 rounded-2xl border border-violet-600 justify-center items-center gap-2.5 inline-flex">
                    {file[0] ? (
                      <div className="text-slate-500">
                        <div className="text-xs font-semibold">
                          File Uploaded
                        </div>
                        <p className="text-xs font-normal mt-1">
                          Click or drag to change the file
                        </p>
                        <div className="text-white text-base mt-1">
                          {file[0]?.name}
                        </div>
                      </div>
                    ) : (
                      <div className="flex-col justify-center items-center gap-2.5 inline-flex">
                        <img
                          src={uploadIcon}
                          alt="preview thumbnail art"
                          className="w-4 h-4 relative rounded-[10.41px] object-cover"
                        />
                        <div className="text-center text-slate-500 text-xs font-semibold leading-tight">
                          Upload or Drag & Drop the Track{" "}
                        </div>
                        <div className="text-center text-slate-500 text-xs font-normal leading-tight">
                          Supported file type: mp3
                        </div>
                      </div>
                    )}
                  </div>
                </FileUpload>
              </div>
              <section className="max-w-[904px] w-full flex-col justify-start items-start gap-10 inline-flex">
                <div className="self-stretch justify-between items-center inline-flex">
                  <div className="text-white text-base font-extrabold uppercase leading-3">
                    Add collaborators
                  </div>
                </div>
                <div className="self-stretch flex-col justify-start items-start gap-6 flex">
                  <div className="self-stretch justify-between inline-flex items-start">
                    <p className="text-white w-fit whitespace-nowrap mr-3 mt-[17px]">
                      VELV ID:
                    </p>
                    <InputValidation
                      type="text"
                      placeholder="(Insert ID here)"
                      value={collaborator}
                      onChange={(e) => setCollaborator(e.target.value)}
                      className="!pl-0 !border-none !outline-none"
                      errorMsg={collabError}
                    />

                    <button
                      disabled={!collaborator}
                      className="flex gap-3 text-gray-50 text-sm font-bold leading-none mt-[10px] px-6 py-3 rounded-[90px] border-2 border-violet-600 justify-center items-center disabled:border-gray-500 w-fit disabled:opacity-70 hover:shadow-[0_0_20px_0_rgba(108,40,255,1)] cursor-pointer"
                      onClick={handleSubmitCollaborator}
                    >
                      <img
                        src={plusIcon}
                        alt="add collaborator"
                        className="w-4 h-4"
                      />
                      Add
                      <p className="hidden md:flex ml-[-8px]">Collaborator</p>
                    </button>
                  </div>

                  {list?.map((item, index) => (
                    <div
                      key={index}
                      className="flex text-white w-full items-center gap-3 text-sm"
                    >
                      <img
                        src={item?.profileImage || avatarImg}
                        alt="avatar"
                        className="w-8 h-8 rounded-full"
                      />
                      <p> {item?.name || "-"}</p> |
                      <p className="text-neutral-400">{item?.velvId || "-"}</p>
                      <div
                        className="ml-auto cursor-pointer text-red-500 capitalize"
                        onClick={() => handleDelete(index)}
                      >
                        remove
                      </div>
                    </div>
                  ))}
                </div>
              </section>
            </>
          )}

          {tabActive === 2 && (
            <>
              <div className="max-w-[426px] lg:max-w-[260px] flex flex-col gap-5 justify-start text-white w-full text-base font-bold">
                <div className="flex gap-8">
                  <p className="w-20 text-slate-500">Artist:</p>
                  <span className="text-gray-50 capitalize">
                    {drop?.artist}
                  </span>
                </div>
                <div className="flex gap-8">
                  <p className="w-20 text-slate-500">Drop ID:</p>
                  <span className="text-gray-50 uppercase">
                    {dropIdRef?.current}
                  </span>
                </div>
                <div className="flex gap-8">
                  <p className="w-20 text-slate-500">Submit ID:</p>
                  <span className="text-gray-50 uppercase">
                    {submitIdRef?.current}
                  </span>
                </div>
                <div className="flex gap-8">
                  <p className="w-20 text-slate-500">Attached file:</p>
                  <span className="text-gray-50">{file[0]?.name}</span>
                </div>

                <div className="w-full h-px relative bg-zinc-700 max-w-[904px] mt-2" />
                <div className="flex flex-col md:flex-row gap-8">
                  <p className="w-24 text-slate-500">Collaborators:</p>
                  <div className="flex flex-col gap-4">
                    {list?.map((item, index) => (
                      <div
                        key={index}
                        className="flex text-white w-full items-center gap-3 text-sm"
                      >
                        <img
                          src={item?.profileImage || avatarImg}
                          alt="avatar"
                          className="w-8 h-8 rounded-full"
                        />
                        <div>
                          <p> {item?.name || "-"}</p>
                          <p className="text-neutral-400">
                            {item?.velvId || "-"}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </>
          )}

          {tabActive === 3 && (
            <>
              <div className="max-w-[426px] lg:max-w-[260px] w-full px-4 py-4 text-white bg-black bg-opacity-60 rounded-2xl border border-violet-600 justify-center items-center  text-center text-lg">
                {file[0]?.name}
              </div>
              <div className="text-center text-gray-50 text-2xl lg:text-3xl font-bold lg:leading-[56px] lg:mt-4">
                Proceed to Stripe to complete the payments <br />
                <p className="text-2xl lg:text-2xl mt-4 text-gray-600">
                  Click bellow after completing to check your demos
                </p>
              </div>
              {stripeUrl && (
                <button
                  onClick={() => {
                    window.location.replace(stripeUrl, { replace: true });
                  }}
                  className="px-5 py-3 bg-violet-600 rounded-full text-gray-50 text-lg font-bold leading-snug w-full md:w-fit"
                >
                  Check it out
                </button>
              )}
            </>
          )}

          {tabActive < 3 && (
            <div className="w-full h-px relative bg-zinc-700 max-w-[904px] -mt-5" />
          )}
          <section className="flex justify-between w-full max-w-[904px]">
            {tabActive > 1 && tabActive < 3 ? (
              <button
                onClick={() => setTabActive((prev) => prev - 1)}
                className="w-[150px] px-6 py-3 rounded-[90px] border-2 border-zinc-700 justify-center items-center gap-3 inline-flex"
              >
                <img
                  src={arrowRight}
                  alt="arrow right"
                  className="w-4 h-4 relative rotate-180"
                />
                <div className="text-right text-gray-50 text-base font-bold leading-none">
                  Previous
                </div>
              </button>
            ) : null}
            {tabActive < 3 ? (
              <button
                onClick={() => {
                  handleDemoSubmit();
                }}
                disabled={!file?.[0] || isLoading}
                type="button"
                className="ml-auto px-6 py-3 bg-violet-600 disabled:bg-violet-950 rounded-[90px] justify-center items-center gap-3 flex w-28"
              >
                {isLoading ? (
                  <img
                    src={loadingAnimation}
                    alt="loading"
                    className="w-4 h-4 brightness-[103%] invert-[99%] saturate-[6%] hue-rotate-[206deg] contrast-[104%]"
                  />
                ) : (
                  <>
                    <div
                      className={`text-right ${
                        !file?.[0] ? "text-gray-500" : "text-gray-50"
                      } text-base font-bold leading-none disabled:bg-gray-500`}
                    >
                      Next
                    </div>
                    <img
                      src={arrowRight}
                      alt="arrow right"
                      className="w-4 h-4 relative"
                    />
                  </>
                )}
              </button>
            ) : null}
          </section>
        </div>
      </div>
      {showDeleteModal ? (
        <Modal setShowModal={setShowDeleteModal} title="Remove">
          <div className="flex flex-col gap-4">
            <p className="text-gray-50 text-base font-medium leading-normal mb-8 lg:mb-4 text-center">
              All data will be lost? <br />
              Are you sure you want to quit this drop?
            </p>
            <button
              onClick={() => {
                blocker?.proceed();
              }}
              className="w-full h-10 px-6 py-3 text-gray-50 font-bold bg-red-500 rounded-[90px] justify-center items-center gap-3 inline-flex"
            >
              Yes
            </button>
            <button
              onClick={() => {
                blocker?.reset();
                setShowDeleteModal(false);
              }}
              className="w-full h-10 px-6 py-3 text-gray-50 font-bold rounded-[90px] border-2 border-zinc-700 justify-center items-center gap-3 inline-flex"
            >
              Cancel
            </button>
          </div>
        </Modal>
      ) : null}
      <Footer />
    </div>
  );
}

export default SubmitDrop;

function TabItem({ number, title, active, success, className = "" }) {
  return (
    <div
      className={`max-w-64 flex-col justify-start items-start gap-3 inline-flex ${className}`}
    >
      <div className="self-stretch justify-start items-center gap-[16px] inline-flex pr-10">
        <div
          className={`px-[10px] py-1 ${active ? "bg-gray-50" : "bg-zinc-700"} ${
            success && "!bg-[#45B26B] !px-2"
          } rounded-[40px] flex-col justify-center items-center gap-2.5 inline-flex`}
        >
          <div
            className={`text-center text-sm ${success && "!text-white"} ${
              active ? "text-zinc-800" : "text-slate-500"
            } font-medium`}
          >
            {success ? (
              <img
                src={checkmarkIcon}
                alt="checkmark"
                className="w-[14px] h-5 invert-0 sepia-0 saturate-[13%] hue-rotate-[235deg] brightness-[160%] contrast-[150%]"
              />
            ) : (
              number
            )}
          </div>
        </div>
        <div
          className={`grow shrink basis-0 ${
            active ? "text-gray-50" : "text-slate-500"
          } ${
            success && "!text-[#45B26B]"
          } text-lg lg:text-base font-medium leading-normal`}
        >
          {title}
        </div>
      </div>
      <div
        className={`w-full h-0.5 relative ${
          active ? "bg-gray-50" : "bg-slate-500"
        } ${success && "!bg-[#45B26B]"} rounded-sm`}
      />
    </div>
  );
}

export function Countdown({ date, seconds, minute }) {
  return (
    <div className="grow shrink basis-0 h-[66px] justify-center items-center gap-2 flex">
      <div className="w-[260px] lg:w-[180px] flex-col justify-start items-center inline-flex">
        <div className="self-stretch justify-between items-start inline-flex">
          <div className="px-2 py-2 lg:py-0 justify-center items-center gap-2.5 flex">
            <div className="text-white text-2xl lg:text-base font-normal font-digitalnumbers">
              {displayDate(date?.day) || "00"}
            </div>
          </div>
          <div className="px-2 py-2 lg:py-0 justify-center items-center gap-2.5 flex">
            <div className="text-white text-2xl lg:text-base font-normal font-digitalnumbers">
              {displayDate(date?.hour) || "00"}
            </div>
          </div>
          <div className="px-2 py-2 lg:py-0 justify-center items-center gap-2.5 flex">
            <div className="text-white text-2xl lg:text-base font-normal font-digitalnumbers">
              {displayDate(minute) || "00"}
            </div>
          </div>
          <div className="px-2 py-2 lg:py-0 justify-center items-center gap-2.5 flex">
            <div className="text-white text-2xl lg:text-base font-normal font-digitalnumbers">
              {seconds || "00"}
            </div>
          </div>
        </div>
        <div className="self-stretch justify-between items-start inline-flex">
          <div className="px-1.5 justify-center items-center gap-2.5 flex">
            <div className="w-10 lg:w-8 text-right text-white text-lg lg:text-base font-normal leading-tight">
              Day
            </div>
          </div>
          <div className="px-1.5 justify-center items-center gap-2.5 flex">
            <div className="text-white text-lg lg:text-base font-normal leading-tight">
              Hour
            </div>
          </div>
          <div className="px-1.5 justify-center items-center gap-2.5 flex">
            <div className="text-white text-lg lg:text-base font-normal leading-tight">
              Min
            </div>
          </div>
          <div className="px-1.5 justify-center items-center gap-2.5 flex">
            <div className="text-white text-lg lg:text-base font-normal leading-tight">
              Sec
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function CoverPreview({ image, title, dateList }) {
  const [seconds, setSeconds] = useState(dateList?.second);
  const [date, setDate] = useState(dateList);

  //TODO: Extract this to separate component to prevent re-rendering
  useEffect(() => {
    if (seconds > 0) {
      const timer = setTimeout(() => setSeconds(seconds - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      if (dateList?.minute && dateList?.minute > 0) {
        setSeconds(59);
        setDate((prev) => ({ ...prev, minute: dateList?.minute - 1 }));
      } else setSeconds("00");
    }
  }, [seconds]);

  useEffect(() => {
    setDate(dateList);
  }, []);

  return (
    <main className="max-w-[426px] lg:max-w-[260px] w-full flex-col justify-start items-center gap-4 flex relative">
      <div className="text-white text-xl font-extrabold absolute top-4 left-4 leading-[41.63px] capitalize">
        {title}
      </div>
      <img
        src={image}
        alt="preview cover art"
        className="w-[426px] lg:w-[260px] lg:h-[260px] object-cover rounded-xl h-[426px]"
      />

      <div className="w-full px-2 py-2 lg:py-0 rounded-2xl border border-violet-600 justify-center items-center gap-2.5 inline-flex">
        {image ? (
          <img
            src={image}
            alt="preview thumbnail art"
            className="w-20 h-20 lg:w-12 lg:h-12 relative border border-purple rounded-[10.41px] object-cover"
          />
        ) : (
          <div className="w-20 h-20 lg:w-12 lg:h-12 relative border border-purple rounded-[10.41px]" />
        )}
        <Countdown seconds={seconds} date={dateList} minute={date?.minute} />
      </div>
    </main>
  );
}
