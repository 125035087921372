import { Header } from "components/shared/Header/Header";
import { MainWrapper } from "components/shared/MainWrapper/MainWrapper";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router";

const CancelPayment = () => {
  const { demoId } = useParams();
  const navigate = useNavigate();
  const ids = JSON.parse(localStorage.getItem("ids"));

  useEffect(() => {
    if (ids[demoId]) {
      let removeFromStorage = { ...ids };
      delete removeFromStorage[demoId];
      localStorage.setItem("ids", JSON.stringify(removeFromStorage));
    } else {
      navigate("/");
    }
  }, []);

  return (
    <MainWrapper>
      <Header />
      <section className="flex flex-col items-center gap-10 mt-20">
        <div className="text-center text-gray-50 text-2xl lg:text-3xl font-bold lg:leading-[56px] lg:mt-12">
          Sorry! <br />
          <p className="text-2xl mt-5 text-gray-600">
            Your demo was not added
          </p>
        </div>
        <p className="text-center text-gray-600 text-base">In order to submit your demo for review, you need to first proceed with payment</p>
        <button
          onClick={() => {
            navigate("/");
          }}
          className="px-5 py-3 bg-violet-600 rounded-full text-gray-50 text-lg font-bold leading-snug w-full lg:w-fit"
        >
          Go Home
        </button>
      </section>
    </MainWrapper>
  );
};

export default CancelPayment;
