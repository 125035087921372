import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import homeIcon from "assets/icons/home-icon.svg";
import bellIcon from "assets/icons/bell-icon.svg";
import slidersIcon from "assets/icons/sliders-icon.svg";
import closeIcon from "assets/icons/close-icon.svg";
import caretIcon from "assets/icons/circle-arrow-icon.svg";
import checkmarkIcon from "assets/icons/checkmark-icon.svg";
import velvLogo from "assets/images/velv-logo.svg";
import velvLogoAnimated from "assets/images/velv-logo.gif";
import velvNotifications from "assets/icons/velv-notification.svg";
import "./Sidebar.scss";
import {
  AFRICAN_LIST,
  ASIAN_LIST,
  EUROPEAN_LIST,
  LATIN_LIST,
  MIDDLE_EASTER_LIST,
  NORTH_AMERICA_LIST,
  OCEANIAN_LIST,
  SCANDINAVIAN_LIST,
} from "utils/constants";
import { onMessage } from "@firebase/messaging";
import { messaging } from "config/firebase-config";
import { getAllContinentsList } from "utils/helpers";

export function Sidebar() {
  const navigate = useNavigate();
  const [showFilters, setShowFilters] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const [selectAll, setSelectAll] = useState(true);
  const [notifications, setNotifications] = useState([]);
  let countryFilters = JSON.parse(localStorage.getItem("filters")) || [];

  onMessage(messaging, (payload) => {
    setNotifications((prev) => [...prev, payload?.notification]);
  });

  return (
    <>
      <div
        className={`hidden lg:flex flex-col w-full md:w-[375px] fixed px-8 h-screen top-0 bottom-0 pt-14 z-30 bg-[#141416] ease-in-out duration-300 cursor-pointer overflow-y-auto hide-scroll-bar ${
          showFilters || showNotifications ? "left-0" : "-left-full"
        }`}
      >
        <section className="flex justify-between items-center">
          <img src={velvLogo} alt="velv" className="w-8" />
          <img
            src={closeIcon}
            alt="close"
            className="w-4 h-4 ml-auto"
            onClick={() => {
              setShowFilters(false);
              setShowNotifications(false);
            }}
          />
        </section>
        {showFilters && (
          <>
            <MenuSectionFilters
              setSelectAll={setSelectAll}
              checkmarkIcon={checkmarkIcon}
              selectAll={selectAll}
            />
            <button
              className="text-center bg-purple w-full text-white rounded-full py-3 mt-8 font-bold text-sm mb-20"
              onClick={() => navigate(0)}
            >
              Add filters
            </button>
          </>
        )}
        {showNotifications && (
          <MenuSectionNotifications
            setSelectAll={setSelectAll}
            checkmarkIcon={checkmarkIcon}
            selectAll={selectAll}
            notifications={notifications}
          />
        )}
      </div>

      <div className="hidden lg:flex flex-col w-[90px] gap-9 fixed pl-8 h-screen top-0 bottom-0 pt-8 z-10">
        <div className="w-auto">
          <img src={velvLogoAnimated} alt="velv" className="w-10 h-10 -ml-1" />
        </div>
        {/* <img
          src={hamburgerIcon}
          alt="hamburger"
          className="menu-icon active-icon"
        /> */}
        <img
          src={homeIcon}
          alt="home"
          className="menu-icon"
          onClick={() => navigate("/")}
        />
        <div className="relative">
          {notifications?.length ? (
            <div className="bg-red-500 rounded-full flex justify-center items-center w-4 h-4 text-sm text-white font-bold absolute -top-1 right-7 z-10">
              {notifications?.length}
            </div>
          ) : null}
          <img
            src={bellIcon}
            alt="bell"
            className="menu-icon"
            onClick={() => setShowNotifications(true)}
          />
        </div>
        <div className="relative">
          {countryFilters?.length ? (
            <div className="bg-purple rounded-full flex justify-center items-center w-4 h-4 text-sm text-white font-bold absolute -top-1 right-7 z-10">
              {countryFilters?.length}
            </div>
          ) : null}
          <img
            src={slidersIcon}
            alt="sliders"
            className="menu-icon"
            onClick={() => setShowFilters(true)}
          />
        </div>
      </div>
    </>
  );
}

function FilterLabel({ country, countryList, isSelected, setSelectAll }) {
  const [selected, setSelected] = useState(isSelected);
  const [openList, setOpenList] = useState(false);

  const handleSelected = (e) => {
    let countryFilters = JSON.parse(localStorage.getItem("filters")) || [];
    setSelectAll(false);
    setSelected((prev) => !prev);
    e.preventDefault();
    let newFilters = [];
    const parsedCountry = country.split(" ").join("").toLowerCase();
    if (countryFilters !== null) {
      if (countryFilters.includes(parsedCountry)) {
        countryFilters = countryFilters.filter(
          (item) => item !== parsedCountry
        );
        newFilters = [...countryFilters];
      } else newFilters = [...countryFilters, parsedCountry];
    } else newFilters.push(parsedCountry);

    localStorage.setItem("filters", JSON.stringify(newFilters));
  };

  useEffect(() => {
    setSelected(isSelected);
  }, [isSelected]);

  return (
    <label className="w-full">
      <input
        className="peer/showLabel absolute scale-0"
        type="checkbox"
        onClick={() => setOpenList((prev) => !prev)}
      />
      <span className="block max-w-xs overflow-hidden px-4 py-0 text-white p-2 border-b border-[#353945] transition-all duration-300">
        <div className="flex h-14 cursor-pointer items-center font-bold text-base">
          <div
            className="border-2 border-solid border-purple rounded-full h-5 w-5 mr-2"
            onClick={handleSelected}
          >
            {selected ? (
              <img
                src={checkmarkIcon}
                alt="checkmark"
                className="purple-filter"
              />
            ) : null}
          </div>
          {country}
          <img
            src={caretIcon}
            alt="caret"
            className="ml-auto w-6 h-6 transition-all duration-300 rotate-90"
          />
        </div>
        {openList && (
          <ul className="mb-2 w-full flex gap-2 flex-col">
            {countryList &&
              Object.values(countryList)?.map((item) => (
                <li key={item} className="p-2">
                  {item}
                </li>
              ))}
          </ul>
        )}
      </span>
    </label>
  );
}

export const MenuSectionFilters = ({
  setSelectAll,
  checkmarkIcon,
  selectAll,
}) => {
  const countryFilters = JSON.parse(localStorage.getItem("filters")) || [];
  const listOfAllCountries = getAllContinentsList();

  const handleSelectAll = (status) => {
    if (status === "add") {
      if (selectAll) {
        localStorage.setItem("filters", JSON.stringify(listOfAllCountries));
        setSelectAll(false);
      } else {
        localStorage.setItem("filters", JSON.stringify([]));
        setSelectAll(true);
      }
    }
    if (status === "remove") {
      localStorage.setItem("filters", JSON.stringify([]));
      setSelectAll(true);
    }
  };

  return (
    <section>
      <h3 className="mt-12 text-xs font-extrabold text-[#B1B5C3]">
        FILTER MARKETS
      </h3>
      <section className="grid place-items-center mt-3 rounded-xl bg-[#23262F] border border-solid border-[#353945] shadow-lg">
        <label className="w-full">
          <input className="absolute scale-0" type="checkbox" />
          <span className="w-full block max-h-14 max-w-xs overflow-hidden px-4 py-0 text-white border-b border-[#353945] p-2 rounded-b-none">
            <div className="flex h-14 cursor-pointer items-center font-bold text-purple w-full text-base">
              <div
                className="border-2 border-solid border-purple rounded-full h-5 w-5 mr-2"
                onClick={() => handleSelectAll("add")}
              >
                <img
                  src={checkmarkIcon}
                  alt="checkmark"
                  className={`${
                    !countryFilters?.length && selectAll
                      ? "visible"
                      : "invisible"
                  } purple-filter`}
                />
              </div>
              All Markets
              <p
                className="ml-auto text-gray-light opacity-50 text-base"
                onClick={() => handleSelectAll("remove")}
              >
                Remove Filters
              </p>
            </div>
          </span>
        </label>
        <FilterLabel
          country={"Africa"}
          countryList={AFRICAN_LIST}
          isSelected={!countryFilters?.includes("africa")}
          setSelectAll={setSelectAll}
        />
        <FilterLabel
          country={"Asia"}
          countryList={ASIAN_LIST}
          isSelected={!countryFilters?.includes("asia")}
          setSelectAll={setSelectAll}
        />
        <FilterLabel
          country={"Europe"}
          countryList={EUROPEAN_LIST}
          isSelected={!countryFilters?.includes("europe")}
          setSelectAll={setSelectAll}
        />
        <FilterLabel
          country={"Latin America"}
          countryList={LATIN_LIST}
          isSelected={!countryFilters?.includes("latinamerica")}
          setSelectAll={setSelectAll}
        />
        <FilterLabel
          country={"Middle East"}
          countryList={MIDDLE_EASTER_LIST}
          isSelected={!countryFilters?.includes("middleeast")}
          setSelectAll={setSelectAll}
        />
        <FilterLabel
          country={"North America"}
          countryList={NORTH_AMERICA_LIST}
          isSelected={!countryFilters?.includes("northamerica")}
          setSelectAll={setSelectAll}
        />
        <FilterLabel
          country={"Oceania"}
          countryList={OCEANIAN_LIST}
          isSelected={!countryFilters?.includes("oceania")}
          setSelectAll={setSelectAll}
        />
        <FilterLabel
          country={"Scandinavia"}
          countryList={SCANDINAVIAN_LIST}
          isSelected={!countryFilters?.includes("scandinavia")}
          setSelectAll={setSelectAll}
        />
      </section>
    </section>
  );
};

export function MenuSectionNotifications({ notifications }) {
  return (
    <section>
      <h3 className="mt-12 text-sm font-extrabold text-[#B1B5C3]">
        NOTIFICATIONS
      </h3>
      <section className="grid place-items-center mt-3 rounded-xl bg-[#23262F] border border-solid border-[#353945] shadow-lg">
        <label className="w-full">
          <input className="absolute scale-0" type="checkbox" />
          <div className="w-full block max-w-xs overflow-hidden pl-2 py-0 text-white rounded-b-none">
            {notifications?.length
              ? notifications?.map((item, idx) => (
                  <div key={idx}>
                    <div className="flex gap-3 h-14 cursor-pointer text-[10px] items-center font-bold text-gray-200 w-full justify-start align-middle my-2 px-1 rounded-xl">
                      <img src={velvNotifications} alt="Velv notification" />
                      <div>
                        <div className="w-full text-gray-200 text-[14px] leading-5">
                          {item?.title}
                        </div>
                        <div className="text-slate-500 text-[12px] leading-[12.47px]">
                          {item?.body}
                        </div>
                      </div>
                    </div>
                    {notifications?.length - 1 === idx ? null : (
                      <div className="w-full h-px relative bg-zinc-700" />
                    )}
                  </div>
                ))
              : "No new notifications"}
          </div>
        </label>
      </section>
    </section>
  );
}
