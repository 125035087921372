import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Header } from "components/shared/Header/Header";
import { Footer } from "components/shared/Footer/Footer";
import { Sidebar } from "components/Sidebar/Sidebar";
import arrowIcon from "assets/icons/arrow-icon.svg";
import outlineStarIcon from "assets/icons/star-outline-icon.svg";
import starIcon from "assets/icons/yellow-star-icon.svg";
import copySuccessIcon from "assets/icons/copy-success-icon.svg";
import shareNewIcon from "assets/icons/share-new-icon.svg";
import "./Drop.scss";
import {
  Timestamp,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  limit,
  query,
  where,
} from "firebase/firestore";
import { auth, db, getLocalisedPriceFromProduct } from "config/firebase-config";
import { useParams, useLocation } from "react-router-dom";
import { ROLES_LIST } from "utils/constants";
import {
  calculateTimeDifference,
  displayDate,
  getAllCountriesObject,
  getGenreTitleByKey,
  handleFavorites,
  isWithinSixMonth,
  roundNumberTwoDecimalPlaces,
} from "utils/helpers";
import NotFound from "pages/NotFound/NotFound";
import backgroundImage from "assets/images/drop-background.png";
import { Modal } from "components/shared/Modal";
import { Tooltip } from "react-tooltip";
import ReactGA from "react-ga4";
import loadingAnimation from "assets/icons/loading.svg";

const COPY_MESSAGE_DURATION = 2000;

const Drop = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const [isFavorite, setIsFavorite] = useState(false);
  const [drop, setDrop] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingRate, setLoadingRate] = useState(false);
  const [date, setDate] = useState(null);
  const [currencyRate, setCurrencyRate] = useState(0);
  const [copyMessage, setCopyMessage] = useState("");
  const [isNotFound, setIsNotFound] = useState(false);
  const allCountriesObject = getAllCountriesObject();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [canDeleteDrop, setCanDeleteDrop] = useState(false);

  const getItem = async () => {
    setLoading(true);
    const docRef = doc(db, "drops", id);
    try {
      const snapshot = await getDoc(docRef);
      const data = snapshot.data();

      if (data) {
        setDrop({ ...data, dropId: snapshot?.id });
        setIsNotFound(false);
      } else {
        setIsNotFound(true);
      }
      const startDate = data?.createdTime;
      const endDate = data?.expireTime;
      const dateList = calculateTimeDifference(startDate, endDate);

      setDate(dateList);
    } catch (error) {
      console.log("Error getting drops", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getItem();
  }, []);

  useEffect(() => {
    setLoadingRate(true);
    const favorites = localStorage.getItem("favorites");
    if (favorites) {
      const list = JSON.parse(favorites);
      if (list[id]) setIsFavorite(true);
    }
    fetch(getLocalisedPriceFromProduct, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        productId: process.env.REACT_APP_DEFAULT_PRODUCT_ID,
      }),
    })
      .then((response) => response.json())
      .then((item) => {
        const rate =
          (user?.soundtrap &&
          user?.discount > 0 &&
          isWithinSixMonth(user?.createdTime)
            ? item?.price / 2
            : item?.price) / 100;
        setCurrencyRate({
          ...item,
          price: roundNumberTwoDecimalPlaces(rate),
          original: roundNumberTwoDecimalPlaces(item?.price / 100),
        });
      })
      .finally(() => setLoadingRate(false));
  }, [drop]);

  const copyToClipBoard = async () => {
    try {
      await navigator.clipboard.writeText(window.location.href);
      setCopyMessage("Link copied!");
    } catch (err) {
      setCopyMessage("Failed to copy!");
    }
    setTimeout(() => {
      setCopyMessage("");
    }, COPY_MESSAGE_DURATION);
  };

  const isMyDrop =
    auth?.currentUser?.uid === drop?.userId &&
    (user?.profession === ROLES_LIST.ANR ||
      user?.profession === ROLES_LIST.MANAGER ||
      user?.profession === ROLES_LIST.SUPERVISOR ||
      user?.profession === ROLES_LIST.CORPORATE);

  const checkIfDropIsDeleteable = async () => {
    try {
      const dropId = drop?.dropId ?? "";
      const qDemos = query(
        collection(db, "demos"),
        where("dropId", "==", dropId),
        limit(1)
      );
      const querySnapshotDemos = await getDocs(qDemos);
      const hasNoMatchingDocs = querySnapshotDemos.docs.length === 0;
      setCanDeleteDrop(hasNoMatchingDocs);
    } catch (error) {
      console.error("Error checking if drop is deleteable:", error);
    }
  };

  useEffect(() => {
    if (isMyDrop) {
      checkIfDropIsDeleteable();
    }
  }, [isMyDrop]);

  const onPressDeleteDrop = () => {
    if (canDeleteDrop) {
      setShowDeleteModal(true);
    }
  };

  const deleteDropConfirm = async () => {
    setShowDeleteModal(false);
    const itemDoc = doc(db, "drops", drop?.dropId ?? "");
    await deleteDoc(itemDoc);
    navigate(-1);
  };

  useEffect(() => {
    const dropTitle = drop?.artist?.toLowerCase().replaceAll(" ", "_");
    ReactGA.event({
      category: "Drop View",
      action: dropTitle ? `soundtrap_drop_view_${dropTitle}` : "drop_view",
      label: location.pathname + location.search,
      nonInteraction: true,
      transport: "beacon",
    });
  }, [location]);

  const handleClickSubmitBtn = () => {
    const dropTitle = drop?.artist?.toLowerCase().replaceAll(" ", "_");
    ReactGA.event({
      category: "Submit Click",
      action: dropTitle ? `soundtrap_submit_click_${dropTitle}` : "submit_click",
      label: location.pathname + location.search,
      nonInteraction: true,
      transport: "beacon",
    });
    navigate("submit");
  };

  return (
    <div
      className="bg-[radial-gradient(circle_at_center_left,_var(--tw-gradient-stops))] from-[#00315F] to-25%"
      draggable="false"
    >
      <Sidebar />
      <div className="mx-4 lg:ml-32 lg:mr-16 relative z-10">
        <Header />
        <img
          src={arrowIcon}
          alt="chevron"
          className="hidden lg:flex rotate-90 w-5 h-5 cursor-pointer mt-10"
          onClick={() => navigate(-1)}
        />
        {isNotFound ? (
          <NotFound />
        ) : (
          <>
            <div className="flex flex-col lg:flex-row w-full gap-6 lg:gap-12 mt-7 relative">
              <section className="max-h-[266px] max-w-[266px] lg:max-h-[306px] lg:max-w-[296px] w-auto col-span-1 flex flex-col mx-auto lg:mx-0">
                {loading ? (
                  <div
                    className={`animate-pulse bg-gray-800 h-[266px] lg:h-[326px] w-auto lg:w-[296px] relative rounded-xl border border-violet-600 overflow-hidden`}
                  ></div>
                ) : (
                  <img
                    src={drop?.artworkUrl}
                    alt="preview hero"
                    className="relative -z-10 h-[266px] lg:h-[326px] w-auto lg:w-[296px] object-cover rounded-3xl"
                  />
                )}

                <div className="flex items-center bg-black pl-2 pr-0 lg:w-[296px] rounded-b-3xl -mt-16 backdrop-blur-xl bg-opacity-75 max-h-[75px]">
                  {date && (
                    <Countdown dateList={date} image={drop?.artworkUrl} />
                  )}
                  {(user?.profession === ROLES_LIST.PRODUCER ||
                    user?.profession === ROLES_LIST.SONG_WRITER ||
                    user?.profession === ROLES_LIST.SONG_WRITER_PRODUCER) && (
                    <div className="flex items-end justify-end">
                      <img
                        draggable="false"
                        src={isFavorite ? starIcon : outlineStarIcon}
                        alt="favorites"
                        className="favorites w-5 h-24 cursor-pointer mr-2"
                        onClick={() => {
                          handleFavorites(drop);
                          setIsFavorite((prev) => !prev);
                        }}
                      />
                    </div>
                  )}
                </div>
              </section>
              <section className="flex flex-col gap-3 text-left text-white col-span-2 max-w-[403px] w-full">
                <div className="absolute right-0 text-white w-fit flex flex-col items-end lg:items-center">
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      copyToClipBoard();
                    }}
                    className="border border-solid border-slate-500 p-2 rounded-full hover:shadow-[0_0_20px_0_rgba(108,40,255,1)]"
                  >
                    <div className="relative flex gap-2 justify-center items-center cursor-pointer click focus:text-purple focus:font-bold">
                      <img
                        src={shareNewIcon}
                        alt="share"
                        className="w-[24px] h-[24px]"
                      />
                      {copyMessage && (
                        <div className="absolute flex items-center gap-1 top-[-36px] right-[-18px] lg:right-[-12px] z-50 bg-[#000] h-[26px]">
                          <span className="font-Mulish font-bold text-[#6D28FF] text-xs leading-[13.81px] whitespace-nowrap">
                            {copyMessage}
                          </span>
                          <img
                            src={copySuccessIcon}
                            alt="copySuccess"
                            className="w-4 h-4"
                          />
                        </div>
                      )}
                    </div>
                  </button>
                  {isMyDrop && (
                    <>
                      <button
                        disabled={!canDeleteDrop}
                        className={`tooltipAnchor lg:px-6 lg:py-3 lg:bg-red-500 rounded-[90px] lg:justify-center lg:items-center gap-3 lg:mx-0 w-fit mt-3 lg:mt-4 ${
                          canDeleteDrop ? "" : "opacity-50"
                        }`}
                        onClick={onPressDeleteDrop}
                      >
                        <div className="text-center text-red-500 lg:text-gray-50 text-base font-bold leading-none w-fit">
                          Delete Drop
                        </div>
                      </button>
                      {!canDeleteDrop && (
                        <Tooltip anchorSelect={`.tooltipAnchor`}>
                          <div
                            className={`text-left px-2 py-1 text-white text-sm rounded-md right-auto lg:-right-1`}
                          >
                            <p className="font-bold text-xs text-center">
                              This Drop can not be deleted!
                            </p>
                            <p className="max-w-[240px] text-xs mt-1 leading-xl">
                              This drop can not be deleted as you have
                              submissions for this Drop.
                            </p>
                          </div>
                        </Tooltip>
                      )}
                    </>
                  )}
                </div>
                <h1 className="text-xl lg:text-lg font-bold capitalize">
                  {drop?.artist || "-"}
                </h1>
                <div className="flex text-sm lg:text-base">
                  <p>Label/Company:</p>
                  <h4 className="font-extrabold ml-4 capitalize">
                    {drop?.recordLabel || "-"}
                  </h4>
                </div>
                <div className="flex text-sm lg:text-base">
                  <p>Regional office :</p>
                  <h4 className="font-extrabold ml-4 capitalize">
                    {allCountriesObject[drop?.regionalOffice] ||
                      drop?.regionalOffice}
                  </h4>
                </div>
                <div className="flex text-sm lg:text-base">
                  <p>Genre:</p>
                  <h4 className="font-extrabold ml-4 capitalize">
                    {getGenreTitleByKey(drop?.genre) || "-"}
                  </h4>
                </div>
                {Timestamp.fromDate(new Date()) <= drop?.expireTime &&
                  (user?.profession === ROLES_LIST.PRODUCER ||
                    user?.profession === ROLES_LIST.SONG_WRITER ||
                    user?.profession === ROLES_LIST.SONG_WRITER_PRODUCER) && (
                    <>
                      <button
                        onClick={handleClickSubmitBtn}
                        className="max-w-[300px] text-center bg-gradient-to-r from-[#4a229d] from-40% to-[#9e00cd] w-full text-white rounded-full py-3 mt-8 font-bold text-smg lg:text-sm hover:shadow-[0_0_20px_0_rgba(108,40,255,1)]"
                      >
                        Submit
                      </button>
                      <div className="mt-2 lg:mt-6 flex justify-between items-center">
                        <b className="uppercase text-xs lg:text-base font-bold">
                          SUBMISSION FEE:
                        </b>
                        {loadingRate ? (
                          <img
                            src={loadingAnimation}
                            alt="loading"
                            className="w-8 h-7 brightness-[103%] invert-[99%] saturate-[6%] hue-rotate-[206deg] contrast-[104%]"
                          />
                        ) : (
                          <div className="flex items-center">
                            <div>
                              {user?.soundtrap &&
                                user?.discount > 0 &&
                                isWithinSixMonth(user?.createdTime) && (
                                  <p className="line-through decoration-red-500 text-gray-500">{`${
                                    currencyRate?.original || "-"
                                  } ${currencyRate?.currency || ""}`}</p>
                                )}
                              <p>{`${currencyRate?.price || "-"} ${
                                currencyRate?.currency || ""
                              }`}</p>
                            </div>
                            {user?.soundtrap &&
                              user?.discount > 0 &&
                              isWithinSixMonth(user?.createdTime) && (
                                <span className="bg-red-500 rounded-md px-1 h-fit ml-2">
                                  50% off
                                </span>
                              )}
                          </div>
                        )}
                      </div>
                    </>
                  )}
                {isMyDrop && (
                  <button
                    onClick={() => navigate(`/drop/edit/${id}`)}
                    className="max-w-[300px] text-center bg-gradient-to-r from-[#4a229d] from-40% to-[#9e00cd] w-full text-white rounded-full py-3 mt-8 font-bold text-smg lg:text-sm hover:shadow-[0_0_20px_0_rgba(108,40,255,1)]"
                  >
                    Edit Drop
                  </button>
                )}
              </section>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-2 w-full gap-28 mt-9 lg:mt-16 relative text-white text-left">
              <section>
                <h1 className="text-2xl lg:text-3xl mb-4">Looking for:</h1>
                <p className="text-sm lg:text-base whitespace-pre-wrap">
                  {drop?.sections[0]?.text || "-"}
                </p>
              </section>
            </div>
            {drop?.sections?.length && drop?.sections[1]?.text && (
              <div className="grid grid-cols-1 lg:grid-cols-2 w-full gap-28 mt-12 relative text-white text-left">
                <section>
                  <h1 className="text-2xl lg:text-3xl">Important note:</h1>
                  <p className="text-sm lg:text-base whitespace-pre-wrap">
                    {drop?.sections[1]?.text || "-"}
                  </p>
                </section>
              </div>
            )}
            {drop?.referenceLinks?.length ? (
              <div className="grid grid-cols-1 lg:grid-cols-2 w-full gap-28 mt-8 relative text-white text-left">
                <section className="flex flex-col">
                  <h1 className="flex text-2xl lg:text-lg font-bold mb-2">
                    Reference links:
                  </h1>
                  {drop?.referenceLinks?.map((links, index) => (
                    <a
                      key={index}
                      href={links?.urlString}
                      className="text-sm lg:text-base text-purple mb-1"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {links?.title || "-"}
                    </a>
                  ))}
                </section>
              </div>
            ) : null}
            <div className="grid grid-cols-1 w-full gap-12 border border-[#ffffff99] border-[1px] rounded-[18px] bg-[#01010199] relative text-white text-left p-6 mt-12">
              <img
                src={backgroundImage}
                alt="background"
                className="hidden lg:flex absolute -z-10 top-0 right-0 h-full w-2/3"
              />
              <section>
                <h1 className="text-2xl lg:text-lg mb-2 font-bold">
                  Production fee & Royalty:
                </h1>
                <p className="text-sm lg:text-base">
                  Any production fee/flat fee/dealpoint will be negotiated
                  directly with the A&R/music supervisor/client on a
                  song-by-song basis.
                </p>
              </section>
              <section>
                <h1 className="text-2xl lg:text-lg mb-2 font-bold">
                  Publishing requirements:
                </h1>
                <p className="text-sm lg:text-base">
                  {drop?.publishingRequirement
                    ? "This drop is open for all the writers and  producers with or without Publishing agreements."
                    : "This drop requires you to be free from Publishing agreements."}
                </p>
              </section>
              <section>
                <h1 className="text-2xl lg:text-lg mb-2 font-bold">
                  Velv recommendations:
                </h1>
                <ul className="text-sm lg:text-base list-disc ml-4">
                  <li>
                    Make sure that you read and understood the brief correctly
                  </li>
                  <li>
                    Always make sure to have the stems, instrumentals and
                    acapella ready
                  </li>
                  <li>Do not use producer tags in your demos</li>
                </ul>
              </section>
            </div>
          </>
        )}
      </div>
      {showDeleteModal && (
        <Modal setShowModal={setShowDeleteModal} title="Delete Drop?">
          <div className="flex flex-col gap-4">
            <p className="text-gray-50 text-base font-medium leading-normal mb-4 text-center">
              Are you sure you want to delete this Drop? <br />
              All data will be lost. This can not be undone.
            </p>
            <button
              onClick={deleteDropConfirm}
              className="w-full h-10 px-6 py-3 text-gray-50 font-bold bg-red-500 rounded-[90px] justify-center items-center gap-3 inline-flex"
            >
              Yes
            </button>
            <button
              onClick={() => setShowDeleteModal(false)}
              className="w-full h-10 px-6 py-3 text-gray-50 font-bold rounded-[90px] border-2 border-zinc-700 justify-center items-center gap-3 inline-flex"
            >
              Cancel
            </button>
          </div>
        </Modal>
      )}
      <Footer />
    </div>
  );
};

export default Drop;

function Countdown({ dateList, image }) {
  const [seconds, setSeconds] = useState(dateList?.second);
  const [date, setDate] = useState(null);
  //TODO: Extract this to separate component to prevent re-rendering
  useEffect(() => {
    if (seconds > 0) {
      const timer = setTimeout(() => setSeconds(seconds - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      if (dateList?.minute && dateList?.minute > 0) {
        setDate((prev) => ({ ...prev, minute: dateList?.minute - 1 }));
        setSeconds(59);
      } else setSeconds("00");
    }
  }, [seconds]);

  useEffect(() => {
    setDate(dateList);
  }, []);

  return (
    <div className="w-full p-2 rounded-2xl justify-center items-center gap-1.5 inline-flex">
      <div className="hidden lg:flex">
        {image ? (
          <img
            src={image}
            alt="preview thumbnail art"
            className="w-14 h-14 relative border border-purple rounded-[10.41px] object-cover"
          />
        ) : (
          <div className="w-14 h-14 relative border border-purple rounded-[10.41px]" />
        )}
      </div>
      <div className="grow shrink basis-0 lg:justify-center items-center gap-2 flex">
        <div className="lg:w-[180px] flex-col justify-start items-center inline-flex">
          <div className="self-stretch justify-between items-start inline-flex">
            <div className="p-2 justify-center items-center gap-1.5 flex">
              <div className="text-white text-base font-normal font-digitalnumbers">
                {displayDate(date?.day) || "00"}
              </div>
            </div>
            <div className="p-2 justify-center items-center gap-1.5 flex">
              <div className="text-white text-base font-normal font-digitalnumbers">
                {displayDate(date?.hour) || "00"}
              </div>
            </div>
            <div className="p-2 justify-center items-center gap-1.5 flex">
              <div className="text-white text-base font-normal font-digitalnumbers">
                {displayDate(date?.minute) || "00"}
              </div>
            </div>
            <div className="p-2 justify-center items-center gap-1.5 flex">
              <div className="text-white text-base font-normal font-digitalnumbers">
                {seconds || "00"}
              </div>
            </div>
          </div>
          <div className="self-stretch justify-between items-start inline-flex text-base">
            <div className="pr-2 justify-center items-center gap-2.5 flex">
              <div className="w-10 text-right text-white font-normal leading-tight">
                Day
              </div>
            </div>
            <div className="pr-2 justify-center items-center gap-2.5 flex">
              <div className="text-white font-normal leading-tight">Hour</div>
            </div>
            <div className="pr-2 justify-center items-center gap-2.5 flex">
              <div className="text-white font-normal leading-tight">Min</div>
            </div>
            <div className="pr-2 justify-center items-center gap-2.5 flex">
              <div className="text-white font-normal leading-tight">Sec</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
