import WidthWrapper from '../shared/WidthWrapper/WidthWrapper';
import PropTypes from 'prop-types';

const DownloadVelv = ({ appleStoreImg }) => {
  return (
    <div className="text-white text-center bg-[radial-gradient(circle_at_bottom_right,_var(--tw-gradient-stops))] to-[#000] via-[#40137A] via-0% from-[#00315F] to-75% pb-4 lg:pb-[90px]">
      <WidthWrapper>
        <h1 className="font-semibold text-2xl lg:text-5xl pt-4 lg:pt-24 text-center">
          Download Velv
        </h1>
        <img
          src={appleStoreImg}
          onClick={() =>
            window.open(process.env.REACT_APP_IOS_DOWNLOAD_URL, '_blank')
          }
          alt="app store"
          className="lg:mt-10 mt-3 mx-auto cursor-pointer w-28 lg:w-60 hover:shadow-[0_0_20px_0_rgba(108,40,255,1)]"
        />
      </WidthWrapper>
    </div>
  );
};

DownloadVelv.propTypes = { appleStoreImg: PropTypes.any };

export default DownloadVelv;
