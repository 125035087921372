import { useState } from "react";
import { DropItems } from "pages/AddDrop/DropItems";
import { Header } from "components/shared/Header/Header";
import { Footer } from "components/shared/Footer/Footer";
import { Sidebar } from "components/Sidebar/Sidebar";
import checkmarkIcon from "assets/icons/checkmark-icon.svg";
import deleteIcon from "assets/icons/delete-icon.svg";
import { Modal } from "components/shared/Modal";
import "pages/MyPitches/MyPitches.scss";

const AddDrop = ({ data }) => {
  const [tabActive, setTabActive] = useState(1);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [clearForm, setClearForm] = useState(false);

  return (
    <div className="bg-[radial-gradient(circle_at_center_left,_var(--tw-gradient-stops))] from-[#00315F] to-25%">
      <Sidebar />
      <div className="mx-4 lg:ml-32 lg:mr-16 relative z-20 mb-28">
        {/* overflow-hidden */}
        <Header tab="adddrop" />
        <div className="flex flex-col items-center gap-12 justify-center mx-auto mt-6">
          <div className="self-stretch justify-start items-center gap-4 inline-flex">
            <div className="grow shrink basis-0 text-gray-50 text-xl lg:text-2xl font-extrabold">
              {data ? "Edit" : "Add"} Drop
            </div>
            <button
              onClick={() => setShowDeleteModal(true)}
              className="ml-auto w-5"
            >
              <img src={deleteIcon} alt="delete drop" />
            </button>
          </div>
          <section className="my-auto justify-between items-start inline-flex w-full mx-auto max-w-[904px]">
            <div className={tabActive === 1 ? "flex" : "hidden lg:flex"}>
              <TabItem
                number={1}
                title={`${data ? "Edit" : "Add"} Drop`}
                active={tabActive === 1}
                success={tabActive > 1}
              />
            </div>
            <div>
              <TabItem
                number={2}
                title={`${data ? "Edit" : "Add"} Details`}
                active={tabActive === 2}
                success={tabActive > 2}
              />
            </div>
            <div
              className={
                tabActive === 3 || tabActive === 2 || tabActive === 4
                  ? "flex"
                  : "hidden lg:flex"
              }
            >
              <TabItem
                number={3}
                title={"Preview"}
                active={tabActive === 3}
                success={tabActive > 3}
              />
            </div>
          </section>
          <DropItems
            tabActive={tabActive}
            setTabActive={setTabActive}
            clearForm={clearForm}
            setClearForm={setClearForm}
            data={data}
          />
        </div>
      </div>
      {showDeleteModal && (
        <Modal setShowModal={setShowDeleteModal} title="Remove">
          <div className="flex flex-col gap-4">
            <p className="text-gray-50 text-base font-medium leading-normal mb-4 text-center">
              All data will be lost? <br />
              Are you sure you want to quit this drop?
            </p>
            <button
              onClick={() => {
                setShowDeleteModal(false);
                window.location.reload();
              }}
              className="w-full h-10 px-6 py-3 text-gray-50 font-bold bg-red-500 rounded-[90px] justify-center items-center gap-3 inline-flex"
            >
              Yes
            </button>
            <button
              onClick={() => setShowDeleteModal(false)}
              className="w-full h-10 px-6 py-3 text-gray-50 font-bold rounded-[90px] border-2 border-zinc-700 justify-center items-center gap-3 inline-flex"
            >
              Cancel
            </button>
          </div>
        </Modal>
      )}
      <Footer />
    </div>
  );
};

export default AddDrop;

function TabItem({ number, title, active, success, className = "" }) {
  return (
    <div
      className={`max-w-64 flex-col justify-start items-start gap-3 inline-flex ${className}`}
    >
      <div className="self-stretch justify-start items-center gap-[16px] inline-flex pr-10">
        <div
          className={`px-[10px] ${success ? "py-[4px]" : "py-[2px]"} ${active ? "bg-gray-50" : "bg-zinc-700"} ${
            success && "!bg-[#45B26B] !px-2"
          } rounded-[40px] flex-col justify-center items-center gap-2.5 inline-flex`}
        >
          <div
            className={`text-center ${success && "!text-white"} ${
              active ? "text-zinc-800" : "text-slate-500"
            } font-medium`}
          >
            {success ? (
              <img
                src={checkmarkIcon}
                alt="checkmark"
                className="w-[14px] h-5 invert-0 sepia-0 saturate-[13%] hue-rotate-[235deg] brightness-[160%] contrast-[150%]"
              />
            ) : (
              number
            )}
          </div>
        </div>
        <div
          className={`grow shrink basis-0 ${
            active ? "text-gray-50" : "text-slate-500"
          } ${success && "!text-[#45B26B]"} text-lg lg:text-base font-medium leading-normal`}
        >
          {title}
        </div>
      </div>
      <div
        className={`w-full h-0.5 relative ${
          active ? "bg-gray-50" : "bg-slate-500"
        } ${success && "!bg-[#45B26B]"} rounded-sm`}
      />
    </div>
  );
}
