import { useEffect, useState } from "react";
import DropsList from "pages/DropsList/DropsList";
import { COUNTRIES_LIST } from "utils/constants";
import { Timestamp, doc, getDoc } from "firebase/firestore";
import { db } from "config/firebase-config";
import { Loading } from "components/shared/Loading/Loading";

const Favorites = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getItem = async (id) => {
    const docRef = doc(db, "drops", id);
    try {
      const snapshot = await getDoc(docRef);
      if (snapshot?.exists()) {
        return { ...snapshot?.data(), dropId: id };
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  };

  useEffect(() => {
    const fetchFavorites = async () => {
      setIsLoading(true);
      const favorites = JSON.parse(localStorage?.getItem("favorites")) || {};
      const countryFilters = JSON.parse(localStorage?.getItem("filters")) || [];

      const favoriteIds = Object?.keys(favorites);
      let fetchedDrops = [];
      try {
        fetchedDrops = await Promise.all(favoriteIds?.map((id) => getItem(id)));
      } catch {
        setIsLoading(false);
        return;
      }

      const validDrops = fetchedDrops?.filter((drop) => drop !== null);

      const drops = validDrops?.map((item) => ({
        ...item,
        createdTime: item?.createdTime
          ? Timestamp.fromDate(
              new Date(
                item?.createdTime?.seconds * 1000 +
                  item?.createdTime?.nanoseconds / 1000000
              )
            )
          : null,
        expireTime: item?.expireTime
          ? Timestamp.fromDate(
              new Date(
                item?.expireTime?.seconds * 1000 +
                  item?.expireTime?.nanoseconds / 1000000
              )
            )
          : null,
      }));

      const currentTime = Timestamp.now();
      const futureDrops = drops?.filter(
        (drop) => drop?.expireTime && drop?.expireTime > currentTime
      );

      futureDrops?.sort((a, b) => a?.expireTime - b?.expireTime);

      let newList = [];
      Object.keys(COUNTRIES_LIST).forEach((item) => {
        if (!countryFilters.includes(item))
          newList = [...newList, ...Object.keys(COUNTRIES_LIST[item])];
      });
      let filteredDrops = [];
      if (newList?.length) {
        futureDrops?.forEach((item) => {
          const country = item?.regionalOffice?.replaceAll(" ", "");
          if (newList?.includes(country)) filteredDrops?.push(item);
        });
        filteredDrops?.sort((a, b) => a?.expireTime - b?.expireTime);
        setData(filteredDrops);
      } else {
        setData(drops);
      }
      setIsLoading(false);
    };

    fetchFavorites();
  }, []);

  const onClickItemFavorite = (id) => {
    setData(data?.filter((item) => item?.dropId !== id));
  };

  if (isLoading) return <Loading />;
  return (
    <DropsList
      title="Favorites"
      tab="favorites"
      data={data}
      showArtwork={false}
      isLoading={isLoading}
      onClickItemFavorite={onClickItemFavorite}
    />
  );
};

export default Favorites;
