import Pitches from "components/Pitches/Pitches";
import { useLocation } from "react-router-dom";

function PitchLearn() {
  const { state } = useLocation();

  return (
    <Pitches page={`/${state?.id}/${state?.pitchId}`}>
      <div className="text-white mt-6 mx-4 lg:mx-12">
        <h1 className="text-2xl mt-4 lg:mt-6 lg:mb-2 font-bold">
          How does Pitching status work?
        </h1>
        <div className="grid lg:grid-cols-2 gap-6 lg:gap-2">
          <ArticleItem
            title="Screening"
            text={
              "Screeners are now going through your demo to make sure it meets the brief guidelines."
            }
          />
          <ArticleItem
            title="Pitched"
            text={"Your demo has been submitted to the A&R/Music supervisor."}
          />
          <ArticleItem
            title="Hold"
            text={
              "The A&R/Music supervisor has shown interest in your demo and they require additional time for a final decision. The HOLD status allows you to be contacted."
            }
          />
          <ArticleItem
            title="Cut"
            text={
              <>
                <p>Congratulations!</p>
                <p>
                  The A&R/Music supervisor has shown interest in your demo, and
                  now it's up to them to contact you. Please keep in mind that a
                  song is not guaranteed a placement until it has been
                  officially released. The CUT status allows you to be
                  contacted.
                </p>
              </>
            }
          />
          <ArticleItem
            title="No Go"
            text={
              <>
                <p>
                  - The A&R/Music supervisor chooses not to proceed with the
                  demo. The demo didn't meet the guidelines in the brief.
                </p>
                <p>- The demo lacked sufficient quality.</p>
              </>
            }
          />
          <ArticleItem
            title="Disclaimer"
            text={
              "Status updates can be freely modified by brief publisher within 60 days from the delivery date."
            }
          />
        </div>
      </div>
    </Pitches>
  );
}

export default PitchLearn;

function ArticleItem({ title, text }) {
  const borderColors = {
    Screening: "#6D27FF",
    Pitched: "#00C2FF",
    Hold: "#FFFB00",
    Cut: "#00F900",
    "No Go": "#FF2600",
  };
  const border = borderColors?.[title]
    ? "border w-fit py-1 px-4 -ml-4 lg:px-6 lg:-ml-6 rounded-full"
    : "pt-1";

  return (
    <>
      <article className="max-w-[512px]">
        <h1
          className={`text-xl font-semibold mt-4 lg:mt-4 mb-6 lg:mb-2 ${border}`}
          style={{ borderColor: borderColors?.[title] }}
        >
          {title}
        </h1>
        <p className="text-sm font-normal">{text}</p>
      </article>
      <div className="flex lg:hidden w-full border-b border-solid border-zinc-700 h-4 lg:h-5 relative -z-10"></div>
    </>
  );
}
