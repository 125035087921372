import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import { Timestamp, doc, setDoc } from "firebase/firestore";
import { auth, db, generateUniqueVelvIdUrl } from "config/firebase-config";
import { Dropdown } from "components/shared/Dropdown/Dropdown";
import { InputValidation } from "components/shared/InputValidation/InputValidation";
import { TooltipItem } from "components/shared/Tooltip/Tooltip";
import infoIcon from "assets/icons/info-icon.svg";
import homeIcon from "assets/icons/home-icon.svg";
import Login from "pages/Login/Login";
import {
  deleteCookie,
  doesCookieExist,
  getPropertyNameByValue,
  handleRedirectToApp,
  isValidSocialMediaLink,
  isValidUrl,
  parseFirebaseErrors,
  setCookie,
} from "utils/helpers";
import {
  GENRES_LIST,
  LANGUAGES_LIST,
  PROFESSIONS_LIST,
  PROFESSIONS_LIST_MAP,
  ROLES_LIST,
  SCREENER_LIST,
  SCREENER_LIST_MAP,
} from "utils/constants";
import loadingAnimation from "assets/icons/loading.svg";
import { AuthContext } from "context/AuthContext";
import { AcceptTermsAndConditions } from "./AcceptTermsAndConditions";

const ANR_LIST = [
  ROLES_LIST.ANR,
  ROLES_LIST.MANAGER,
  ROLES_LIST.SUPERVISOR,
  ROLES_LIST.CORPORATE,
];

function SignUp() {
  const { setValue } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [showVerifyPassword, setShowVerifyPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [verifyPassword, setVerifyPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [signUpStep, setSignUpStep] = useState(1);
  const [formValues, setFormValues] = useState({
    profession: "",
    name: "",
    email: "",
    companyName: "",
    companyEmail: "",
    companyUrl: "",
    socialMediaUrl: "",
    screenerLevel: "",
    screeningGenres: [],
    screeningLanguages: [],
    hiringManager: "",
    acceptTerms: false,
  });
  const REQUIRED_FIELDS = ["profession", "name", "email"];
  const REQUIRED_FIELDS_ANR = [
    "companyName",
    "companyEmail",
    "companyUrl",
    "socialMediaUrl",
  ];
  const REQUIRED_FIELDS_SCREENER = [
    "screenerLevel",
    "screeningGenres",
    "screeningLanguages",
    "hiringManager",
  ];

  const addItem = async (item, uid) => {
    const usersRef = doc(db, "users", uid);
    await setDoc(usersRef, item, { merge: true });
  };

  const handleSignUp = async () => {
    try {
      setLoading(true);
      const newUser = await createUserWithEmailAndPassword(
        auth,
        formValues.email,
        password
      );
      const velv = await fetch(generateUniqueVelvIdUrl);
      const id = await velv?.text();

      const genres = formValues?.screeningGenres?.map((item) =>
        adaptGenres(item)
      );
      const languages = formValues?.screeningLanguages?.map((item) =>
        adaptGenres(item)
      );
      if (formValues.profession !== "screener") delete formValues.screenerLevel;
      const values = {
        ...formValues,
        createdTime: Timestamp.fromDate(new Date()),
        velvId: id,
        acceptTerms: true,
        screeningGenres: genres,
        screeningLanguages: languages,
      };

      const isSoundtrapUser = doesCookieExist("soundtrap");

      if (
        isSoundtrapUser &&
        (formValues?.profession === ROLES_LIST.PRODUCER ||
          formValues?.profession === ROLES_LIST.SONG_WRITER ||
          formValues?.profession === ROLES_LIST.SONG_WRITER_PRODUCER)
      ) {
        values["soundtrap"] = true;
        values["discount"] = 3;
      }

      await addItem(values, auth?.currentUser.uid);
      await sendEmailVerification(newUser?.user);
      localStorage.setItem("user", JSON.stringify(values));
      setCookie("user", JSON.stringify(values));
      deleteCookie("soundtrap");
      setValue({ user: values, auth: true });
      setSignUpStep(2);
    } catch (err) {
      const user = auth?.currentUser;
      setError({ ...error, email: parseFirebaseErrors(err?.code) });
      await user.delete();
      setSignUpStep(1);
    } finally {
      setLoading(false);
    }
  };

  const adaptGenres = (str) => {
    const charactersToRemove = ["-", "(", " ", "&", "/", "'"];
    const regex = new RegExp("[" + charactersToRemove.join("") + "]", "g");
    let value = str.replace(regex, "");
    if (value?.length) {
      value = value[0]?.toLowerCase() + value.slice(1);
    }
    return value.replace(regex, "");
  };

  const handleProfessionChange = (value) => {
    let resetValues = {};
    if (value === "Screener") {
      resetValues = {
        companyName: "",
        companyEmail: "",
        companyUrl: "",
        socialMediaUrl: "",
        hiringManager: "",
      };
    }

    if (
      value === "A&R" ||
      value === "Manager" ||
      value === "Supervisor" ||
      value === "Corporate"
    ) {
      resetValues = {
        screenerLevel: "",
        screeningGenres: [],
        screeningLanguages: [],
      };
    }

    setFormValues((prev) => ({
      ...prev,
      ...resetValues,
      profession: PROFESSIONS_LIST_MAP[value],
    }));
  };

  const handleInputChanges = (data) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailPattern.test(data?.email)) {
      let errors = { ...error };
      delete errors["email"];
      setError({ ...errors });
    } else setError({ ...error, email: "Enter valid email address" });
    if (data?.email === "") {
      setError({ ...error, email: "This field is required" });
    }
    setFormValues({ ...formValues, ...data });
  };

  const validateFullName = (fullName) => {
    const trimmedFullName = fullName.trim();
    const nameParts = trimmedFullName.split(/\s+/);

    return nameParts.length >= 2 && nameParts.every((part) => part !== "");
  };

  useEffect(() => {
    ReactGA.event({
      category: "Page View",
      action: "soundtrap_signup_visit",
      label: location.pathname + location.search,
      nonInteraction: true,
      transport: "beacon",
    });
  }, [location]);

  return (
    <>
      <div className="flex justify-between max-w-7xl w-full md:mx-auto mt-16 -mb-16 pr-3">
        <button
          className="flex cursor-pointer py-2 px-4 rounded-3xl top-5 right-5 items-center"
          onClick={() => handleRedirectToApp("/home")}
        >
          <img
            src={homeIcon}
            alt="home"
            className="flex w-6 h-6 cursor-pointer"
          />
        </button>
        <button
          className="text-center text-sm cursor-pointer bg-purple py-2 px-4 rounded-3xl top-5 right-5 text-white hover:shadow-[0_0_20px_0_rgba(108,40,255,1)]"
          onClick={() => navigate("/login")}
        >
          Login
        </button>
      </div>
      <Login>
        <div className="font-extrabold text-2xl text-white text-center">
          {signUpStep === 1 ? "Create Account" : " Accept Terms & Conditions"}
        </div>

        {signUpStep === 1 && (
          <div className="grid grid-cols-1 gap-4 mt-6">
            <Dropdown
              height={"max-h-92"}
              value={getPropertyNameByValue(
                formValues.profession,
                PROFESSIONS_LIST_MAP
              )}
              onChange={(value) => {
                handleProfessionChange(value);
              }}
              data={PROFESSIONS_LIST}
              placeholder={"Select your profession"}
            />
            <InputValidation
              type="text"
              id="name"
              errorMsg={error?.name}
              autoComplete="name"
              placeholder="Full Name"
              value={formValues.name}
              onChange={(e) => {
                if (!e.target.value)
                  setError({ ...error, name: "This field is required" });
                else if (!validateFullName(e.target.value)) {
                  setError({ ...error, name: "Please enter your Full Name" });
                } else {
                  let errors = { ...error };
                  delete errors["name"];
                  setError({ ...errors });
                }
                setFormValues((prev) => ({ ...prev, name: e.target.value }));
              }}
              className={`h-12`}
            />
            <InputValidation
              id="email"
              autoComplete="email"
              required={true}
              errorMsg={error?.email}
              onInvalid={(e) => console.log("Error email", e)}
              type="email"
              placeholder="Email address"
              value={formValues.email}
              onChange={(e) => handleInputChanges({ email: e.target.value })}
              className={`h-12`}
            />
            <div className="password-container flex">
              <InputValidation
                type="password"
                value={password}
                placeholder="Password"
                errorMsg={error?.password}
                onChange={(e) => {
                  if (!e.target.value)
                    setError({ ...error, password: "This field is required" });

                  if (e.target.value?.length < 8)
                    setError({
                      ...error,
                      password: "Password must be at least 8 characters long.",
                    });
                  else {
                    let errors = { ...error };
                    delete errors["password"];
                    setError({ ...errors });
                  }
                  setPassword(e.target.value);
                }}
                showIcon={true}
                className={`h-12`}
              />
            </div>
            <div className="password-container flex">
              <InputValidation
                type={showVerifyPassword ? "text" : "password"}
                value={verifyPassword}
                placeholder={"Verify Password"}
                onChange={(e) => setVerifyPassword(e.target.value)}
                showIcon={true}
                errorMsg={
                  verifyPassword !== password && "Passwords do not match"
                }
                className={`h-12`}
              />
            </div>
            <div
              className={`${
                ANR_LIST.includes(formValues?.profession) ||
                formValues?.profession === "screener"
                  ? "flex flex-col gap-4"
                  : "hidden"
              }`}
            >
              <div className="text-white text-left">
                <h5 className="font-semibold text-sm">
                  Verify your profession
                </h5>
                <p className="text-xs mt-3 leading-5">
                  {formValues?.profession === "screener"
                    ? "To verify your screener authorization as Screener 1, 2 or 3, we would like you to please fill in the details below."
                    : "To verify your profession as A&R, Manager, Music supervisor or Corporate, we would like you to fill in your company details."}
                </p>
              </div>
              {formValues?.profession === "screener" ? (
                <>
                  <Dropdown
                    value={getPropertyNameByValue(
                      formValues.screenerLevel,
                      SCREENER_LIST_MAP
                    )}
                    onChange={(value) => {
                      setFormValues((prev) => ({
                        ...prev,
                        screenerLevel: SCREENER_LIST_MAP[value],
                      }));
                    }}
                    data={SCREENER_LIST}
                    placeholder={"Select your Screener level"}
                  />
                  <Dropdown
                    type="pils"
                    value={formValues.screeningGenres}
                    onChange={(values) =>
                      setFormValues((prev) => ({
                        ...prev,
                        screeningGenres: [...values],
                      }))
                    }
                    data={GENRES_LIST}
                    multiple={true}
                    placeholder={"Select your top 3 genres"}
                  />
                  <Dropdown
                    type="pils"
                    value={formValues.screeningLanguages}
                    onChange={(values) =>
                      setFormValues((prev) => ({
                        ...prev,
                        screeningLanguages: [...values],
                      }))
                    }
                    data={LANGUAGES_LIST.slice(1)}
                    multiple={true}
                    placeholder={"Select your fluent language (max 3)"}
                  />
                  <InputValidation
                    type="text"
                    placeholder="Name of the hiring manager"
                    value={formValues.hiringManager}
                    errorMsg={error?.hiringManager}
                    onChange={(e) => {
                      if (!e.target.value)
                        setError({
                          ...error,
                          hiringManager: "This field is required",
                        });
                      else {
                        let errors = { ...error };
                        delete errors["hiringManager"];
                        setError({ ...errors });
                      }
                      setFormValues((prev) => ({
                        ...prev,
                        hiringManager: e.target.value,
                      }));
                    }}
                    className={`h-12`}
                  />
                </>
              ) : (
                <>
                  <InputValidation
                    type="text"
                    placeholder="Company Name"
                    value={formValues.companyName}
                    errorMsg={error?.companyName}
                    onChange={(e) => {
                      if (!e.target.value)
                        setError({
                          ...error,
                          companyName: "This field is required",
                        });
                      else {
                        let errors = { ...error };
                        delete errors["companyName"];
                        setError({ ...errors });
                      }
                      setFormValues((prev) => ({
                        ...prev,
                        companyName: e.target.value,
                      }));
                    }}
                    className={`h-12`}
                  />
                  <div className="flex w-full items-center">
                    <InputValidation
                      type="email"
                      placeholder="Business Email"
                      value={formValues.companyEmail}
                      errorMsg={error?.companyEmail}
                      onChange={(e) => {
                        if (!e.target.value)
                          setError({
                            ...error,
                            companyEmail: "This field is required",
                          });
                        else {
                          const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                          if (emailPattern.test(e.target.value)) {
                            let errors = { ...error };
                            delete errors["companyEmail"];
                            setError({ ...errors });
                          } else {
                            setError({
                              ...error,
                              companyEmail: "Enter valid email address",
                            });
                          }
                        }

                        setFormValues((prev) => ({
                          ...prev,
                          companyEmail: e.target.value,
                        }));
                      }}
                      className={`h-12`}
                    />
                    <TooltipItem icon={infoIcon} className={"ml-2"}>
                      <p className="leading-2 text-white pt-2 pb-2">
                        Please use your own business email. <br /> Example:
                        name@company.com
                      </p>
                    </TooltipItem>
                  </div>
                  <InputValidation
                    type="url"
                    placeholder="Company Website URL"
                    value={formValues.companyUrl}
                    errorMsg={error?.companyUrl}
                    onChange={(e) => {
                      if (!e.target.value)
                        setError({
                          ...error,
                          companyUrl: "This field is required",
                        });
                      else {
                        if (isValidUrl(e.target.value)) {
                          let errors = { ...error };
                          delete errors["companyUrl"];
                          setError({ ...errors });
                        } else {
                          setError({
                            ...error,
                            companyUrl: "Please enter valid url",
                          });
                        }
                      }
                      setFormValues((prev) => ({
                        ...prev,
                        companyUrl: e.target.value,
                      }));
                    }}
                    className={`h-12`}
                  />
                  <InputValidation
                    type="url"
                    placeholder="Social Media Link"
                    value={formValues.socialMediaUrl}
                    errorMsg={error?.socialMediaUrl}
                    onChange={(e) => {
                      if (!e.target.value)
                        setError({
                          ...error,
                          socialMediaUrl: "This field is required",
                        });
                      else {
                        let errors = { ...error };
                        delete errors["socialMediaUrl"];
                        setError({ ...errors });
                      }

                      if (isValidSocialMediaLink(e.target.value)) {
                        let errors = { ...error };
                        delete errors["socialMediaUrl"];
                        setError({ ...errors });
                      } else {
                        setError({
                          ...error,
                          socialMediaUrl:
                            "Please enter valid Social Media url (Facebook, Instagram)",
                        });
                      }
                      setFormValues((prev) => ({
                        ...prev,
                        socialMediaUrl: e.target.value,
                      }));
                    }}
                    className={`h-12`}
                  />
                </>
              )}
            </div>

            <p className="text-gray-400 italic text-center text-xs">
              Please fill required values before proceeding
            </p>
            <button
              onClick={async () => {
                if (
                  !REQUIRED_FIELDS?.every((item) => formValues[item]) &&
                  ((formValues?.profession === "screener" &&
                    !REQUIRED_FIELDS_SCREENER?.every(
                      (item) => formValues[item]
                    )) ||
                    (["anr", "corporate", "manager", "supervisor"].includes(
                      formValues?.profession
                    ) &&
                      !REQUIRED_FIELDS_ANR?.every((item) => formValues[item])))
                )
                  setError({ ...error });
                else setSignUpStep(2);
              }}
              disabled={
                !REQUIRED_FIELDS?.every((item) => formValues[item]) ||
                (formValues?.profession === "screener" &&
                  !REQUIRED_FIELDS_SCREENER?.every(
                    (item) => formValues[item]
                  )) ||
                (["anr", "corporate", "manager", "supervisor"].includes(
                  formValues?.profession
                ) &&
                  !REQUIRED_FIELDS_ANR?.every((item) => formValues[item])) ||
                !password ||
                verifyPassword !== password ||
                Object.keys(error)?.length
              }
              className={`${
                Object.keys(error)?.length ||
                !REQUIRED_FIELDS?.every((item) => formValues[item]) ||
                !password
                  ? "bg-gray-700 cursor-not-allowed text-gray-300"
                  : "bg-purple text-white"
              } text-center  w-full rounded-full py-3 text-base flex justify-center leading-normal disabled:bg-red-500`}
            >
              {loading ? (
                <img
                  src={loadingAnimation}
                  alt="loading"
                  className="w-8 h-[28px] brightness-[103%] invert-[99%] saturate-[6%] hue-rotate-[206deg] contrast-[104%]"
                />
              ) : (
                "Go to Terms & Agreement"
              )}
            </button>
          </div>
        )}
        {signUpStep === 2 && (
          <AcceptTermsAndConditions
            error={error}
            loading={loading}
            loadingAnimation={loadingAnimation}
            handleSignUp={handleSignUp}
            role={formValues?.profession}
          />
        )}
      </Login>
    </>
  );
}

export default SignUp;
