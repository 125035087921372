import { Sidebar } from "components/Sidebar/Sidebar";
import { Footer } from "../Footer/Footer";
import previewHeroImg from "assets/images/hero-bg-preview.png";

export function MainWrapper({
  children,
  coverImage = false,
  showFooter = true,
}) {
  return (
    <div className="bg-[radial-gradient(circle_at_center_left,_var(--tw-gradient-stops))] from-[#00315F] to-25%">
      <Sidebar />
      {coverImage && (
        <img
          src={previewHeroImg}
          alt="hero"
          className="w-full absolute top-0 -z-10"
        />
      )}
      <div className="ml-4 lg:ml-32 mr-4 lg:mr-16 relative z-10 overflow-hidden">
        {children}
      </div>
      {showFooter && <Footer />}
    </div>
  );
}
