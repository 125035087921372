import { Sidebar } from "components/Sidebar/Sidebar";
import { CardsList } from "components/shared/CardsList/CardsList";
import EmptyState from "components/shared/EmptyState";
import { Footer } from "components/shared/Footer/Footer";
import { Header } from "components/shared/Header/Header";
import { Loading } from "components/shared/Loading/Loading";

const DropsList = ({
  title,
  tab,
  data,
  showArtwork = true,
  isLoading = false,
  onClickItemFavorite = () => {},
}) => {
  return (
    <div className="bg-[radial-gradient(circle_at_center_left,_var(--tw-gradient-stops))] from-[#00315F] to-25%">
      <Sidebar />
      <div className="absolute top-0 max-h-[722.25px] w-full h-full -z-10">
        <div className="bg-gradient-to-t from-black w-full h-full z-1"></div>
        {data?.length && showArtwork && (
          <img
            src={data[0]?.artworkUrl}
            alt="hero"
            className="fade-in-animation w-full absolute top-0 max-h-[722.25px] -z-10 object-cover "
          />
        )}
      </div>
      <div className="ml-4 lg:ml-32 mr-4 lg:mr-16 relative z-10 overflow-hidden">
        <Header tab={tab} />
        {/* <div className="mt-32 text-6xl font-extrabold text-white text-left">
          {data?.length ? data[0]?.artist : ""}
        </div> */}
        {isLoading ? (
          <Loading />
        ) : (
          <div className="flex gap-4 mt-16">
            {data?.length && data ? (
              <CardsList
                title={title}
                isList={true}
                data={data}
                showBackBtn={true}
                onClickItemFavorite={onClickItemFavorite}
              />
            ) : null}
            {data?.length === 0 && <EmptyState title="No drops added" />}
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default DropsList;
