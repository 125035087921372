import { useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import WidthWrapper from "landing/components/shared/WidthWrapper/WidthWrapper";
// import emailIcon from 'landing/assets/icons/email-icon.svg';
// import instaIcon from 'landing/assets/icons/insta-icon.svg';
import xIcon from "landing/assets/icons/x-icon.svg";
import expandIcon from "landing/assets/icons/expand-icon.svg";
import velvLogo from "landing/assets/videos/velv-logo.webp";
import velvTextLogo from "landing/assets/images/velv-text.svg";
import { handleRedirectToApp } from "landing/helpers/functions";
import betaIcon from "landing/assets/images/beta.png";
import menuIcon from "landing/assets/icons/menu.svg";

function Header() {
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(false);

  const handleShowMenu = () => {
    setShowMenu((prev) => !prev);
  };
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showMenu]);

  return (
    <div
      className="bg-[#010101] backdrop-blur-md sticky top-0 z-20 lg:z-50"
      ref={menuRef}
    >
      <WidthWrapper>
        <div className="flex justify-between px-4 lg:px-20 py-4 mx-auto">
          <div className="relative flex items-end">
            <img
              src={velvLogo}
              alt="logo"
              className="cursor-pointer w-11 h-11"
              onClick={() => navigate("/home")}
            />
            <img
              src={velvTextLogo}
              alt="logo"
              className="cursor-pointer hidden lg:flex"
              onClick={() => navigate("/home")}
            />
            <img
              src={betaIcon}
              alt="beta"
              className="absolute -top-3 left-12 w-16 h-16 lg:-top-4 lg:left-40 lg:w-24 lg:h-24"
            />
          </div>
          <div className="flex gap-6">
            <button
              onClick={() => handleRedirectToApp("/signup")}
              className="justify-center items-center md:flex w-[85px] bg-purple rounded-3xl text-xs py-3 px-5 text-white font-semibold hover:shadow-[0_0_20px_0_rgba(108,40,255,1)]"
            >
              Sign Up
            </button>
            <button
              onClick={() => handleRedirectToApp("/login")}
              className="justify-center items-center md:flex w-[85px] border-purple border rounded-3xl text-xs py-3 px-5 text-white font-semibold hover:shadow-[0_0_20px_0_rgba(108,40,255,1)]"
            >
              Login
            </button>
            <button className="w-11">
              <img
                src={menuIcon}
                alt="hamburger menu"
                onClick={handleShowMenu}
                className="w-8 h-8 lg:h-11 lg:w-11"
              />
            </button>
            {showMenu && (
              <div className="bg-black bg-opacity-90 fixed left-0 md:left-auto right-0 h-[100dvh] md:w-[380px] -z-10 lg:z-10 text-white text-2xl lg:pl-20 top-0">
                <div className="opacity-0 lg:opacity-100 flex justify-end text-right mt-16 mb-20 mr-20">
                  <img
                    src={xIcon}
                    alt="close"
                    className="cursor-pointer"
                    onClick={handleShowMenu}
                  />
                </div>
                <ul className="flex ml-8 lg:ml-0 flex-col gap-4">
                  <li
                    className="cursor-pointer"
                    onClick={() => handleRedirectToApp("/login")}
                  >
                    Login
                  </li>
                  <li
                    className="cursor-pointer"
                    onClick={() => handleRedirectToApp("/signup")}
                  >
                    Sign Up
                  </li>
                  <li
                    className="cursor-pointer"
                    onClick={() => {
                      navigate("/about");
                      handleShowMenu();
                    }}
                  >
                    About
                  </li>
                  <li
                    className="cursor-pointer"
                    onClick={() => {
                      navigate("/howto");
                      handleShowMenu();
                    }}
                  >
                    How to
                  </li>
                  <li className="cursor-pointer">
                    <details>
                      <summary>
                        Legal
                        <img
                          src={expandIcon}
                          alt="expand"
                          className="icon !w-7 h-7"
                        />
                      </summary>
                      <li
                        className="cursor-pointer text-xl py-3 ml-2"
                        onClick={() => {
                          navigate("/termsconditions");
                          handleShowMenu();
                        }}
                      >
                        Terms & condition
                      </li>
                      <li
                        className="cursor-pointer text-xl py-3 ml-2"
                        onClick={() => {
                          navigate("/privacycookies");
                          handleShowMenu();
                        }}
                      >
                        Private policy & Cookies
                      </li>
                    </details>
                  </li>
                  <li
                    className="cursor-pointer"
                    onClick={() => {
                      navigate("/contact");
                      handleShowMenu();
                    }}
                  >
                    Contact
                  </li>
                </ul>
                {/* <div className="hidden lg:flex gap-4 text-base absolute bottom-20">
                  <div className="flex h-4 gap-2 items-center">
                    <img src={emailIcon} alt="email" />
                    Email
                  </div>
                  <div className="flex h-4 gap-2 items-center">
                    <a
                      href="https://www.instagram.com/velv.music/"
                      target="_blank"
                      rel="noreferrer"
                      className='flex gap-2'>
                      <img src={instaIcon} alt="instagram" />
                      Instagram
                    </a>
                  </div>
                </div> */}
              </div>
            )}
          </div>
        </div>
      </WidthWrapper>
    </div>
  );
}

export default Header;
