import PropTypes from "prop-types";
import WidthWrapper from "landing/components/shared/WidthWrapper/WidthWrapper";

function TermsAndConditionContent({ data, mainTitle }) {
  return (
    <WidthWrapper>
      <h1 className="text-2xl lg:text-4xl font-extrabold pt-16 text-left lg:text-center text-wrap break-word">{mainTitle}</h1>

      {data?.map(({ title, body }, id) => (
        <TextBoxPrivacy title={title} body={body} key={id} />
      ))}
    </WidthWrapper>
  );
}

function TextBoxPrivacy({ title, body }) {
  return (
    <div>
      <h1 className="text-4xl font-extrabold pt-10">{title}</h1>
      <p className="py-6 font-normal">{body}</p>
    </div>
  );
}

TextBoxPrivacy.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
};

TermsAndConditionContent.propTypes = {
  data: PropTypes.any,
  mainTitle: PropTypes.string,
};

export default TermsAndConditionContent;
