import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Timestamp,
  collection,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { auth, db } from "config/firebase-config";
import { Sidebar } from "components/Sidebar/Sidebar";
import { Footer } from "components/shared/Footer/Footer";
import { Header } from "components/shared/Header/Header";
import { Loading } from "components/shared/Loading/Loading";
import EmptyState from "components/shared/EmptyState";

const MyInbox = () => {
  const navigate = useNavigate();
  const [drops, setDrops] = useState(null);
  const [firstDrop, setFirstDrop] = useState(null);
  const [loading, setLoading] = useState(false);
  const [reviewedDrops, setReviewedDrops] = useState([]);

  const qDrops = query(
    collection(db, "drops"),
    where("userId", "==", auth?.currentUser?.uid)
  );

  const getDemos = async (dropId, drop) => {
    const qDemos = query(
      collection(db, "demos"),
      where("dropId", "==", dropId)
    );
    const querySnapshotDemos = await getDocs(qDemos);
    let pitchedDemosNum = 0;
    let hasResultDemo = 0;
    let dropsList = [];
    querySnapshotDemos.forEach((doc) => {
      const item = doc.data();
      dropsList.push(item);
      if (
        item.screenings &&
        item.screenings[item.screenings?.length - 1]?.status === "nogo"
      )
        pitchedDemosNum += 1;

      if (item?.result) hasResultDemo += 1;
    });

    if (
      hasResultDemo > 0 &&
      hasResultDemo === querySnapshotDemos?.size - pitchedDemosNum
    ) {
      setReviewedDrops((prev) => [
        ...prev,
        {
          ...drop,
          dropId: dropId,
          size: querySnapshotDemos?.size - pitchedDemosNum || 0,
        },
      ]);
      return null;
    } else
      return {
        ...drop,
        dropId: dropId,
        size: querySnapshotDemos?.size - pitchedDemosNum || 0,
      };
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const querySnapshotDrops = await getDocs(qDrops);
        let dropList = [];
        setReviewedDrops([]);
        querySnapshotDrops.forEach(async (doc) => {
          const data = doc.data();
          if (Timestamp.fromDate(new Date()) > data?.expireTime) {
            try {
              if (data?.deliveryDate) {
                const daysLeft = calculateCountdown(data?.deliveryDate);
                if (daysLeft > 0) {
                  const list = getDemos(doc?.id, doc.data());
                  dropList.push(list);
                }
              }
            } catch (error) {
              console.error(error);
            }
          }
        });

        let results = await Promise.all(dropList);
        let listDrops = [];
        results.forEach((item) => {
          if (item)
            listDrops.push({
              ...item,
              daysLeft: calculateCountdown(item?.deliveryDate),
            });
        });
        listDrops = listDrops.sort(
          (a, b) => a?.expireTime?.toDate() - b?.expireTime?.toDate()
        );

        const [firstDrop, ...newArray] = listDrops;

        setFirstDrop(firstDrop);
        setDrops([...newArray]);
      } catch (error) {
        console.error("Error getting item:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  function calculateCountdown(firebaseTimestamp) {
    const endDate = firebaseTimestamp.toDate();
    const today = new Date();
    const daysPassed = Math.floor((today - endDate) / (1000 * 60 * 60 * 24));

    if (daysPassed >= 20) {
      return 0;
    }

    return 20 - daysPassed;
  }

  return (
    <div className="bg-[radial-gradient(circle_at_center_left,_var(--tw-gradient-stops))] from-[#00315F] to-25%">
      <Sidebar />
      <div className="ml-4 lg:ml-32 mr-4 lg:mr-16 relative z-10 overflow-hidden">
        <Header tab="myinbox" />
        {loading ? (
          <Loading />
        ) : (
          <Main
            drops={drops}
            firstDrop={firstDrop}
            navigate={navigate}
            reviewedDrops={reviewedDrops}
          />
        )}
      </div>
      <Footer />
    </div>
  );
};

function Main({ drops, firstDrop, reviewedDrops, navigate }) {
  return (
    <>
      <div className="lg:flex gap-4 justify-start items-center text-4xl lg:text-2xl font-extrabold mt-14 text-white text-center lg:text-left">
        My Inbox
      </div>
      {!drops?.length && !firstDrop && !reviewedDrops?.length ? (
        <EmptyState title={"No drops to show"} />
      ) : null}
      <section className="mt-8 flex flex-col items-center">
        {firstDrop && firstDrop?.size ? (
          <>
            <div className="w-full text-center text-gray-50 text-2xl font-bold leading-10 mb-9">
              Next Up
            </div>
            <main
              key={firstDrop?.dropId}
              onClick={() => {
                sessionStorage.setItem("wasNavigated", "true");
                firstDrop?.size && navigate(`/drop/pitch/${firstDrop?.dropId}`);
              }}
              className={`max-w-[225px] h-[305px] w-full flex-col justify-start items-center gap-4 flex relative ${
                firstDrop?.size && "cursor-pointer"
              } mb-8 lg:mb-16`}
            >
              <div className="text-white text-xl font-extrabold absolute top-4 left-4 leading-[41.63px] z-20 capitalize">
                {firstDrop?.artist}
              </div>

              <div className="w-full h-[225px] bg-gradient-to-r from-black absolute z-10 border border-violet-600 rounded-xl flex justify-center items-center text-white italic">
                {`${firstDrop?.size || 0} demos`}
              </div>

              <LazyLoadedImage
                src={firstDrop?.artworkUrl}
                alt="preview cover art"
                className="w-full h-[225px] object-cover rounded-xl"
              />
              <div className="border border-solid border-purple rounded-lg w-full text-white text-center text-xs pt-2 pb-1">
                <p className="font-orbitron text-xs">
                  {firstDrop ? firstDrop?.daysLeft : 0}
                </p>
                <p className="text-xs">Days</p>
                <p className="text-slate-500">Left for reviewing</p>
              </div>
            </main>
          </>
        ) : null}
        <div className="flex flex-wrap w-full gap-10 justify-center">
          {drops?.length || firstDrop
            ? drops?.map((item) =>
                item?.deliveryDate && firstDrop?.size ? (
                  <main
                    key={item?.dropId}
                    className={`max-w-[225px] h-[305px] w-full flex-col justify-start items-center gap-4 flex relative flex-wrap mb-8 lg:mb-16`}
                  >
                    <div className="w-full h-full bg-black absolute z-40 opacity-60 rounded-xl"></div>
                    <div className="text-white text-xl font-extrabold absolute top-4 left-4 leading-[41.63px] z-20 capitalize">
                      {item?.artist}
                    </div>

                    <div className="w-full max-h-[225px] h-full absolute z-10 border border-violet-600 rounded-xl flex justify-center items-center text-white italic">
                      {`${item?.size || 0} demos`}
                    </div>
                    <div className="w-full">
                      <div className="mb-4 w-full">
                        <LazyLoadedImage
                          src={item?.artworkUrl}
                          alt="preview cover art"
                          className="w-full h-[225px] object-cover rounded-xl"
                        />
                      </div>
                      <div className="border border-solid border-purple rounded-lg w-full text-white text-center text-xs pt-2 pb-1">
                        <p className="font-orbitron text-xs">
                          {item ? item?.daysLeft : 0}
                        </p>
                        <p className="text-xs">Days</p>
                        <p className="text-slate-500">Left for reviewing</p>
                      </div>
                    </div>
                  </main>
                ) : null
              )
            : null}
        </div>
        {reviewedDrops?.length ? (
          <>
            <div className="w-full text-center text-gray-50 text-2xl font-bold leading-10 mb-9">
              Reviewed
            </div>
            <div className="flex flex-wrap w-full gap-10 justify-center">
              {reviewedDrops?.map((item) =>
                item?.deliveryDate ? (
                  <main
                    key={item?.dropId}
                    onClick={() => {
                      sessionStorage.setItem("wasNavigated", "true");
                      item?.deliveryDate &&
                        navigate(`/drop/pitch/${item?.dropId}`);
                    }}
                    className={`max-w-[225px] h-[225px] w-full flex-col justify-start items-center gap-4 flex relative flex-wrap ${
                      item?.deliveryDate && "cursor-pointer"
                    } mb-8 lg:mb-16`}
                  >
                    <div className="text-white text-xl font-extrabold absolute top-4 left-4 leading-[41.63px] z-20 capitalize">
                      {item?.artist}
                    </div>

                    <div className="w-full max-h-[225px] h-full bg-gradient-to-r from-black absolute z-10 border border-violet-600 rounded-xl flex justify-center items-center text-white italic">
                      {`${item?.size || 0} demos`}
                    </div>

                    <LazyLoadedImage
                      src={item?.artworkUrl}
                      alt="preview cover art"
                      className="w-full max-h-[225px] h-full object-cover rounded-xl"
                    />
                  </main>
                ) : null
              )}
            </div>
          </>
        ) : null}
      </section>
    </>
  );
}

const LazyLoadedImage = ({ src, alt, ...rest }) => {
  const [loaded, setLoaded] = useState(false);
  const imgRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const img = entry.target;
          img.src = img.dataset.src;
          observer.disconnect();
          setLoaded(true);
        }
      });
    });

    if (imgRef.current) {
      observer.observe(imgRef.current);
    }

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, []);

  return (
    <div className="w-full h-full">
      {!loaded && (
        <div className="absolute bg-slate-800 w-full h-[253px] object-cover rounded-xl"></div>
      )}
      <img
        ref={imgRef}
        src={loaded ? src : ""}
        alt={alt}
        data-src={src}
        {...rest}
        loading="eager"
        style={{ opacity: loaded ? 1 : 0 }}
        onLoad={() => {
          setLoaded(true);
        }}
      />
    </div>
  );
};

export default MyInbox;
