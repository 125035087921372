import { useEffect, useState } from "react";
import { Receipt } from "pages/Pitch/Receipt";
import { Status } from "pages/Pitch/Status";
import statusIcon from "assets/icons/status-icon.svg";
import receiptIcon from "assets/icons/receipt-icon.svg";
import Pitches from "components/Pitches/Pitches";
import { useParams } from "react-router-dom";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { auth, db } from "config/firebase-config";

const Pitch = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const { id, pitchId } = useParams();
  const [tabActive, setTabActive] = useState(1);
  const [drop, setDrop] = useState(null);
  const [demo, setDemo] = useState(null);
  const [isCollaborator, setIsCollaborator] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const qDemos = query(
    collection(db, "demos"),
    where("pitchId", "==", pitchId)
  );

  const getItem = async () => {
    const docRef = doc(db, "drops", id);
    try {
      setIsLoading(true);
      const snapshot = await getDoc(docRef);
      const querySnapshotDemos = await getDocs(qDemos);
      setDrop(snapshot.data());
      querySnapshotDemos.forEach((doc) => {
        setDemo({ ...doc.data(), id: doc?.id });
        setIsCollaborator(doc.data()?.userId !== auth?.currentUser?.uid);
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("Error getting drops", error);
    }
  };

  useEffect(() => {
    getItem();
  }, []);

  return (
    <Pitches>
      <div className="grid grid-cols-1 lg:grid-cols-2 border border-solid border-[#353945] lg:w-4/5 lg:ml-[10%] text-white py-5 lg:py-8 pl-4 lg:pl-4 pr-4 lg:pr-9 rounded-2xl gap-8 mt-5 lg:mt-16">
        <section className="text-center flex flex-col-reverse lg:flex-col items-center">
          <div>
            <h1 className="font-extrabold text-xl mb-2 mt-8 lg:mt-0 capitalize">
              {drop?.artist || "-"}
            </h1>
            <p className="text-base mb-0 lg:mb-2">{demo?.title || "-"}</p>
          </div>
          {drop ? (
            <img
              src={drop?.artworkUrl || "-"}
              alt="preview"
              className="h-auto max-h-[284px] lg:h-[284px] w-[248px] object-cover rounded-2xl"
            />
          ) : (
            <div className="skeleton h-[484px] w-[448px] object-cover rounded-2xl" />
          )}
        </section>
        <section>
          {!isLoading ? (
            <>
              {isCollaborator ? (
                <div className="flex flex-row text-xl font-bold gap-2 justify-start items-center mt-8 lg:mt-20">
                  <img src={statusIcon} alt="status" className="w-6" />
                  Status
                </div>
              ) : (
                <div className="flex bg-[#353945] justify-start gap-4 items-center text-white rounded-2xl font-semibold bg-opacity-40 backdrop-blur-sm max-w-[718px] mx-auto w-fit mt-0 lg:mt-4">
                  <div
                    className={`${tabActive === 1 && "tab-active"} tab !py-2`}
                    onClick={() => setTabActive(1)}
                  >
                    <img src={statusIcon} alt="status" />
                    Status
                  </div>
                  <div
                    className={`${tabActive === 2 && "tab-active"} tab !py-2`}
                    onClick={() => setTabActive(2)}
                  >
                    <img src={receiptIcon} alt="receipt" />
                    Receipt
                  </div>
                </div>
              )}
            </>
          ) : (
            <div className="mt-8 lg:mt-40" />
          )}

          {tabActive === 1 ? (
            <Status
              tabActive={tabActive}
              setTabActive={setTabActive}
              id={id}
              pitchId={pitchId}
              data={demo}
            />
          ) : (
            <Receipt
              data={demo}
              dropId={id}
              velvId={user?.velvId}
              companyName={drop?.recordLabel}
            />
          )}
        </section>
      </div>
    </Pitches>
  );
};

export default Pitch;
