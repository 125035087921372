import { useRef, useState } from "react";
import arrowIcon from "assets/icons/arrow-icon.svg";
import { useNavigate } from "react-router-dom";
import { formatDate } from "utils/helpers";
import { STATUS_STATE } from "utils/constants";
import "./PitchItem.scss";

export function PitchItem({
  name,
  audioTitle,
  date,
  status,
  screenings,
  img,
  id,
  pitchId,
  mediaUrl,
  onPlay,
  isPlayingSong,
  index,
  isActive,
}) {
  const navigate = useNavigate();
  const [isPlaying, setIsPlaying] = useState(true);
  const audioRef = useRef();
  const [image, setImage] = useState(true);

  const checkStatus = (screening) => {
    if (!screening?.length) return "screening";
    if (screening[0]?.status === "nogo" && screening[1]?.status !== "pitch")
      return "nogo";
    if (screening[0]?.status === "nogo" && screening[1]?.status === "nogo")
      return "nogo";
    if (screening[screening?.length - 1]?.screenerLevel !== "level3")
      return "screening";
    if (screening[screening?.length - 1]?.status === "nogo") return "nogo";
    return "pitch";
  };

  const handlePlay = (play) => {
    if (
      isPlayingSong === audioRef.current ||
      isPlayingSong === null ||
      index === 0
    )
      setIsPlaying(play);
    if (audioRef.current) {
      onPlay(index, audioRef.current, setIsPlaying);
    }
  };

  return (
    <div
      className={`flex justify-between pb-0 pt-2 lg:py-6 border-t border-solid border-[#777e906e] cursor-pointer mt-4 lg:mt-0 ${
        isActive ? "border !border-purple" : ""
      }`}
      onClick={() => navigate(`/my-pitches/${id}/${pitchId}`)}
    >
      <div className="flex items-center">
        <div
          className="flex justify-center items-center"
          onClick={(e) => {
            e.stopPropagation();
            handlePlay(!isPlaying);
          }}
        >
          <div className="w-10 h-10 absolute">
            <audio id="audio" className="hidden" controls ref={audioRef}>
              <source src={mediaUrl} type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio>
            <button className="w-10 h-10 left-0 top-0 absolute rounded-full border-4 border-white flex justify-center items-center">
              {(isPlayingSong === audioRef.current ? isPlaying : isActive) ? (
                <svg
                  className="w-10 h-10 absolute cursor-pointer p-2"
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.25 6.25V33.75C16.25 34.7446 15.8549 35.6984 15.1517 36.4016C14.4484 37.1049 13.4946 37.5 12.5 37.5H11.25C10.2554 37.5 9.30161 37.1049 8.59835 36.4016C7.89509 35.6984 7.5 34.7446 7.5 33.75V6.25C7.5 5.25544 7.89509 4.30161 8.59835 3.59835C9.30161 2.89509 10.2554 2.5 11.25 2.5H12.5C13.4946 2.5 14.4484 2.89509 15.1517 3.59835C15.8549 4.30161 16.25 5.25544 16.25 6.25ZM28.75 2.5H27.5C26.5054 2.5 25.5516 2.89509 24.8483 3.59835C24.1451 4.30161 23.75 5.25544 23.75 6.25V33.75C23.75 34.7446 24.1451 35.6984 24.8483 36.4016C25.5516 37.1049 26.5054 37.5 27.5 37.5H28.75C29.7446 37.5 30.6984 37.1049 31.4017 36.4016C32.1049 35.6984 32.5 34.7446 32.5 33.75V6.25C32.5 5.25544 32.1049 4.30161 31.4017 3.59835C30.6984 2.89509 29.7446 2.5 28.75 2.5Z"
                    fill="#fff"
                  />
                </svg>
              ) : (
                <svg
                  width="64"
                  height="64"
                  viewBox="0 0 64 64"
                  className="w-10 h-10 absolute cursor-pointer"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18.6667 28.4446V35.5557C18.6667 43.4948 18.6667 47.4643 21.256 49.0179C23.8454 50.5715 27.3479 48.7035 34.3529 44.9675L41.0196 41.4119C48.7843 37.2707 52.6667 35.2002 52.6667 32.0002C52.6667 28.8002 48.7843 26.7296 41.0196 22.5884L34.3529 19.0328C27.3479 15.2968 23.8454 13.4288 21.256 14.9824C18.6667 16.536 18.6667 20.5056 18.6667 28.4446Z"
                    fill="#fff"
                  />
                </svg>
              )}
            </button>
          </div>
          <img
            src={img}
            onLoad={() => setImage(false)}
            alt="profile"
            className="w-16 h-16 rounded-md object-cover"
          />
          {image ? (
            <div className="skeleton w-16 h-16 bg-slate-900 absolute z-10 left-0 rounded-md"></div>
          ) : null}
        </div>

        <div className="flex flex-col gap-1 lg:gap-0 text-white text-left ml-2 lg:ml-7">
          <p className="font-semibold text-sm lg:text-lg capitalize">{name}</p>
          <p className="text-xs lg:text-base overflow-hidden truncate max-w-[190px] lg:max-w-full">
            {audioTitle}
          </p>
          <p className="text-xs lg:text-sm text-[#777E90]">
            {formatDate(date)}
          </p>
        </div>
      </div>

      <div className="flex gap-1 lg:gap-7 items-center">
        <button className={`status ${status || checkStatus(screenings)} px-2`}>
          <img
            src={STATUS_STATE[status || checkStatus(screenings)]?.icon}
            alt="icon"
            className="w-4 h-4 hidden lg:flex"
          />
          <p className="text-center w-full">
            {STATUS_STATE[status || checkStatus(screenings)]?.name}
          </p>
        </button>
        <img
          src={arrowIcon}
          className="-rotate-90 w-2 lg:w-4 h-2 lg:h-4"
          alt="menu"
        />
      </div>
    </div>
  );
}
