import { Footer } from "components/shared/Footer/Footer";
import bgVideo from "assets/images/bg-video.mp4";
import "./Login.scss";

const Login = ({ children, className }) => {
  return (
    <>
      <div className={`flex flex-col justify-center items-center mb-40 ${className}`}>
        <video
          className="absolute top-1/3 lg:top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 mx-auto max-w-[1280px] w-full -z-50"
          autoPlay
          playsInline // Required for Safari
          muted
          loop
        >
          <source src={bgVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="grid grid-cols-1 relative z-10 bg-[#030101] px-3 lg:px-6 py-6 rounded-3xl bg-opacity-90 w-full md:w-[475px] mt-20 lg:mt-24 mb-10 lg:mb-28 mx-6 lg:mx-0">
          {children}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Login;
