import { useState } from "react";
import { BriefsText } from "landing/components/BriefsText/BriefsText";
import { CreatorsText } from "landing/components/CreatorsText/CreatorsText";
import { ScreenersText } from "landing/components/ScreenersText/ScreenersText";
import WidthWrapper from "landing/components/shared/WidthWrapper/WidthWrapper";
import ScrollToTop from "landing/helpers/ScrollToTop";
import Header from "landing/components/Header/Header";

const HowToPage = () => {
  const [tabNumber, setTabNumber] = useState(1);

  return (
    <>
      <Header />
      <div className="bg-[radial-gradient(circle_at_center_left,_var(--tw-gradient-stops))] from-[#40137a8f] from-5% to-[#000] to-30%">
        <ScrollToTop />
        <div className="text-white mx-8 lg:mx-36 pt-14 text-2xl font-extrabold">
          <WidthWrapper>
            <h1>HOW TO</h1>
          </WidthWrapper>
        </div>
        <div className="bg-[#101010]">
          <div className="text-white mx-8 lg:mx-36 mt-14 text-base lg:text-2xl font-extrabold pt-3">
            <WidthWrapper>
              <div className="flex justify-center items-center gap-5 lg:gap-12 flex-col text-center lg:flex-row">
                <h1
                  className={`border-b-2 text-gray-500 ${
                    tabNumber === 1
                      ? "border-solid border-purple !text-white "
                      : "border-transparent"
                  } pb-3 cursor-pointer uppercase`}
                  onClick={() => setTabNumber(1)}
                >
                  Briefs
                </h1>
                <h1
                  className={`border-b-2 text-gray-500 ${
                    tabNumber === 2
                      ? "border-solid border-purple !text-white "
                      : "border-transparent"
                  } pb-3 cursor-pointer uppercase`}
                  onClick={() => setTabNumber(2)}
                >
                  CREATIVES
                </h1>
                <h1
                  className={`border-b-2 text-gray-500 ${
                    tabNumber === 3
                      ? "border-solid border-purple !text-white "
                      : "border-transparent"
                  } pb-3 cursor-pointer uppercase`}
                  onClick={() => setTabNumber(3)}
                >
                  SCREENERS
                </h1>
              </div>
            </WidthWrapper>
          </div>
        </div>
        {tabNumber === 1 && <BriefsText />}
        {tabNumber === 2 && <CreatorsText />}
        {tabNumber === 3 && <ScreenersText />}
      </div>
    </>
  );
};

export default HowToPage;
