import React from "react";
import { TooltipItem } from "components/shared/Tooltip/Tooltip";
import { STATUS_STATE } from "utils/constants";

const Button = React.memo(
  ({ status, title, color = "red", updateItem, disabled = false }) => {
    return (
      <button
        disabled={disabled}
        onClick={() => updateItem && updateItem(status)}
        className={`button-status-${color} disabled:cursor-default disabled:opacity-40`}
      >
        <img
          src={STATUS_STATE[status]?.icon}
          alt="status"
          className="w-4 h-4 relative"
        />
        <div className="text-right text-gray-50 text-lg font-bold whitespace-nowrap">{title}</div>
      </button>
    );
  }
);

const ANRButtons = ({ infoIcon, updateItem, disabled }) => {
  return (
    <section className="justify-center items-center gap-2 lg:gap-[40px] inline-flex flex-row w-full lg:-ml-9">
      <div className="w-fit">
        <TooltipItem icon={infoIcon} className="ml-auto lg:ml-0 hidden lg:flex">
          <p className="text-white text-xs font-bold leading-tight mb-2">
            Pitching status explanation
          </p>
          <ul className="flex flex-col gap-4 text-xs">
            <li>
              <p>HOLD</p>
              <p>You're interested in the demo and require more time for a final decision.</p>
              <p>The mail icon becomes visible and enables contacting the creator.</p>
            </li>
            <li>
              <p>CUT</p>
              <p>You're interested in the demo and would like to contact the producer/writer and take it further in the process.</p>
              <p>The mail icon becomes visible and enables contacting the creator.</p>
            </li>
            <li>
              <p>NO GO</p>
              <p>· You choose not to proceed with the demo</p>
              <p>· The demo didn't meet the guidelines in the brief</p>
              <p>· The demo lacked sufficient quality</p>
            </li>
            <li>
              <p>Disclaimer</p>
              <span>The feedback you submit is anonymous by default unless you choose to indentify yourself.</span>
            </li>
            <li>
              <p>Status updates can be freely modified within 60 days from the delivery date.</p>
            </li>
          </ul>
        </TooltipItem>
      </div>
      <Button
        status={"nogo"}
        title="No Go"
        color="red"
        updateItem={updateItem}
        disabled={disabled}
      />
      <Button
        status={"hold"}
        title="Hold"
        color="yellow"
        updateItem={updateItem}
        disabled={disabled}
      />
      <Button
        status={"cut"}
        title="Cut"
        color="green"
        updateItem={updateItem}
        disabled={disabled}
      />
    </section>
  );
};

function ScreenerThreeButtons({
  demosSelected = 0,
  demos,
  handleBatchUpdate,
  requestedPitchQuantity,
  disabled,
}) {
  return (
    <section className="justify-between items-center gap-8 lg:gap-[113.99px] inline-flex flex-row w-full">
      <div className="justify-center flex-col items-center flex w-full lg:w-fit text-left lg:text-right lg:gap-1">
        <p className="font-medium text-sm lg:text-lg text-white w-full">
          {demosSelected}/{requestedPitchQuantity || 0}
        </p>
        <span className="font-bold text-sm lg:text-lg text-neutral-500 text-left lg:text-right w-full">
          selected
        </span>
      </div>

      <Button
        status={"pitch"}
        title="Pitch"
        color="green"
        updateItem={handleBatchUpdate}
        disabled={disabled}
      />
    </section>
  );
}

function ScreenerOneTwoButtons({ updateItem, disabled }) {
  return (
    <section className="justify-center items-start gap-8 lg:gap-[113.99px] inline-flex lg:flex-row w-full">
      <Button
        status={"nogo"}
        title="No Go"
        color="red"
        updateItem={updateItem}
        disabled={disabled}
      />
      <Button
        status={"pitch"}
        title="Pitch"
        color="green"
        updateItem={updateItem}
        disabled={disabled}
      />
    </section>
  );
}

const Buttons = {
  Button,
  ANRButtons,
  ScreenerThreeButtons,
  ScreenerOneTwoButtons,
};

export default Buttons;
