import { useNavigate } from "react-router-dom";
import WidthWrapper from "landing/components/shared/WidthWrapper/WidthWrapper";
import PropTypes from "prop-types";

export function Footer({ instagramIcon, facebookIcon, youtubeIcon }) {
  const navigate = useNavigate();
  return (
    <>
      <div className="text-white">
        <WidthWrapper>
          <div className="flex flex-col lg:flex-row justify-between mx-8 mt-12 mb-12">
            {/* <div>
              <img src={viteLogo} alt="logo" />
              <p className="mt-8 text-lg">
                The music platform for <br /> songwriters and producers.
              </p>
            </div> */}
            <div className="flex flex-col lg:hidden my-8">
              <p className="text-base mb-3">Join our community</p>
              <div className="flex gap-4 items-center">
                <a
                  href="https://www.instagram.com/velv.music/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={instagramIcon}
                    alt="instagram"
                    className="cursor-pointer"
                  />
                </a>
                <a
                  href="https://www.facebook.com/Velvmusic"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={facebookIcon}
                    alt="instagram"
                    className="cursor-pointer"
                  />
                </a>
                <a
                  href="https://youtube.com/@VELVMUSIC?si=o0qZuxcgtMJqOhgc"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={youtubeIcon}
                    alt="instagram"
                    className="cursor-pointer"
                  />
                </a>
              </div>
            </div>
            <div className="flex gap-11">
              <div>
                <h1 className="text-2xl mb-3 lg:mb-6 font-semibold">
                  Quick Links
                </h1>
                <ul className="text-lg lg:text-2xl font-semibold text-[#ADADAD] flex flex-col gap-3">
                  <li className="cursor-pointer" onClick={() => navigate("/home")}>
                    Home
                  </li>
                  <li
                    className="cursor-pointer"
                    onClick={() => navigate("/about")}
                  >
                    About
                  </li>
                  <li
                    className="cursor-pointer"
                    onClick={() => navigate("/howto")}
                  >
                    How To
                  </li>
                  <li
                    className="cursor-pointer flex lg:hidden"
                    onClick={() => navigate("/termsconditions")}
                  >
                    Legal
                  </li>
                  <li
                    className="cursor-pointer flex lg:hidden"
                    onClick={() => navigate("/contact")}
                  >
                    Contact
                  </li>
                </ul>
              </div>
              <div className="hidden lg:flex lg:flex-col">
                <h1 className="text-2xl mb-6 font-semibold opacity-0">
                  Quick Links
                </h1>
                <ul className="text-2xl text-[#ADADAD] flex flex-col gap-3">
                  <li
                    className="cursor-pointer"
                    onClick={() => navigate("/termsconditions")}
                  >
                    Legal
                  </li>
                  <li
                    className="cursor-pointer"
                    onClick={() => navigate("/contact")}
                  >
                    Contact
                  </li>
                </ul>
              </div>
            </div>
            <div className="hidden lg:flex flex-col gap-4 items-center">
              <h1 className="text-2xl mb-6 font-semibold">Social Media</h1>
              <div className="flex gap-4">
                <a
                  href="https://www.instagram.com/velv.music/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={instagramIcon}
                    alt="instagram"
                    className="cursor-pointer"
                  />
                </a>
                <a
                  href="https://www.facebook.com/Velvmusic"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={facebookIcon}
                    alt="instagram"
                    className="cursor-pointer"
                  />
                </a>
                <a
                  href="https://youtube.com/@VELVMUSIC?si=o0qZuxcgtMJqOhgc"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={youtubeIcon}
                    alt="instagram"
                    className="cursor-pointer"
                  />
                </a>
              </div>
            </div>
            <div className="flex flex-col mt-10 lg:mt-0">
              <h1 className="text-2xl mb-6 font-semibold">Contact</h1>
              <p className="text-lg lg:text-2xl mb-6">info@velvmusic.com</p>
              <p className="text-lg lg:text-2xl mb-6">support@velvmusic.com</p>
            </div>
          </div>
        </WidthWrapper>
      </div>
      <hr className="border-gray-700 mt-28 mb-11" />
      <div className="text-gray-400 mb-16 font-semibold text-lg">
        <WidthWrapper>
          <div className="flex justify-between px-8 w-full lg:w-auto ">
            <p>©{new Date().getFullYear()} Velv. All rights reserved</p>
            <p className="cursor-pointer hidden lg:flex gap-2">
              <a
                onClick={() => navigate("/privacycookies")}
                className="hover:text-blue-100"
              >
                Privacy Policy
              </a>
              |
              <a
                onClick={() => navigate("/termsconditions")}
                className="hover:text-blue-100"
              >
                Term & Conditions
              </a>
            </p>
          </div>
        </WidthWrapper>
      </div>
    </>
  );
}

Footer.propTypes = {
  viteLogo: PropTypes.any,
  instagramIcon: PropTypes.any,
  facebookIcon: PropTypes.any,
  youtubeIcon: PropTypes.any,
};
