import { useEffect, useState, useRef } from "react";
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  Routes,
  BrowserRouter,
} from "react-router-dom";
import { doc, getDoc, onSnapshot, updateDoc } from "firebase/firestore";
import { sendEmailVerification, signOut } from "firebase/auth";
import { Loading } from "./components/shared/Loading/Loading";
import routes from "utils/routes";
import {
  auth,
  createAccountForEmail,
  db,
  generateToken,
  getAccountWithId,
  getLinkForAccountWithId,
  onAuthChanged,
  messaging,
} from "config/firebase-config";
import PrivateRoute from "components/PrivateRoute/PrivateRoute";
import PublicRoutes from "components/PublicRoutes/PublicRoutes";
import loadingAnimation from "assets/icons/loading.svg";
import "App.scss";
import { AuthContext } from "context/AuthContext";
import { ROLES_LIST } from "utils/constants";
import { deleteCookie, parseFirebaseErrors, setCookie } from "utils/helpers";
import bgVideo from "assets/images/bg-video.mp4";
import closeIcon from "assets/icons/close-icon.svg";
import whiteCheckmarkIcon from "assets/icons/white-checkmark-icon.svg";
import Account from "pages/Account/Account";
import { MenuDropdown } from "components/shared/Header/MenuDropdown";

function App() {
  const [isAuth, setIsAuth] = useState(null);
  const [loading, setLoading] = useState(isAuth || isAuth === null ? true : false);
  const [emailResendLoading, setResendLoading] = useState(false);
  const [stripeLoading, setStripeLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [value, setValue] = useState(null);
  const [emailVerified, setEmailVerified] = useState(false);
  const [, setStatus] = useState("");
  const [showProfileAsUnverified, setShowProfileAsUnverified] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthChanged(auth, (authUser) => {
      if (authUser) {
        setIsAuth(true);
        setEmailVerified(authUser?.emailVerified || false);
      } else {
        setIsAuth(false);
      }
      setLoading(false);
    });

    return () => {
      unsubscribe();
    };
  }, [user]);

  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showMenu]);

  function checkEmailVerificationStatus() {
    const user = auth?.currentUser;

    if (user && isAuth) {
      user
        .reload()
        .then(() => {
          if (user.emailVerified) {
            clearInterval(intervalId);
            setEmailVerified(true);
          }
        })
        .catch((error) => {
          console.error("Error reloading user:", error);
        });
    }
  }

  // Set up interval to periodically check email verification status (every 500ms)
  const intervalId = setInterval(checkEmailVerificationStatus, 0.3 * 60 * 1000);

  if (emailVerified) clearInterval(intervalId);

  useEffect(() => {
    let unsubscribe = null;
    if (auth?.currentUser) {
      getItem(auth?.currentUser?.uid);
      try {
        unsubscribe = onSnapshot(
          doc(db, "users", auth?.currentUser?.uid),
          (doc) => {
            if (doc.exists) setUser(doc.data());
          }
        );
      } catch (error) {
        console.log("Error fetching user", error);
      }
    }
    return () => {
      if (unsubscribe) unsubscribe();
    }; // Call the unsubscribe function here
  }, [isAuth, value]);

  const getItem = async (id) => {
    const docRef = doc(db, "users", id);
    try {
      const snapshot = await getDoc(docRef);
      if (snapshot?.data()) {
        localStorage.setItem("user", JSON.stringify(snapshot?.data()));
        setCookie("user", snapshot?.data());
        setUser(snapshot?.data());
      }
    } catch (error) {
      console.log("Failed fetching user", error);
    }
  };

  const handleSendEmailVerification = () => {
    setResendLoading(true);
    setStatus("");
    sendEmailVerification(auth?.currentUser)
      .then(() => {
        setStatus("Email verification sent!");
      })
      .catch((error) => {
        console.log("Error sending email verification:", error);
        setStatus("Error sending email verification!");
      })
      .finally(() => setResendLoading(false));
  };

  const handleCreatingStripeAccount = () => {
    setStripeLoading(true);
    fetch(createAccountForEmail, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email: auth?.currentUser?.email }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then(async (data) => {
        if (data?.id) {
          const itemDoc = doc(db, "users", auth?.currentUser?.uid);
          await updateDoc(itemDoc, {
            stripeAccount: {
              accountId: data?.id,
              detailsSubmitted: false,
              payoutsEnabled: false,
            },
          });
          localStorage.setItem(
            "list",
            JSON.stringify({
              [data?.id]: {
                accountId: data?.id,
                detailsSubmitted: false,
                payoutsEnabled: false,
              },
            })
          );
        }
        // Second fetch request - only initiated after the first one is complete
        return getStripeAccountWithId(data);
      })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        if (data) window.location.href = data?.url;
      })
      .catch((error) => {
        console.error("Error:", error);
      })
      .finally(() => setStripeLoading(false));
  };

  const getStripeAccountWithId = (data) => {
    return fetch(getLinkForAccountWithId, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: data?.id,
        refreshUrl: `${window.location.origin}/stripe/verification/${data?.id}`, // Redirect URI after refresh
        returnUrl: `${window.location.origin}/stripe/verification/${data?.id}`,
      }),
    });
  };

  useEffect(() => {
    if (
      user &&
      user?.profession === "screener" &&
      (!user?.stripeAccount?.payoutsEnabled ||
        !user?.stripeAccount?.detailsSubmitted) &&
      user?.profession === "screener"
    ) {
      getStripeAccountStatus();
    }
  }, [user]);

  const getStripeAccountStatus = async () => {
    try {
      const response = await fetch(getAccountWithId, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: user?.stripeAccount?.accountId,
        }),
      });

      if (!response.ok) {
        throw new Error("Error getting account status!");
      }

      const data = await response.json();
      if (data?.details_submitted && data?.payouts_enabled) {
        const dropsRef = doc(db, "users", auth?.currentUser?.uid);
        await updateDoc(dropsRef, {
          stripeAccount: {
            accountId: data?.id,
            detailsSubmitted: data?.details_submitted,
            payoutsEnabled: data?.payouts_enabled,
          },
        });
      } else {
        getStripeAccountWithId(data);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      localStorage.removeItem("user");
      deleteCookie("user");
      setIsAuth(false);
      setUser(null);
    } catch (error) {
      console.error("Error: ", parseFirebaseErrors(error?.code));
    }
  };

  useEffect(() => {
    if (
      "Notification" in window &&
      window.Notification?.permission === "granted"
    ) {
      generateToken();
    }
  }, []);

  const isANROrScreener =
    user?.profession === ROLES_LIST.ANR ||
    user?.profession === ROLES_LIST.MANAGER ||
    user?.profession === ROLES_LIST.SUPERVISOR ||
    user?.profession === ROLES_LIST.CORPORATE ||
    user?.profession === ROLES_LIST.SCREENER;

  if (isAuth && !user) return <Loading loadingAnimation={loadingAnimation} />;

  if (showProfileAsUnverified) {
    return (
      <AuthContext.Provider value={{ value, setValue }}>
        <BrowserRouter>
          <Routes>
            <Route
              path="*"
              element={
                <Account
                  notCompletedVerification={true}
                  onClickBack={() => setShowProfileAsUnverified(false)}
                />
              }
            />
          </Routes>
        </BrowserRouter>
      </AuthContext.Provider>
    );
  }

  if (
    (!emailVerified ||
      (!user?.verifiedByVelv && isANROrScreener) ||
      ((!user?.stripeAccount ||
        !user.stripeAccount?.payoutsEnabled ||
        !user?.stripeAccount?.detailsSubmitted) &&
        user?.profession === ROLES_LIST.SCREENER)) &&
    user
  )
    return (
      <div className="w-dvw h-dvh flex flex-col justify-center items-center">
        <video
          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 mx-auto max-w-[1280px] w-full -z-50"
          autoPlay
          playsInline // Required for Safari
          muted
          loop
        >
          <source src={bgVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="absolute z-10 top-8 right-4 lg:top-16 lg:right-16">
          <MenuDropdown
            user={user}
            showMenu={showMenu}
            setShowMenu={setShowMenu}
            loading={false}
            onClickLogout={handleLogout}
            onClickProfile={() => setShowProfileAsUnverified(true)}
          />
        </div>
        <div className="w-full max-w-[464px] h-[454px] bg-zinc-900 rounded-2xl shadow flex-col justify-center items-center inline-flex">
          <div className="h-[454px] p-6 flex-col justify-center items-center gap-5 flex">
            <div className="self-stretch h-20 flex-col justify-center items-center flex">
              <div className="self-stretch text-center text-gray-50 text-3xl font-bold leading-[48px]">
                Verification
              </div>
            </div>
            {isANROrScreener && (
              <div className="self-stretch text-center text-gray-50 text-sm font-medium leading-normal">
                In order to verify your account, you need to complete the
                following steps.
              </div>
            )}
            <div className="self-stretch h-[238px] flex-col justify-start items-start gap-4 flex">
              <div className="self-stretch h-[238px] flex-col justify-start items-start gap-8 flex">
                <div className="relative w-full">
                  {isANROrScreener && (
                    <div className="w-full h-1 bg-white top-5 absolute"></div>
                  )}
                  <div
                    className={`flex ${
                      isANROrScreener ? "justify-between" : "justify-center"
                    } w-full`}
                  >
                    {isANROrScreener && (
                      <div className="w-12 h-12 relative flex justify-center items-center">
                        <div
                          className={`w-12 h-12 left-0 top-0 absolute ${
                            emailVerified
                              ? "bg-green-500 border-green-500"
                              : "bg-white border-white"
                          } rounded-full border-2 `}
                        />
                        {emailVerified ? (
                          <img
                            src={whiteCheckmarkIcon}
                            alt="checkmark"
                            className="absolute z-10"
                          />
                        ) : (
                          <div className="w-[12.48px] h-[23.04px] left-[18.24px] top-[12.48px] absolute text-zinc-700 text-lg font-semibold leading-tight">
                            1
                          </div>
                        )}
                      </div>
                    )}
                    {isANROrScreener && (
                      <div className="w-12 h-12 relative flex justify-center items-center">
                        <div
                          className={`w-12 h-12 left-0 top-0 absolute ${
                            user?.verifiedByVelv
                              ? "bg-green-500 border-green-500"
                              : "bg-white border-white"
                          } rounded-full border-2 `}
                        />
                        {user?.verifiedByVelv ? (
                          <img
                            src={whiteCheckmarkIcon}
                            alt="checkmark"
                            className="absolute z-10"
                          />
                        ) : (
                          <div className="w-[12.48px] h-[23.04px] left-[18.24px] top-[12.48px] absolute text-zinc-700 text-lg font-semibold leading-tight">
                            2
                          </div>
                        )}
                      </div>
                    )}

                    {user?.profession === ROLES_LIST.SCREENER && (
                      <div className="w-12 h-12 relative flex justify-center items-center">
                        <div
                          className={`w-12 h-12 left-0 top-0 absolute ${
                            user?.stripeAccount &&
                            user.stripeAccount?.payoutsEnabled &&
                            user?.stripeAccount?.detailsSubmitted
                              ? "bg-green-500 border-green-500"
                              : "bg-white border-white"
                          } rounded-full border-2 `}
                        />
                        {user?.stripeAccount &&
                        user.stripeAccount?.payoutsEnabled &&
                        user?.stripeAccount?.detailsSubmitted ? (
                          <img
                            src={whiteCheckmarkIcon}
                            alt="checkmark"
                            className="absolute z-10"
                          />
                        ) : (
                          <div className="w-[12.48px] h-[23.04px] left-[18.24px] top-[12.48px] absolute text-zinc-700 text-lg font-semibold leading-tight">
                            3
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className="self-stretch h-[156px] flex-col justify-start items-start gap-4 flex">
                  <div className="self-stretch justify-start items-center gap-5 inline-flex">
                    {!emailVerified && (
                      <div className="flex flex-col">
                        <div className="grow shrink basis-0 flex-col justify-start items-start gap-1 inline-flex">
                          <div
                            className={`self-stretch text-gray-50 text-base font-medium leading-normal 
                            ${!isANROrScreener && "text-xl text-center"}`}
                          >
                            Verify email address
                          </div>
                          <div className="self-stretch text-slate-500 text-sm font-normal leading-tight">
                            An email has been sent to:{" "}
                            <p className="text-white inline-flex">
                              {user?.email}
                            </p>
                          </div>
                          <div className="self-stretch text-slate-500 text-sm font-normal leading-tight">
                            If the link is not in your inbox, please check your
                            junk mail folder.
                          </div>
                        </div>
                        <button
                          onClick={handleSendEmailVerification}
                          className={`self-stretch text-gray-50 text-base font-bold px-6 py-3 bg-zinc-700 rounded-[90px] justify-center items-center gap-3 inline-flex mt-5
                          ${!isANROrScreener && "mt-[80px]"}`}
                        >
                          {emailResendLoading ? (
                            <img
                              src={loadingAnimation}
                              alt="loading"
                              className="w-12 h-[28px] brightness-[103%] invert-[99%] saturate-[6%] hue-rotate-[206deg] contrast-[104%]"
                            />
                          ) : (
                            "Resend email"
                          )}
                        </button>
                      </div>
                    )}
                    {!user?.verifiedByVelv &&
                      emailVerified &&
                      isANROrScreener && (
                        <div className="w-[349px] h-32 flex-col justify-start items-start gap-1 inline-flex">
                          <div className="self-stretch text-gray-50 text-base font-medium leading-normal">
                            Velv authorization
                          </div>
                          <div className="self-stretch">
                            <span className="text-slate-500 text-sm font-normal leading-tight">
                              Your account is currently undergoing verification
                              by Velv. <br />
                              Thank you for your patience. If you encounter any
                              issues or have questions, feel free to contact our
                            </span>
                            <a
                              rel="noreferrer"
                              target="_blank"
                              href="mailto:support@velvmusic.com"
                              className="text-slate-500 text-sm font-normal underline leading-tight px-1"
                            >
                              support team
                            </a>
                            <span className="text-slate-500 text-sm font-normal leading-tight">
                              for assistance
                            </span>
                          </div>
                        </div>
                      )}
                    {(!user?.stripeAccount ||
                      !user.stripeAccount?.payoutsEnabled ||
                      !user?.stripeAccount?.detailsSubmitted) &&
                      emailVerified &&
                      user?.verifiedByVelv &&
                      user?.profession === ROLES_LIST.SCREENER && (
                        <div className="flex flex-col">
                          <div className="grow shrink basis-0 flex-col justify-start items-start gap-1 inline-flex">
                            <div className="self-stretch text-gray-50 text-base font-medium leading-normal">
                              Stripe Account
                            </div>
                            <div className="self-stretch">
                              <span className="text-slate-500 text-sm font-normal leading-tight">
                                Your account needs to connect with Stripe in
                                order to receive payments. <br />
                                Contact
                              </span>
                              <a
                                rel="noreferrer"
                                target="_blank"
                                href="mailto:support@velvmusic.com"
                                className="text-slate-500 text-sm font-normal underline leading-tight px-1"
                              >
                                support team
                              </a>
                              <span className="text-slate-500 text-sm font-normal leading-tight">
                                for assistance
                              </span>
                            </div>
                          </div>
                          <button
                            onClick={handleCreatingStripeAccount}
                            className="self-stretch text-gray-50 text-base font-bold px-6 py-3 bg-zinc-700 rounded-[90px] justify-center items-center gap-3 inline-flex mt-5"
                          >
                            {stripeLoading ? (
                              <img
                                src={loadingAnimation}
                                alt="loading"
                                className="w-12 h-[28px] brightness-[103%] invert-[99%] saturate-[6%] hue-rotate-[206deg] contrast-[104%]"
                              />
                            ) : (
                              "Connect Stripe"
                            )}
                          </button>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        {routes.map((route, i) => {
          const key = `route-${i}`;
          if (route.protected) {
            return (
              <Route
                key={key}
                path={route.path}
                element={
                  <PrivateRoute
                    isAuth={isAuth}
                    path={route.path}
                    protect={route.protected}
                    roles={route?.role}
                    currentRole={user?.profession}
                  >
                    <route.component />
                  </PrivateRoute>
                }
              />
            );
          } else {
            return (
              <Route
                key={key}
                path={route.path}
                element={
                  <PublicRoutes
                    isAuth={isAuth}
                    path={route.path}
                    protect={route.protected}
                  >
                    <route.component />
                  </PublicRoutes>
                }
              />
            );
          }
        })}
      </>
    )
  );

  return (
    <div className="App">
      <AuthContext.Provider value={{ value, setValue }}>
        <div className="app-content">
          {loading ? (
            <Loading loadingAnimation={loadingAnimation} />
          ) : (
            <RouterProvider router={router} />
          )}
        </div>
      </AuthContext.Provider>
    </div>
  );
}

export default App;
