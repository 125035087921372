import { db } from "config/firebase-config";
import { collection, getDocs, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { formatDate } from "utils/helpers";

export function Receipt({ data, dropId, velvId, companyName }) {
  const [receipt, setReceipt] = useState({});
  const qDemo = query(
    collection(db, "receipts"),
    where("demoId", "==", data?.pitchId)
  );

  const getItem = async () => {
    try {
      const demo = await getDocs(qDemo);
      demo.forEach((doc) => {
        const data = doc.data();
        const date = new Date(formatDate(data?.createdAt));

        const formattedDate = date.toLocaleDateString("en-US", {
          year: "numeric",
          month: "long",
          day: "2-digit",
        });
        const totalAmountFormat =
          data?.totalAmount > 0 ? data?.totalAmount / 100 : data?.totalAmount;
        const formattedCurrency = totalAmountFormat?.toLocaleString("en-US", {
          style: "currency",
          currency: data?.currency || "sek",
        });
        setReceipt({
          ...data,
          createdAt: formattedDate,
          totalAmount: formattedCurrency,
        });
      });
    } catch (error) {
      console.log("Error getting drops", error);
    }
  };

  useEffect(() => {
    getItem();
  }, []);

  return (
    <div className="flex flex-col gap-4 lg:gap-4 mt-8 lg:mt-0">
      <h1 className="hidden lg:inline-block text-lg text-center text-white mt-12 mb-3">
        Receipt
      </h1>
      <div className="flex flex-col lg:flex-row justify-between lg:ml-16 text-base font-semibold">
        <p className="w-1/2 text-[#777E90]">Date:</p>
        <p className="lg:w-1/2 truncate">{receipt?.createdAt}</p>
      </div>
      <div className="flex flex-col lg:flex-row justify-between lg:ml-16 text-base font-semibold">
        <p className="w-1/2 text-[#777E90]">Demo Title:</p>
        <p className="lg:w-1/2 truncate">{data?.title}</p>
      </div>
      <div className="flex flex-col lg:flex-row justify-between lg:ml-16 text-base font-semibold">
        <p className="w-1/2 text-[#777E90]">Label/Company:</p>
        <p className="lg:w-1/2 truncate">{companyName}</p>
      </div>
      <div className="flex flex-col lg:flex-row justify-between lg:ml-16 text-base font-semibold">
        <p className="w-1/2 text-[#777E90]">Velv ID:</p>
        <p className="lg:w-1/2 truncate">{velvId}</p>
      </div>
      <div className="flex flex-col lg:flex-row justify-between lg:ml-16 text-base font-semibold">
        <p className="w-1/2 text-[#777E90]">Drop ID:</p>
        <p className="lg:w-1/2 truncate">{receipt?.dropId}</p>
      </div>
      <div className="flex flex-col lg:flex-row justify-between lg:ml-16 text-base font-semibold ">
        <p className="w-1/2 text-[#777E90]">Submit ID:</p>
        <p className="lg:w-1/2 truncate">{data?.id}</p>
      </div>
      <div className="flex flex-col lg:flex-row justify-between lg:ml-16 text-base font-semibold">
        <p className="w-1/2 text-[#777E90]">Price:</p>
        <p className="lg:w-1/2 truncate">{receipt?.totalAmount}</p>
      </div>
      <div className="flex flex-col lg:flex-row justify-between lg:ml-16 text-base font-semibold">
        <p className="w-1/2 text-[#777E90]">Paid by:</p>
        <p className="lg:w-1/2 truncate">{receipt?.userId}</p>
      </div>
    </div>
  );
}
