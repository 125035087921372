import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { sendPasswordResetEmail } from "firebase/auth";
import { InputValidation } from "components/shared/InputValidation/InputValidation";
import Login from "pages/Login/Login";
import { auth } from "config/firebase-config";
import { parseFirebaseErrors } from "utils/helpers";
import loadingAnimation from "assets/icons/loading.svg";
import arrowIcon from "assets/icons/arrow-icon.svg";

function ForgotPassword() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleForgotPassword = async () => {
    try {
      setLoading(true);
      await sendPasswordResetEmail(auth, email);
      setSuccess(true);
      setEmail("");
    } catch (error) {
      setError(
        parseFirebaseErrors(error?.code) || "Can't sign in, try again please!"
      );
      console.error("Error: ", error?.code);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChanges = (data) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailPattern.test(data)) {
      setError("");
    } else setError("Enter valid email address");
    if (data === "") {
      setError("This field is required");
    }
    setEmail(data);
  };

  return (
    <>
      <div className="flex justify-between max-w-7xl w-full px-4 md:mx-auto mt-16 -mb-16">
        <button
          className="flex gap-2 text-left text-white text-sm  cursor-pointer hover:text-white border border-solid border-white py-2 px-5  rounded-3xl top-5 right-5 items-center"
          onClick={() => navigate("/")}
        >
          <img
            src={arrowIcon}
            alt="chevron"
            className="flex rotate-90 w-3 h-3 cursor-pointer"
          />{" "}
          Back
        </button>
        <button
          className="text-center text-sm cursor-pointer bg-purple py-2 px-5 rounded-3xl top-5 right-5 text-white"
          onClick={() => navigate("/signin")}
        >
          Sign In
        </button>
      </div>
      <Login className={`lg:mt-16`}>
        <div className="font-extrabold text-2xl text-white mb-10 text-center">
          Reset password
        </div>
        <div className="grid grid-cols-1 gap-4">
          <InputValidation
            errorMsg={error}
            type="email"
            placeholder="Email address"
            value={email}
            onChange={(e) => handleInputChanges(e.target.value)}
            className={`border h-12`}
          />
        </div>
        {success ? (
          <>
            <div className="text-green-300 mt-2">
              Email was sent successfully sent!
            </div>
            <button
              className="text-center bg-purple w-full text-white rounded-full py-3 text-base mt-10 flex gap-2 justify-center"
              onClick={() => navigate("/signin")}
            >
              Go to Sign In
            </button>
          </>
        ) : (
          <button
            disabled={!email || error?.length}
            className="text-center bg-purple w-full text-white disabled:bg-red-600 rounded-full py-3 text-base mt-10 flex gap-2 justify-center"
            onClick={handleForgotPassword}
          >
            {loading ? (
              <img
                src={loadingAnimation}
                alt="loading"
                className="w-8 h-[28px] brightness-[103%] invert-[99%] saturate-[6%] hue-rotate-[206deg] contrast-[104%]"
              />
            ) : (
              "Reset Password"
            )}
          </button>
        )}
      </Login>
    </>
  );
}

export default ForgotPassword;
