import { useState } from "react";
import WidthWrapper from "landing/components/shared/WidthWrapper/WidthWrapper";
import DownloadVelv from "landing/components/DownloadVelv/DownloadVelv";
import wrapedIphoneImg from "landing/assets/images/warped-iphone.png";
// import partnersImg from 'landing/assets/images/partners-logo.png';
import arrowRightIcon from "landing/assets/icons/arrow-right.svg";
import appVideoVideo from "landing/assets/videos/VELV_TEASER_BLACK.mp4";
import videoThumbnail from "landing/assets/images/video-preview.png";
import browseImage from "landing/assets/images/browse-iphone-1.png";
import pitchImage from "landing/assets/images/pitch-iphone-1.png";
import trackImage from "landing/assets/images/track-iphone-1.png";
import appleStoreImg from "landing/assets/images/app-store.png";
import signInPreviewImg from "landing/assets/images/web-preview.png";
import ScrollToTop from "landing/helpers/ScrollToTop";
import iphoneFrame from "landing/assets/images/iphone-frame.png";
import browseVideo from "landing/assets/videos/browse_video.mov";
import pitchVideo from "landing/assets/videos/pitch_video.mov";
import trackVideo from "landing/assets/videos/track_video.mov";
import noteIcon from "landing/assets/icons/note-icon.svg";
import { handleRedirectToApp } from "landing/helpers/functions";
import Header from "landing/components/Header/Header";
import { Footer } from "components/shared/Footer/Footer";

export function HomePage() {
  const path = window.location.pathname;
  const [isOpened, setIsOpened] = useState(1);

  const handleIsOpened = (position) => {
    if (isOpened !== position) setIsOpened(position);
  };

  return (
    <>
      <Header />
      <ScrollToTop />
      <div className="mt pt-12 lg:pt-[165px] mx-auto lg:h-[840px] bg-[radial-gradient(circle_at_bottom_right,_var(--tw-gradient-stops))] from-[#00315F] via-[#40137A] via-0% to-[#000] to-60%">
        <WidthWrapper>
          <div className="lg:mx-auto flex-col lg:flex-row flex lg:mb-[259px] mx-6 ">
            <div className="relative z-10 w-full lg:w-2/3 text-white lg:ml-32">
              <h1 className="font-light text-4xl md:text-6xl gap-2">
                Your platform for <br />
                <b className="font-semibold">Music briefs</b>
                <span className="hidden lg:inline mx-4">and</span>
                <span className="inline lg:hidden">&</span>
                <b className="font-semibold block lg:inline">
                  <span className="lg:hidden">Music </span>Pitches
                </b>
              </h1>
              <p className="hidden lg:flex w-fit text-xl mt-12">
                Sign up and monetize your music by <br /> pitching to official
                briefs from record labels.
              </p>
              <p className="hidden lg:flex w-fit text-xl mt-12">
                No subscription fees - 100% free sign up
              </p>

              <button
                onClick={() => handleRedirectToApp("/signup")}
                className="hidden lg:flex w-full mx-auto lg:mx-0 text-center lg:justify-center max-w-[323px] lg:max-w-[445px] border-purple border rounded-3xl py-3 text-white font-semibold mt-16 text-lg lg:px-6 hover:shadow-[0_0_20px_0_rgba(108,40,255,1)]"
              >
                Sign Up Now
              </button>
            </div>
            <div className="lg:w-1/3 lg:mr-32 flex justify-end">
              <img
                className="lg:absolute lg:-ml-[225px] h-48 md:h-auto -mt-8 lg:mt-0"
                src={wrapedIphoneImg}
                alt=""
              />
            </div>
            <p className="lg:hidden w-fit text-base mt-8 text-white text-center">
              No subscription fees - 100% free sign up
            </p>

            <button
              onClick={() => handleRedirectToApp("/signup")}
              className="lg:hidden w-full mx-auto lg:mx-0 text-center max-w-[323px] lg:max-w-[445px] border-purple border rounded-3xl py-3 text-white font-semibold mt-8 text-lg lg:px-6 mb-20"
            >
              Sign Up Now
            </button>
          </div>
        </WidthWrapper>
      </div>

      <div className="bg-black text-white text-center mb-9">
        <WidthWrapper>
          {/* <h1 className="font-semibold text-4xl pt-8 mb-6">Used by</h1>
          <div className="">
            <img className="mx-auto" src={partnersImg} />
          </div> */}
        </WidthWrapper>
      </div>

      <div className="text-white text-center bg-[radial-gradient(circle_at_center_left,_var(--tw-gradient-stops))] from-[#00315F] via-[#40137A] via-0% to-[#000] to-60%">
        <WidthWrapper>
          <h1 className="font-semibold text-2xl lg:text-5xl pt-8 mb-4 lg:pt-16 lg:mb-20">
            Explore our main feature
          </h1>
          <div className="flex flex-col-reverse lg:flex-row text-left mx-4 lg:mx-32">
            {isOpened === 1 && (
              <PhoneVideoItem source={browseVideo} preview={browseImage} />
            )}
            {isOpened === 2 && (
              <PhoneVideoItem source={pitchVideo} preview={pitchImage} />
            )}
            {isOpened === 3 && (
              <PhoneVideoItem source={trackVideo} preview={trackImage} />
            )}
            <div className="ml-6 lg:ml-40 mt-6 lg:mt-28">
              <div className="mb-16">
                <img
                  src={arrowRightIcon}
                  alt="arrow right"
                  className={`absolute -ml-7 h-7 mt-[6px] ${
                    isOpened === 1 ? " flex" : "hidden"
                  }`}
                />
                <h4
                  className="text-xl font-extrabold lg:text-4xl cursor-pointer"
                  onClick={() => handleIsOpened(1)}
                >
                  Browse
                </h4>
                <p
                  className={`lg:text-2xl mt-5 ${
                    isOpened === 1 ? " flex" : "hidden"
                  }`}
                >
                  Browse through music briefs from global music markets.
                </p>
              </div>
              <div className="mb-16">
                <img
                  src={arrowRightIcon}
                  alt="arrow right"
                  className={`absolute -ml-7 h-7 mt-[6px] ${
                    isOpened === 2 ? " flex" : "hidden"
                  }`}
                />
                <h4
                  className="text-xl font-extrabold lg:text-4xl cursor-pointer"
                  onClick={() => handleIsOpened(2)}
                >
                  Pitch
                </h4>
                <p
                  className={`lg:text-2xl mt-5 ${
                    isOpened === 2 ? " flex" : "hidden"
                  }`}
                >
                  Pitch to official music briefs and pay only per submission, no
                  other hidden fees!
                </p>
              </div>
              <div className="mb-2 lg:mb-16">
                <img
                  src={arrowRightIcon}
                  alt="arrow right"
                  className={`absolute -ml-7 h-7 mt-[6px] ${
                    isOpened === 3 ? " flex" : "hidden"
                  }`}
                />
                <h4
                  className="text-xl font-extrabold lg:text-4xl cursor-pointer"
                  onClick={() => handleIsOpened(3)}
                >
                  Track
                </h4>
                <p
                  className={`lg:text-2xl mt-5 ${
                    isOpened === 3 ? " flex" : "hidden"
                  }`}
                >
                  Track your submissions and get status of the pitching process.
                </p>
              </div>
            </div>
          </div>
        </WidthWrapper>
      </div>

      <div className="text-white text-center">
        <WidthWrapper>
          <h1 className="font-semibold text-2xl py-8 px-6 lg:text-5xl lg:pt-32 lg:mb-20 text-center">
            A groundbreaking music platform
          </h1>
          <video
            className="w-full lg:mt-32 lg:max-h-[472px] h-[202px] lg:h-auto"
            poster={videoThumbnail}
            controls="controls"
          >
            <source src={appVideoVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </WidthWrapper>
      </div>

      <DownloadVelv appleStoreImg={appleStoreImg} />

      <div className="text-white text-center">
        <WidthWrapper>
          <div className="flex flex-col lg:flex-row mt-12 lg:mt-32 ml-4 mr-4 lg:mr-2 lg:ml-36 font-semibold">
            <div className="w-full lg:w-1/2 text-left">
              <h1 className="uppercase lg:text-2xl text-sm mb-6">
                Launch Alert
              </h1>
              <div className="text-2xl lg:text-5xl leading-[20px] lg:leading-[64px]">
                Web App
              </div>
              <div className="text-2xl lg:text-5xl text-purple mt-4">
                Live now
              </div>
              <div className="flex mt-7 gap-2 items-center">
                <img
                  src={noteIcon}
                  alt="bell icon"
                  className="w-4 h-4 lg:w-5 lg:h-5"
                />
                <p className="text-sm lg:text-2xl">Start Exploring</p>
              </div>
              <div className="flex mb-6 mt-6">
                <button
                  onClick={() => handleRedirectToApp("/signup")}
                  className="rounded-full bg-purple text-white font-semibold px-5 py-2 lg:px-7 lg:py-4 text-xs lg:text-base w-40 lg:w-fit hover:shadow-[0_0_20px_0_rgba(108,40,255,1)]"
                >
                  Sign Up
                </button>
              </div>
            </div>
            <img
              className="w-full lg:w-[600px] mx-auto"
              src={signInPreviewImg}
              alt="sign in"
            />
          </div>
        </WidthWrapper>
      </div>

      <div className="text-white text-center bg-[radial-gradient(circle_at_center_right,_var(--tw-gradient-stops))] to-[#000] via-[#40137A] via-0% from-[#00315F] to-75% pb-6 lg:pb-[90px]">
        <WidthWrapper>
          <div className="mx-4 lg:mx-36">
            <h1 className="font-semibold text-2xl lg:text-5xl py-6 lg:pt-16 text-center">
              Frequently asked questions
            </h1>
            <div className="text-left">
              <QAItems />
            </div>
          </div>
        </WidthWrapper>
      </div>

      {path !== "/soundtrap" ? <Footer /> : null}
    </>
  );
}

function QAItems() {
  const [isOpenedFAQ, setIsOpenedFAQ] = useState(1);

  const handleIsOpenedFAQ = (position) => {
    setIsOpenedFAQ(isOpenedFAQ === position ? 0 : position);
  };

  const faqItems = [
    {
      id: 1,
      question: "What is Velv?",
      answer:
        "Velv establishes an infrastructure connecting global music markets, democratizing the pitching process for A&R, music supervisors, songwriters, producers, and ad agencies worldwide.",
    },
    {
      id: 2,
      question: "Who publishes the music briefs?",
      answer:
        "Velv collaborates exclusively with official companies in the business that publish official music briefs.",
    },
    {
      id: 3,
      question: "Are there any submitting fees?",
      answer: "You only pay per submission with no additional hidden fees.",
    },
    {
      id: 4,
      question: "Will my music be heard?",
      answer:
        "All submitted music will be listened to. All submitted music matching the brief will be forwarded to the brief publisher, unless they request Velv screeners to handle the selection for them.",
    },
    {
      id: 5,
      question: "What is the role of the screeners?",
      answer:
        "The screeners, comprising highly experienced individuals in the music business, involve three inspection stages to screen submitted music for production quality and alignment with the brief.",
    },
    {
      id: 6,
      question: "How will I get updates on the various pitching stages?",
      answer:
        "All submissions receive a final status update through our in-app tracking system. Every submitted song is guaranteed a final status within 60 days from the delivery of demos to the A&R.",
    },
  ];

  return (
    <>
      {faqItems.map((item) => (
        <div
          key={item.id}
          className="flex flex-col mt-6 lg:mt-14 text-sm lg:text-2xl"
        >
          <div
            className="flex justify-between cursor-pointer"
            onClick={() => handleIsOpenedFAQ(item?.id)}
          >
            <div>
              <span className="text-[#9E9E9E]">{`0${item?.id}:`}</span>{" "}
              {item?.question}
            </div>
            <div className="text-2xl lg:text-5xl">
              {isOpenedFAQ === item?.id ? "-" : "+"}
            </div>
          </div>
          <div
            className={`mt-6 ml-8 text-left text-sm lg:text-lg ${
              isOpenedFAQ === item?.id ? "flex" : "hidden"
            }`}
          >
            {item?.answer}
          </div>
        </div>
      ))}
    </>
  );
}

function PhoneVideoItem({ source, preview }) {
  return (
    <div className="relative w-[255px] mx-auto lg:mx-0 py-12">
      <img src={iphoneFrame} alt="iphone" className="absolute" />
      <video
        className="w-full relative z-10 rounded-[40px] px-2 lg:px-[5px] mt-1 h-[calc(100%-15px)]"
        poster={preview}
        controls={false}
        autoPlay={true}
        playsInline={true}
        loop={true}
        muted={true}
      >
        <source src={source} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
}

export default HomePage;
