import emptyStateIcon from "assets/icons/empty-state-icon.svg";

const EmptyState = ({title}) => {
  return (
    <div className="w-full h-[526.65px] flex-col justify-start items-center gap-6 inline-flex mb-16">
      <img
        src={emptyStateIcon}
        alt="empty state"
        className="max-w-[425.97px] max-h-[310.65px] w-full h-full lg:pl-[34.36px] lg:pr-[34.24px] pt-[33.65px] pb-[34.32px] justify-center items-center inline-flex"
      />
      <div className="self-stretch flex-col justify-start items-center gap-6 flex">
        <div className="w-[910px] text-center text-white text-2xl lg:text-4xl font-light leading-[72px]">
          {title || ""}
        </div>
        {/* <div className="max-w-[637px] text-center text-white text-sm lg:text-[22px] font-normal leading-normal">
          Due to the new launch, there may be no or limited music drops
          available. Ensure that notifications are turned on and be part of
          career-changing opportunities.
        </div> */}
      </div>
    </div>
  );
};

export default EmptyState;
