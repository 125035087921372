import { textList } from "landing/constants/data";
import WidthWrapper from "landing/components/shared/WidthWrapper/WidthWrapper";
import PropTypes from "prop-types";
import ScrollToTop from "landing/helpers/ScrollToTop";
import Header from "landing/components/Header/Header";

function PrivacyAndCookies() {
  return (
    <>
      <Header />
      <div className="bg-[radial-gradient(circle_at_center_left,_var(--tw-gradient-stops))] from-[#40137a8f] from-5% to-[#000] to-30%">
        <ScrollToTop />
        <div className="text-white mx-8 lg:mx-36 pt-14 text-2xl font-extrabold">
          <WidthWrapper>
            <h1>HOW TO</h1>
          </WidthWrapper>
        </div>

        <div className="bg-[#101010]">
          <div className="text-white mx-8 lg:mx-36 mt-14 text-5xl font-extrabold pt-3">
            <WidthWrapper>
              <div className="flex justify-center items-center gap-12">
                <h1 className="border-b-2 border-solid border-transparent pb-3 cursor-pointer">
                  Private policy & Cookies
                </h1>
              </div>
            </WidthWrapper>
          </div>
        </div>
        <div className="text-white mx-8 lg:mx-36 pb-32">
          <WidthWrapper>
            <div>
              <h1 className="text-4xl font-extrabold pt-16">
                Velv&rsquo;s privacy policy
              </h1>
              <p className="py-6 font-semibold">
                This privacy policy applies when VELV AB supplies the service as
                defined in the Terms of Use and the VELV mobile application.
              </p>
              <p>
                We are responsible for the processing of your Personal Data (as
                defined below) for the purpose and the means of processing that
                we consider necessary in order to provide you with our
                offerings. With” Personal Data”, we mean information that is
                directly or indirectly referable to a natural living person,
                e.g. full name, but also possibly location data or IP addresses.{" "}
                <br />
                <br /> This privacy policy (the “Privacy Policy”) informs you
                about how we treat Personal Data that we process about you. If
                you do not agree to any part of this Privacy Policy, then we
                cannot provide the Services to you and you should stop access
                the Service, deactivate your VELV account and remove the
                application from Your device.
                <br />
                <br /> You have the right to refuse your Personal Data being
                used for direct marketing and you may at any time recall your
                prior consent by contacting VELV or by terminating your VELV
                subscription.
              </p>
            </div>
            <div>
              <h1 className="text-4xl font-extrabold pt-16">Processed data</h1>
              <p className="py-6">
                In order to deliver, develop and maintain the Service, starting
                from when you subscribe to the Service and continuously over
                time as long as the subscription remains, we collect and use
                Personal Data from you including:
              </p>
              <ul className="list-disc ml-10 pb-6">
                <li>Full name;</li>
                <li>E-mail address;</li>
                <li>Company; and</li>
                <li>IP-address</li>
              </ul>
              <p>
                Please note that we are unable to provide the Service unless you
                provide the above stated Personal Data. When you use the
                Service, certain information will be collected automatically,
                including: information about your use of the Service
              </p>
              <ul className="list-disc ml-10 py-6">
                <li>your IP address;</li>
                <li>your network and computer performance;</li>
                <li>your browser type; and</li>
                <li>preferred language</li>
                <li>your operating system</li>
              </ul>
              <p>
                We collect information through technology like cookies, pixels
                and local storage (e.g. on your browser or device). For
                information about how we use system technologies and analytic
                tools and how you can prevent the use of cookies, please see our
                Cookie Policy.
              </p>
            </div>
            <div>
              <h1 className="text-4xl font-extrabold pt-16 py-6">
                Collection and use of personal data
              </h1>
              <div className="font-bold">User Account:</div>
              <p className="py-6">
                we collect and use the following Personal Data from you when you
                use the Services: (i) full name; (ii) e-mail address; (iii)
                profile picture.
              </p>

              <div className="mt-6 font-bold">Technical Data:</div>
              <p className="py-6">
                when you use the Service, certain information will be collected
                automatically by us, including; (i) information about your use
                of the Platform and the Services; (ii) technical data, which may
                include the URL you are accessing the Site from, your device
                token and network performance); (iii) geo-location information
                such as GPS from mobile device (with your permission) as well as
                Wi-Fi and IP-addresses; and device information such as what
                device you are using the Service on, operating system, device
                settings, unique device identifiers and crash data.
              </p>

              <div className="mt-6 font-bold">
                Information about Other People:
              </div>
              <p className="py-6">
                via our Website, Applications, and Social Media Network Services
                you can submit personal information about other people. For
                example, you may submit the name and e-mail address to any
                individual you would like to refer the VELV&rsquo;s Services
                directly to. The personal information that may be collected
                about other people include the recipient&rsquo;s name and e-mail
                address.
              </p>

              <div className="mt-6 font-bold">
                Information from partners or other third parties:
              </div>
              <p className="py-6">
                VELV may receive information from partners or others that we
                could use to make our own information better or more useful.
                This might be aggregated level information about which IP
                addresses go with which postal codes or more specific
                information about how well an online marketing or email campaign
                performed.
              </p>
            </div>
            {textList?.map(({ title, body }, id) => (
              <TextBoxPrivacy title={title} body={body} key={id} />
            ))}

            <CookiesText />
          </WidthWrapper>
        </div>
      </div>
    </>
  );
}

function TextBoxPrivacy({ title, body }) {
  return (
    <div>
      <h1 className="text-4xl font-extrabold pt-16">{title}</h1>
      <p className="py-6">{body}</p>
    </div>
  );
}

TextBoxPrivacy.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
};

export default PrivacyAndCookies;

function CookiesText() {
  return (
    <>
      <div>
        <h1 className="text-4xl font-extrabold pt-16">
          Cookies and Internet advertising
        </h1>
        <p className="py-6">
          We and our Service Providers use cookies and other technologies (such
          as web signals) for various purposes. For example, cookies and other
          technologies are used to make it easy to access our services by
          remembering when you return, to provide and analyze our services, to
          find out more about our users and their likely interests, and to
          deliver and tailor marketing and advertising. We want to inform you
          about how we use these techniques. This message therefore describes
          the different types of techniques we use, what they do and what
          opportunities you have to choose how they are used.
        </p>
      </div>
      <div>
        <h1 className="text-xl font-black pt-16">What are cookies?</h1>
        <p className="py-6">
          We and our Service Providers use cookies and other technologies (such
          as web signals) for various purposes. For example, cookies and other
          technologies are used to make it easy to access our services by
          remembering when you return, to provide and analyze our services, to
          find out more about our users and their likely interests, and to
          deliver and tailor marketing and advertising. We want to inform you
          about how we use these techniques. This message therefore describes
          the different types of techniques we use, what they do and what
          opportunities you have to choose how they are used.
        </p>
      </div>
      <div>
        <h1 className="text-xl font-black pt-16">Why do VELV use cookies?</h1>
        <p className="py-6">
          We and our Service Providers may use the following types of cookies:
          Required cookies: These cookies are absolutely necessary for us to
          provide our website or online service. For example, we or our Service
          Providers may use these cookies to verify and identify our users as
          they use our websites and applications so that we can deliver our
          service to them. They also help us enforce our User Terms, prevent
          fraud, and maintain the security of our service. Cookies for
          performance and functionality: These cookies are not necessary, but
          they help us to customize and enhance your VELV experience online. For
          example, they contribute to remembering your preferences so that you
          do not have to enter information that you have already entered (for
          example when you registered as a user). These cookies are also used to
          collect information (such as popular pages, conversion rate, training
          patterns, clickthrough rates and other information) about our
          visitors&rsquo; use of the VELV service, so that we can improve and
          personalize our website and service and conduct market research. If
          you delete this type of cookie, the functionality of the service will
          be limited. Advertisement Cookies: These cookies use information about
          your visits to this and other websites, such as which pages you visit,
          your use of our service or your response to advertisements and emails,
          to provide ads that are more relevant to you. These types of ads are
          called “interest-based ads”.
        </p>
      </div>
      <div>
        <h1 className="text-xl font-black pt-16">
          How does VELV use web signals and other technologies?
        </h1>
        <p className="py-6">
          Web signals (also called transparent GIFs or pixel tags) are often
          used in conjunction with cookies. We and our Service Providers may use
          them for purposes similar to cookies, for example, to understand and
          improve the use of our service, to improve website performance, to
          monitor visitor traffic and activity on our site, and to understand
          interactions with our marketing (including email, post and online ads
          on third party websites). Since web signals are often used in
          conjunction with cookies, denial of cookies can often impair the
          effectiveness of web signals.
        </p>
      </div>
    </>
  );
}
