import Pitches from "components/Pitches/Pitches";
import searchIcon from "assets/icons/search-icon.svg";
import { PitchItem } from "components/PitchItem/PitchItem";
import { useEffect, useRef, useState } from "react";
import { collection, getDocs, query, where, or, doc, getDoc } from "firebase/firestore";
import { auth, db } from "config/firebase-config";
import loadingAnimation from "assets/icons/loading.svg";

const MyPitches = () => {
  const [demos, setDemos] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [currentSongIndex, setCurrentSongIndex] = useState(null);
  const [user, setUser] = useState(null);
  const currentSongRef = useRef(null);

  const handlePlay = (index, audio, setStatus) => {
    if (index !== currentSongIndex) {
      // Pause the currently playing song
      if (currentSongRef.current) {
        currentSongRef.current.pause();
        setStatus(false);
      }
      // Play the new song
      setCurrentSongIndex(index);
      setStatus(true);
      currentSongRef.current = audio;
      audio.play();
    } else {
      // Toggle pause/play for the current song
      if (audio.paused) {
        audio.play();
      } else {
        audio.pause();
      }
    }
  };

  const getUser = async () => {
    const docRef = doc(db, "users", auth?.currentUser?.uid);
    try {
      const snapshot = await getDoc(docRef);
      setUser(snapshot?.data());
    } catch (error) {
      console.log("Error", error);
    }
  };


  const qDemos = query(
    collection(db, "demos"),
    or(where("userId", "==", auth?.currentUser?.uid),
      where("collaboratorVelvIds", "array-contains", user?.velvId || '')
    )
  );
  const qDrops = query(collection(db, "drops"));

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const querySnapshotDemos = await getDocs(qDemos);
      const querySnapshotDrops = await getDocs(qDrops);
      let demoListDemos = [];
      let dropListDrops = [];
      querySnapshotDemos.forEach((doc) => {
        demoListDemos.push(doc.data());
      });
      querySnapshotDrops.forEach((doc) => {
        dropListDrops.push({ ...doc.data(), id: doc?.id });
      });

      const dataDemo = demoListDemos.map((item) => {
        const drop = dropListDrops.find((obj) => obj.id === item?.dropId);
        return {
          ...item,
          artist: drop?.artist || "",
          title: item?.title || "",
          createdTime: item?.createdTime || "",
          result: item?.result || "",
          artworkUrl: drop?.artworkUrl || "",
          dropId: drop?.id || "",
        };
      });
      const filteredDemos = filterData(dataDemo);

      filteredDemos?.sort((a, b) => b.createdTime - a.createdTime);
      setDemos([...filteredDemos]);
    } catch (error) {
      console.error("Error getting item:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    if (user && user?.velvId) {
      fetchData();
    }
  }, [user]);

  const filterData = (data) => {
    const resultsList = search
        ? data.filter(item => 
            item.artist.toLowerCase().includes(search.toLowerCase()) ||
            item.title.toLowerCase().includes(search.toLowerCase())
        )
        : data;
    return resultsList;
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      fetchData();
    }
  };

  const myDemos = demos?.filter(item => item?.userId === auth?.currentUser?.uid);
  const collabDemos = demos?.filter(item => item?.userId !== auth?.currentUser?.uid);

  return (
    <Pitches showBackBtn={false}>
      <p className="text-base mt-2 text-left pb-8 lg:pb-12 text-[#B1B5C3]">
        {demos?.length || 0} PITCHES
      </p>

      <div className="flex rounded-xl bg-opacity-10 bg-white w-full lg:w-fit p-1 justify-between pl-4 pr-2 py-2 lg:pl-1 lg:pr-1 lg:py-1">
        <img
          src={searchIcon}
          alt="search"
          className="hidden lg:flex px-4 group-hover:pr-0"
        />
        <input
          type="text"
          placeholder="Search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          onKeyDown={handleKeyDown}
          className="text-white bg-transparent placeholder-[#808191] py-2 outline-none text-base pr-4"
        />
        <button
          className="hidden lg:flex text-center bg-purple w-fit text-white rounded-2xl py-2 text-base px-9"
          onClick={fetchData}
        >
          Search
        </button>
        <button
          onClick={fetchData}
          className="inline-block lg:hidden bg-purple rounded-full"
        >
          <img
            src={searchIcon}
            alt="search"
            className="px-4 group-hover:pr-0"
          />
        </button>
      </div>
      {isLoading ? (
        <div className="rounded-3xl h-[334.50px] w-full flex justify-center items-center flex-col text-white">
          <img
            src={loadingAnimation}
            alt="loading"
            className="text-white w-20"
          />
          <p>Loading...</p>
        </div>
      ) : demos?.length === 0 ? (
        <div className="w-full h-72 flex justify-center items-center text-white font-bold">
          No drops to display...
        </div>
      ) : (
        <>
          <DemoList
            demos={myDemos}
            title="My Pitches"
            handlePlay={handlePlay}
            currentSongIndex={currentSongIndex}
            currentSongRef={currentSongRef}
          />
          <DemoList
            demos={collabDemos}
            title="Collaborator Pitches"
            handlePlay={handlePlay}
            currentSongIndex={currentSongIndex}
            currentSongRef={currentSongRef}
          />
        </>
      )}
    </Pitches>
  );
};

export default MyPitches;


const DemoList = ({ demos, title, handlePlay, currentSongIndex, currentSongRef }) => (
  <>
    {demos?.length > 0 && (
      <>
        <div className="flex justify-between text-[#B2B3BD]">
          <p className="mt-11 mb-4 lg:mb-6 text-xl lg:text-2xl text-left">{title}</p>
          <p className="mt-11 mb-4 lg:mb-6 text-xl lg:text-2xl text-left">Status</p>
        </div>
        {demos?.map((demo, idx) => (
          <div key={demo?.dropId + demo?.pitchId}>
            <PitchItem
              name={demo?.artist}
              audioTitle={demo?.title}
              date={demo?.createdTime}
              status={demo?.result}
              screenings={demo?.screenings}
              img={demo?.artworkUrl}
              id={demo?.dropId}
              pitchId={demo?.pitchId}
              mediaUrl={demo?.mediaUrl}
              onPlay={handlePlay}
              isPlayingSong={currentSongRef?.current}
              index={idx}
              isActive={idx === currentSongIndex}
            />
          </div>
        ))}
        <div className="border-t border-solid border-[#777e906e] mt-2 lg:mt-0" />
      </>
    )}
  </>
);
