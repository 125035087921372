import { Loading } from "components/shared/Loading/Loading";
import { auth, db } from "config/firebase-config";
import { doc, getDoc } from "firebase/firestore";
import AddDrop from "pages/AddDrop/AddDrop";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const EditDrop = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const { id } = useParams();
  const [drop, setDrop] = useState(null);
  const [loading, setLoading] = useState(true);
  const docRef = doc(db, "drops", id);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const snapshot = await getDoc(docRef);

        const data = snapshot.data();

        if (
          data?.userId !== auth?.currentUser?.uid &&
          ["anr", "corporate", "manager", "supervisor"].includes(
            user?.profession
          )
        ) {
          window.location.href = "/";
        } else {
          const formatData = {
            ...data,
            createdTime: data?.createdTime?.toDate(),
            expireTime: data?.expireTime?.toDate(),
          };
          setDrop(formatData);
        }
      } catch (error) {
        console.log("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  if (loading) {
    return <Loading />;
  }

  return <AddDrop data={drop} />;
};

export default EditDrop;
