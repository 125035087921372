import WidthWrapper from "../shared/WidthWrapper/WidthWrapper";
export function BriefsText() {
  return (
    <div className="text-white mx-8 lg:mx-36 text-center pb-32">
      <WidthWrapper>
        <h1 className="text-2xl lg:text-4xl font-extrabold pb-7 pt-16">Briefs</h1>
        <p>
          VELV is always in search of new and exciting partners with music
          briefs from Record labels, Film & Tv companies, Gaming companies &
          Advertising Agencies worldwide. We accomplish this by monitoring the
          market and leveraging an extensive international network . This
          approach helps all of our users save time and money when using VELV.{" "}
          <br /> To offer our users official music briefs, one must go through a
          verification process to publish a brief. The brief is posted in the
          app, detailing the kind of music sought for their artist, film, or
          other music projects. Once A&R/Music supervisors receive their demos,
          they make decisions and notify the creator by sending status updates
          through VELV’s build in tracking system. In cases where a demo sparks
          interest, it's the responsibility of the A&R/Music supervisor to
          contact creators and advance the process further.
        </p>
      </WidthWrapper>
    </div>
  );
}
