import { useState } from "react";
import { ProducerView } from "./ProducerView";
import { ScreenerView } from "./ScreenerView";
import { Sidebar } from "components/Sidebar/Sidebar";
import { Footer } from "components/shared/Footer/Footer";
import { ROLES_LIST } from "utils/constants";
import "./Home.scss";

const Home = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const [heroImage, setHeroImage] = useState("");

  return (
    <div className="bg-[radial-gradient(circle_at_center_left,_var(--tw-gradient-stops))] from-[#00315F] to-25%">
      <Sidebar />
      <div className="absolute top-0 max-h-[735px] w-full h-full -z-10">
        <div className="bg-gradient-to-t from-black w-full h-full z-1"></div>
        {heroImage && (
          <img
            key={Math.floor(Math.random() * 11)}
            src={heroImage}
            alt="hero"
            className="fade-in-animation w-full absolute top-0 max-h-[522.25px] lg:max-h-[722.25px] h-[100%] -z-10 object-cover"
          />
        )}
      </div>
      <div className="ml-4 lg:ml-32 mr-4 lg:mr-16 relative z-10 overflow-hidden">
        {user && user?.profession === ROLES_LIST.SCREENER ? (
          <ScreenerView setHeroImage={setHeroImage} />
        ) : (
          <ProducerView setHeroImage={setHeroImage} />
        )}
      </div>
      <Footer />
    </div>
  );
};

export default Home;
