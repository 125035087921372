import { useState } from "react";
import WidthWrapper from "landing/components/shared/WidthWrapper/WidthWrapper";
import TermsAndConditionContent from "landing/pages/TermsAndConditionsPage/TermsAndConditionContent";
import {
  managerList,
  producerList,
  screenerList,
} from "landing/constants/data";
import ScrollToTop from "landing/helpers/ScrollToTop";
import Header from "landing/components/Header/Header";

const TermsAndConditionsPage = () => {
  const [tabNumber, setTabNumber] = useState(1);
  return (
    <>
      <Header />
      <div className="bg-[radial-gradient(circle_at_center_left,_var(--tw-gradient-stops))] from-[#40137a8f] from-5% to-[#000] to-30%">
        <ScrollToTop />
        <div className="text-white mx-8 lg:mx-36 pt-14 text-2xl font-extrabold">
          <WidthWrapper>
            <h1>TERMS & CONDITIONS</h1>
          </WidthWrapper>
        </div>
        <div className="bg-[#101010]">
          <div className="text-white mx-8 lg:mx-36 mt-14 text-base lg:text-2xl font-extrabold pt-3">
            <WidthWrapper>
              <div className="flex justify-center items-center gap-5 flex-col text-center lg:flex-row">
                <h1
                  className={`border-b-2 text-gray-500  ${
                    tabNumber === 1
                      ? "border-solid border-purple !text-white "
                      : "border-transparent"
                  } pb-3 cursor-pointer uppercase`}
                  onClick={() => setTabNumber(1)}
                >
                  Producer | Songwriter
                </h1>
                <h1
                  className={`border-b-2 text-gray-500 ${
                    tabNumber === 2
                      ? "border-solid border-purple !text-white "
                      : "border-transparent"
                  } pb-3 cursor-pointer uppercase`}
                  onClick={() => setTabNumber(2)}
                >
                  A&R | Music Supervisor | Manager | Corporate
                </h1>
                <h1
                  className={`border-b-2 text-gray-500 ${
                    tabNumber === 3
                      ? "border-solid border-purple !text-white "
                      : "border-transparent"
                  } pb-3 cursor-pointer uppercase`}
                  onClick={() => setTabNumber(3)}
                >
                  Screeners
                </h1>
              </div>
            </WidthWrapper>
          </div>
        </div>
        <div className="text-white mx-8 lg:mx-36 pb-32 transition ease-in-out delay-150">
          {tabNumber === 1 && (
            <TermsAndConditionContent
              data={producerList}
              mainTitle={"Producer | Songwriter"}
            />
          )}
          {tabNumber === 2 && (
            <TermsAndConditionContent
              data={managerList}
              mainTitle={"A&R | Music supervisor | Manager | Corporate"}
            />
          )}
          {tabNumber === 3 && (
            <TermsAndConditionContent
              data={screenerList}
              mainTitle={"Screener"}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default TermsAndConditionsPage;
