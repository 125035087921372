import { useEffect, useState } from "react";
import {
  Timestamp,
  collection,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { Sidebar } from "components/Sidebar/Sidebar";
import { Footer } from "components/shared/Footer/Footer";
import { Header } from "components/shared/Header/Header";
import { auth, db } from "config/firebase-config";
import { displayDate } from "utils/helpers";
import { useNavigate } from "react-router-dom";
import { Loading } from "components/shared/Loading/Loading";
import EmptyState from "components/shared/EmptyState";

dayjs.extend(duration);

const MyDrops = () => {
  const navigate = useNavigate();
  const [drops, setDrops] = useState(null);
  const [nowScreening, setNowScreening] = useState(null);
  const [screeningList, setScreeningList] = useState(null);
  const [loading, setLoading] = useState(false);

  const qDrops = query(
    collection(db, "drops"),
    where("userId", "==", auth?.currentUser?.uid)
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const querySnapshotDrops = await getDocs(qDrops);
        let dropListDrops = [];
        let screeningList = [];
        querySnapshotDrops.forEach((doc) => {
          const data = doc.data();
          if (
            Timestamp.fromDate(new Date()) <= data?.expireTime &&
            !data?.deliveryDate
          ) {
            dropListDrops.push({ ...data, dropId: doc?.id });
          } else {
            if (!data?.deliveryDate)
              screeningList.push({ ...data, dropId: doc?.id });
          }
        });

        if (dropListDrops?.length)
          setNowScreening({
            ...dropListDrops[0],
            expired:
              Timestamp.fromDate(new Date()) >= dropListDrops[0]?.expireTime,
          });
        if (!dropListDrops?.length && screeningList?.length) {
          setNowScreening({
            ...screeningList[0],
            expired:
              Timestamp.fromDate(new Date()) >= screeningList[0]?.expireTime,
          });
        }
        if (dropListDrops?.length > 1) {
          dropListDrops.shift();
          setDrops([...dropListDrops]);
        }

        if (dropListDrops?.length === 0 && screeningList?.length >= 1) {
          screeningList.shift();
          setScreeningList([...screeningList]);
        } else {
          setScreeningList(screeningList);
        }
      } catch (error) {
        console.error("Error getting items:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="bg-[radial-gradient(circle_at_center_left,_var(--tw-gradient-stops))] from-[#00315F] to-25%">
      <Sidebar />
      <div className="ml-4 lg:ml-32 mr-4 lg:mr-16 relative z-10 overflow-hidden">
        <Header tab="mydrops" />
        <div className="lg:flex gap-4 justify-start items-center text-4xl lg:text-2xl font-extrabold mt-14 text-white text-center lg:text-left cursor-pointer">
          My Drops
        </div>
        {loading ? (
          <Loading />
        ) : nowScreening || screeningList?.length ? (
          <div>
            <section className="mt-8 flex flex-col items-center">
              {loading ? (
                <div
                  className={`animate-pulse bg-gray-800 max-w-[300px] w-full h-[367px] relative rounded-xl border border-violet-600 overflow-hidden`}
                ></div>
              ) : (
                <div
                  onClick={() =>
                    drops?.length && navigate(`/drop/${nowScreening?.dropId}`)
                  }
                >
                  {nowScreening ? (
                    <div className="relative">
                      {nowScreening?.expired ? (
                        <div className="absolute bg-black z-10 w-full flex justify-center items-center bg-opacity-50 text-neutral-200 max-h-[383px] h-full">
                          <p className="-mt-20">Screening in progress</p>
                        </div>
                      ) : null}
                      <DropItem
                        title={nowScreening?.artist || "-"}
                        name={nowScreening?.artist || "-"}
                        startDate={nowScreening?.createdTime}
                        endDate={nowScreening?.expireTime}
                        image={nowScreening?.artworkUrl}
                        id={nowScreening?.dropId}
                        showFavorites={false}
                        hideSeconds={false}
                      />
                    </div>
                  ) : null}
                </div>
              )}
            </section>
            <section className="my-10 lg:my-20">
              {!drops?.length && !screeningList?.length ? (
                <p className="text-center">
                  "No more drops to show..."
                </p> 
              ) : (
                <div className="flex flex-row justify-center gap-x-6 gap-y-9 flex-wrap">
                  {drops?.map((item) => {
                    return (
                      <div key={item?.id}>
                        <DropItem
                          title={item?.artist || "-"}
                          name={item?.artist || "-"}
                          startDate={item?.createdTime}
                          endDate={item?.expireTime}
                          image={item?.artworkUrl}
                          id={item?.dropId}
                        />
                      </div>
                    );
                  })}
                  {screeningList?.map((item) => {
                    return (
                      <div className="relative" key={item?.id}>
                        <div className="absolute bg-black z-10 w-full flex justify-center items-center bg-opacity-50 text-neutral-200 max-h-[383px] h-full">
                          <p className="-mt-20">Screening in progress</p>
                        </div>
                        <DropItem
                          title={item?.artist || "-"}
                          name={item?.artist || "-"}
                          startDate={item?.createdTime}
                          endDate={item?.expireTime}
                          image={item?.artworkUrl}
                          id={item?.dropId}
                          showCountdown={false}
                        />
                      </div>
                    );
                  })}
                </div>
              )}
            </section>
          </div>
        ) : (
          <EmptyState title={"No drops to show"} />
        )}
      </div>
      <Footer />
    </div>
  );
};

function DropItem({
  title,
  startDate,
  endDate,
  image,
  id,
  hideSeconds,
  showCountdown = true,
}) {
  const navigate = useNavigate();
  const [date, setDate] = useState(null);
  const [time, setTime] = useState(null);

  function calculateTimeDifference(startDate, endDate) {
    // Parsing the dates using Day.js
    if (typeof startDate === "object") {
      startDate = new Date();
      endDate = new Date(endDate?.toDate());
    }
    const dateCreated = new Date(startDate);
    const dateExpire = endDate;
    const start = dayjs(dateCreated);
    const end = dayjs(dateExpire);
    // Calculating the duration between the two dates
    const duration = dayjs.duration(end.diff(start));
    // Getting the duration in days, hours, minutes, and seconds
    const days = duration?.months()
      ? Math.round(duration?.asDays())
      : duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    // Formatting to ensure not to exceed 60 seconds, minutes, or hours
    const formattedDays = days;
    const formattedHours = hours % 24;
    const formattedMinutes = minutes % 60;
    const formattedSeconds = seconds % 60;

    setTime(seconds);
    return {
      day: formattedDays,
      hour: formattedHours,
      minute: formattedMinutes,
      second: formattedSeconds,
    };
  }

  //TODO: Extract this to separate component to prevent re-rendering
  useEffect(() => {
    if (time > 0) {
      if (time === 0) {
      }
      const timer = setTimeout(() => setTime(time - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      if (date?.minute && date?.minute > 0) {
        setTime(59);
        setDate((prev) => ({ ...prev, minute: prev.minute - 1 }));
      } else setTime("00");
    }
  }, [time]);

  useEffect(() => {
    const dateList = calculateTimeDifference(startDate, endDate);
    setDate(dateList);
  }, []);

  return (
    <main
      onClick={() => navigate(`/drop/${id}`)}
      className="max-w-[300px] lg:max-w-[260px] h-[367px] lg:h-[327px] w-full flex-col justify-start items-center gap-4 lg:gap-2   flex relative cursor-pointer group hover:bg-black hover:bg-opacity-50"
    >
      <div className="relative w-[260px] h-[253px] rounded-xl">
        <div className="text-white text-xl font-extrabold absolute top-1 left-2 leading-[41.63px] capitalize">
          {title}
        </div>
        <img
          src={image}
          alt="preview cover art"
          className="absolute w-full h-full object-cover -z-10 top-0 border-2 border-solid border-transparent group-hover:border-purple rounded-xl box-border transition-all duration-300"
        />
      </div>

      <div className="w-full p-2 lg:!py-1 rounded-2xl border border-violet-600 justify-center items-center gap-2.5 inline-flex">
        {image ? (
          <img
            src={image}
            alt="preview thumbnail art"
            className="w-14 h-14 relative border border-purple rounded-[10.41px] object-cover"
          />
        ) : (
          <div className="w-14 h-14 relative border border-purple rounded-[10.41px]" />
        )}
        <Countdown
          artist={title}
          date={showCountdown ? date : 0}
          time={showCountdown ? time : 0}
          hideSeconds={hideSeconds}
        />
      </div>
    </main>
  );
}

function Countdown({ artist, date, time, hideSeconds = true }) {
  return (
    <div className="group w-[180px] flex-col justify-start items-center inline-flex">
      <div className="self-stretch justify-start items-start gap-[4.83px] inline-flex">
        <div className="px-[4.83px] justify-center items-center gap-[8.06px] flex">
          <div className="text-right text-white text-sm font-bold leading-none lg:mb-1">
            {artist}
          </div>
        </div>
      </div>
      <div className="self-stretch justify-between items-start inline-flex">
        <div className="px-[6.44px] justify-center items-center gap-[8.06px] flex">
          <div className="text-white text-lg lg:text-base font-digitalnumbers">
            {displayDate(date?.day) || "00"}
          </div>
        </div>
        <div className="px-[6.44px] justify-center items-center gap-[8.06px] flex">
          <div className="text-white text-lg lg:text-base font-digitalnumbers">
            {displayDate(date?.hour) || "00"}
          </div>
        </div>
        <div className="px-[6.44px] justify-center items-center gap-[8.06px] flex">
          <div className="text-white text-lg lg:text-base font-digitalnumbers">
            {displayDate(date?.minute) || "00"}
          </div>
        </div>
        <div
          className={`px-[6.44px] justify-center items-center gap-[8.06px] flex ${
            hideSeconds ? "invisible" : ""
          } group-hover:visible`}
        >
          <div className="text-white text-lg lg:text-base font-digitalnumbers">
            {time || "00"}
          </div>
        </div>
      </div>
      <div className="self-stretch justify-between items-start inline-flex">
        <div className="px-[4.83px] justify-center items-center gap-[8.06px] flex">
          <div className="w-[28.22px] lg:w-[26px] text-right text-white text-xs">Day</div>
        </div>
        <div className="px-[4.83px] justify-center items-center gap-[8.06px] flex">
          <div className="text-white text-xs">Hour</div>
        </div>
        <div className="px-[4.83px] justify-center items-center gap-[8.06px] flex">
          <div className="text-white text-xs">Min</div>
        </div>
        <div
          className={`px-[4.83px] justify-center items-center gap-[8.06px] flex ${
            hideSeconds ? "invisible mr-2" : "mr-1"
          } group-hover:visible`}
        >
          <div className="text-white text-xs">Sec</div>
        </div>
      </div>
    </div>
  );
}

export default MyDrops;
