import React from 'react';
import { useNavigate } from 'react-router-dom';
import './NotFound.scss';

const NotFound = () => {
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate('/');
  };
  return (
    <div className='NotFound'>
      <h1 className='text-white'>
        Page you are looking for doesn't exist.
      </h1>
      <button className='button-lotus redirect-btn text-purple' onClick={handleRedirect}>
        Click here to go to Home
      </button>
    </div>
  );
};

export default NotFound;