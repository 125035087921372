import {
  Timestamp,
  collection,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import DropsList from "pages/DropsList/DropsList";
import { db } from "config/firebase-config";
import { Loading } from "components/shared/Loading/Loading";
import { COUNTRIES_LIST } from "utils/constants";
import { getGenreTitleByKey } from "utils/helpers";

const Genre = () => {
  const params = useParams();
  const location = useLocation();
  const genre = location?.state?.genre ?? "";
  const [drops, setDrops] = useState(null);
  const [loading, setLoading] = useState(true);
  const dropsRef = collection(db, "drops");

  const countryFilters = JSON.parse(localStorage.getItem("filters")) || [];

  const qDrops = query(collection(db, "drops"), where("genre", "==", genre));

  const getAllItem = async (ref) => {
    const snapshot = await getDocs(ref);
    const filterList = [];
    snapshot.docs
      .map((doc) => {
        const data = doc.data();
        if (Timestamp.fromDate(new Date()) <= data?.expireTime) {
          filterList.push({ dropId: doc.id, ...doc.data() });
          return { dropId: doc.id, ...doc.data() };
        }
        return null;
      })
      .filter((val) => val !== null);

    return filterList.sort((a, b) => b.createdTime - a.createdTime);
  };

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const isExpired = params?.type === "expired";
        const ref = isExpired ? collection(db, "drops") : qDrops;

        const querySnapshotDrops = await getDocs(ref);
        let dropList = [];
        let expiredList = [];
        querySnapshotDrops.forEach((doc) => {
          const data = doc.data();
          if (Timestamp.fromDate(new Date()) <= data?.expireTime) {
            dropList.push({ ...data, dropId: doc?.id });
          } else {
            expiredList.push({ ...data, dropId: doc?.id, isExpired: true });
          }
        });

        let list = [];
        if (isExpired) dropList = expiredList;
        Object.keys(COUNTRIES_LIST).forEach((item) => {
          if (!countryFilters.includes(item))
            list = [...list, ...Object.keys(COUNTRIES_LIST[item])];
        });
        let filteredDrops = [];
        if (list?.length) {
          dropList.forEach((item) => {
            const country = item.regionalOffice.replaceAll(" ", "");
            if (list.includes(country)) filteredDrops.push(item);
          });
          filteredDrops.sort((a, b) => a.expireTime - b.expireTime);
          setDrops(filteredDrops);
        }
      } catch (error) {
        console.error("Error getting item:", error);
      } finally {
        setLoading(false);
      }
    };
    if (params?.type === "newdrops" || params?.type === "other")
      (async () => {
        let data = await getAllItem(dropsRef);

        let list = [];
        Object.keys(COUNTRIES_LIST).forEach((item) => {
          if (!countryFilters.includes(item))
            list = [...list, ...Object.keys(COUNTRIES_LIST[item])];
        });

        setDrops([]);
        if (list?.length) {
          let filteredDrops = [];
          data.forEach((item) => {
            const country = item.regionalOffice.replaceAll(" ", "");
            if (list.includes(country)) filteredDrops.push(item);
          });
          filteredDrops.sort((a, b) => a.expireTime - b.expireTime);
          if (params.type === "other") {
            const genreCountMap = filteredDrops?.reduce((map, item) => {
              map[item.genre] = (map[item?.genre] || 0) + 1;
              return map;
            }, {});
            // Use only drops that are unique by genre
            const otherDrops = filteredDrops?.filter(
              (item) => genreCountMap[item?.genre] === 1
            );
            setDrops(otherDrops);
          } else {
            setDrops(filteredDrops?.slice(0, 10));
          }
        }
        setLoading(false);
      })();
    else fetchData();
  }, []);

  if (loading) return <Loading />;
  return (
    <DropsList
      title={getGenreTitleByKey(params?.type)}
      tab=""
      data={drops}
      showArtwork={false}
    />
  );
};

export default Genre;
