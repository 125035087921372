export const InputRadio = ({ value, checked, ...rest }) => {
  return (
    <div {...rest} className="pr-2 py-2 justify-start items-center gap-2 flex">
      <div className="flex justify-center items-center w-5 h-5 relative border-2 border-purple rounded-full cursor-pointer">
        {checked ? (
          <div className="w-3 h-3 relative bg-purple rounded-full" />
        ) : null}
      </div>
      <span className="text-white text-[11px] lg:text-sm font-semibold leading-normal">
        {value}
      </span>
    </div>
  );
};
