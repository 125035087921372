import { useCallback, useEffect, useState } from "react";
import { DropForm } from "pages/AddDrop/DropForm";
import FileUpload from "components/FileUpload/FileUpload";
import uploadPlus from "assets/icons/upload-plus-icon.svg";
import { DetailsForm } from "pages/AddDrop/DetailsForm";
import PreviewForm from "pages/AddDrop/PreviewForm";
import DropSuccess from "pages/AddDrop/DropSuccess";
import arrowRight from "assets/icons/arrow-right-icon.svg";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { auth, db, storage } from "config/firebase-config";
import {
  Timestamp,
  addDoc,
  collection,
  doc,
  updateDoc,
} from "firebase/firestore";
import {
  adaptGenres,
  calculateTimeDifference,
  getAllCountriesObject,
  parseRequestQuantityPitch,
} from "utils/helpers";
import { Countdown } from "pages/SubmitDrop/SubmitDrop";
import { useBlocker, useParams } from "react-router-dom";
import { Modal } from "components/shared/Modal";
import loadingAnimation from "assets/icons/loading.svg";

const FIRST_STEP = [
  "artist",
  "artworkUrl",
  "expireTime",
  "recordLabel",
  "regionalOffice",
  "genre",
  "language",
];

const SECOND_STEP = [
  "artworkUrl",
  "publishingRequirement",
  "sections",
  // "requestedPitchQuantity",
];

const FORM_DEFAULT = {
  artworkUrl: "",
  artist: "",
  createdTime: "",
  expireTime: "",
  recordLabel: "",
  regionalOffice: "",
  genre: "",
  language: "",
  referenceLinks: null,
  publishingRequirement: null,
  requestedPitchQuantity: "",
  sections: null,
};

export function DropItems({ tabActive, setTabActive, data }) {
  const { id } = useParams();
  const [file, setFile] = useState("");
  const [sections, setSections] = useState(data?.sections || []);
  const [referenceLinks, setReferenceLinks] = useState(
    data?.referenceLinks || []
  );
  const [referenceLink, setReferenceLink] = useState({
    urlString: "",
    title: "",
  });
  const [formValues, setFormValues] = useState(data || FORM_DEFAULT);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const allCountriesObject = getAllCountriesObject();

  const countryExists = data
    ? allCountriesObject[adaptGenres(formValues.regionalOffice)]
    : allCountriesObject[adaptGenres(formValues.regionalOffice)] ===
      formValues.regionalOffice;

  const blockNavigation = useCallback(
    ({ currentLocation, nextLocation }) => {
      let isBlocking = false;
      for (let key in formValues) {
        if (formValues[key] || file?.length) {
          isBlocking = true;
        }
      }
      return isBlocking && currentLocation.pathname !== nextLocation.pathname;
    },
    [formValues, file]
  );

  let blocker = useBlocker(blockNavigation);

  useEffect(() => {
    if (blocker?.state === "blocked") setShowDeleteModal(true);
  }, [blocker]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "";
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const handleSubmit = async () => {
    const requestedPitchQuantity =
      typeof formValues?.requestedPitchQuantity === "number"
        ? formValues?.requestedPitchQuantity
        : parseRequestQuantityPitch(
            formValues?.requestedPitchQuantity || "0-100",
            formValues?.requestedPitchQuantity === "All"
              ? 0
              : Number(formValues?.requestedPitchQuantity)
          );
    const data = {
      ...formValues,
      sections: sections,
      referenceLinks: referenceLinks,
      requestedPitchQuantity: requestedPitchQuantity,
      publishingRequirement: formValues?.publishingRequirement || true,
      // This is just hot fix for afropop. Not sure if more cases like this. Should be same like in the ios app.
      genre: formValues?.genre === "Afro-Pop" ? "afropop" : adaptGenres(formValues?.genre),
      regionalOffice: adaptGenres(formValues?.regionalOffice),
      language:
        adaptGenres(formValues?.language)?.toLowerCase() ===
        "nonlookingforinstrumentals"
          ? "none"
          : adaptGenres(formValues?.language),
    };

    if (data && id) {
      editDrop(data);
      setTabActive(4);
    } else uploadFile(file[0], data);
  };

  const isFormFilledStep1 = FIRST_STEP.every(
    (value) => formValues[value] !== "" && countryExists
  );

  useEffect(() => {
    setFormValues((prev) => ({
      ...prev,
      artworkUrl: file?.length ? URL?.createObjectURL(file[0]) : "",
    }));
  }, [file]);

  const isFormFilledStep2 = SECOND_STEP.every(
    (value) =>
      formValues[value] !== "" &&
      formValues[value] !== undefined &&
      sections?.length &&
      sections[0]?.text
  );

  const addItem = async (item) => {
    const dropsRef = collection(db, "drops");
    return await addDoc(dropsRef, item);
  };

  const uploadFile = async (file, values, itemDoc) => {
    setIsLoading(true);
    const name = `drops/${new Date().getTime()}-${file.name}`;
    const storageRef = ref(storage, name);

    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
          default:
            break;
        }
      },
      (error) => {
        console.error("Upload Failed", error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref)
          .then(async (downloadURL) => {
            let data = { ...values };
            if (itemDoc) {
              data.artworkUrl = downloadURL;
              await updateDoc(itemDoc, data);
            } else {
              data.artworkUrl = downloadURL;
              data.createdTime = Timestamp.fromDate(new Date());
              data.expireTime = Timestamp.fromDate(data.expireTime);
              data.userId = auth?.currentUser?.uid;
              await addItem(data);
            }
          })
          .catch((error) => console.log("Error adding drop", error))
          .finally(() => {
            setIsLoading(false);
            setTabActive(4);
          });
      }
    );
  };

  const editDrop = async (data) => {
    const itemDoc = doc(db, "drops", id);
    data.genre = adaptGenres(formValues?.genre);
    data.regionalOffice = adaptGenres(formValues.regionalOffice);
    data.language =
      adaptGenres(formValues?.language)?.toLowerCase() ===
      "nonlookingforinstrumentals"
        ? "none"
        : adaptGenres(formValues?.language);

    if (file) await uploadFile(file[0], data, itemDoc);
    else await updateDoc(itemDoc, data);
  };

  useEffect(() => {
    if (data) {
      setFormValues(data);
      setSections(data?.sections);
      setReferenceLinks(data?.referenceLinks);
    }
  }, [data]);

  return (
    <div className="self-stretch flex-col justify-start items-center gap-8 flex max-w-[904px] mx-auto w-full">
      {showDeleteModal ? (
        <Modal setShowModal={setShowDeleteModal} title="Remove">
          <div className="flex flex-col gap-4">
            <p className="text-gray-50 text-base font-medium leading-normal mb-4 text-center">
              All data will be lost? <br />
              Are you sure you want to quit this drop?
            </p>
            <button
              onClick={() => {
                blocker?.proceed();
              }}
              className="w-full h-10 px-6 py-3 text-gray-50 font-bold bg-red-500 rounded-[90px] justify-center items-center gap-3 inline-flex"
            >
              Yes
            </button>
            <button
              onClick={() => {
                blocker?.reset();
                setShowDeleteModal(false);
              }}
              className="w-full h-10 px-6 py-3 text-gray-50 font-bold rounded-[90px] border-2 border-zinc-700 justify-center items-center gap-3 inline-flex"
            >
              Cancel
            </button>
          </div>
        </Modal>
      ) : null}
      <section className="mx-auto w-full">
        {tabActive < 3 ? (
          <CoverPreview
            key={formValues?.expireTime}
            file={file}
            image={formValues?.artworkUrl}
            setFile={setFile}
            endDate={
              formValues?.expireTime
                ? calculateTimeDifference(
                    Timestamp?.fromDate(new Date()),
                    Timestamp?.fromDate(formValues?.expireTime)
                  )
                : null
            }
          />
        ) : null}
        {tabActive === 1 ? (
          <DropForm setFormValues={setFormValues} formValues={formValues} />
        ) : null}
        {tabActive === 2 ? (
          <DetailsForm
            setFormValues={setFormValues}
            formValues={formValues}
            sections={sections}
            setSections={setSections}
            refLinks={referenceLinks}
            setReferenceLinks={setReferenceLinks}
            setReferenceLink={setReferenceLink}
            referenceLink={referenceLink}
          />
        ) : null}
        {tabActive === 3 ? (
          <PreviewForm
            setFormValues={setFormValues}
            sections={sections}
            formValues={formValues}
            image={file ? URL?.createObjectURL(file[0]) : data?.artworkUrl}
          />
        ) : null}
        {tabActive === 4 ? (
          <DropSuccess
            setFormValues={setFormValues}
            blocker={blocker}
            formValues={formValues}
            setFile={setFile}
            FORM_DEFAULT={FORM_DEFAULT}
            image={file ? URL?.createObjectURL(file[0]) : data?.artworkUrl}
            edit={data}
          />
        ) : null}
      </section>
      {tabActive < 4 && <div className="w-full h-px relative bg-zinc-700" />}
      <section className="flex justify-between w-full">
        {tabActive > 1 && tabActive < 4 ? (
          <button
            onClick={() => setTabActive((prev) => prev - 1)}
            className="w-[150px] h-10 px-6 py-3 rounded-[90px] border-2 border-zinc-700 justify-center items-center gap-3 inline-flex"
          >
            <img
              src={arrowRight}
              alt="arrow right"
              className="w-4 h-4 relative rotate-180"
            />
            <div className="text-right text-gray-50 text-base font-bold leading-none">
              Previous
            </div>
          </button>
        ) : null}
        {tabActive < 4 ? (
          <>
            {tabActive === 3 ? (
              <button
                disabled={isLoading}
                onClick={() => {
                  handleSubmit();
                }}
                type="submit"
                className="ml-auto px-6 py-3 bg-violet-600 rounded-[90px] justify-center items-center gap-3 flex h-10"
              >
                <div className="text-right text-gray-50 text-base font-bold leading-none">
                  {isLoading ? (
                    <img
                      src={loadingAnimation}
                      alt="loading"
                      className="w-12 h-[28px] brightness-[103%] invert-[99%] saturate-[6%] hue-rotate-[206deg] contrast-[104%]"
                    />
                  ) : (
                    "Submit"
                  )}
                </div>
              </button>
            ) : (
              <button
                onClick={() => setTabActive((prev) => prev + 1)}
                disabled={
                  (!isFormFilledStep1 && tabActive === 1) ||
                  (!isFormFilledStep2 && tabActive === 2)
                }
                type="button"
                className="ml-auto px-6 !py-3 bg-violet-600 disabled:bg-violet-950 rounded-[90px] justify-center items-center gap-3 flex"
              >
                <div
                  disabled={
                    (!isFormFilledStep1 && tabActive === 1) ||
                    (!isFormFilledStep2 && tabActive === 2)
                  }
                  className={`text-right ${
                    (!isFormFilledStep1 && tabActive === 1) ||
                    (!isFormFilledStep2 && tabActive === 2)
                      ? "text-gray-500"
                      : "text-gray-50"
                  } text-base font-bold leading-none`}
                >
                  Next
                </div>
                <img
                  src={arrowRight}
                  alt="arrow right"
                  className={`w-4 h-4 relative ${
                    (!isFormFilledStep1 && tabActive === 1) ||
                    (!isFormFilledStep2 && tabActive === 2)
                      ? "opacity-50"
                      : "opacity-100"
                  }`}
                />
              </button>
            )}
          </>
        ) : null}
      </section>
    </div>
  );
}

function CoverPreview({ file, image, setFile, endDate }) {
  const [seconds, setSeconds] = useState(endDate?.second);
  const [date, setDate] = useState(null);
  //TODO: Extract this to separate component to prevent re-rendering
  useEffect(() => {
    if (seconds > 0) {
      const timer = setTimeout(() => setSeconds(seconds - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      if (endDate?.minute && endDate?.minute > 0) {
        setSeconds(59);
        setDate((prev) => ({ ...prev, minute: endDate?.minute - 1 }));
      } else setSeconds("00");
    }
  }, [seconds]);

  useEffect(() => {
    setDate(endDate);
  }, [endDate]);

  return (
    <div className="self-stretch h-[548px] lg:h-[388px] flex-col justify-start items-center gap-4 flex">
      {image || file?.length ? (
        <FileUpload
          setFile={setFile}
          className="max-w-[426px] lg:max-w-[260px] w-full h-[426px] lg:!h-[260px] relative rounded-xl border border-violet-600 overflow-hidden hover:shadow-[0_0_20px_0_rgba(108,40,255,1)]"
        >
          <div className="text-white text-[27.76px] font-extrabold absolute top-4 left-4 leading-[41.63px]"></div>
          <img
            src={image ? image : file?.length && URL?.createObjectURL(file[0])}
            alt="preview cover art"
            className="h-full w-full object-cover"
          />
        </FileUpload>
      ) : (
        <FileUpload
          setFile={setFile}
          className="max-w-[426px] lg:max-w-[260px] w-full px-20 lg:px-8 flex flex-col justify-between py-12 lg:py-8 h-[426px] lg:!h-[260px] relative rounded-xl border border-violet-600 hover:shadow-[0_0_20px_0_rgba(108,40,255,1)]"
        >
          <div className="top-[21px] text-gray-50 text-base font-bold text-center w-full mb-16 lg:mb-4">
            Upload cover art
          </div>
          <div className="left-[132.34px] top-[18px] text-[#777E90] text-xl lg:text-base font-bold text-center w-full mb-2">
            Only add official Artist/Project Cover art
          </div>
          <div className="flex-col justify-center items-center gap-2 inline-flex">
            <img
              src={uploadPlus}
              alt="upload plus"
              className="w-20 h-20 lg:w-12 lg:h-12 relative"
            />
            <div className="w-full text-center text-[#777E90] text-base lg:text-sm font-semibold">
              Drag and Drop or Select from Your Device
            </div>
          </div>
          <div className="left-[139.34px] top-[314px] text-center text-[#777E90] text-sm mt-2">
            Upload PNG or JPG file
          </div>
        </FileUpload>
      )}

      <div className="max-w-[421px] lg:max-w-[260px] w-full p-2 lg:!py-1 rounded-2xl border border-violet-600 justify-center items-center gap-2.5 inline-flex">
        {image || file?.length ? (
          <img
            src={image ? image : file?.length && URL?.createObjectURL(file[0])}
            alt="preview thumbnail art"
            className="w-20 h-20 lg:w-14 lg:h-14 relative border border-purple rounded-[10.41px] object-cover"
          />
        ) : (
          <div className="w-20 h-20 lg:w-14 lg:h-14 relative border border-purple rounded-[10.41px]" />
        )}

        <Countdown seconds={seconds} date={endDate} minute={date?.minute} />
      </div>
    </div>
  );
}
