/* const Login = lazy(async () => await import("pages/Login/Login")); */
import SignIn from "pages/SignIn/SignIn";
import SignUp from "pages/SignUp/SignUp";
import NotFound from "pages/NotFound/NotFound";
import Home from "pages/Home/Home";
import Drop from "pages/Drop/Drop";
import MyPitches from "pages/MyPitches/MyPitches";
import Favorites from "pages/Favorites/Favorites";
import Genre from "pages/Home/Genre/Genre";
import Pitch from "pages/Pitch/Pitch";
import PitchLearn from "pages/PitchLearn/PitchLearn";
import Account from "pages/Account/Account";
import AddDrop from "pages/AddDrop/AddDrop";
import SubmitDrop from "pages/SubmitDrop/SubmitDrop";
import Wallet from "pages/Wallet/Wallet";
import PitchingView from "pages/PitchingView/PitchingView";
import { ROLES_LIST } from "./constants";
import MyDrops from "pages/MyDrops/MyDrops";
import MyInbox from "pages/MyInbox/MyInbox";
import SuccessPayment from "pages/PaymentStatus/SuccessPayment";
import CancelPayment from "pages/PaymentStatus/CancelPayment";
import StripeVerification from "pages/StripeStatus/StripeVerification";
import EditDrop from "pages/EditDrop/EditDrop";
import ForgotPassword from "pages/ForgotPassword/ForgotPassword";
import HomePage from "landing/pages/HomePage/HomePage";
import HowToPage from "landing/pages/HowToPage/HowToPage";
import TermsAndConditionsPage from "landing/pages/TermsAndConditionsPage/TermsAndConditionsPage";
import PrivacyAndCookies from "landing/components/PrivacyAndCookies/PrivacyAndCookies";
import About from "landing/pages/About/About";
import Contact from "landing/pages/Contact/Contact";
import { SoundTrap } from "landing/pages/SoundTrap/SoundTrap";

export const routes = [
  {
    path: "*",
    name: "NotFound",
    exact: false,
    component: NotFound,
    protected: true,
  },
  {
    path: "/signup",
    name: "Login",
    exact: true,
    component: SignUp,
    protected: false,
  },

  {
    path: "/forgotpassword",
    name: "ForgotPassword",
    exact: true,
    component: ForgotPassword,
    protected: false,
  },
  {
    path: "/login",
    name: "Login",
    exact: true,
    component: SignIn,
    protected: false,
  },
  {
    path: "/home",
    name: "LandingPage",
    exact: true,
    component: HomePage,
    protected: false,
  },
  {
    path: "/howto",
    name: "HowToPage",
    exact: true,
    component: HowToPage,
    protected: false,
  },
  {
    path: "/termsconditions",
    name: "TermsAndConditionsPage",
    exact: true,
    component: TermsAndConditionsPage,
    protected: false,
  },
  {
    path: "/privacycookies",
    name: "PrivacyAndCookies",
    exact: true,
    component: PrivacyAndCookies,
    protected: false,
  },
  {
    path: "/about",
    name: "About",
    exact: true,
    component: About,
    protected: false,
  },
  {
    path: "/contact",
    name: "Contact",
    exact: true,
    component: Contact,
    protected: false,
  },
  {
    path: "/soundtrap",
    name: "SoundTrap",
    exact: true,
    component: SoundTrap,
    protected: false,
  },
  {
    path: "/",
    name: "Home",
    exact: true,
    component: Home,
    protected: true,
    role: [
      ROLES_LIST.PRODUCER,
      ROLES_LIST.SONG_WRITER,
      ROLES_LIST.SONG_WRITER_PRODUCER,
      ROLES_LIST.ANR,
      ROLES_LIST.MANAGER,
      ROLES_LIST.SUPERVISOR,
      ROLES_LIST.CORPORATE,
      ROLES_LIST.SCREENER,
    ],
  },
  {
    path: "/drop/:id",
    name: "Drop",
    exact: true,
    component: Drop,
    protected: true,
    role: [
      ROLES_LIST.PRODUCER,
      ROLES_LIST.SONG_WRITER,
      ROLES_LIST.SONG_WRITER_PRODUCER,
      ROLES_LIST.ANR,
      ROLES_LIST.MANAGER,
      ROLES_LIST.SUPERVISOR,
      ROLES_LIST.CORPORATE,
    ],
  },
  {
    path: "/drop/:id/submit",
    name: "SubmitDrop",
    exact: true,
    component: SubmitDrop,
    protected: true,
    role: [
      ROLES_LIST.PRODUCER,
      ROLES_LIST.SONG_WRITER,
      ROLES_LIST.SONG_WRITER_PRODUCER,
    ],
  },
  {
    path: "/demo/:demoId/success",
    name: "SubmitDropSuccess",
    exact: true,
    component: SuccessPayment,
    protected: true,
    role: [
      ROLES_LIST.PRODUCER,
      ROLES_LIST.SONG_WRITER,
      ROLES_LIST.SONG_WRITER_PRODUCER,
    ],
  },
  {
    path: "/demo/:demoId/cancel",
    name: "SubmitDropSuccess",
    exact: true,
    component: CancelPayment,
    protected: true,
    role: [
      ROLES_LIST.PRODUCER,
      ROLES_LIST.SONG_WRITER,
      ROLES_LIST.SONG_WRITER_PRODUCER,
    ],
  },
  {
    path: "/drop/add",
    name: "AddDrop",
    exact: true,
    component: AddDrop,
    protected: true,
    role: [
      ROLES_LIST.ANR,
      ROLES_LIST.MANAGER,
      ROLES_LIST.SUPERVISOR,
      ROLES_LIST.CORPORATE,
    ],
  },
  {
    path: "/drop/edit/:id",
    name: "EditDrop",
    exact: true,
    component: EditDrop,
    protected: true,
    role: [
      ROLES_LIST.ANR,
      ROLES_LIST.MANAGER,
      ROLES_LIST.SUPERVISOR,
      ROLES_LIST.CORPORATE,
    ],
  },
  {
    path: "/drop/pitch/:id",
    name: "PitchingView",
    exact: true,
    component: PitchingView,
    protected: true,
    role: [
      ROLES_LIST.ANR,
      ROLES_LIST.MANAGER,
      ROLES_LIST.SUPERVISOR,
      ROLES_LIST.CORPORATE,
      ROLES_LIST.SCREENER,
    ],
  },
  {
    path: "/drop/mydrops",
    name: "MyDrops",
    exact: true,
    component: MyDrops,
    protected: true,
    role: [
      ROLES_LIST.ANR,
      ROLES_LIST.MANAGER,
      ROLES_LIST.SUPERVISOR,
      ROLES_LIST.CORPORATE,
    ],
  },
  {
    path: "/drop/myinbox",
    name: "MyInbox",
    exact: true,
    component: MyInbox,
    protected: true,
    role: [
      ROLES_LIST.ANR,
      ROLES_LIST.MANAGER,
      ROLES_LIST.SUPERVISOR,
      ROLES_LIST.CORPORATE,
    ],
  },
  {
    path: "/genre/:type",
    name: "Genre",
    exact: true,
    component: Genre,
    protected: true,
    role: [
      ROLES_LIST.ANR,
      ROLES_LIST.MANAGER,
      ROLES_LIST.SUPERVISOR,
      ROLES_LIST.CORPORATE,
      ROLES_LIST.PRODUCER,
      ROLES_LIST.SONG_WRITER,
      ROLES_LIST.SONG_WRITER_PRODUCER,
    ],
  },
  {
    path: "/favorites",
    name: "Favorites",
    exact: true,
    component: Favorites,
    protected: true,
    role: [
      ROLES_LIST.PRODUCER,
      ROLES_LIST.SONG_WRITER,
      ROLES_LIST.SONG_WRITER_PRODUCER,
    ],
  },
  {
    path: "/my-pitches",
    name: "MyPitches",
    exact: true,
    component: MyPitches,
    protected: true,
    role: [
      ROLES_LIST.PRODUCER,
      ROLES_LIST.SONG_WRITER,
      ROLES_LIST.SONG_WRITER_PRODUCER,
    ],
  },
  {
    path: "/my-pitches/learn",
    name: "PitchLearn",
    exact: true,
    component: PitchLearn,
    protected: true,
    role: [
      ROLES_LIST.PRODUCER,
      ROLES_LIST.SONG_WRITER,
      ROLES_LIST.SONG_WRITER_PRODUCER,
    ],
  },
  {
    path: "/my-pitches/:id/:pitchId",
    name: "Pitch",
    exact: true,
    component: Pitch,
    protected: true,
    role: [
      ROLES_LIST.PRODUCER,
      ROLES_LIST.SONG_WRITER,
      ROLES_LIST.SONG_WRITER_PRODUCER,
    ],
  },
  {
    path: "/wallet",
    name: "Wallet",
    exact: true,
    component: Wallet,
    protected: true,
    role: [ROLES_LIST.SCREENER],
  },
  {
    path: "/account",
    name: "Account",
    exact: true,
    component: Account,
    protected: true,
    role: [
      ROLES_LIST.PRODUCER,
      ROLES_LIST.SONG_WRITER,
      ROLES_LIST.SONG_WRITER_PRODUCER,
      ROLES_LIST.ANR,
      ROLES_LIST.MANAGER,
      ROLES_LIST.SUPERVISOR,
      ROLES_LIST.CORPORATE,
      ROLES_LIST.SCREENER,
    ],
  },
  {
    path: "/stripe/verification/:id",
    name: "StripeVerification",
    exact: true,
    component: StripeVerification,
    protected: true,
    role: [
      ROLES_LIST.ANR,
      ROLES_LIST.MANAGER,
      ROLES_LIST.SUPERVISOR,
      ROLES_LIST.CORPORATE,
      ROLES_LIST.SCREENER,
    ],
  },
];

export default routes;
