import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import { InputValidation } from "components/shared/InputValidation/InputValidation";
import Login from "pages/Login/Login";
import { auth } from "config/firebase-config";
import { handleRedirectToApp, parseFirebaseErrors } from "utils/helpers";
import loadingAnimation from "assets/icons/loading.svg";
import homeIcon from "assets/icons/home-icon.svg";

function SignInForm() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSignIn = async () => {
    try {
      setLoading(true);
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      setError(
        parseFirebaseErrors(error?.code) || "Can't sign in, try again please!"
      );
      console.error("Error: ", error?.code);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="flex justify-between max-w-7xl w-full md:mx-auto mt-16 -mb-16 pr-3">
        <button
          className="flex cursor-pointer py-2 px-4 rounded-3xl top-5 right-5 items-center"
          onClick={() => handleRedirectToApp("/home")}
        >
          <img
            src={homeIcon}
            alt="home"
            className="flex w-6 h-6 cursor-pointer"
          />
        </button>
        <button
          className="text-center text-sm cursor-pointer bg-purple py-2 px-4 rounded-3xl top-5 right-5 text-white hover:shadow-[0_0_20px_0_rgba(108,40,255,1)]"
          onClick={() => navigate("/signup")}
        >
          Sign Up
        </button>
      </div>
      <Login className={`lg:mt-16`}>
        <div className="font-extrabold text-2xl text-white mb-6 text-center">
          Login
        </div>
        <div className="grid grid-cols-1 gap-4">
          <InputValidation
            errorMsg={error}
            type="email"
            placeholder="Email address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className={`border h-12`}
          />
          <div className="flex flex-col gap-2">
            <div className="password-container">
              <InputValidation
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                showIcon={true}
                className={`h-12`}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    handleSignIn();
                  }
                }}
              />
            </div>
          </div>
        </div>
        <button
          className="text-center bg-purple w-full text-white rounded-full py-[10px] text-lg mt-4 flex gap-2 justify-center"
          onClick={handleSignIn}
        >
          {loading ? (
            <img
              src={loadingAnimation}
              alt="loading"
              className="w-8 h-[28px] brightness-[103%] invert-[99%] saturate-[6%] hue-rotate-[206deg] contrast-[104%]"
            />
          ) : (
            "Sign In"
          )}
        </button>
        <button
          className="text-right text-[#707070] text-xs mt-4 cursor-pointer hover:text-white"
          onClick={() => navigate("/forgotpassword")}
        >
          Forgot Password?
        </button>
      </Login>
    </>
  );
}

export default SignInForm;
