import React, { useEffect, useState } from "react";
import openEye from "assets/icons/eye-open.svg";
import closedEye from "assets/icons/eye-closed.svg";
import { Input } from "components/shared/Input/Input";

export function InputValidation({
  children,
  errorMsg,
  showIcon,
  onClick,
  src,
  ...rest
}) {
  const [showPassword, setShowPassword] = useState(false);
  const [type, setShowType] = useState(rest.type);

  useEffect(() => {
    if (rest.type === "password") {
      setShowType(showPassword ? "text" : "password");
    } else {
      setShowType(rest.type || "text");
    }
  }, [showPassword, rest]);

  const disableAutoZoom = () => {
    document.body.style.zoom = "1";
  };

  return (
    <div className={`flex flex-col w-full gap-2`}>
      <div className="flex w-full items-center gap-2">
        <Input
          {...rest}
          type={type}
          className={`${errorMsg && "tw-input-error"} ${rest.className}`}
          onBlur={disableAutoZoom}
          onFocus={disableAutoZoom}
        />
        {showIcon && (
          <>
            {showPassword ? (
              <img
                onClick={() => {
                  setShowPassword(false);
                  rest.type = "password";
                }}
                src={openEye}
                alt="eye icon"
                className="-ml-12 cursor-pointer h-fit w-[20px] h-[20px]"
              />
            ) : (
              <img
                onClick={() => setShowPassword(true)}
                src={closedEye}
                alt="closed eye icon"
                className="-ml-12 cursor-pointer h-fit w-[20px] h-[20px]"
              />
            )}
          </>
        )}
      </div>
      {errorMsg && (
        <span className="text-error text-left text-sm">{errorMsg}</span>
      )}
    </div>
  );
}
