import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import outlineStarIcon from "assets/icons/star-outline-icon.svg";
import starIcon from "assets/icons/yellow-star-icon.svg";
import dropImg from "assets/images/drop-preview.png";
import { displayDate, handleFavorites } from "utils/helpers";

export function CardItem({
  title,
  name,
  startDate,
  endDate,
  image,
  id,
  className,
  showFavorites = true,
  data,
  onClickItemFavorite = () => {},
  expired = false,
}) {
  dayjs.extend(duration);
  const navigate = useNavigate();
  const [isFavorite, setIsFavorite] = useState(false);
  const [date, setDate] = useState(null);
  const [time, setTime] = useState(null);

  const handleIsFavorite = (e) => {
    e.stopPropagation();
    handleFavorites(data);
    setIsFavorite((prev) => !prev);
    onClickItemFavorite(id);
  };

  function calculateTimeDifference(startDate, endDate) {
    // Parsing the dates using Day.js
    if (typeof startDate === "object") {
      startDate = new Date();
      if (endDate?.toDate) endDate = new Date(endDate?.toDate());
      else endDate = new Date(endDate);
    }
    const dateCreated = new Date(startDate);
    const dateExpire = endDate;
    const start = dayjs(dateCreated);
    const end = dayjs(dateExpire);
    // Calculating the duration between the two dates
    const duration = dayjs.duration(end.diff(start));

    // Getting the duration in days, hours, minutes, and seconds
    const days = duration?.months()
      ? Math.round(duration?.asDays())
      : duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    // Formatting to ensure not to exceed 60 seconds, minutes, or hours
    const formattedDays = days;
    const formattedHours = hours % 24;
    const formattedMinutes = minutes % 60;
    const formattedSeconds = seconds % 60;

    setTime(seconds);
    return {
      day: formattedDays,
      hour: formattedHours,
      minute: formattedMinutes,
      second: formattedSeconds,
    };
  }

  useEffect(() => {
    const favorites = localStorage.getItem("favorites");
    if (favorites) {
      const list = JSON.parse(favorites);
      if (list[id]) setIsFavorite(true);
    }
  }, [id, title]);

  //TODO: Extract this to separate component to prevent re-rendering
  useEffect(() => {
    if (time > 0) {
      if (time === 0) {
      }
      const timer = setTimeout(() => setTime(time - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      if (date?.minute && date?.minute > 0) {
        setTime(59);
        setDate((prev) => ({ ...prev, minute: prev.minute - 1 }));
      } else setTime("00");
    }
  }, [time]);

  useEffect(() => {
    const dateList = calculateTimeDifference(startDate, endDate);
    setDate(dateList);
  }, []);

  return (
    <div
      className={`${
        className || ""
      } w-[190px] relative h-full group cursor-pointer hover:bg-black hover:bg-opacity-50 rounded-3xl`}
      onClick={() => !expired && navigate(`/drop/${id}`)}
    >
      <p className="px-2 py-3 font-extrabold text-white text-left truncate capitalize">
        {title}
      </p>
      <img
        src={image || dropImg}
        alt="drop"
        className="absolute w-full h-full object-cover -z-10 top-0 border-2 border-solid border-transparent group-hover:border-purple rounded-3xl box-border transition-all duration-300 pb-[62px]"
      />
      <div
        className="grid grid-cols-4 bg-black bg-opacity-75 h-16 w-full mt-[150px] backdrop-blur-xl px-2 py-3"
        onClick={(e) => e.stopPropagation()}
      >
        <img
          src={image || dropImg}
          alt="profile"
          className="w-11 h-11 bg-center rounded-md object-cover"
        />
        <div className="col-span-2 text-left w-full text-white ml-2">
          <p className="text-xs truncate capitalize">{name}</p>
          <div className="flex gap-2 text-xs">
            <div className="flex flex-col">
              <p className="font-digitalnumbers">
                {expired ? "00" : displayDate(date?.day) || "00"}
              </p>
              <p className="text-[8px]">Day</p>
            </div>
            <div className="flex flex-col">
              <p className="font-digitalnumbers">
                {expired ? "00" : displayDate(date?.hour) || "00"}
              </p>
              <p className="text-[8px]">Hour</p>
            </div>
            <div className="flex flex-col">
              <p className="font-digitalnumbers">
                {expired ? "00" : displayDate(date?.minute) || "00"}
              </p>
              <p className="text-[8px]">Min</p>
            </div>
            <div className="flex flex-col">
              {/*  invisible group-hover:visible */}
              <p className="font-digitalnumbers">{expired ? "00" : time}</p>
              <p className="text-[8px]">Sec</p>
            </div>
          </div>
        </div>
        {showFavorites && (
          <div className="flex items-center justify-end">
            <img
              draggable="false"
              src={isFavorite ? starIcon : outlineStarIcon}
              alt="favorites"
              className="favorites w-4 h-4 cursor-pointer"
              onClick={handleIsFavorite}
            />
          </div>
        )}
      </div>
    </div>
  );
}
