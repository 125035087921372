import instagramIcon from "assets/icons/instagram-icon.svg";
import facebookIcon from "assets/icons/facebook-icon.svg";
import youtubeIcon from "assets/icons/youtube-icon.svg";
import { handleRedirectToApp } from "utils/helpers";

export function Footer() {
  return (
    <>
      <div className="text-white text-left">
        <div className="flex flex-col lg:flex-row justify-between mx-8 lg:mx-36 mt-12">
          <div className="flex flex-col lg:hidden my-8">
            <p className="text-sm mb-3">Join our community</p>
            <div className="flex gap-4">
              <a
                href="https://www.instagram.com/velv.music/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={instagramIcon}
                  alt="instagram"
                  className="cursor-pointer h-4"
                />
              </a>
              <a
                href="https://www.facebook.com/Velvmusic"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={facebookIcon}
                  alt="instagram"
                  className="cursor-pointer h-4"
                />
              </a>
              <a
                href="https://youtube.com/@VELVMUSIC?si=o0qZuxcgtMJqOhgc"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={youtubeIcon}
                  alt="instagram"
                  className="cursor-pointer h-4"
                />
              </a>
            </div>
          </div>
          <div className="flex gap-11">
            <div>
              <h1 className="text-base mb-3 lg:mb-6 font-normal">
                Quick Links
              </h1>
              <ul className="text-base font-normal text-[#ADADAD] flex flex-col gap-3">
                <li
                  className="cursor-pointer"
                  onClick={() => handleRedirectToApp("/")}
                >
                  Home
                </li>
                <li className="cursor-pointer"
                  onClick={() => handleRedirectToApp("/about")}>About</li>
                <li
                  className="cursor-pointer"
                  onClick={() => handleRedirectToApp("/howto")}
                >
                  How To
                </li>
                <li
                  className="cursor-pointer flex lg:hidden"
                  onClick={() => handleRedirectToApp("/termsconditions")}
                >
                  Legal
                </li>
                <li
                  className="cursor-pointer flex lg:hidden"
                  onClick={() => handleRedirectToApp("/contact")}
                >
                  Contact
                </li>
              </ul>
            </div>
            <div className="hidden lg:flex lg:flex-col">
              <h1 className="text-base mb-6 font-semibold opacity-0">
                Quick Links
              </h1>
              <ul className="text-base text-[#ADADAD] flex flex-col gap-3">
                <li
                  className="cursor-pointer"
                  onClick={() => handleRedirectToApp("/termsconditions")}
                >
                  Legal
                </li>
                <li
                  className="cursor-pointer"
                  onClick={() => handleRedirectToApp("/contact")}
                >
                  Contact
                </li>
              </ul>
            </div>
          </div>
          <div className="hidden lg:flex lg:flex-col">
            <h1 className="text-base mb-6 font-semibold">Social Media</h1>
            <div className="flex gap-4">
              <a
                href="https://www.instagram.com/velv.music/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={instagramIcon}
                  alt="instagram"
                  className="cursor-pointer h-4"
                />
              </a>
              <a
                href="https://www.facebook.com/Velvmusic"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={facebookIcon}
                  alt="instagram"
                  className="cursor-pointer h-4"
                />
              </a>
              <a
                href="https://youtube.com/@VELVMUSIC?si=o0qZuxcgtMJqOhgc"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={youtubeIcon}
                  alt="instagram"
                  className="cursor-pointer h-4"
                />
              </a>
            </div>
          </div>
          <div className="hidden lg:flex lg:flex-col">
            <h1 className="text-base mb-6 font-semibold">Contact</h1>
            <p className="text-base mb-6">info@velvmusic.com</p>
            <p className="text-base mb-6">support@velvmusic.com</p>
          </div>
        </div>
      </div>
      <hr className="border-gray-700 mt-28 mb-11" />
      <div className="text-gray-400 mb-16 font-normal text-base">
        <div className="flex justify-between px-8 lg:mx-40 w-full lg:w-auto ">
          <p>©{new Date().getFullYear()} Velv. All rights reserved</p>
          <div className="cursor-pointer hidden lg:flex gap-2">
            <div
              onClick={() => handleRedirectToApp("/privacycookies")}
              className="hover:text-blue-100"
            >
              Privacy Policy
            </div>
            |
            <div
              onClick={() => handleRedirectToApp("/termsconditions")}
              className="hover:text-blue-100"
            >
              Term & Conditions
            </div>
            |
          </div>
        </div>
      </div>
    </>
  );
}
