import { useEffect, useState } from "react";
import dayjs from "dayjs";
import {
  Timestamp,
  collection,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import relativeTime from "dayjs/plugin/relativeTime";
import duration from "dayjs/plugin/duration";
import { CardsList } from "components/shared/CardsList/CardsList";
import { Header } from "components/shared/Header/Header";
import { db } from "config/firebase-config";
import loadingAnimation from "assets/icons/loading.svg";
import { limit } from "firebase/firestore";
import { COUNTRIES_LIST } from "utils/constants";
import EmptyState from "components/shared/EmptyState";
import { getGenreTitleByKey } from "utils/helpers";

const ALL_COUNTRIES_NUM = 8;

export function ProducerView({ title, setHeroImage }) {
  const [drops, setDrops] = useState(null);
  const [genres, setGenres] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [allGenres, setAllGenres] = useState(null);
  const [allDrops, setAllDrops] = useState(null);
  const [other, setOther] = useState([]);

  const countryFilters = JSON.parse(localStorage.getItem("filters")) || [];

  const dropsRef = collection(db, "drops");
  const qDemos = query(
    collection(db, "drops"),
    where("genre", "==", "hiphopRap"),
    limit(20)
  );

  const getAllItem = async (ref) => {
    const snapshot = await getDocs(ref);
    const filterList = [];
    const allDrops = [];

    snapshot.docs
      .map((doc) => {
        const data = doc.data();
        if (Timestamp.fromDate(new Date()) <= data?.expireTime) {
          filterList.push({ dropId: doc.id, ...doc.data() });
          return { dropId: doc.id, ...doc.data() };
        } else {
          allDrops.push({ dropId: doc.id, isExpired: true, ...doc.data() });
        }
        return null;
      })
      .filter((val) => val !== null);

    setAllDrops(allDrops);
    return filterList.sort((a, b) => b.createdTime - a.createdTime);
  };

  const filterByGenres = (filterList) => {
    const groupedByGenre = filterList.reduce((acc, currentItem) => {
      const { genre } = currentItem;

      if (!acc[genre]) {
        acc[genre] = [];
      }
      acc[genre].push(currentItem);
      return acc;
    }, {});
    let othersList = [];
    const filteredEntries = Object.entries(groupedByGenre).filter(
      ([_, items]) => {
        if (items.length < 2) othersList = [...othersList, ...items];
        return items.length > 1;
      }
    );
    setOther([...othersList]);

    // Sort the filtered array of [key, value] pairs by the length of the value (array)
    filteredEntries.sort((a, b) => b[1].length - a[1].length);

    // Convert the sorted array back to an object
    const sortedGroupedByGenre = Object.fromEntries(filteredEntries);

    setAllGenres(sortedGroupedByGenre);
  };

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        dayjs.extend(relativeTime);
        dayjs.extend(duration);
        const dropsQuery = query(dropsRef);
        let data = await getAllItem(dropsQuery);
        // data = data.slice(0, 10);
        let filteredDrops = [];
        let list = [];
        Object.keys(COUNTRIES_LIST).forEach((item) => {
          if (!countryFilters?.includes(item))
            list = [...list, ...Object.keys(COUNTRIES_LIST[item])];
        });

        data.forEach((item) => {
          const country = item.regionalOffice.replaceAll(" ", "");
          if (list.includes(country)) filteredDrops.push(item);
        });

        if (filteredDrops) setHeroImage(filteredDrops[0]?.artworkUrl || "");
        filterByGenres(filteredDrops);
        filteredDrops = filteredDrops.slice(0, 20);
        setDrops(filteredDrops);
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (drops?.length)
        setCurrentIndex((prevIndex) => (prevIndex + 1) % drops?.length);
    }, 10000); // Every ten seconds we change the hero image
    if (drops?.length) {
      setHeroImage(drops[currentIndex || 0]?.artworkUrl);
    }
    return () => clearInterval(intervalId);
  }, [drops, currentIndex, setHeroImage]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const querySnapshotDemos = await getDocs(qDemos);
        let demoListDemos = [];
        querySnapshotDemos.forEach((doc) => {
          const data = doc.data();

          if (Timestamp.fromDate(new Date()) <= data?.expireTime) {
            demoListDemos.push({ ...data, dropId: doc?.id });
          }
        });

        let filteredDemos = [];
        let list = [];
        if (countryFilters !== null) {
          countryFilters?.forEach((item) => {
            list = [...list, ...Object.keys(COUNTRIES_LIST[item])];
          });
        }
        demoListDemos = demoListDemos.slice(0, 20);
        if (list?.length) {
          demoListDemos.forEach((item) => {
            const country = item.regionalOffice.replaceAll(" ", "");
            if (list.includes(country) && list?.length)
              filteredDemos.push(item);
          });
          setGenres(filteredDemos);
        } else setGenres(demoListDemos);
      } catch (error) {
        console.error("Error getting item:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <Header tab="drops" />
      <div className="mt-16">
        {isLoading ? (
          <div className="rounded-3xl h-[334.50px] w-full flex justify-center items-center flex-col text-white">
            <img
              src={loadingAnimation}
              alt="loading"
              className="text-white w-20"
            />
            <p>Loading...</p>
          </div>
        ) : (
          <>
            {countryFilters?.length === ALL_COUNTRIES_NUM ? (
              <EmptyState title={"Please select filters to view drops"} />
            ) : drops?.length ? (
              <>
                <CardsList
                  title={"New Drops"}
                  data={drops?.slice(0, 10)}
                  isLoading={isLoading}
                  isFiltered={false}
                />

                {allGenres && Object.values(allGenres)?.length
                  ? Object.values(allGenres)?.map((item, index) => (
                      <div key={item?.id + index}>
                        <CardsList
                          title={getGenreTitleByKey(item[0]?.genre)}
                          data={item}
                        />
                      </div>
                    ))
                  : null}
                {other?.length ? (
                  <CardsList title={"Other"} data={other} />
                ) : null}
                <div>
                  <CardsList title={"Expired"} data={allDrops} />
                </div>
              </>
            ) : (
              <EmptyState title={"No drops to show"} />
            )}
          </>
        )}
      </div>
    </>
  );
}
