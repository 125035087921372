import { useRef, useState } from "react";
import loadingAnimation from "assets/icons/loading.svg";
import {
  ANR_TERMS_LIST,
  PRODUCER_TERMS_LIST,
  SCREENER_TERMS_LIST,
} from "utils/constants";

export function AcceptTermsAndConditions({
  handleSignUp,
  error,
  loading,
  role = "producer",
}) {
  const contentRef = useRef(null);
  const [scrolledToBottom, setScrolledToBottom] = useState(false);

  let ROLE_TERMS = null;
  switch (role) {
    case "producer":
    case "songWriter":
    case "songWriterProducer":
      ROLE_TERMS = PRODUCER_TERMS_LIST;
      break;
    case "screener":
      ROLE_TERMS = SCREENER_TERMS_LIST;
      break;
    default:
      ROLE_TERMS = ANR_TERMS_LIST;
  }

  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = contentRef.current;
    const isScrolledToBottom = scrollTop + clientHeight >= scrollHeight;
    if (isScrolledToBottom && !scrolledToBottom) {
      setScrolledToBottom(true);
    }
  };
  return (
    <>
      <style>
        {`
          ::-webkit-scrollbar {
            width: 8px;
          }
          ::-webkit-scrollbar-track {
            background: transparent;
          }
          ::-webkit-scrollbar-thumb {
            background: #888;
            border-radius: 20px;
          }
        `}
      </style>
      <div
        className="max-h-96 overflow-y-auto flex flex-col gap-4 pr-3 mt-2"
        ref={contentRef}
        onScroll={handleScroll}
      >
        {ROLE_TERMS.map((item, idx) => (
          <div key={idx} className="text-white">
            <h3 className="text-base font-bold">{item?.title}</h3>
            <p className="text-xs">{item?.body}</p>
          </div>
        ))}
      </div>
      <button
        onClick={handleSignUp}
        disabled={error?.length || !scrolledToBottom}
        className={`${
          error?.length || !scrolledToBottom
            ? "bg-gray-700 cursor-not-allowed text-gray-500"
            : "bg-purple text-white"
        } text-center rounded-full py-3 text-base flex justify-center leading-normal mt-6`}
      >
        {loading ? (
          <img
            src={loadingAnimation}
            alt="loading"
            className="w-8 h-[28px] brightness-[103%] invert-[99%] saturate-[6%] hue-rotate-[206deg] contrast-[104%]"
          />
        ) : (
          "Accept & Sign up"
        )}
      </button>
    </>
  );
}
