import WidthWrapper from "landing/components/shared/WidthWrapper/WidthWrapper";
import ScrollToTop from "landing/helpers/ScrollToTop";
import instagramIcon from "landing/assets/icons/instagram-icon.svg";
import facebookIcon from "landing/assets/icons/facebook-icon.svg";
import youtubeIcon from "landing/assets/icons/youtube-icon.svg";
import { useEffect, useState } from "react";
import loadingAnimation from "landing/assets/icons/loading.svg";
import Header from "landing/components/Header/Header";

const CONTACT_FORM_SENT_MESSAGE_DURATION = 10000;

function Contact() {
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(null);
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [validationErrors, setValidationErrors] = useState({
    name: "",
    email: "",
    message: "",
  });

  const validateForm = () => {
    let isValid = true;
    const errors = {};

    if (!formValues?.name?.trim()) {
      errors.name = "Name is required.";
      isValid = false;
    }
    if (!formValues?.email?.trim()) {
      errors.email = "Email is required.";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formValues?.email)) {
      errors.email = "Email is not valid.";
      isValid = false;
    }
    if (!formValues?.message?.trim()) {
      errors.message = "Message is required.";
      isValid = false;
    }

    setValidationErrors(errors);
    return isValid;
  };

  const resetForm = () => {
    setFormValues({
      name: "",
      email: "",
      message: "",
    });
    setValidationErrors({
      name: "",
      email: "",
      message: "",
    });
  };

  const sendEmail = async () => {
    if (!validateForm()) {
      return;
    }
    setLoading(true);
    try {
      const response = await fetch(process.env.REACT_APP_SEND_EMAIL_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          toEmails: ["info@velvmusic.com"],
          subject: "Landing Page| New Contact",
          htmlBody: `
            <p>Name: ${formValues.name ?? "-"}</p>
            <p>Email: ${formValues.email ?? "-"}</p>
            <p>Message: ${formValues.message ?? "-"}</p>
          `,
        }),
      });
      if (response?.ok) {
        setEmailSent(true);
      } else {
        setEmailSent(false);
      }
    } catch (error) {
      setEmailSent(false);
    } finally {
      setLoading(false);
      resetForm();
    }
  };

  useEffect(() => {
    if (emailSent !== null) {
      const timer = setTimeout(() => {
        setEmailSent(null);
      }, CONTACT_FORM_SENT_MESSAGE_DURATION);
      return () => clearTimeout(timer);
    }
  }, [emailSent]);

  return (
    <>
      <Header />
      <div className="bg-[radial-gradient(circle_at_bottom_left,_var(--tw-gradient-stops))] from-[#40137a8f] from-5% to-[#000] to-30%">
        <ScrollToTop />
        <div className="text-white mx-8 lg:mx-36 pt-14 text-2xl font-extrabold">
          <WidthWrapper>
            <h1>CONTACT</h1>
          </WidthWrapper>
        </div>

        <div>
          <div className="text-white mx-8 lg:mx-36 mt-14 text-5xl font-extrabold pt-3">
            <WidthWrapper>
              <div className="flex justify-center items-center gap-12">
                <h1 className="border-b-2 border-solid border-transparent pb-3 cursor-pointer text-3xl lg:text-5xl text-center">
                  Contact Us
                </h1>
              </div>
            </WidthWrapper>
          </div>
        </div>

        <div className="text-white mx-8 lg:mx-36 pb-32 mt-16 text-center leading-8">
          <WidthWrapper>
            <div className="flex flex-col max-w-[836px] mx-auto text-black">
              <input
                type="text"
                value={formValues?.name}
                placeholder="Name"
                className={`py-5 px-7 rounded-3xl placeholder-[#2D2D2D] border-[2px] ${
                  validationErrors?.name ? "border-red-500" : ""
                }`}
                onChange={(e) => {
                  setValidationErrors((prev) => ({ ...prev, name: "" }));
                  setFormValues((prev) => ({
                    ...prev,
                    name: e?.target?.value,
                  }));
                }}
              />
              {validationErrors?.name && (
                <p className="text-left mt-1 text-red-500">
                  {validationErrors?.name}
                </p>
              )}
              <input
                type="email"
                value={formValues?.email}
                placeholder="Email"
                className={`py-5 px-7 rounded-3xl placeholder-[#2D2D2D] mt-5 border-[2px] ${
                  validationErrors?.email ? "border-red-500" : ""
                }`}
                onChange={(e) => {
                  setValidationErrors((prev) => ({ ...prev, email: "" }));
                  setFormValues((prev) => ({
                    ...prev,
                    email: e?.target?.value,
                  }));
                }}
              />
              {validationErrors?.email && (
                <p className="text-left text-red-500 mt-1">
                  {validationErrors?.email}
                </p>
              )}
              <textarea
                placeholder="Type your message here"
                value={formValues?.message}
                className={`py-5 px-7 rounded-3xl placeholder-[#2D2D2D] resize-none h-48 mt-5 border-[2px] ${
                  validationErrors?.message ? "border-red-500" : ""
                }`}
                onChange={(e) => {
                  setValidationErrors((prev) => ({ ...prev, message: "" }));
                  setFormValues((prev) => ({
                    ...prev,
                    message: e?.target?.value,
                  }));
                }}
              />
              {validationErrors?.message && (
                <p className="text-left text-red-500 mt-1">
                  {validationErrors?.message}
                </p>
              )}
              <div className="flex flex-col lg:flex-row justify-between mt-8">
                <button
                  onClick={sendEmail}
                  className="relative rounded-full bg-purple text-white font-semibold px-5 py-3 lg:px-7 lg:py-4 text-xs lg:text-base w-full lg:w-40 h-[48px]"
                >
                  {loading ? (
                    <div className="absolute inset-0 flex items-center justify-center">
                      <img
                        src={loadingAnimation}
                        alt="loading"
                        className="w-4 h-4 brightness-[103%] invert-[99%] saturate-[6%] hue-rotate-[206deg] contrast-[104%]"
                      />
                    </div>
                  ) : (
                    <span className="flex items-center justify-center h-full">
                      Submit
                    </span>
                  )}
                </button>
                <div className="flex items-center gap-4 mt-6 lg:mt-0">
                  <SocialMediaIcon
                    url="https://www.instagram.com/velv.music/"
                    icon={instagramIcon}
                    alt="Instagram"
                  />
                  <SocialMediaIcon
                    url="https://www.facebook.com/Velvmusic/"
                    icon={facebookIcon}
                    alt="Facebook"
                  />
                  <SocialMediaIcon
                    url="https://youtube.com/@VELVMUSIC?si=o0qZuxcgtMJqOhgc"
                    icon={youtubeIcon}
                    alt="YouTube"
                  />
                </div>
              </div>
              {emailSent !== null && (
                <span
                  className={`font-normal text-[14px] leading-[18px] mb-[9px] mt-8 text-left ${
                    emailSent ? "text-[#A0FF28]" : "text-[#FF0000]"
                  }`}
                >
                  {emailSent
                    ? "Your message has been successfully sent. Our team will get back to you as soon as possible. Thank you for reaching out to us!"
                    : "Something went wrong with the servers. Please try again later. We apologize for the inconvenience."}
                </span>
              )}
            </div>
          </WidthWrapper>
        </div>
      </div>
    </>
  );
}

export default Contact;

function SocialMediaIcon({ url, icon, alt }) {
  return (
    <a href={url} target="_blank" rel="noreferrer">
      <img src={icon} alt={alt} className="cursor-pointer" />
    </a>
  );
}
