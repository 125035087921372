import { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { Header } from "components/shared/Header/Header";
import { MainWrapper } from "components/shared/MainWrapper/MainWrapper";
import { auth, db } from "config/firebase-config";
import { doc, updateDoc } from "@firebase/firestore";

const StripeVerification = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const ids = JSON.parse(localStorage.getItem("list"));
  const { id } = useParams();
  const navigate = useNavigate();

  const addItem = async () => {
    try {
      const dropsRef = doc(db, "users", auth?.currentUser?.uid);
      return await updateDoc(dropsRef, {
        ...user,
        stripeAccount: {
          ...user?.stripeAccount,
        },
      });
    } catch (error) {
      console.log("Error adding stripe", error);
    }
  };

  useEffect(() => {
    if (ids[id]) {
      (async () => {
        await addItem();
        let removeFromStorage = { ...ids };
        delete removeFromStorage[id];
        localStorage.setItem("list", JSON.stringify(removeFromStorage));
      })();
    } else {
      navigate("/");
    }
  }, []);

  return (
    <MainWrapper>
      <Header />

      <section className="flex flex-col items-center gap-10 mt-20">
        <div className="text-center text-gray-50 text-2xl lg:text-3xl font-bold lg:leading-[56px] lg:mt-8">
          Congratulations! <br />
          <p className="text-2xl mt-4 text-gray-600">
            You successfully verified your stripe 🎉
          </p>
        </div>
        <button
          onClick={() => {
            navigate("/");
          }}
          className="px-5 py-3 bg-violet-600 rounded-full text-gray-50 text-lg font-bold leading-snug w-full md:w-fit"
        >
          Check it out
        </button>
      </section>
    </MainWrapper>
  );
};

export default StripeVerification;
