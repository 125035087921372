import WidthWrapper from "landing/components/shared/WidthWrapper/WidthWrapper";
import cicleIcon from "landing/assets/icons/circle-icon.svg";

export function CreatorsText() {
  return (
    <div className="text-white mx-8 lg:mx-36 text-center pb-32">
      <WidthWrapper>
        <h1 className="text-2xl lg:text-4xl font-extrabold pb-7 pt-16">Creatives</h1>
        <p>
          One of the significant challenges for music creatives in the industry
          has been establishing the right connections. Now, you no longer need
          to live in a big city, know the right people, and waste time and money
          on travel. Velv democratizes and establishes the infrastructure for
          the future Brief and Pitch process. We achieve this by monitoring the
          market and utilizing an extensive international network. This approach
          enables our users to save time and money when using VELV. Browse
          through a multitude of music projects and pitch to the genre and
          market that aligns with your style. The motto is simple:
        </p>
        <h2 className="font-extrabold text-2xl mt-11 mb-16">
          The creatives focuses on the music while VELV facilitates the music
          briefs.
        </h2>
        <div className="text-left text-xl font-extrabold">
          <h1 className="uppercase">how does it work?</h1>
        </div>
        <div>
          <ul className="flex flex-col gap-10 text-xl font-semibold text-left mt-12 max-w-[893px]">
            <li className="flex justify-start items-center gap-6">
              <div className="flex relative justify-center items-center w-14 h-14 grow-0 flex-shrink-0 basis-14">
                <img src={cicleIcon} alt="circle icon" />
                <p className="absolute font-semibold text-3xl text-purple">
                  1
                </p>
              </div>
              A&R ́s and Music supervisor publishes their music briefs into the
              app
            </li>
            <li className="flex justify-start items-center gap-6">
              <div className="flex relative justify-center items-center w-14 h-14 grow-0 flex-shrink-0 basis-14">
                <img src={cicleIcon} alt="circle icon" />
                <p className="absolute font-semibold text-3xl text-purple">
                  2
                </p>
              </div>
              Producers/Songwriters submits their music through the app
            </li>
            <li className="flex justify-start items-center gap-6">
              <div className="flex relative justify-center items-center w-14 h-14 grow-0 flex-shrink-0 basis-14">
                <img src={cicleIcon} alt="circle icon" />
                <p className="absolute font-semibold text-3xl text-purple">
                  3
                </p>
              </div>
              <p>
                VELV reviews the submitted music with its own Screeners to
                ensure it aligns with the briefs ,then forwards the selected
                songs to the A&R/Music supervisors.
              </p>
            </li>
            <li className="flex justify-between items-center gap-6">
              <div className="flex relative justify-center items-center w-14 h-14 grow-0 flex-shrink-0 basis-14">
                <img src={cicleIcon} alt="circle icon" />
                <div className="absolute font-semibold text-3xl text-purple">
                  4
                </div>
              </div>
              The Producers/Songwriters receive updates on the various stages of
              the pitch progress through the App ́s dedicated tracking system.
            </li>
            <li className="flex justify-start items-center gap-6">
              <div className="flex relative justify-center items-center w-14 h-14 grow-0 flex-shrink-0 basis-14">
                <img src={cicleIcon} alt="circle icon" />
                <p className="absolute font-semibold text-3xl text-purple">
                  5
                </p>
              </div>
              When a song captures interest , it&rsquo;s then up to the A&R or the
              Music supervisor to reach out to the Producer/Songwriter
            </li>
          </ul>
        </div>
      </WidthWrapper>
    </div>
  );
}
